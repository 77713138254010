import { useEffect, useRef } from 'react';
import { ToastId } from '@chakra-ui/react';
import { ModalType } from 'containers/modals/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { closeModal, openModal } from 'slices/modalSlice';
import { getAvailableVersions, getCurrentVersion, setUpdateSoftwareStep } from 'slices/updateSlice';

import { closeToast, updateNotification } from 'components/common/Notification';

export const QUERY_INTERVAL = 10 * 1000;

export const useUpdate = () => {
  const { currentVersion, availableUpdate, updateStep, status } = useAppSelector(state => state.update.software);
  const { userData } = useAppSelector(state => state.user);
  const toastRef = useRef<{ id: ToastId; type: 'local' | 'remote' }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status.updateStatus === 'error') {
      dispatch(openModal({ type: ModalType.UPDATE_SYSTEM_ERROR, id: 'software' }));

      return () => {
        dispatch(closeModal(ModalType.UPDATE_SYSTEM_ERROR));
      };
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (userData && toastRef.current) {
      closeToast(toastRef.current.id);
      toastRef.current = undefined;
    }
  }, [userData]);

  useEffect(() => {
    if (!updateStep?.step || (userData && updateStep?.step === 'question')) {
      return;
    }
    dispatch(openModal({ type: ModalType.UPDATE_SYSTEM, id: 'software' }));

    return () => {
      dispatch(closeModal(ModalType.UPDATE_SYSTEM));
    };
  }, [dispatch, updateStep?.step, userData]);

  useEffect(() => {
    if (toastRef.current) {
      closeToast(toastRef.current.id);
    }

    if (!availableUpdate || userData) {
      return;
    }

    switch (availableUpdate.source) {
      case 'remote':
        if (!toastRef.current || toastRef.current.type !== 'remote') {
          toastRef.current = {
            id: updateNotification('update.tooltip.newInternetVersion1', 'update.tooltip.newInternetVersion2', () => {
              dispatch(
                setUpdateSoftwareStep({
                  step: 'question',
                  ...availableUpdate,
                }),
              );
            }),
            type: 'remote',
          };
        }
        break;
      case 'local':
        toastRef.current = {
          id: updateNotification(
            'update.tooltip.newPenDriveVersion1',
            `update.tooltip.newPenDriveVersion2`,

            () => {
              dispatch(
                setUpdateSoftwareStep({
                  step: 'question',
                  ...availableUpdate,
                }),
              );
            },
            {
              newVersion: availableUpdate.version,
            },
          ),
          type: 'local',
        };
        break;
    }
  }, [availableUpdate, currentVersion, dispatch, userData]);

  useEffect(() => {
    if (!userData && !updateStep) {
      // do not check for new version if updateStep is active
      dispatch(getCurrentVersion());

      const ref = setInterval(() => {
        dispatch(getAvailableVersions());
      }, QUERY_INTERVAL);

      return () => {
        clearInterval(ref);
      };
    }
  }, [userData, dispatch, updateStep]);
};
