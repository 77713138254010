import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { __ } from 'helpers/i18n';
import { WifiNetworkDTO } from 'types';
import * as yup from 'yup';

import { MainButton } from 'components/buttons/MainButton';
import { FormField } from 'components/form/FormField';
import { InputText } from 'components/form/InputText';
import { ShowPasswordIcon } from 'components/icons';
import { MainHeading } from 'components/texts/MainHeading';

import { ModalProps } from './Modal';
export interface WiFiConnectNetworkModalFormData {
  ssid: string;
  password?: string;
}

const createValidationSchema = (addHiddenNetwork: boolean) => {
  if (addHiddenNetwork) {
    return yup.object().shape({
      ssid: yup.string().required(__('validation.fieldIsRequired')),
      password: yup.string().required(__('validation.fieldIsRequired')),
    });
  } else {
    return yup.object().shape({
      password: yup.string().required(__('validation.fieldIsRequired')),
    });
  }
};

const WifiConnectNetworkModal = ({
  wifiNetwork,
  addHiddenNetwork = false,
  callback,
  close,
}: ModalProps & {
  wifiNetwork: Partial<WifiNetworkDTO> | undefined;
  addHiddenNetwork?: boolean;
}) => {
  const [hidePasswordMask, setHidePasswordMask] = useState(true);

  const methods = useForm<WiFiConnectNetworkModalFormData>({
    mode: 'onBlur',
    resolver: yupResolver(createValidationSchema(addHiddenNetwork)),
    defaultValues: {
      ssid: '',
      password: '',
    },
  });
  const { handleSubmit, setValue } = methods;

  useEffect(() => {
    if (wifiNetwork && wifiNetwork.ssid) {
      setValue('ssid', wifiNetwork.ssid);
    }
  }, [wifiNetwork, setValue]);

  const submit = (data: WiFiConnectNetworkModalFormData) => {
    if (callback) {
      callback(data);
    }

    close();
  };

  return (
    <Flex flexDirection="column" pt={4}>
      <MainHeading variant="subheading30" text="wifi.connectToNetwork" pb={5} />
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Box minH={28}>
            <Controller
              name="ssid"
              render={({ field: { onChange, value } }) => (
                <FormField mb={0} indicator variant="mainLabel" labelTxt="wifi.wifiName" name="ssid">
                  <InputText
                    width={96}
                    disabled={!addHiddenNetwork}
                    onChange={onChange}
                    value={value}
                    placeholder="placeholders.name"
                    data-testid="ssid-input"
                  />
                </FormField>
              )}
            />
          </Box>

          <Box minH={28}>
            <Controller
              name="password"
              render={({ field: { onChange, value } }) => (
                <FormField mb={0} variant="mainLabel" labelTxt="labels.password" name="password">
                  <Box position="relative">
                    <InputText
                      width={96}
                      pwd={hidePasswordMask}
                      onChange={onChange}
                      value={value}
                      placeholder="placeholders.password"
                      data-testid="password-input"
                    />
                    <Button
                      onClick={() => setHidePasswordMask(prev => !prev)}
                      position="absolute"
                      bg="transparent"
                      _hover={{ bg: 'transparent' }}
                      right={5}
                      top="50%"
                      transform="translate(0,-50%)"
                      zIndex="1"
                      data-testid="button-changes-visiblity-password"
                    >
                      <ShowPasswordIcon showingPassword={hidePasswordMask} />
                    </Button>
                  </Box>
                </FormField>
              )}
            />
          </Box>

          <Flex w="100%" justifyContent="space-around">
            <MainButton
              type="button"
              text="select.cancel"
              variant="smOutlinedPrimaryButton"
              onClick={() => {
                close();
              }}
              minW={32}
            />
            <MainButton
              data-testid="submit-button"
              type="submit"
              text="wifi.connect"
              variant="smPrimaryButton"
              width={32}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
export default WifiConnectNetworkModal;
