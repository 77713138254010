import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DeviceState = {
  serialNumber: string | null;
};

const initialState: DeviceState = {
  serialNumber: null,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setSerialNumber: (state, { payload }: PayloadAction<string>) => {
      state.serialNumber = payload;
    },
  },
});

export const { setSerialNumber } = deviceSlice.actions;

export default deviceSlice.reducer;
