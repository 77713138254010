export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const firstLetterToLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const includesAnyString = <T extends string>(sourceString: T, stringArray: T[]): boolean => {
  return stringArray.some(item => sourceString.includes(item));
};

export const includesAllStrings = <T extends string>(sourceStrings: T[], stringArray: T[]): boolean => {
  return stringArray.every(sourceString => sourceStrings.some(string => string === sourceString));
};

export const isStringNumber = (value: unknown): value is number => {
  return (typeof value === 'string' && !isNaN(+value)) || typeof value === 'number';
};
