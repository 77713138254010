import React, { useContext } from 'react';
import { ExoSession } from '@egzotech/exo-session';
import { useSignals } from '@preact/signals-react/runtime';
import { logger } from 'helpers/logger';
import {
  CAMExercise,
  DeviceType,
  GeneratedCAMLikeExerciseDefinition,
  isCAMExerciseDefinition,
} from 'libs/exo-session-manager/core';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

import { CAMInstanceContext } from './CAMInstanceContext';

export function CAMProvider({ children, errorContent }: { children: React.ReactNode; errorContent?: React.ReactNode }) {
  const session = useContextSelector(ExoGlobalContext, state => state.device.session);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);

  // FIXME Temporary cam implementation
  if (
    !session ||
    !(selectedDeviceType === 'sidra-leg' || selectedDeviceType === 'meissa-ot') ||
    !selectedProgram ||
    !isCAMExerciseDefinition(selectedProgram)
  ) {
    logger.error('CPMProvider', 'Provider was used without selected device or with invalid program type');

    return <>{errorContent}</>;
  }

  return (
    <CAMActiveProvider session={session} definition={selectedProgram} deviceType={selectedDeviceType}>
      {children}
    </CAMActiveProvider>
  );
}

interface CAMActiveProviderProps {
  session: ExoSession;
  definition: GeneratedCAMLikeExerciseDefinition;
  deviceType: DeviceType;
  children: React.ReactNode;
}

function CAMActiveProvider({ session, definition, deviceType, children }: CAMActiveProviderProps) {
  const selectedProgramId = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgramId);
  useSignals();

  const camExercise = React.useMemo(
    () => new CAMExercise(definition, session, selectedProgramId, deviceType),
    [definition, session, selectedProgramId, deviceType],
  );

  React.useEffect(() => {
    camExercise.init();
    return () => {
      camExercise.destroy();
    };
  }, [camExercise]);

  if (!camExercise.programData.cam.initialized.value) {
    return <></>;
  }

  return (
    <CAMInstanceContext.Provider value={camExercise}>
      <>{children}</>
    </CAMInstanceContext.Provider>
  );
}

export const useCAMProgram = () => {
  const camInstance = useContext(CAMInstanceContext);

  if (!camInstance) {
    throw new Error('useCAMProgram must be used within a CAMProvider');
  }

  return camInstance;
};
