import { useMemo } from 'react';
import { logger } from 'helpers/logger';
import { useAppSelector } from 'hooks/store';
import {
  CalibrationFlowStateIdentifier,
  CalibrationFlowStatesTypedData,
  filterStoredCalibrationDataForReplayableSteps,
  getDeviceTrainingCalibrationFlow,
  TrainingType,
} from 'libs/exo-session-manager/core';
import { useDevice, useStoredCalibrationData } from 'libs/exo-session-manager/react';
import { VersionAdapter, versionAdapter } from 'libs/version-adapter';
import { TrainingDataDTO } from 'slices/trainingReportSlice';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

/**
 *
 * @returns
 * - Combined calibration data combined from two sources:
 *  1) Patient Card
 *  2) Training Data if there is a repetition of the training
 *
 * - null, if data is not yet ready
 */

export const useCombinedInitialCalibrationData = () => {
  const { calibrationData } = useStoredCalibrationData();
  const { isRepeated, currentExercise, repeatedTraining } = useAppSelector(state => state.training);
  const { selectedDevice } = useDevice();
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);

  const combinedInitialCalibrationData = useMemo(() => {
    if (calibrationData === null) {
      return null;
    }

    if (!selectedDevice) {
      return null;
    }

    if (!selectedDeviceType) {
      throw new Error('DeviceType is required.');
    }

    if (!selectedProgram?.calibrationFlow) {
      return null;
    }

    const definedCalibrationFlowSteps = getDeviceTrainingCalibrationFlow(
      selectedDeviceType,
      selectedProgram?.calibrationFlow as TrainingType,
    ).states;

    let returnValue = filterStoredCalibrationDataForReplayableSteps(
      calibrationData,
      isRepeated,
      definedCalibrationFlowSteps,
    );
    if (isRepeated && repeatedTraining) {
      if (!returnValue) {
        returnValue = {} as CalibrationFlowStatesTypedData;
      }

      const exerciseData = (repeatedTraining.trainingData?.[currentExercise]?.data as TrainingDataDTO | undefined)
        ?.exercise;
      let baseTrainingCalibration: CalibrationFlowStatesTypedData = {};

      if (exerciseData && 'calibration' in exerciseData) {
        versionAdapter.chooseStrategy({ from: exerciseData?.version, to: VersionAdapter.CURRENT_VERSION });
        baseTrainingCalibration = versionAdapter.normalizeCalibrationFlowData(exerciseData.calibration);
      } else {
        logger.error(
          'useCombinedInitialCalibrationData',
          `Trying to repeat a training '${repeatedTraining.id}' and the exercise number '${currentExercise}' has no stored data. ` +
            `Without this data we cannot recall parameters of the exercise`,
        );

        // TODO: what now? show an error to the user and stop the training? or let the user continue?
        // This case will only appear if there is more than 1 exercise in training and not all data is saved.
      }

      // data from last training has higher priority than data from the patient cart
      const sideId =
        selectedDevice.type === 'meissa-ot' ? 'meissa-basing-side-selection' : 'leg-basing-leg-side-selection';
      if (baseTrainingCalibration[sideId]?.sideSelection !== returnValue[sideId]?.sideSelection) {
        // reset data from the patient card id that data is for different body side than data from the last training.
        returnValue = {};
      }

      for (const key of Object.keys(baseTrainingCalibration) as CalibrationFlowStateIdentifier[]) {
        if (key === 'emg-calibration') {
          continue;
        }
        const state = (baseTrainingCalibration as CalibrationFlowStatesTypedData)[key];
        returnValue = {
          ...returnValue,
          [key]: state,
        };
      }
    }
    return returnValue;
    // get only initial data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepeated, calibrationData === null, selectedProgram?.calibrationFlow]);

  return combinedInitialCalibrationData;
};
