import { DirectHubManager } from '@egzotech/exo-session';
import { Logger } from '@egzotech/universal-logger-js';
import { deepPickByTruthyValue } from 'helpers/object';

export class DirectHubMessageSender {
  private static _instance: DirectHubMessageSender;

  private _directHubManager = new DirectHubManager(`${process.env.REACT_APP_DIRECT_HUB_URL}`, 'json', {
    key: 'exo-clinic',
    secret: '',
  });

  private _connected = false;
  private _messages: Record<string, any> = {};

  private readonly _logger = Logger.getInstance('DirectHubMessageSender');

  private constructor() {
    if (!this._connected) {
      this._directHubManager
        .start()
        .then(() => {
          this._logger.debug('constructor', 'Connected to Direct Hub.');
          this._connected = true;
        })
        .catch(e => {
          this._logger.warn('constructor', 'Failed connecting to Direct Hub.', e);
        });
    }
  }

  get directHubManager() {
    return this._directHubManager;
  }

  get connected() {
    return this._connected;
  }

  get messages() {
    return this._messages;
  }

  static getInstance() {
    if (!DirectHubMessageSender._instance) {
      DirectHubMessageSender._instance = new DirectHubMessageSender();
    }

    return DirectHubMessageSender._instance;
  }

  dispose() {
    this._directHubManager.dispose();
    this._connected = false;
  }

  addMessage(json: Record<string, any>) {
    Object.assign(this._messages, deepPickByTruthyValue(json));
  }

  sendMessage(json: Record<string, any>) {
    if (!this._connected) {
      this._logger.debug('sendMessage', 'Cannot send message, because app is not connected to Direct Hub.');
      return false;
    }
    return this._directHubManager.send(json);
  }

  sendAppMessages() {
    if (!this._connected) {
      this._logger.debug('sendMessages', 'Cannot send messages, because app is not connected to Direct Hub.');
      return false;
    }
    if (Object.keys(this._messages).length === 0) {
      this._logger.debug('sendMessages', 'No messages to send');
      return false;
    }
    return this._directHubManager.send({ application: this._messages });
  }
}
