import { ExoSession } from '@egzotech/exo-session';
import { Recordable } from '@egzotech/exo-session/features/common';
import { ExoSensorForceFeature } from '@egzotech/exo-session/features/sensor-force';
import { Signal, signal } from 'helpers/signal';
import { SensorChange, SensorsName } from 'libs/exo-session-manager/core/types';

import { Triggerable } from '../common/Triggerable';

type ForceSensorElement = {
  feature: ExoSensorForceFeature;
  status: 'idle' | 'completed' | 'inprogress';
};

export const initSensorData = {
  knee: {
    current: 0,
  },
  toes: {
    current: 0,
  },
  heel: {
    current: 0,
  },
  torque: {
    current: 0,
  },
  extension: {
    current: 0,
  },
};
const generateInitData = (sensors: SensorsName[], initialAttributes: { [key: string]: number }) => {
  const bodyStructure: { [key: string]: object } = {};

  sensors.forEach(part => {
    bodyStructure[part] = { ...initialAttributes };
  });

  return bodyStructure;
};

export class SensorForcePlugin {
  private _features: Record<string, ForceSensorElement> = {};
  private _results: Record<string, Float32Array> = {};
  private _sensorData: SensorChange = structuredClone(initSensorData);
  onTareCompleted?: (values: Record<string, Float32Array>) => void;
  onSensorData?: (value: SensorChange) => void;
  onSensorAdditionalData?: (sensorName: SensorsName) => void;

  readonly signals = Object.keys(initSensorData).reduce(
    (prev, next) => ((prev[next as SensorsName] = signal(0, 'SensorForcePlugin.signals.' + next)), prev),
    {} as Record<SensorsName, Signal<number>>,
  );
  signalsAdditionalData = Object.keys(
    generateInitData(['knee', 'toes', 'heel', 'torque', 'extension'], {
      positiveThreshold: 0,
      negativeThreshold: 0,
      positiveMaxValue: 0,
      negativeMaxValue: 0,
    }),
  ).reduce(
    (prev, next) => (
      (prev[next as SensorsName] = {
        positiveThreshold: signal(0, 'SensorForcePlugin.signalsAdditionalData.' + next + '.positiveThreshold'),
        negativeThreshold: signal(0, 'SensorForcePlugin.signalsAdditionalData.' + next + '.negativeThreshold'),
        positiveMaxValue: signal(0, 'SensorForcePlugin.signalsAdditionalData.' + next + '.positiveMaxValue'),
        negativeMaxValue: signal(0, 'SensorForcePlugin.signalsAdditionalData.' + next + '.negativeMaxValue'),
      }),
      prev
    ),
    {} as Record<
      SensorsName,
      {
        positiveThreshold: Signal<number>;
        negativeThreshold: Signal<number>;
        positiveMaxValue: Signal<number>;
        negativeMaxValue: Signal<number>;
      }
    >,
  );

  get sensors() {
    return this.requiredSensors;
  }

  constructor(
    private _session: ExoSession,
    private requiredSensors: string[] = [],
    private readonly emgTriggers: Triggerable[] = [],
  ) {
    for (const sensorName of this.requiredSensors) {
      this._features[sensorName] = {
        feature: this._session.activate(ExoSensorForceFeature, { name: sensorName }),
        status: 'idle',
      };
    }
  }
  activateAdditionalSensor(sensorName: SensorsName) {
    this._features[sensorName] = {
      feature: this._session.activate(ExoSensorForceFeature, { name: sensorName }),
      status: 'idle',
    };
  }

  private checkTareCompleted() {
    for (const sensor in this._features) {
      if (this._features[sensor].status !== 'completed') {
        return false;
      }
    }
    return true;
  }

  dispose() {
    this.stopSensorForceMeasurement();
    for (const sensor in this._features) {
      this._features[sensor].feature.dispose();
    }
  }

  startTare() {
    const onForceHandler = (sensorName: string, values: Float32Array) => {
      this._results[sensorName] = values;
      this._features[sensorName].status = 'completed';
      this._features[sensorName].feature.onForce = undefined;

      if (this.checkTareCompleted()) {
        this.onTareCompleted?.(this._results);
      }
    };
    for (const sensor in this._features) {
      this._features[sensor].status = 'inprogress';
      this._features[sensor].feature.onForce = values => onForceHandler(sensor, values);
      this._features[sensor].feature.tare();
    }
  }
  startSensorForceMeasurement() {
    this._sensorData = structuredClone(initSensorData);
    if (this.onSensorData) {
      this.onSensorData(this._sensorData);
    }
    const onForceHandler = (sensorName: string, values: Float32Array) => {
      for (const key in this._sensorData) {
        if (key === sensorName) {
          const lastValuesArrayElement = Array.from(values).slice(-1)[0];

          this._sensorData[key as SensorsName] = {
            current: lastValuesArrayElement,
          };

          this.signals[key as SensorsName].value = lastValuesArrayElement;

          if (this.onSensorData) {
            this.onSensorData(this._sensorData);
          }
        }
      }

      this.emgTriggers?.forEach(trigger => trigger.trigger());
    };
    for (const sensor in this._features) {
      this._features[sensor].feature.onForce = values => onForceHandler(sensor, values);
    }
  }
  stopSensorForceMeasurement() {
    for (const sensor in this._features) {
      delete this._features[sensor].feature.onForce;
    }
  }

  getForce(sensor: string) {
    return this._sensorData[sensor as SensorsName].current;
  }

  getRecordables(): Recordable[] {
    return Object.values(this._features).map(v => v.feature);
  }

  getNoiseThreshold(sensor: string) {
    return this._features[sensor].feature.noiseThreshold;
  }

  isUnidirectional(sensor: SensorsName) {
    return this._features[sensor]?.feature.type === 'unidirectional';
  }

  setReliefMode(isEnabled: boolean) {
    for (const sensor in this._features) {
      if (this._features[sensor].feature.supports('relief')) {
        this._features[sensor].feature.setReliefMode(isEnabled);
      }
    }
  }
}
