import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  CalibrationFlow,
  CalibrationFlowDefinitionStateData,
  CalibrationFlowStateIdentifier,
} from 'libs/exo-session-manager/core';

export const useCalibrationFlowState = <T extends CalibrationFlowStateIdentifier>(flow: CalibrationFlow, state?: T) => {
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [canProceed, setCanProceed] = useState(flow.canProceed);
  const [canGoBack, setCanGoBack] = useState(flow.canGoBack);

  const setStateData = useCallback(
    (
      stateData:
        | CalibrationFlowDefinitionStateData<T>
        | ((prev: CalibrationFlowDefinitionStateData<T> | null) => CalibrationFlowDefinitionStateData<T>),
    ) => {
      if (typeof stateData === 'function') {
        flow.setStateData(stateData(flow.getStateData<T>(state)), state);
      } else {
        flow.setStateData(stateData, state);
      }
    },
    [flow, state],
  );

  const checkStateDataChange = useCallback(
    (_data: object | null, changedState: CalibrationFlowStateIdentifier | null) => {
      if (changedState === state) {
        forceUpdate();
      }
    },
    [state],
  );

  useEffect(() => {
    flow.events.addHandler('canProceedChange', setCanProceed);
    flow.events.addHandler('canGoBackChange', setCanGoBack);

    return () => {
      flow.events.removeHandler('canProceedChange', setCanProceed);
      flow.events.removeHandler('canGoBack', setCanGoBack);
    };
  }, [flow]);

  useEffect(() => {
    flow.events.addHandler('stateDataChange', checkStateDataChange);

    return () => {
      flow.events.removeHandler('stateDataChange', checkStateDataChange);
    };
  }, [flow, checkStateDataChange]);

  return [!flow.finished ? flow.getStateData(state) : null, setStateData, canProceed, canGoBack] as const;
};
