const shadows = {
  sm: '0 0.125rem 0.625rem #0000000D',
  smDark: '0 0.125rem 0.625rem #0000001A',
  smDarker: '0 0.125rem 0.625rem #00000033',
  md: '0 0.1875rem 0.875rem #00000029',

  alertShadow: '0px 0.1875rem 0.375rem #00000029',
};

export default shadows;
