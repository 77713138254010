import { useEffect, useState } from 'react';
import { errorNotification } from 'helpers/handleNotifications';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

import { useAppSelector } from './store';

export const useWifiNotification = () => {
  const [wasConnected, setWasConnected] = useState(false);
  const { connected } = useAppSelector(state => state.wifi);

  useEffect(() => {
    if (EGZOTechHostApi.instance?.options?.disableLostWifiNotification) {
      return;
    }

    if (!connected) {
      if (wasConnected) {
        errorNotification('wifi.notifications.lostWifiConnection');
      }
    } else {
      setWasConnected(true);
    }
  }, [connected, wasConnected]);
};
