import { cssVar } from '@chakra-ui/react';

import { parseStyleConfig } from './helpers';

const widthCssVar = cssVar('switch-track-width');
const heightCssVar = cssVar('switch-track-height');

const Switch = parseStyleConfig([], {
  baseStyle: {
    thumb: {
      shadow: 'smDark',
    },
  },
  sizes: {
    lg: {
      container: {
        [widthCssVar.variable]: '2.75rem',
        [heightCssVar.variable]: '1.25rem',
      },
    },
  },
});

export default Switch;
