import { useEffect } from 'react';
import {
  Center,
  Container,
  HStack,
  Image,
  ListItem,
  ModalBody,
  ModalContent,
  Spinner,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import errorImage from 'assets/images/StellaBIOConnectionError.svg';
import { useReconfigureBackendDetectMechanism } from 'hooks/useReconfigureBackendDetectMechanism';
import { useDevice } from 'libs/exo-session-manager/react';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ConnectionErrorModalProps } from './ConnectionErrorModal';

export const StellaBIOConnectionErrorModal = ({ onContinue, onExit, close }: ConnectionErrorModalProps) => {
  const device = useDevice();

  useEffect(() => {
    if (device.selectedDevice?.connectionStatus === 'connected') {
      onContinue();
      close();
    }
  }, [close, device.selectedDevice?.connectionStatus, onContinue]);

  // When we display this dialog we want automatically to reconfigure backend device detection
  useReconfigureBackendDetectMechanism();

  return (
    <ModalContent maxW={{ base: 'lg', 'xl+': 'xl', '2xl': '3xl' }} borderRadius="rLg" p="2">
      <ModalBody>
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="center"
          py="4"
        >
          <VStack flex="1 0" gap="3" justify="space-between">
            <Center>
              <MainHeading variant="subheading36" text="modals.stellaBIOConnectionError.heading" />
            </Center>
            <VStack pt={{ base: '3', '2xl': '5' }} pb={{ base: '6', '2xl': '10' }}>
              <Image src={errorImage} maxH={{ base: '2xs', '2xl': 'md' }} maxW={{ base: '2xs', '2xl': 'md' }} mt="4" />
              <HStack mt={{ base: '6', '2xl': '9' }} mb={{ base: '6', '2xl': '9' }} gap={{ base: '3', '2xl': '5' }}>
                <Spinner thickness="1" speed="0.65s" emptyColor="white" color="egzotechPrimaryColor" boxSize="6" />
                <TranslateText
                  variant="openSans24Bold"
                  color="egzotechPrimaryColor"
                  text="modals.stellaBIOConnectionError.connecting"
                />
              </HStack>
              <UnorderedList>
                <ListItem>
                  <TranslateText variant="openSans24" text="modals.stellaBIOConnectionError.description1" />
                </ListItem>
                <ListItem mt="5">
                  <TranslateText variant="openSans24" text="modals.stellaBIOConnectionError.description2" />
                </ListItem>
                <ListItem mt="5">
                  <TranslateText variant="openSans24" text="modals.stellaBIOConnectionError.description3" />
                </ListItem>
              </UnorderedList>
            </VStack>
            <MainButton
              text="modals.alerts.exit"
              variant="mdOutlinedPrimaryButton"
              onClick={() => {
                onExit?.('/');
                close();
              }}
              minW="32"
            />
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
