import { useEffect, useState } from 'react';
import { ExoSensorForceFeature } from '@egzotech/exo-session/features/sensor-force';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

import { GlobalChangeableData } from '../contexts/ExoGlobalContext';

export default function useDeviceState(deviceState: GlobalChangeableData['deviceState'], deviceManager: DeviceManager) {
  const [isDeviceDummy, setIsDeviceDummy] = useState(false);
  const autoConnectDeviceType = EGZOTechHostApi.instance?.options?.autoConnectDeviceType ?? null;

  useEffect(() => {
    const dummyDeviceFlag = localStorage.getItem('dummyDeviceFlag');
    if (dummyDeviceFlag) {
      const flag: boolean = JSON.parse(dummyDeviceFlag);
      setIsDeviceDummy(flag);
    }
  }, []);

  useEffect(() => {
    // Initialize the device manager so it knows if it should use dummy or not.
    deviceManager.init(isDeviceDummy);
    return () => {
      deviceManager.destroy();
    };
  }, [deviceManager, isDeviceDummy]);

  useEffect(() => {
    if (autoConnectDeviceType && !deviceState.wantConnectDeviceId) {
      const device = Object.values(deviceState.candidates).find(v => v.deviceType === autoConnectDeviceType);

      if (device) {
        deviceManager.autoConnect(device.id);
      }
    }
  }, [autoConnectDeviceType, deviceManager, deviceState.candidates, deviceState.wantConnectDeviceId]);

  const hasSensorForceFeature = deviceManager.selectedDevice?.session?.features.includes('sensor-force');

  useEffect(() => {
    if (
      hasSensorForceFeature &&
      typeof DeviceManager.pecStandardDeviation.value === 'undefined' &&
      deviceManager.selectedDevice?.type === 'meissa-ot'
    ) {
      const sensorForceFeature = deviceManager.selectedDevice?.session?.activate(ExoSensorForceFeature, {
        name: 'torque',
      });

      if (sensorForceFeature) {
        sensorForceFeature
          .getPECStandardDeviation(5000)
          .then(result => (DeviceManager.pecStandardDeviation.value = result))
          .catch(err =>
            DeviceManager.logger.error(
              'useDeviceState',
              'Error occurred when requesting device for pec standard deviation: ',
              err,
            ),
          )
          .finally(() => sensorForceFeature.dispose());
      }
    }
  }, [deviceManager.selectedDevice?.session, deviceManager.selectedDevice?.type, hasSensorForceFeature]);

  const setDummyDevice = (dummyDeviceFlag: boolean) => {
    localStorage.setItem('dummyDeviceFlag', JSON.stringify(dummyDeviceFlag));
    setIsDeviceDummy(dummyDeviceFlag);
  };

  return [isDeviceDummy, setDummyDevice] as const;
}
