import { useEffect } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from 'helpers/signal';
import { ChartDataSource } from 'libs/chart-datasources';
import { useEMSExerciseInstance } from 'libs/exo-session-manager/react/providers/EMSProvider';

import { TimelineChart } from 'components/timeline-charts/TimelineChart';

export const ElectrostimTimelineChart = (props: {
  chartDataSource: ChartDataSource;
  options: { windowWidth?: number };
}) => {
  const { chartDataSource, options = {} } = props;
  const { windowWidth = 20 } = options;
  useSignals();
  const emsExercise = useEMSExerciseInstance();
  const time = useSignal<number>(0, 'ElectrostimTimelineChart.time');

  useEffect(() => {
    const handler = setInterval(() => {
      time.value = emsExercise.emsFeature ? emsExercise.emsFeature.progress() / (1000 * 1000) : 0;
    }, 50);

    return () => {
      clearTimeout(handler);
    };
  }, [emsExercise.emsFeature, time]);

  return (
    <TimelineChart
      chartDataSource={chartDataSource}
      time={time}
      options={{
        shiftDatasetsOnAxisY: true,
        windowWidth: windowWidth,
      }}
    />
  );
};
