import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function sideSelectionFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'meissa-basing-side-selection': {
      prev,
      next,
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
  } as const;
}
