export const isInReliefRange = (currentPosition: number): boolean => {
  const moduloPosition = Math.abs(currentPosition) % 360;
  const range1Start = 21;
  const range1End = 159;
  const range2Start = 201;
  const range2End = 339;

  return (
    (moduloPosition >= range1Start && moduloPosition <= range1End) ||
    (moduloPosition >= range2Start && moduloPosition <= range2End)
  );
};
