import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, ChakraProps, useBreakpointValue } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import { ReadonlySignal, useComputed, useSignal } from 'helpers/signal';

import { SVGBackgroundBar } from './SVGBackgroundBar';
import { SVGLevelBar } from './SVGLevelBar';
import { SVGSlider } from './SVGSlider';
import { SVGYAxis } from './SVGYAxis';

export interface EMGBarTemplate {
  barWidth: number;
  yAxisWidth: number;
  xOffset: number;
  containerWidth: ChakraProps['width'];
  viewBoxWidth: number;
  collapseThreshold?: boolean;
  xShift?: number;
}

export const emgBarTemplates = {
  narrow: {
    viewBoxWidth: 208,
    yAxisWidth: 88,
    barWidth: 46,
    xOffset: 56,
    containerWidth: {
      base: '36',
      'xl+': '44',
      '2xl': '52',
    },
    xShift: -3,
  },
  // If you have any suggestion how to name it better,
  // Just tell us on the R&D Chat. Thank you.
  'narrow-emg-view': {
    viewBoxWidth: 211,
    yAxisWidth: 120,
    barWidth: 66,
    xOffset: 59,
    containerWidth: {
      base: '36',
      'xl+': '42',
      '2xl': '52',
    },
    collapseThreshold: true,
    xShift: -6,
  },
  default: {
    viewBoxWidth: 288,
    yAxisWidth: 157,
    barWidth: 115,
    xOffset: 56,
    containerWidth: {
      base: '48',
      'xl+': '52',
      '2xl': '64',
    },
    xShift: 0,
  },
  'default-emg-view': {
    viewBoxWidth: 288,
    yAxisWidth: 220,
    barWidth: 140,
    xOffset: 66,
    containerWidth: {
      base: '48',
      'xl+': '52',
      '2xl': '64',
    },
    xShift: -7,
  },
} satisfies Record<string, EMGBarTemplate>;

export type EMGBarTemplateName = keyof typeof emgBarTemplates;

export const EMGBar = memo(
  (props: {
    channel: number;
    currentValue: ReadonlySignal<number>;
    maxValue: ReadonlySignal<number>;
    thresholdLevel: number;
    onLevelChange?: (level: number) => void;
    isDisabled?: boolean;
    isThresholdDisabled?: boolean;
    color?: string;
    lightColor?: string;
    thresholdColor?: string;
    collapseThreshold?: boolean;
    barIcon?: ReactNode;
    emgBarTemplate?: EMGBarTemplate;
    heightInset?: number;
  }) => {
    const {
      channel,
      currentValue,
      maxValue,
      onLevelChange,
      thresholdLevel,
      isDisabled,
      isThresholdDisabled,
      color,
      lightColor,
      thresholdColor,
      collapseThreshold,
      barIcon,
      emgBarTemplate: template = emgBarTemplates['default'],
      heightInset = 0,
    } = props;
    useSignals();
    const [currentMaxValue, setCurrentMaxValue] = useState(maxValue.value);
    const [isRescaling, setIsRescaling] = useState(false);
    const maxValueRef = useRef(currentMaxValue);
    const primaryColor = color ?? colors.channel[(channel + 1) as Channel];
    const primaryLightColor = lightColor ?? colors.channel.light[(channel + 1) as Channel];

    const updateCurrentMax = useCallback(() => {
      setCurrentMaxValue(v => {
        const delta = v - maxValueRef.current;
        return Math.abs(delta) < 1 ? maxValueRef.current : maxValueRef.current + delta / 2;
      });
    }, []);

    useEffect(() => {
      if (!isRescaling || isDisabled) {
        return;
      }
      const frameId = requestAnimationFrame(updateCurrentMax);

      return () => {
        cancelAnimationFrame(frameId);
      };
    }, [isRescaling, currentMaxValue, updateCurrentMax, isDisabled]);

    useEffect(() => {
      setIsRescaling(maxValue.value !== currentMaxValue);
      maxValueRef.current = maxValue.value;
    }, [currentMaxValue, maxValue.value]);

    // These values strictly reflects dimensions in mockup
    // yHeight is vertical dimenstion of the bar and
    // ySpace is space between top of SVG and the bar beginning
    const yHeight = 438.4 - heightInset;
    const ySpace = 33.5;
    const defaultBackgroundLevelBar = useSignal(100, 'EMGBar.defaultBackgroundLevelBar');
    const svgBackgroundBar = useComputed(() => {
      const value = Math.abs(currentValue.value);
      return maxValue.value ? (value / maxValue.value) * 100 : 0;
    }, 'EMGBar.svgBackgroundBar');

    const { barWidth, containerWidth, xShift, xOffset, viewBoxWidth, yAxisWidth } = template;
    const isSliderCollapsed = collapseThreshold && isThresholdDisabled;

    const barReduction = 115 - barWidth;

    const viewBox = useBreakpointValue({
      base: `-32 0 ${viewBoxWidth} ${490 - heightInset}`,
      '2xl': `-32 0 ${viewBoxWidth} ${490 - heightInset}`,
    });

    return (
      <Box w={containerWidth} boxSizing="content-box">
        <svg viewBox={viewBox} style={{ position: 'relative', left: `${xShift}%`, overflow: 'visible' }}>
          {!isDisabled && (
            <SVGYAxis
              width={yAxisWidth}
              yAxisMaxNoLines={10}
              maxValue={(currentMaxValue * (yHeight + ySpace)) / yHeight}
              yHeight={ySpace + yHeight}
            />
          )}
          <g>
            <SVGBackgroundBar
              width={barWidth}
              xOffset={xOffset}
              yOffset={ySpace}
              yHeight={yHeight}
              level={defaultBackgroundLevelBar}
              color={isDisabled ? colors.gray[300] : primaryLightColor}
            />
            {!isDisabled && (
              <SVGBackgroundBar
                width={barWidth}
                xOffset={xOffset}
                yOffset={ySpace}
                yHeight={yHeight}
                level={svgBackgroundBar}
                color={primaryColor}
              />
            )}
          </g>
          <g transform={`translate(${xOffset + barWidth / 2 - 24} 217)`}>{barIcon}</g>
          {!isDisabled && !isThresholdDisabled && (
            <SVGLevelBar
              width={196 - barReduction}
              xOffset={29}
              yOffset={ySpace}
              yHeight={yHeight}
              level={thresholdLevel}
            />
          )}
          {!isSliderCollapsed && (
            <SVGSlider
              level={thresholdLevel}
              xOffset={viewBoxWidth - 100}
              yOffset={ySpace}
              yHeight={yHeight}
              color={thresholdColor ?? primaryColor}
              onLevelChange={onLevelChange}
              isDisabled={isDisabled || isThresholdDisabled}
            />
          )}
        </svg>
      </Box>
    );
  },
);

EMGBar.displayName = 'EMGBar';
