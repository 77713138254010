import { useMemo } from 'react';
import { Center, Container, HStack, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from '../Modal';

interface WarningModalProps extends ModalProps {
  heading: string;
  description: string;
  description2?: string;
  imageSrc: string;
  acceptRiskButton: boolean;
}

export const WarningModal = ({
  heading,
  description,
  description2,
  acceptRiskButton = false,
  imageSrc,
  close,
}: WarningModalProps) => {
  const actions = useMemo(() => {
    const actions: {
      text: string;
      action: () => void;
    }[] = [];
    if (acceptRiskButton) {
      actions.push({
        text: 'modals.alerts.staffTrainingWarning.action',
        action: () => {
          close();
        },
      });
    }
    return actions;
  }, [acceptRiskButton, close]);

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="2">
      <ModalBody borderWidth="1" borderStyle="solid" borderColor="warningColor" borderRadius="rLg">
        <Container
          variant="modalBox"
          py="4"
          px="0"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
        >
          <VStack justify="space-between" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text={heading} />
            </Center>
            <Image src={imageSrc} maxH={{ base: '3xs', '2xl': 'sm' }} maxW={{ base: '3xs', '2xl': 'sm' }} mt="4" />
            <VStack gap="0" textAlign={'center'}>
              {description && <TranslateText variant="openSans24" text={description} mt="2" />}
              {description2 && <TranslateText variant="openSans24Bold" text={description2} mt="4" />}
            </VStack>
            <HStack w="full" justifyContent="space-evenly" mt="6">
              {actions.map(item => (
                <MainButton
                  key={item.text}
                  text={item.text}
                  isDisabled={false}
                  variant="mdWarningButton"
                  onClick={item.action}
                  minW="32"
                />
              ))}
            </HStack>
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
