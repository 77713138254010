import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { Box, BoxProps, ChakraProps, Flex, FormControl } from '@chakra-ui/react';
import { SerializedStyles } from '@emotion/serialize';
import { FormFieldProvider } from 'hooks/forms/useFormFieldContext';
import { FormFieldContextType } from 'types';

import { LabelText } from 'components/texts/LabelText';

import { FieldHelpBox } from './FieldHelpBox';

interface Props extends FormFieldContextType, BoxProps {
  variant?: string;
  labelTxt?: string;
  labelSize?: ChakraProps['fontSize'];
  helperTxt?: string;
  errorTxt?: string | null;
  isDisabled?: boolean;
  formFieldCustomCSS?: SerializedStyles;
  noHelpBox?: boolean;
  uniqueErrorTxt?: string;
  indicator?: boolean;
  customHeight?: boolean;
  isCheckbox?: boolean;
  setIsInvalid?: Dispatch<SetStateAction<boolean>>;
}

export const FormField: FC<Props> = ({
  name,
  isRequired,
  labelTxt,
  labelSize = 'xl',
  helperTxt,
  errorTxt,
  placeholder,
  children,
  isDisabled,
  id,
  formFieldCustomCSS,
  noHelpBox,
  uniqueErrorTxt,
  indicator,
  customHeight,
  isCheckbox,
  setIsInvalid,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    (get(errors, name)?.message ?? errorTxt) && setIsInvalid?.(true);
    // FIXME: for older code, lint warnings have been disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const { justifyContent, flexDirection, ...rest } = props;

  return (
    <Flex justifyContent={justifyContent ?? 'flex-end'} flexDirection={flexDirection ?? 'column'} mb="1" {...rest}>
      <FormFieldProvider value={{ name, placeholder, id }}>
        <FormControl
          isRequired={isRequired}
          isDisabled={isDisabled}
          isInvalid={get(errors, name)?.message ?? errorTxt}
          css={formFieldCustomCSS}
        >
          {labelTxt === '' ? (
            <Box fontSize={labelSize} h="1.5em" mb="1" />
          ) : (
            labelTxt && <LabelText size={labelSize as string} text={labelTxt} indicator={indicator} />
          )}
          {children}
          {!noHelpBox && (
            <FieldHelpBox
              name={name}
              errors={errors}
              helperTxt={helperTxt}
              errorTxt={errorTxt}
              uniqueErrorTxt={uniqueErrorTxt}
              customHeight={customHeight}
              isCheckbox={isCheckbox}
              fontSize={labelSize === 'xl' ? 'md' : (labelSize as string)}
            />
          )}
        </FormControl>
      </FormFieldProvider>
    </Flex>
  );
};
