import React from 'react';
import { Skeleton, SkeletonCircle, Tr } from '@chakra-ui/react';
import { TableItem } from 'views/specialist/patients/+patientId/trainings/recent/_components/TableItem';

const RecentTrainingSkeleton = () => {
  return (
    <Tr width="100%" height="24">
      <TableItem>
        <SkeletonCircle m="0 auto" startColor="skeletonStartColor" endColor="skeletonEndColor" width={14} height={14} />
      </TableItem>
      <TableItem p="0" px="2" width="full">
        <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={9} width="100%" />
      </TableItem>
      <TableItem p="0" px="2" width="full">
        <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={9} width="56" />
      </TableItem>
      <TableItem p="0" px="2" width="full">
        <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={9} width="32" />
      </TableItem>
      <TableItem px="4">
        <SkeletonCircle m="0 auto" startColor="skeletonStartColor" endColor="skeletonEndColor" width={14} height={14} />
      </TableItem>
      <TableItem px="4">
        <SkeletonCircle m="0 auto" startColor="skeletonStartColor" endColor="skeletonEndColor" width={14} height={14} />
      </TableItem>
    </Tr>
  );
};
export default RecentTrainingSkeleton;
