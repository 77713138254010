import { FC, ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';
import Polyglot from 'node-polyglot';

export interface TranslateProps extends TextProps {
  text?: string;
  fontStyle?: string;
  children?: ReactNode;
  replace?: Polyglot.InterpolationOptions;
  as?: 'span' | 'p';
}

export const TranslateText: FC<TranslateProps> = ({ text, fontStyle, variant, children, replace, as, ...props }) => (
  <Text as={as} {...props} fontStyle={fontStyle} variant={variant && variant}>
    {text ? __(text, replace) : ''}
    {children}
  </Text>
);
