import { createStandaloneToast, ToastId } from '@chakra-ui/react';
import theme from 'config/theme';
import Polyglot, { InterpolationOptions } from 'node-polyglot';

import { Alert } from './Alert';

import './style.scss';

export type toastStatuses = 'success' | 'error' | 'info' | 'warning';
export const { toast, ToastContainer } = createStandaloneToast({ theme });

export const notification = (title: string, status: toastStatuses, replace?: InterpolationOptions) => {
  return toast({
    status,
    duration: 3000,
    isClosable: true,
    position: 'bottom-right',
    containerStyle: {
      margin: theme.sizes['5'],
    },
    render: ({ onClose }: { onClose: () => void }) => (
      <Alert onClose={onClose} title={title} status={status} replace={replace} />
    ),
  });
};

export const updateNotification = (
  title: string,
  subtitle?: string,
  callback?: () => void,
  replace?: Polyglot.InterpolationOptions,
): ToastId => {
  return toast({
    status: 'info',
    duration: 100000,
    isClosable: false,
    position: 'bottom-left',
    containerStyle: {
      margin: theme.sizes['5'],
    },
    render: ({ onClose }: { onClose: () => void }) => {
      const closeHandler = () => {
        onClose();
        callback?.();
      };
      return (
        <Alert
          title={title}
          subtitle={subtitle}
          replace={replace}
          status="update"
          isClosableByClick={true}
          isClosableByX={false}
          onClose={closeHandler}
        />
      );
    },
  });
};

export const closeToast = (toastId: ToastId) => {
  toast.close(toastId);
};
