import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';

export const facilitySpecialistsApi = createApi({
  reducerPath: 'facilitySpecialistsApi',
  baseQuery: fetchBaseQuery({
    fetchFn: queryFetchFn,
    baseUrl: API_URL,
  }),
  endpoints: builder => ({
    checkIfInvititaionTokenIsActive: builder.query<void, string>({
      query: invitationToken => `facility-specialists/invitations/activity/${invitationToken}`,
    }),
  }),
});

export const { useLazyCheckIfInvititaionTokenIsActiveQuery } = facilitySpecialistsApi;
