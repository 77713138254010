import { logger } from 'helpers/logger';
import { Signal, signal } from 'helpers/signal';
import { AppSettingsPayload, exoClinicApi } from 'services/ExoClinicBackendApi';
import { Language } from 'types/language';

export type LocalStorageMode = 'local' | 'api' | undefined;

export type LocalStoragePayload = {
  language: Signal<Language | null>;
  disableModuleIdentificationOnLost: Signal<boolean>;
  disableModuleIncompatibilityCheck: Signal<boolean>;
  disableExtensionDetached: Signal<boolean>;
};

export class LocalStorageManager {
  static mode: LocalStorageMode = undefined;
  static payload: LocalStoragePayload = {
    language: signal<Language | null>(null, 'LocalStorageManager.language'),
    disableModuleIdentificationOnLost: signal<boolean>(false, 'LocalStorageManager.disableModuleIdentificationOnLost'),
    disableModuleIncompatibilityCheck: signal<boolean>(false, 'LocalStorageManager.disableModuleIncompatibilityCheck'),
    disableExtensionDetached: signal<boolean>(false, 'LocalStorageManager.disableExtensionDetached'),
  };

  constructor() {
    this.initializeLocalStorage();
    Object.values(LocalStorageManager.payload).forEach(v =>
      v.subscribe(() => {
        this.updateLocalStorage();
      }),
    );
  }

  /**
   * Defines the localStorage operation mode and obtain initial data
   */
  async initializeLocalStorage() {
    try {
      this.parseResponse(await exoClinicApi.device.localStorage.get());
      LocalStorageManager.mode = 'api';
      logger.info('LocalStorageManager', 'Local Storage initialized. Using mode: ', LocalStorageManager.mode);
    } catch (err: unknown) {
      const errorCode = (err as { response: Response }).response.status;

      if (errorCode >= 500) {
        logger.error('Backend store:', 'Backend local-storage returns 5xx, trying again...');
        setTimeout(() => {
          this.initializeLocalStorage();
        }, 1000);
      }

      if (errorCode === 404) {
        logger.info('Backend store:', "Backend local-storage is not available, switching to browser's local storage");
        LocalStorageManager.mode = 'local';
        logger.info('LocalStorageManager', 'Local Storage initialized. Using mode:', LocalStorageManager.mode);
        this.getLocalStorage();
      }
    }
  }

  /**
   * Gets current data from proper local storage source and assigns values to appropriate signals
   */
  async getLocalStorage() {
    switch (LocalStorageManager.mode) {
      case 'local':
        this.parseResponse(JSON.parse(localStorage.getItem('appSettings') ?? '{}'));
        break;
      case 'api':
        this.parseResponse(await exoClinicApi.device.localStorage.get());
        break;
    }
  }

  /**
   * Updates local storage source basing on current data in signals
   */
  async updateLocalStorage() {
    const newState = Object.entries(LocalStorageManager.payload).reduce((acc, [key, val]) => {
      return { ...acc, [key]: val.peek() };
    }, {});

    switch (LocalStorageManager.mode) {
      case 'local':
        localStorage.setItem('appSettings', JSON.stringify(newState));
        break;
      case 'api':
        await exoClinicApi.device.localStorage.update(newState);
        break;
    }
  }

  /**
   * Assigns values to signals
   *
   * @param response Local Storage payload received from source (local or api)
   */
  parseResponse(response: AppSettingsPayload) {
    Object.entries(response).forEach(([key, value]) => {
      const propSignal = LocalStorageManager.payload[key as keyof LocalStoragePayload];

      if (propSignal === undefined) {
        logger.warn(
          'LocalStorageManager.parseResponse',
          `There is a property: ${key} in local storage which have not defined own signal in LocalStorageManager`,
        );
        return;
      }

      propSignal.value = value;
    });
  }
}
