import React, { useCallback } from 'react';
import DeveloperBar from 'libs/exo-session-manager/dev-tools/DeveloperBar';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { useContextSelector } from 'use-context-selector';

import { DeviceManager } from '../../core/global/DeviceManager';
import { ExerciseSelector } from '../../core/global/ExerciseSelector';
import ExoGlobalContext, { initialGlobalChangeableData } from '../contexts/ExoGlobalContext';
import useDeviceState from '../hooks/useDeviceState';
import useEventToReducer from '../hooks/useEventToReducer';
import { globalReducer } from '../reducers/globalReducer';

const programSelector = new ExerciseSelector();
const deviceManager = new DeviceManager();

// Store deviceManager in window to use gave Cypress tests possibility to change dummy device parameters directly
(window as any).deviceManager = deviceManager;

export function ExoGlobalProvider({ children }: { children: React.ReactNode }) {
  const [{ deviceState, programSelection }, dispatch] = React.useReducer(globalReducer, initialGlobalChangeableData);

  useEventToReducer(programSelector.events, 'onSelectedExerciseChange', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceActivate', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceInit', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceDestroy', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceChange', dispatch);
  useEventToReducer(deviceManager.events, 'onCandidatesChange', dispatch);
  useEventToReducer(deviceManager.events, 'onStoredCandidatesChange', dispatch);
  useEventToReducer(deviceManager.events, 'onSelectedDeviceIdChange', dispatch);
  useEventToReducer(deviceManager.events, 'onWantConnectDeviceIdChange', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceActiveChange', dispatch);
  useEventToReducer(deviceManager.events, 'onDeviceConnectionErrorChange', dispatch);
  useEventToReducer(deviceManager.events, 'onModuleIdentify', dispatch);
  useEventToReducer(deviceManager.events, 'onModuleLost', dispatch);
  useEventToReducer(deviceManager.events, 'onTareDevice', dispatch);

  const [dummyDevice, setDummyDevice] = useDeviceState(deviceState, deviceManager);

  const selectProgram = useCallback((programId: string) => programSelector.selectExercise(programId), []);
  const endProgram = useCallback(() => programSelector.endExercise(), []);

  const activate = useCallback(() => deviceManager.activate(), []);
  const deactivate = useCallback(() => deviceManager.deactivate(), []);
  const requestCandidate = useCallback(async () => deviceManager.requestCandidate(), []);
  const selectDevice = useCallback(async (deviceId: string) => deviceManager.selectDevice(deviceId), []);
  const resetConnectionError = useCallback(() => deviceManager.resetConnectionError(), []);
  const isConnectionRestored = useCallback(() => deviceManager.isConnectionRestored(), []);
  const setDeviceAsTared = useCallback(() => deviceManager.setTareDevicaStatus(), []);
  const disposeSelectedDevice = useCallback(() => deviceManager.disposeSelectedDevice(), []);

  const developerBarEnabled =
    process.env.REACT_APP_DEVELOPER_BAR === 'true' || EGZOTechHostApi.instance?.options?.enableDeveloperBar;

  return (
    <ExoGlobalContext.Provider
      value={{
        programSelection: {
          programs: programSelector.exercises,
          selectedProgramId: programSelection.selectedProgramId,
          selectedProgram: programSelector.selectedExercise,
          type: programSelector.selectedExercise?.type,
          definition: programSelector.selectedExercise,
          isValidProgram: programSelector.isValidExercise,
          selectProgram,
          endProgram,
        },
        device: {
          ...deviceState,
          selectedDevice: deviceManager.selectedDevice ? { ...deviceManager.selectedDevice } : null,
          session: deviceManager.session,
          lastUsedDevice: deviceManager.getLastUsedDevice(dummyDevice),
          activate,
          deactivate,
          requestCandidate,
          selectDevice,
          resetConnectionError,
          isConnectionRestored,
          setDeviceAsTared,
          disposeSelectedDevice,

          get deviceType() {
            return this.selectedDevice?.type ?? this.lastUsedDevice?.type ?? 'unspecified';
          },
        },
      }}
    >
      {developerBarEnabled && (
        <DeveloperBar
          active={deviceState.active}
          deviceManager={deviceManager}
          dummyDevice={dummyDevice}
          setDummyDevice={setDummyDevice}
        />
      )}
      {children}
    </ExoGlobalContext.Provider>
  );
}

export const useProgramSelection = () => {
  const programSelection = useContextSelector(ExoGlobalContext, state => state.programSelection);

  if (!programSelection) {
    throw new Error('useProgramSelection must be used within a ExoGlobalProvider');
  }

  return programSelection;
};
