import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/provider';
import { useSignals } from '@preact/signals-react/runtime';
import CONFIG from 'config/app';
import { setDefaultOptions } from 'date-fns';
import { handleError } from 'helpers/handleError';
import { errorNotification } from 'helpers/handleNotifications';
import { consoleLogBackEndVersion, consoleLogFrontEndVersion } from 'helpers/infoController';
import { getDateFnsLocale } from 'helpers/language';
import { useAppDispatch } from 'hooks/store';
import { LocalStorageManager } from 'libs/exo-session-manager/core/global/LocalStorageManager';
import { ExoGlobalProvider } from 'libs/exo-session-manager/react';
import { SignalRProvider } from 'libs/exo-session-manager/react/providers/SignalRProvider';
import { Router, ScanningRouter } from 'routes/Router';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { exoClinicApi, ResponseError } from 'services/ExoClinicBackendApi';
import { setWifiDisconnect, setWifiStatus } from 'slices/wifiSlice';
import { Language } from 'types/language';

import { FullPageSpinner } from 'components/common/FullPageSpinner';
import { ToastContainer } from 'components/common/Notification';

import 'helpers/session';
// chinese fonts and special character's fonts
import '@fontsource/noto-sans-sc';
import '@fontsource/noto-sans-tc';
import '@fontsource-variable/noto-sans-jp';

import { store } from './config/store';
import theme from './config/theme';
import { i18n } from './helpers/i18n';

import '@fontsource/exo/600.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
//other css
import 'react-simple-keyboard/build/css/index.css';
import 'components/form/CreateConditionsCheckbox/createConditionsCheckbox.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'components/common/DatePicker/datePickerStyles.css';

if (EGZOTechHostApi.instance?.options?.debugSignals) {
  console.log(
    'Signals debug is active. Instructions for using debugging:\n' +
      '  - in DevTools enable log level `Verbose` to see what signals are being created\n' +
      '  - type `showSignals()` in console to see all existing signals and their values\n' +
      '  - type `observeSignals()` to enable observing signals value changes, type `observeSignals(false)` to disable this behavior\n',
  );
}

// Local Storage initialization
const _localStorage = new LocalStorageManager();

exoClinicApi.addGlobalErrorHandler((reason: unknown) => {
  if (reason instanceof ResponseError) {
    handleError(reason.response);
  }
  console.error(reason);
});

const checkWifiStatus = async () => {
  try {
    await exoClinicApi.device.wifi.status().then(res => {
      if (res.connected) {
        store.dispatch(setWifiStatus(res));
      } else {
        store.dispatch(setWifiDisconnect());
      }
    });
  } catch (err) {
    store.dispatch(setWifiDisconnect);
    console.log(err);

    if (!EGZOTechHostApi.instance?.options?.disableLostWifiNotification) {
      errorNotification('wifi.notifications.lostWifiConnection');
    }
  }
};

function wifiStatusInterval() {
  const unsubscribe = store.subscribe(() => {
    const isBackendReady = store.getState().update.software.currentVersion;

    if (!isBackendReady) return;

    unsubscribe();
    checkWifiStatus();
  });

  setInterval(() => {
    // We consider that the backend is ready when we succesfully got the software version
    const isBackendReady = store.getState().update.software.currentVersion;

    if (isBackendReady) {
      checkWifiStatus();
    }
  }, 30000);
}

wifiStatusInterval();

const App: FC = () => {
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  useMemo(() => {
    consoleLogFrontEndVersion();
    consoleLogBackEndVersion();
  }, []);

  return (
    <>
      <SignalRProvider endpoint="device">
        <ExoGlobalProvider>
          <Provider store={store}>
            <ChakraApp forceUpdate={forceUpdate} />
          </Provider>
        </ExoGlobalProvider>
      </SignalRProvider>
      <ToastContainer />
    </>
  );
};

interface Props {
  forceUpdate: () => void;
}

const ChakraApp = ({ forceUpdate }: Props) => {
  const [i18nLoaded, setI18nLoaded] = useState(i18n.loaded);
  const autoConnectDeviceType = EGZOTechHostApi.instance?.options?.autoConnectDeviceType;
  const dispatch = useAppDispatch();
  useSignals();

  const reloadActiveTranslations = useCallback(() => {
    setI18nLoaded(true);
    const language = LocalStorageManager.payload.language?.value;
    if (language) {
      setDefaultOptions({ locale: getDateFnsLocale(language) });
    }
    forceUpdate();
  }, [forceUpdate]);

  useEffect(() => {
    const language = LocalStorageManager.payload.language?.value;
    if (language) {
      i18n.load(language, reloadActiveTranslations);
      return;
    }
    if (language === null) {
      // set default language if it is not set in the backend
      const clientLanguage = window.navigator.language.slice(0, 2).toLowerCase() as Language;
      LocalStorageManager.payload.language.value = CONFIG.LANGUAGES.includes(clientLanguage)
        ? clientLanguage
        : CONFIG.DEFAULT_LANG;
    }
    // We want to rerender when language signal is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reloadActiveTranslations, LocalStorageManager.payload.language?.value]);

  return (
    <ChakraProvider theme={theme} resetCSS>
      {i18nLoaded ? autoConnectDeviceType ? <ScanningRouter /> : <Router /> : <FullPageSpinner />}
    </ChakraProvider>
  );
};

export default App;
