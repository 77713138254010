import { MessageBroker, MessageBrokerCallback } from '@egzotech/exo-session';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

if (EGZOTechHostApi.instance?.options?.debugSessionBroker) {
  console.log(
    'Session debug is active. Instructions for using debugging:\n' +
      '  - type `dumpSessionCache()` in console to see all data from last device messages in the session\n' +
      '  - type `observeSessionTopic("#")` to enable observing all broker topic changes, type `observeSessionTopic("#")` again to disable this behavior\n' +
      '  - type `observeSessionTopic("topic")` to enable observing specific broker "topic" changes, type `observeSessionTopic("topic")` again to disable this behavior. Replace "topic" with the desired value.\n' +
      '  - type `observeSessionTopic("topic/#")` to enable observing all subtopic changes for specific broker "topic", type `observeSessionTopic("topic/#")` again to disable this behavior.\n' +
      '  - type `recordSessionTopic(topic, timeout)` to record specific broker topic changes for specified timeout.\n' +
      '  - type `publishSessionTopic(topic, value)` to send a message to specified topic to simulate communication\n',
  );

  (globalThis as any).dumpSessionCache = () => {
    const deviceManager = (globalThis as any).deviceManager as DeviceManager;

    if (!deviceManager.session) {
      console.warn('There is no session created');
      return;
    }

    Object.entries(deviceManager.session.sessionCache).forEach(([k, v]) => {
      console.log(k, v);
    });
  };

  const observeMap: Record<string, MessageBrokerCallback> = {};

  (globalThis as any).observeSessionTopic = (topic: string) => {
    const deviceManager = (globalThis as any).deviceManager as DeviceManager;

    if (!deviceManager.session) {
      console.warn('There is no session created');
      return;
    }

    const broker = (deviceManager.session as any).broker as MessageBroker;
    const realTopic = topic.endsWith('#') ? '#' : topic;

    if (observeMap[topic]) {
      broker.unsubscribe(realTopic, observeMap[topic]);
      delete observeMap[topic];
      return;
    }

    observeMap[topic] = (value, sourceTopic) => {
      if (topic.endsWith('#') && !sourceTopic.startsWith(topic.substring(0, topic.length - 1))) {
        return;
      }

      console.log('%c%s', 'color: #bada55', sourceTopic, value);
    };
    broker.subscribe(realTopic, observeMap[topic]);
  };

  (globalThis as any).recordSessionTopic = (topic: string, timeout = 1000) => {
    const deviceManager = (globalThis as any).deviceManager as DeviceManager;

    if (!deviceManager.session) {
      console.warn('There is no session created');
      return;
    }

    const broker = (deviceManager.session as any).broker as MessageBroker;
    const realTopic = topic.endsWith('#') ? '#' : topic;

    const callback: MessageBrokerCallback = (value, sourceTopic) => {
      if (topic.endsWith('#') && !sourceTopic.startsWith(topic.substring(0, topic.length - 1))) {
        return;
      }

      console.log('%c%s', 'color: #bada55', sourceTopic, value);
    };

    broker.subscribe(realTopic, callback);

    setTimeout(() => {
      broker.unsubscribe(realTopic, callback);
    }, timeout);
  };

  (globalThis as any).publishSessionTopic = (topic: string, value: unknown) => {
    const deviceManager = (globalThis as any).deviceManager as DeviceManager;

    if (!deviceManager.session) {
      console.warn('There is no session created');
      return;
    }

    const broker = (deviceManager.session as any).broker as MessageBroker;

    broker.publish(topic, value);
  };
}
