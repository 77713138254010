import { emgStepsFlow } from 'libs/exo-session-manager/core/calibrations/emgStepsFlow';

import { connectCableFlow } from './connectCableFlow';
import { emgFlow } from './emgFlow';
import { emsFlow } from './emsFlow';
import { gameFlow } from './gameFlow';
import { prepareChannelsAndConnectElectrodesFlow } from './prepareChannelsAndConnectElectrodesFlow';
import { setParametersFlow } from './setParametersFlow';
import { CalibrationFlowDefinition } from './types';

export const stellaBioCalibrationFlow = {
  'ems/stella': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 5,
    states: {
      ...setParametersFlow(null, 'connect-cable', 0),
      ...connectCableFlow('basing-settings', 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 3),
      ...emsFlow('connect-electrodes', null, 5),
    },
  },
  'ems-emg/stella': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 7,
    states: {
      ...setParametersFlow(null, 'connect-cable', 0),
      ...connectCableFlow('basing-settings', 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 2),
      ...emgFlow('connect-electrodes', 'ems-warnings', 5),
      ...emsFlow('emg-calibration', null, 6),
    },
  },
  'emg-view/stella': {
    initial: 'connect-cable',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 4,
    states: {
      ...connectCableFlow(null, 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', null, 2),
    },
  },
  'cosmic-mission/stella': {
    initial: 'game-select-control',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 5,
    states: {
      ...gameFlow('game-select-level', 'basing-settings', 1),
      ...setParametersFlow(null, 'connect-cable', 1),
      ...connectCableFlow('basing-settings', 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 2),
      ...emgFlow('connect-electrodes', null, 5),
    },
  },
  'exo-run/stella': {
    initial: 'game-select-control',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 5,
    states: {
      ...gameFlow('game-select-level', 'basing-settings', 1),
      ...setParametersFlow(null, 'connect-cable', 1),
      ...connectCableFlow('basing-settings', 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 2),
      ...emgFlow('connect-electrodes', null, 5),
    },
  },
  'emg-view-mvc/stella': {
    initial: 'connect-cable',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 5,
    states: {
      ...connectCableFlow(null, 'channel-muscle-selector', 1),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 2),
      ...emgFlow('connect-electrodes', null, 5),
    },
  },
  'emg/stella': {
    initial: 'emg-steps',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 5,
    states: {
      ...emgStepsFlow(null, 'connect-cable', 1),
      ...connectCableFlow('emg-steps', 'channel-muscle-selector', 2),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 3),
      ...emgFlow('connect-electrodes', null, 6),
    },
  },
} satisfies CalibrationFlowDefinition;
