import { memo } from 'react';
import colors from 'config/theme/colors';

interface Props {
  yAxisMaxNoLines: number;
  yHeight: number;
  maxValue: number;
  width?: number;
}

export const SVGYAxis = memo(({ maxValue, yAxisMaxNoLines, yHeight, width = 157 }: Props) => {
  /**
   *  This is the minimal maxValue (0.5) based on experiments to ensure that values on the Yaxis are always visible,
   *  even when the current maxValue (for instance, force) equals 0.
   */
  maxValue = maxValue < 0.5 ? 0.5 : maxValue;
  const minStep = maxValue / yAxisMaxNoLines;
  const factor = 10 ** Math.floor(Math.log10(minStep));
  const residual = minStep / factor;
  let step;
  if (residual > 6) {
    step = 10 * factor;
  } else if (residual > 2) {
    step = 5 * factor;
  } else if (residual > 1) {
    step = 2 * factor;
  } else {
    step = factor;
  }
  if (step < 0.01) {
    step = 0.01;
  }

  const svgStart = yHeight;
  const svgStep = (step / maxValue) * svgStart;

  const elements = [];
  let yVal = 0;
  for (let svgY = svgStart; svgY >= 0; svgY -= svgStep) {
    elements.push(<line key={svgY} x1="35" y1={svgY} x2={35 + width} y2={svgY} stroke={colors.gray['300']} />);
    if (svgY >= 12) {
      elements.push(
        <text
          key={-svgY}
          x={24}
          y={svgY}
          fill={colors.gray['300']}
          fontFamily="Open Sans, sans-serif"
          fontSize={20}
          textAnchor="end"
          dominantBaseline="middle"
        >
          {maxValue <= 1 ? yVal.toFixed(2) : maxValue < 10 ? yVal.toFixed(1) : yVal.toFixed(0)}
        </text>,
      );
    }

    yVal += step;
  }
  return <>{elements}</>;
});

SVGYAxis.displayName = 'SVGYAxis';
