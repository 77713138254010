import { Container, ModalBody, ModalContent } from '@chakra-ui/react';
import { useDevice } from 'libs/exo-session-manager/react';

import { MainHeading } from 'components/texts/MainHeading';

import { UnlockMeissaMotorButton } from './SystemComponentsModal/UnlockMeissaMotorButton';
import { ModalProps } from './Modal';

export const SystemComponentsModal = ({ containerProps }: ModalProps) => {
  const { selectedDevice } = useDevice();

  return (
    <ModalContent bg="grayAlpha.300" containerProps={containerProps}>
      <ModalBody minW="xs" py="10" px="20">
        <Container display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" gap="20">
          <MainHeading variant="subheading48" text={'modals.systemComponents.header'} />
          {selectedDevice?.type === 'meissa-ot' && <UnlockMeissaMotorButton />}
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
