import {
  Button,
  Flex,
  Heading,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ModalProps } from 'containers/modals/Modal';
import { __ } from 'helpers/i18n';
import { useAppSelector } from 'hooks/store';
import { TrainingResponseDTO } from 'types';

import StrippedProgressBar from 'components/common/StrippedProgressBar';
import { AlertIcon } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

interface ExerciseDialogProps {
  currentExercise: number;
  totalExercises: number;
  completedExercisesPercentage: number;
  onNext: () => void;
  onFinish: () => void;
  nextText?: string;
  finishText?: string;
  remainingExercises: number;
  dataUploaded?: boolean;
  error?: string;
}

const FinalExerciseDialog = ({
  currentExercise,
  totalExercises,
  completedExercisesPercentage,
  onFinish,
  onNext,
  nextText,
  finishText,
  remainingExercises,
  dataUploaded,
  error,
}: ExerciseDialogProps) => {
  const afterErrorNavigateTo = useAppSelector(state => state.training.afterErrorNavigateTo);

  return (
    <>
      <ModalBody textAlign="center" fontWeight="medium" fontSize="xl">
        <Flex justifyContent="space-between" mb="2">
          {dataUploaded ? (
            <TranslateText
              text={
                currentExercise === totalExercises
                  ? 'modals.completedExercise.all'
                  : 'modals.completedExercise.remaining'
              }
            >
              {currentExercise === totalExercises ? '' : ' ' + remainingExercises}
            </TranslateText>
          ) : (
            <Flex>
              <Spinner thickness="1" speed="0.65s" emptyColor="white" color="egzotechPrimaryColor" boxSize="6" mr="2" />
              <TranslateText color="egzotechPrimaryColor" text="modals.completedExercise.saving" />
            </Flex>
          )}
          <Text>
            {currentExercise}/{totalExercises}
          </Text>
        </Flex>
        <StrippedProgressBar value={completedExercisesPercentage} animated={!dataUploaded} />
        <TranslateText text="modals.completedExercise.deviceAdjustmentInfo" mt="6" />
        {!!error && (
          <HStack align="center" justify="center" gap="2">
            <TranslateText color="errorColor" text={`modals.completedExercise.error`} />
            <AlertIcon />
          </HStack>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex w="full" justifyContent="center" gap="16">
          {finishText && (
            <Button isDisabled={!dataUploaded} variant="mdOutlinedPrimaryButton" onClick={() => onFinish()}>
              <TranslateText text={finishText} />
            </Button>
          )}
          {nextText && !afterErrorNavigateTo && (
            <Button isDisabled={!dataUploaded} variant="mdPrimaryButton" onClick={() => onNext()}>
              <TranslateText text={nextText} />
            </Button>
          )}
        </Flex>
      </ModalFooter>
    </>
  );
};

interface ExerciseModalProps extends ModalProps {
  training: TrainingResponseDTO;
  currentExercise: number;
  dataUploaded?: boolean;
  finishText?: string;
  nextText?: string;
  error?: string;
}

export function ExerciseModal({
  callback,
  secondCallback,
  training,
  currentExercise,
  dataUploaded,
  finishText,
  nextText,
  error,
}: ExerciseModalProps) {
  const totalExercises = training.trainingTemplate.exercises
    ? Object.keys(training.trainingTemplate.exercises).length
    : 1;
  const remainingExercises = totalExercises - (currentExercise + 1);
  const completedExercisesPercentage = Math.round(((currentExercise + 1) / totalExercises) * 100);

  return (
    <>
      <ModalContent borderRadius="rMd" p="4" minW="xl">
        <ModalHeader textAlign="center" fontWeight="medium">
          <Heading variant={'heading'}>
            {' '}
            {__(`common.training`) + ' ' + __(`training.headings.title.${training.trainingTemplate.name}`)}
          </Heading>
        </ModalHeader>
        <FinalExerciseDialog
          currentExercise={currentExercise + 1}
          totalExercises={totalExercises}
          completedExercisesPercentage={completedExercisesPercentage}
          remainingExercises={remainingExercises}
          onNext={() => callback?.()}
          onFinish={() => secondCallback?.()}
          finishText={finishText ?? (remainingExercises > 0 ? 'common.finish' : 'common.skip')}
          nextText={nextText ?? (remainingExercises > 0 ? 'common.next' : 'modals.completedExercise.showReport')}
          dataUploaded={dataUploaded}
          error={error}
        />
      </ModalContent>
    </>
  );
}

export default ExerciseModal;
