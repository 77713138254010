import { ExtensionType } from '../libs/exo-session-manager/core';

type NonNullExtensionType = Exclude<ExtensionType, null>;

const extensionsRequirementsRelief: Partial<Record<NonNullExtensionType, { isRelief: boolean }>> = {
  // isRelief true means extension need relief ;)
  pinchmeter: {
    isRelief: true,
  },
  screwdriver: {
    isRelief: true,
  },
  knob: {
    isRelief: true,
  },
  mixer: {
    isRelief: true,
  },
  ball: {
    isRelief: true,
  },
  handle: {
    isRelief: true,
  },
};
export const doesExtensionNeedRelief = (extension: ExtensionType): { isRelief: boolean } => {
  if (!extension) throw new Error('Passed extension is null');
  const currentExtensionData = extensionsRequirementsRelief[extension];
  if (!currentExtensionData) {
    throw new Error(`No data for: ${extension}`);
  }
  return currentExtensionData;
};
