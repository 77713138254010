import { lazy, memo, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Modal } from 'containers/modals/Modal';
import { SystemComponents } from 'containers/SystemComponents';
import { useUpdate } from 'hooks/useUpdate';
import { useWifiNotification } from 'hooks/useWifiNotification';
import { useDeviceScanning } from 'libs/exo-session-manager/react/hooks/useDeviceScanning';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import AllTraining from 'views/specialist/patients/+patientId/trainings/all';
import FavoriteTraining from 'views/specialist/patients/+patientId/trainings/favorite';
import RecentTraining from 'views/specialist/patients/+patientId/trainings/recent';
import SuggestTraining from 'views/specialist/patients/+patientId/trainings/suggest';

import { FullPageSpinner } from 'components/common/FullPageSpinner';

import { useAutoTareMeissa } from '../hooks/useAutoTareMeissa';
import { usePreloadSounds } from '../hooks/usePreloadSounds';

const UnauthorizedRoute = lazy(() => import('./UnauthorizedRoute'));
const AuthorizedRoute = lazy(() => import('./AuthorizedRoute'));
const ServiceRoute = lazy(() => import('./ServiceRoute'));

const Login = lazy(() => import('views/login'));
const Register = lazy(() => import('views/register'));
const RegisterOwner = lazy(() => import('views/welcome-card/home'));
const SetPassword = lazy(() => import('views/welcome-card/set-password'));
const Conditions = lazy(() => import('views/welcome-card/conditions'));
const PasswordForgot = lazy(() => import('views/password/forgot'));
const PasswordForgotOffline = lazy(() => import('views/password/forgot-offline'));
const PasswordSetNew = lazy(() => import('views/password/set'));
const SessionExpired = lazy(() => import('views/session-expired'));

const Training = lazy(() => import('views/+patientId/training/+trainingId'));
const EditData = lazy(() => import('views/profile/edit/home'));
const EditPassword = lazy(() => import('views/profile/edit/change-password'));
const Chat = lazy(() => import('views/profile/chat'));
const PatientCard = lazy(() => import('views/specialist/patients/+patientId/card'));

const RegisterGoogle = lazy(() => import('views/account-fill'));

const SpecialistPatientsLayout = lazy(() => import('views/specialist/patients/list/_containers/PatientsLayout'));
const PatientsItems = lazy(() => import('views/specialist/patients/list/_containers/PatientsItems'));
const PatientAdd = lazy(() => import('views/specialist/patients/add'));
const CalendarManage = lazy(() => import('views/specialist/patients/+patientId/calendar/home'));
const TrainingLayout = lazy(() => import('views/specialist/patients/+patientId/trainings/_containers/TrainingLayout'));
const TrainingReport = lazy(() => import('views/specialist/patients/+patientId/report/+trainingId'));
const DeviceAvailable = lazy(() => import('views/specialist/devices/available'));
const SystemSettings = lazy(() => import('views/specialist/system-settings'));
const DeviceBeforeStart = lazy(() => import('views/specialist/devices/before-start'));
const DeviceConnect = lazy(() => import('views/specialist/devices/connect'));

const ClinicManage = lazy(() => import('views/owner/clinic/home'));
const SpecialistLayout = lazy(() => import('views/owner/clinic/specialist-list/_containers/SpecialistLayout'));
const SpecialistListContent = lazy(
  () => import('views/owner/clinic/specialist-list/_containers/SpecialistListContent'),
);
const PatientsLayout = lazy(() => import('views/owner/clinic/patient-list/_containers/PatientsLayout'));
const PatientListContent = lazy(() => import('views/owner/clinic/patient-list/_containers/PatientListContent'));
const ClinicStatisticsReport = lazy(() => import('views/owner/clinic/statistics-report/StatisticsReport'));

const EmailChange = lazy(() => import('views/email-change'));

const WifiSettings = lazy(() => import('views/wifi/wifi-settings'));

const Help = lazy(() => import('views/specialist/help'));

const Error = lazy(() => import('views/error'));
const PageNotFound = lazy(() => import('views/404'));
const PageUnderBuild = lazy(() => import('views/page-under-build'));

const Diagnostics = lazy(() => import('views/diagnostics')); // only for development
const ServiceMode = lazy(() => import('views/service-mode'));

export const Router = memo(() => {
  const disableAvailableDevicesScreen = EGZOTechHostApi.instance?.options?.disableAvailableDevicesScreen;
  const enableAvailableWifiScreen = EGZOTechHostApi.instance?.options?.enableAvailableWifiScreen;
  const enableOfflinePasswordReset = EGZOTechHostApi.instance?.options?.enableOfflinePasswordReset;

  useUpdate();
  useWifiNotification();
  useAutoTareMeissa();
  usePreloadSounds();
  return (
    <BrowserRouter>
      {/* TODO Consider integrate Modal and EmergencyStopButtonModal */}
      <Modal />
      <SystemComponents />
      <Suspense fallback={<FullPageSpinner />}>
        <Routes>
          {enableAvailableWifiScreen && <Route path="/wifi-settings" element={<WifiSettings />} />}

          <Route element={<UnauthorizedRoute />}>
            <Route index element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/help/*" element={<Help />} />
            <Route path="/register/*" element={<Register />} />
            <Route path="/welcome-card/*">
              <Route index element={<RegisterOwner />} />
              <Route path="set-password" element={<SetPassword />} />
              <Route path="conditions" element={<Conditions />} />
              <Route path="*" element={<Navigate to=".." />} />
            </Route>
            <Route path="/password/*">
              <Route index element={<Navigate to="forgot" />} />
              <Route path="forgot" element={<PasswordForgot />} />
              {enableOfflinePasswordReset && <Route path="forgot-offline" element={<PasswordForgotOffline />} />}
              <Route path="set/:token" element={<PasswordSetNew />} />
              <Route path="set/" element={<Navigate to="/login" />} />
              <Route path="*" element={<Navigate to=".." />} />
            </Route>
            <Route path="/session-expired" element={<SessionExpired />} />
          </Route>

          <Route element={<AuthorizedRoute />}>
            <Route path="/:patientId/training/:trainingId" element={<Training />} />
            <Route path="/profile/*">
              <Route index element={<Navigate to="edit" />} />
              <Route path="edit/*">
                <Route index element={<EditData />} />
                <Route path="change-password" element={<EditPassword />} />
                <Route path="*" element={<Navigate to=".." />} />
              </Route>
              <Route path="chat/*" element={<Chat />} />
              <Route path="*" element={<Navigate to=".." />} />
            </Route>
          </Route>
          <Route element={<AuthorizedRoute roles={['specialist', 'owner']} />}>
            <Route path="/account-fill" element={<RegisterGoogle />} />
            <Route path="/specialist/*">
              <Route path="help/*" element={<Help />} />
              <Route index element={<Navigate to="patients" />} />
              <Route path="patients/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="list/*">
                  <Route element={<SpecialistPatientsLayout />}>
                    <Route index element={<Navigate to="my-patients" />} />
                    <Route path="my-patients" element={<PatientsItems type="my" />} />
                    <Route path="telemedicine-patients" element={<PatientsItems type="telemedicine" />} />
                    <Route path="all-patients" element={<PatientsItems type="all" />} />
                    <Route path="*" element={<Navigate to=".." />} />
                  </Route>
                </Route>
                <Route path="add" element={<PatientAdd />} />
                <Route path=":patientId/calendar/*">
                  <Route index element={<CalendarManage />} />
                  <Route path="*" element={<Navigate to=".." />} />
                </Route>
                <Route path=":patientId/card" element={<PatientCard />} />
                <Route path=":patientId/trainings/*" element={<TrainingLayout />}>
                  <Route index element={<Navigate to="all" />} />
                  <Route path="suggest" element={<SuggestTraining />} />
                  <Route path="favorite" element={<FavoriteTraining />} />
                  <Route path="all" element={<AllTraining />} />
                  <Route path="recent" element={<RecentTraining />} />
                  <Route path="*" element={<Navigate to=".." />} />
                </Route>
                <Route path=":patientId/report/:trainingId" element={<TrainingReport />} />
                <Route path="*" element={<Navigate to=".." />} />
              </Route>
              {!disableAvailableDevicesScreen && (
                <Route path="devices/*">
                  <Route index element={<Navigate to="available" />} />
                  <Route path="available" element={<DeviceAvailable />} />
                  <Route path="before-start" element={<DeviceBeforeStart />} />
                  <Route path="connect" element={<DeviceConnect />} />
                  <Route path="*" element={<Navigate to=".." />} />
                </Route>
              )}
              <Route path="system-settings" element={<SystemSettings />} />
              <Route path="*" element={<Navigate to=".." />} />
            </Route>
          </Route>

          <Route element={<AuthorizedRoute roles={['owner']} />}>
            <Route path="/owner/*">
              <Route index element={<Navigate to="clinic" />} />
              <Route path="clinic/*">
                <Route index element={<ClinicManage />} />
                <Route path="specialist-list/*" element={<SpecialistLayout />}>
                  <Route index element={<Navigate to="active-specialists" />} />
                  <Route path="active-specialists" element={<SpecialistListContent type="active" />} />
                  <Route path="invited-specialists" element={<SpecialistListContent type="invited" />} />
                  <Route path="blocked-specialists" element={<SpecialistListContent type="blocked" />} />
                  <Route path="*" element={<Navigate to=".." />} />
                </Route>
                <Route path="patient-list/*" element={<PatientsLayout />}>
                  <Route index element={<Navigate to="active-patients" />} />
                  <Route path="active-patients" element={<PatientListContent type="active" />} />
                  <Route path="archived-patients" element={<PatientListContent type="archived" />} />
                  <Route path="*" element={<Navigate to=".." />} />
                </Route>
                <Route path="statistics-report" element={<ClinicStatisticsReport />} />
                <Route path="*" element={<Navigate to=".." />} />
              </Route>
              <Route path="*" element={<Navigate to=".." />} />
            </Route>
          </Route>

          <Route element={<AuthorizedRoute roles={['admin']} />}>
            <Route path="/admin/*">
              <Route path="*" element={<Navigate to="/page-under-build" />} />
            </Route>
          </Route>

          <Route path="/patient/*" element={<AuthorizedRoute roles={['patient']} />}>
            <Route path="*" element={<Navigate to="/page-under-build" />} />
          </Route>

          <Route path="/email-change" element={<EmailChange />} />

          <Route path="/error/*" element={<Error />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/page-under-build" element={<PageUnderBuild />} />

          <Route path="/diagnostics" element={<Diagnostics />} />
          <Route element={<ServiceRoute />}>
            <Route path="/service-mode" element={<ServiceMode />} />
          </Route>

          <Route path="*" element={<Navigate to="404" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
});

Router.displayName = 'Router';

export function ScanningRouter() {
  useDeviceScanning();
  return <Router />;
}
