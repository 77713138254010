import { useEffect, useState } from 'react';
import { deepFreeze, DeepReadonly } from '@egzotech/exo-session';
import {
  CalibrationFlow,
  CalibrationFlowStateIdentifier,
  CalibrationFlowStatesData,
} from 'libs/exo-session-manager/core';

import { useCalibrationFlowState } from './useCalibrationFlowState';

export const useCalibrationFlow = (
  onFinish?: (exportedStateData: DeepReadonly<CalibrationFlowStatesData>) => void,
  onExit?: () => void,
  flow?: CalibrationFlow,
) => {
  if (!flow) {
    throw new Error('Flow is required');
  }

  useEffect(() => {
    flow.events.addHandler('stateChange', setCurrentStateId);

    // Data from calibration flow should be freezed, this needs to be done to prevent
    // anybody from changing internal properties. We are assuming that when calibration
    // is finishied, the exported data is immutable.
    const onFinishHandler = (v: DeepReadonly<CalibrationFlowStatesData>) => onFinish?.(deepFreeze(v));

    if (onFinish) {
      flow.events.addHandler('finish', onFinishHandler);
    }

    if (onExit) {
      flow.events.addHandler('exit', onExit);
    }

    return () => {
      flow.events.removeHandler('stateChange', setCurrentStateId);

      if (onFinish) {
        flow.events.removeHandler('finish', onFinishHandler);
      }

      if (onExit) {
        flow.events.removeHandler('exit', onExit);
      }
    };
  }, [flow, onFinish, onExit]);

  const [currentStateId, setCurrentStateId] = useState(flow.currentState);
  const [currentState, setState] = useCalibrationFlowState(flow, currentStateId as CalibrationFlowStateIdentifier);

  useEffect(() => {
    if (currentStateId) {
      if (currentState === null) {
        // Mark step as this one which user has passed
        setState({});
      }
    }
  }, [currentState, currentStateId, flow, setState]);

  return {
    currentState: currentStateId,
    flow,
  } as const;
};
