import { logger } from 'helpers/logger';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { mockedApi } from 'services/mockedApi';
import { RESTMethod } from 'services/RESTClient';

import { API_URL } from './apiUrl';

export type ApiResponse<T> = Omit<Response, 'json'> & { json(): Promise<T> };

export class ApiError extends Error {
  constructor(readonly response: Response) {
    super('Fetch resulted in an API error');
  }
}

function getXSRFCookie() {
  if (!document.cookie) {
    return null;
  }

  const xsrfCookies = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith('XSRF-TOKEN' + '='));

  if (xsrfCookies.length === 0) {
    return null;
  }

  return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

export async function queryFetchFn(input: RequestInfo, init?: RequestInit) {
  const headers = new Headers(init?.headers);
  headers.set('content-type', 'application/json');

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_DEVELOPER_BAR === 'true' ||
    EGZOTechHostApi.instance?.options?.enableDeveloperBar
  ) {
    const method = (init?.method?.toUpperCase() ?? 'GET') as RESTMethod;
    const url = typeof input === 'string' ? new URL(location.href, input) : new URL(input.url);

    const mockedApiEnv = EGZOTechHostApi.instance?.options?.mockedApi;
    if (mockedApiEnv && mockedApi.isMocked(method, url.pathname, url.searchParams)) {
      return mockedApi.mockedFetch(method, url.pathname);
    }
  }

  return apiRawFetch(input, { ...init, headers });
}

export async function apiRawFetch<T = any>(info: RequestInfo | URL, init?: RequestInit): Promise<ApiResponse<T>> {
  if (typeof info === 'string' && !info.startsWith('http')) {
    try {
      info = new URL(info.startsWith('/') ? info.substring(1) : info, API_URL);
    } catch (error) {
      logger.debug('apiFetch', String(error));
      throw error;
    }
  }

  const xsrfCookie = getXSRFCookie();
  const headers = new Headers(init?.headers);

  headers.set('api-version', '1');

  if (xsrfCookie && !headers.has('X-XSRF-TOKEN')) {
    headers.set('X-XSRF-TOKEN', xsrfCookie);
  }

  return (await fetch(info, {
    credentials: info instanceof Request ? info.credentials : 'include',
    ...init,
    headers,
  })) as ApiResponse<T>;
}

export async function apiFetch<T>(pathWithQuery: string, init?: RequestInit): Promise<ApiResponse<T>> {
  const response = await apiRawFetch(pathWithQuery, init);

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response;
}

type RemoveFirstFromTuple<T> = T extends [any, ...infer Rest] ? [...Rest] : never;

export function apiJsonFetch<T, TBody = any>(
  pathWithQuery: string,
  body: TBody,
  init?: RequestInit,
  ...jsonArgs: [...RemoveFirstFromTuple<Parameters<(typeof JSON)['stringify']>>]
): Promise<ApiResponse<T>> {
  const headers = new Headers(init?.headers);
  headers.set('Content-Type', 'application/json');

  return apiFetch(pathWithQuery, {
    ...init,
    body: JSON.stringify(body, jsonArgs[0], jsonArgs[1]),
    headers,
  });
}
