const translate = (key: string): string => `error.${key}`;
const errorList = ['signInInput.invalid'];

// temporary any, in the future ApiErrorCode
export const getErrorMessage = (error: string): string => {
  if (errorList.includes(error)) {
    return translate(error);
  }

  if (error === '') {
    return translate('occurred');
  }

  return translate(error);
};
