import { useContext } from 'react';
import { CPMBasingData } from 'libs/exo-session-manager/core';

import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';

type CPMBasingHook = {
  (): CPMBasingData;
  (optional: true): Partial<CPMBasingData>;
};

export const useCPMBasing = ((optional?: boolean) => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);

  if (cpm) {
    // TODO: for now we are returning value from Signal, we should return whole signal in the future
    return cpm.basingData.value;
  }
  if (cpmEms) {
    return cpmEms.basingData.value;
  }

  if (!optional) {
    throw new Error('useCPMBasing must be used within a Provider which requires CPM feature');
  }

  return {};
}) as CPMBasingHook;
