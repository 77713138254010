import { FC, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppSelector } from 'hooks/store';

import { FiltersWrapper } from './FiltersWrapper';
import { TrainingFilterBar } from './TrainingFilterBar';
import { TrainingFilterInput } from './TrainingFilterInput';

export const TrainingFilter: FC = () => {
  const { loadAvailableFilters, removeFilter, getAllCurrentFilters } = useTrainingList();

  useEffect(() => {
    loadAvailableFilters();
    // We want to retrieve information about available filters from the backend only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { availableFilters } = useAppSelector(state => state.trainingList);
  const currentFilters = availableFilters ? getAllCurrentFilters(availableFilters) : [];

  return (
    <>
      <FiltersWrapper text="training.filter">
        <TrainingFilterInput />
      </FiltersWrapper>
      {availableFilters && currentFilters.length ? (
        <Flex gap="2.5" flexWrap="wrap" mt="2" w="full">
          {currentFilters.map(item => (
            <TrainingFilterBar key={item.id} item={item.name} onClick={() => removeFilter(item)} />
          ))}
        </Flex>
      ) : null}
    </>
  );
};
