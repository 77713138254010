import { FC } from 'react';
import { createFilter, Select, SingleValue } from 'chakra-react-select';
import { ChakraStylesConfig } from 'chakra-react-select/src/types';
import { __ } from 'helpers/i18n';
import { SelectData } from 'types';

export interface MainSearchSelectProps {
  options: SelectData[];
  width?: string;
  value?: SelectData | null;
  placeholder?: string;
  isSearchable?: boolean;
  onChange?: (value: SingleValue<SelectData>) => void;
}

export const MainSearchSelect: FC<MainSearchSelectProps> = ({
  options,
  width,
  value,
  placeholder,
  isSearchable = true,
  onChange,
}) => {
  const chakraStyles: ChakraStylesConfig<SelectData> = {
    control: provided => ({
      ...provided,
      minH: '12',
      fontSize: ['md', 'lg', null, 'lg'],
      w: width ?? ['100%', null, null, '30%'],
      borderRadius: 'rSm',
      bg: 'white',
      cursor: 'pointer',
      borderColor: 'gray.300',
      _focus: {
        color: 'textColor',
        borderColor: 'gray.300',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      fontSize: '2xl',
      bg: 'transparent',
      px: '2.5',
      cursor: 'inherit',
    }),
    downChevron: provided => ({
      ...provided,
      w: '6',
      h: '6',
      opacity: 1,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: 'gray.300',
    }),
    option: provided => ({
      ...provided,
      color: 'headerTextColor',
      fontSize: 'xl',
      h: '12',
      py: '1.5',
      px: '3',
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <Select<SelectData>
      maxMenuHeight={200}
      menuPlacement="bottom"
      value={value}
      isSearchable={isSearchable}
      options={options}
      filterOption={createFilter({
        stringify: option => `${option.label}`,
      })}
      onChange={onChange}
      placeholder={placeholder && __(placeholder)}
      chakraStyles={chakraStyles}
      noOptionsMessage={() => __('select.noOptions')}
    />
  );
};
