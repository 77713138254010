import { FunctionComponent, lazy, Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { type ResponsiveValue, type ThemeTypings, Box, Image, ImageProps } from '@chakra-ui/react';
import placeholder200 from 'assets/images/muscles/placeholder200.png';
import { getMuscleIdByName, isMuscleValidForChannelsRange, MuscleId, RegionId } from 'config/muscles';
import colors, { type Channel } from 'config/theme/colors';
import { logger } from 'helpers/logger';
import { capitalize } from 'helpers/string';
import { EMSExerciseDefinition } from 'libs/exo-session-manager/core';

export type ImagesModuleKey = keyof typeof import('./images');

export type ExtractImageKey<T> = T extends `${string}Image` ? T : never;

export type ExtractElectrodesKey<T> = T extends `${string}Electrodes${'Ems' | 'Emg'}` ? T : never;

export interface MusclePath {
  type: 'region' | 'muscle' | 'FES';
  muscleId: RegionId | MuscleId;
  exercise?: EMSExerciseDefinition;
}

export interface MuscleImageProps extends MusclePath, ImageProps {
  electrodes?: 'emg' | 'ems';
  channels: Channel[];
}

const SvgFallbackComponent: FunctionComponent<React.SVGProps<SVGSVGElement>> = () => <svg></svg>;

export function useMuscleImageSources({
  type,
  muscle,
  side,
  channel,
}: {
  type: 'region' | 'muscle' | 'FES';
  muscle: string;
  side: 'left' | 'right' | 'no-side';
  channel?: string;
}) {
  const [image, setImage] = useState(placeholder200);
  const [importedCount, setImportedCount] = useState(0);

  const importId = useMemo(
    () =>
      `${capitalize(type)}${capitalize(muscle)}${side === 'no-side' ? '' : capitalize(side)}${
        channel ? capitalize(channel) : ''
      }`,
    [muscle, side, type, channel],
  );

  useLayoutEffect(() => {
    import('./images')
      .then((module: Record<ExtractImageKey<ImagesModuleKey>, string>) => {
        if (module[`${importId}Image` as keyof typeof module]) {
          setImage(module[`${importId}Image` as keyof typeof module]);
        } else {
          logger.warn('useMuscleImageSources', `Image: ${importId} does not exists`);
        }
      })
      .catch(e => logger.warn('useMuscleImageSources', `Failed loading image: ${importId}`, e))
      .finally(() => setImportedCount(prev => prev + 1));
  }, [importId]);

  const ElectrodesEms = useMemo(
    () =>
      lazy(() =>
        import('./images')
          .then(
            (
              module: Record<ExtractElectrodesKey<ImagesModuleKey>, FunctionComponent<React.SVGProps<SVGSVGElement>>>,
            ) => ({
              default: module[`${importId}ElectrodesEms` as keyof typeof module]
                ? module[`${importId}ElectrodesEms` as keyof typeof module]
                : SvgFallbackComponent,
            }),
          )
          .finally(() => setImportedCount(prev => prev + 1)),
      ),
    [importId],
  );

  const ElectrodesEmg = useMemo(
    () =>
      lazy(() =>
        import('./images')
          .then(
            (
              module: Record<ExtractElectrodesKey<ImagesModuleKey>, FunctionComponent<React.SVGProps<SVGSVGElement>>>,
            ) => ({
              default: module[`${importId}ElectrodesEmg` as keyof typeof module]
                ? module[`${importId}ElectrodesEmg` as keyof typeof module]
                : SvgFallbackComponent,
            }),
          )
          .finally(() => setImportedCount(prev => prev + 1)),
      ),
    [importId],
  );

  return {
    importedCount,
    image,
    ElectrodesEms,
    ElectrodesEmg,
  } as const;
}

function ElectrodeComponent({
  muscleId,
  svgComponent,
  channelColor,
  electrodes,
  importedCount,
  isImageInChannelsRange,
}: {
  muscleId: MusclePath['muscleId'];
  channelColor: string;
  importedCount: number;
  isImageInChannelsRange: boolean;
  electrodes: MuscleImageProps['electrodes'];
  svgComponent: React.LazyExoticComponent<FunctionComponent<React.SVGProps<SVGSVGElement>>>;
}) {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const channelId = muscleId.split('.').at(-1);
    if (channelId) {
      const currentChannelElectrodeElement = svgRef.current?.getElementById(channelId) as SVGAElement | undefined;
      if (currentChannelElectrodeElement) {
        currentChannelElectrodeElement.style.fill = channelColor;
        currentChannelElectrodeElement.style.color = channelColor;
      }
    }
  }, [channelColor, muscleId, electrodes, importedCount]);

  const isImageWithChannels = muscleId.split('.').at(-1)?.includes('channels');

  const SVGComponent = svgComponent;

  return (
    <SVGComponent
      ref={svgRef}
      color={isImageWithChannels || isImageInChannelsRange ? undefined : channelColor}
      height="100%"
      width="100%"
    />
  );
}

const parseMuscleInfo = (muscleId: MuscleImageProps['muscleId']) => {
  const isFESMuscle = muscleId.split('.')[0] === 'fes';
  const hasChannel = muscleId.split('.').at(-1)?.includes('channel');
  const muscle = (hasChannel ? muscleId.split('.').at(-2) : muscleId.split('.').at(-1)) ?? '';
  const side = (muscleId.split('.')[1] ?? 'no-side') as 'left' | 'right' | 'no-side';
  const imageWithChannels = getMuscleIdByName(`${side}.${muscle}.channels`);
  const isImageInChannelsRange = imageWithChannels && isMuscleValidForChannelsRange(muscleId, imageWithChannels);
  const channel = isFESMuscle
    ? isImageInChannelsRange
      ? imageWithChannels.split('.').at(-1)
      : hasChannel
      ? muscleId.split('.').at(-1)
      : undefined
    : undefined;

  return {
    isFESMuscle,
    muscle,
    side,
    channel,
    isImageInChannelsRange,
  };
};

export function MuscleImage({ type, muscleId, electrodes, channels, boxSize = 48, ...props }: MuscleImageProps) {
  const { channel, isFESMuscle, isImageInChannelsRange, muscle, side } = parseMuscleInfo(muscleId);

  const sources = useMuscleImageSources({
    type: isFESMuscle ? 'FES' : type,
    muscle,
    side,
    channel,
  });

  const channelColor: ResponsiveValue<ThemeTypings['colors']> =
    typeof channels[0] === 'number' ? colors[`channel.${(channels[0] + 1) as Channel}`] : 'black';

  const svgComponent = electrodes === 'ems' ? sources.ElectrodesEms : sources.ElectrodesEmg;

  return (
    <Box position="relative" h={boxSize} w={boxSize}>
      <Image boxSize={boxSize} src={sources.image} alt="muscle" {...props} />
      <Suspense>
        <Box position="absolute" top={0} left={0} h={boxSize} w={boxSize}>
          <ElectrodeComponent
            channelColor={channelColor}
            electrodes={electrodes}
            importedCount={sources.importedCount}
            isImageInChannelsRange={!!isImageInChannelsRange}
            muscleId={muscleId}
            svgComponent={svgComponent}
          />
        </Box>
      </Suspense>
    </Box>
  );
}
