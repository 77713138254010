import { useEffect, useRef, useState } from 'react';
import { Flex, ModalBody, ModalContent, Spinner, VStack } from '@chakra-ui/react';

import { CheckIcon } from 'components/icons';

import { MainButton } from '../../components/buttons/MainButton';
import { MainHeading } from '../../components/texts/MainHeading';
import { TranslateText } from '../../components/texts/TranslateText';
import colors from '../../config/theme/colors';
import { exoClinicApi } from '../../services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from '../../services/ExoClinicBackendOpenApi';

import { ModalProps } from './Modal';

const ExportDatabaseModal = ({ close }: ModalProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [status, setStatus] = useState<ExoClinicBackendOpenApiSchemas['ExportDatabaseStatusDto'] | ''>('');
  const [exportSuccess, setExportSuccess] = useState(false);

  let currentErrorText;
  switch (status) {
    case 'fileAlreadyExist': {
      currentErrorText = 'exportDatabase.modal.exportErrorFileExists';
      break;
    }
    case 'error': {
      currentErrorText = 'exportDatabase.modal.exportErrorUnknown';
      break;
    }
    case 'missingDrive': {
      currentErrorText = 'exportDatabase.modal.exportErrorUSBDriverUnplugged';
      break;
    }
    default: {
      currentErrorText = '';
      break;
    }
  }

  useEffect(() => {
    if (exportInProgress) {
      const checkStatusOfExporting = async () => {
        try {
          const currentStatusOfExport = await exoClinicApi.database.exportStatus();
          setStatus(currentStatusOfExport.status);
          const isFinished = currentStatusOfExport.status === 'finished';
          const isInProgress = ['readyToStart', 'copying', 'compressing', 'encrypting'].includes(
            currentStatusOfExport.status,
          );

          if (isFinished || !isInProgress) {
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }
            if (isFinished) {
              setExportSuccess(true);
            } else {
              setExportSuccess(false);
            }
            setExportInProgress(false);
          }
        } catch (err) {
          console.log(err);
          setStatus('error');
          setExportInProgress(false);
        }
      };

      const startExport = async () => {
        try {
          await exoClinicApi.database.startExport();
          checkStatusOfExporting();
          intervalRef.current = setInterval(checkStatusOfExporting, 2000);
        } catch (error) {
          setExportInProgress(false);
          checkStatusOfExporting();
        }
      };

      if (exportInProgress) {
        startExport();
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [exportInProgress]);

  return (
    <ModalContent minWidth={{ base: '43.75rem', '2xl': '54rem' }} borderRadius="rMd" py="8" px={4}>
      <ModalBody>
        <MainHeading variant="subheading36" text="exportDatabase.modal.header" />
        {exportSuccess && (
          <Flex justifyContent="center" mt={20} mb={14}>
            <CheckIcon
              bgColor="stellaBioPrimaryColor"
              color="white"
              borderRadius="full"
              p="1"
              fontWeight="bold"
              mr="2"
              width={7}
              height={7}
            />
            <TranslateText
              variant="openSans24Bold"
              text="exportDatabase.modal.exportSuccess"
              color={colors.stellaBioPrimaryColor}
            />
          </Flex>
        )}
        {currentErrorText !== '' ? (
          <VStack mb={10} mt={16}>
            <TranslateText
              variant="openSans24Bold"
              text="exportDatabase.modal.errorOccurredDuringExport"
              color={colors.errorColor}
            />
            <TranslateText variant="openSans24Bold" text={currentErrorText} color={colors.errorColor} />
          </VStack>
        ) : exportSuccess ? (
          <></>
        ) : (
          <VStack my={12} gap={0}>
            <TranslateText variant="openSans24" text="exportDatabase.modal.description1"></TranslateText>
            <TranslateText variant="openSans24" text="exportDatabase.modal.description2">
              <TranslateText
                as="span"
                fontWeight="bold"
                variant="openSans24"
                text="exportDatabase.modal.description3"
              ></TranslateText>
              <TranslateText variant="openSans24" as="span" text="exportDatabase.modal.description4" />
              <TranslateText
                variant="openSans24"
                fontWeight="bold"
                as="span"
                text="exportDatabase.modal.description5"
              />
            </TranslateText>
          </VStack>
        )}
        {exportSuccess || currentErrorText !== '' ? (
          <Flex justifyContent="center">
            <MainButton
              type="button"
              text="exportDatabase.modal.close"
              variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
              onClick={() => close()}
              minW={32}
            />
          </Flex>
        ) : exportInProgress ? (
          <Flex width="100%" justifyContent="center" gap={5} alignItems="center">
            <Spinner thickness="1" speed="1s" emptyColor="white" color="egzotechPrimaryColor" boxSize="5" />
            <TranslateText
              color={colors.egzotechPrimaryColor}
              variant="openSans24"
              fontWeight="bold"
              as="span"
              text="exportDatabase.modal.exportInProgress"
            />
          </Flex>
        ) : (
          !exportSuccess && (
            <Flex justifyContent="space-between" px={5}>
              <MainButton
                type="button"
                text="exportDatabase.modal.cancel"
                variant={{ base: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
                onClick={() => close()}
                minW={32}
              />
              <MainButton
                type="submit"
                text="exportDatabase.modal.export"
                variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
                width={32}
                onClick={() => {
                  setExportInProgress(true);
                }}
              />
            </Flex>
          )
        )}
      </ModalBody>
    </ModalContent>
  );
};
export default ExportDatabaseModal;
