import { camAnkleFlow, camAnkleKneeFlow, camKneeAnkleFlow, camKneeFlow } from './camFlows';
import { connectCableFlow } from './connectCableFlow';
import { cpmAnkleFlow, cpmKneeAnkleFlow, cpmKneeFlow } from './cpmFlow';
import { emgFlow } from './emgFlow';
import { emsFlow } from './emsFlow';
import { forceFlow } from './forceFlow';
import { gameFlow } from './gameFlow';
import { moveToCenterAnkleFlow, moveToCenterKneeAnkleFlow, moveToCenterKneeFlow } from './moveToCenterFlow';
import { prepareChannelsAndConnectElectrodesFlow } from './prepareChannelsAndConnectElectrodesFlow';
import { setParametersFlow } from './setParametersFlow';
import { CalibrationFlowDefinition } from './types';

export const sidraLegCalibrationFlow = {
  'cpm/knee': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 16,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeFlow('basing-settings', null, 2),
    },
  },

  'cpm/ankle': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 16,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmAnkleFlow('basing-settings', null, 2),
    },
  },

  'cpm/knee-synced': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 18,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', null, 2),
    },
  },

  'cpm-ems/knee-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 22,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 19),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 20),
      ...emsFlow('connect-electrodes', null, 23),
    },
  },
  'cpm-emg/knee-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 13,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 19),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 20),
      ...emgFlow('connect-electrodes', null, 23),
    },
  },

  'cpm/ankle-synced': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 18,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', null, 2),
    },
  },
  'cpm-emg/ankle-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 13,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 19),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 20),
      ...emgFlow('connect-electrodes', null, 23),
    },
  },
  'cpm-ems/ankle-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 13,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 14),
      ...emsFlow('connect-electrodes', null, 17),
    },
  },

  'cpm-emg/knee': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 15,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', null, 17),
    },
  },
  'cpm-emg/ankle': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 15,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', null, 17),
    },
  },

  'cpm-ems/knee': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 16,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 14),
      ...emsFlow('connect-electrodes', null, 16),
    },
  },

  'cpm-ems/ankle': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 16,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 14),
      ...emsFlow('connect-electrodes', null, 16),
    },
  },

  'cpm-ems-emg/knee': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', 'ems-warnings', 17),
      ...emsFlow('emg-calibration', null, 18),
    },
  },

  'cpm-ems-emg/ankle': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', 'ems-warnings', 17),
      ...emsFlow('emg-calibration', null, 18),
    },
  },

  'cpm-ems-emg/knee-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', 'ems-warnings', 17),
      ...emsFlow('emg-calibration', null, 18),
    },
  },

  'cpm-ems-emg/ankle-synced': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...cpmKneeAnkleFlow('basing-settings', 'connect-cable', 2),
      ...connectCableFlow('leg-basing-limb-unweight', 'channel-muscle-selector', 13),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 14),
      ...emgFlow('connect-electrodes', 'ems-warnings', 17),
      ...emsFlow('emg-calibration', null, 18),
    },
  },

  'cam-isokinetic/knee': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camKneeFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-isokinetic/knee/cosmic-mission': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 21,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camKneeFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', null, 21),
    },
  },
  'cam-isokinetic/knee/driving-game': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 22,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camKneeFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', 'leg-basing-move-center-knee', 21),
      ...moveToCenterKneeFlow('force-calibration', null, 22),
    },
  },
  'cam-isokinetic/ankle': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camAnkleFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-isokinetic/ankle/cosmic-mission': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 21,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camAnkleFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', null, 21),
    },
  },
  'cam-isokinetic/ankle/driving-game': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 22,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camAnkleFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', 'leg-basing-move-center-ankle', 21),
      ...moveToCenterAnkleFlow('force-calibration', null, 22),
    },
  },
  'cam-isokinetic/ankle-synced': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camKneeAnkleFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-isokinetic/ankle-synced/cosmic-mission': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 22,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camKneeAnkleFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', null, 22),
    },
  },
  'cam-isokinetic/ankle-synced/driving-game': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 24,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'leg-basing-leg-side-selection', 3),
      ...camKneeAnkleFlow('basing-settings', 'force-calibration', 4),
      ...forceFlow('leg-basing-limb-unweight', 'leg-basing-move-center-knee', 22),
      ...moveToCenterKneeAnkleFlow('force-calibration', null, 23),
    },
  },
  'cam-isokinetic/knee-synced': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 17,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camAnkleKneeFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-torque/knee': {
    initial: 'basing-settings',
    alerts: {},
    stepsNumber: 21,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camKneeFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-torque/ankle': {
    initial: 'basing-settings',
    alerts: {},
    stepsNumber: 21,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camAnkleFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-torque/knee-synced': {
    initial: 'basing-settings',
    alerts: {},
    stepsNumber: 21,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camAnkleKneeFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
  'cam-torque/ankle-synced': {
    initial: 'basing-settings',
    alerts: {},
    stepsNumber: 21,
    states: {
      ...setParametersFlow(null, 'leg-basing-leg-side-selection', 1),
      ...camKneeAnkleFlow('basing-settings', 'force-calibration', 2),
      ...forceFlow('leg-basing-limb-unweight', null, 16),
    },
  },
} satisfies CalibrationFlowDefinition;
