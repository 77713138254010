import { useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { logger } from 'helpers/logger';

import { useAppSelector } from './store';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const { navigationDisabled, callback } = useAppSelector(state => state.navigation);

  const appNavigate = useCallback(
    function (to: To | number, options?: NavigateOptions) {
      if (navigationDisabled) {
        logger.info(
          'useAppNavigate.navigate',
          `Default navigation was prevented for ${JSON.stringify(to)} and was redirected to callback function`,
        );
        callback?.(to, options);
        return;
      }
      if (typeof to === 'number') {
        return navigate(to);
      } else {
        return navigate(to, options);
      }
    },
    [callback, navigate, navigationDisabled],
  );

  return appNavigate;
};
