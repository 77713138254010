import { useMemo, useState } from 'react';
import { Center, Container, HStack, Image, ModalBody, ModalContent, ThemingProps, VStack } from '@chakra-ui/react';
import sessionErrorImage from 'assets/images/SessionConnectionError.svg';
import sessionErrorResolvedImage from 'assets/images/SessionConnectionErrorResolved.svg';
import { useAppDispatch } from 'hooks/store';
import { useDevice } from 'libs/exo-session-manager/react';
import { setErrorNavigationForTraining } from 'slices/trainingSlice';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ConnectionErrorModalProps } from './ConnectionErrorModal';

const RESET_TIMEOUT = 5000;

export const SessionConnectionErrorModal = ({ onContinue, onExit, id, close }: ConnectionErrorModalProps) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<'initial' | 'resetting' | 'success' | 'failed'>('initial');
  const { resetConnectionError, isConnectionRestored } = useDevice();

  const actions = useMemo(() => {
    const actions: {
      id: string;
      text: string;
      btnVariant: ThemingProps<'Button'>['variant'];
      action: () => void;
    }[] = [
      {
        id: 'exit',
        text: 'modals.alerts.exit',
        btnVariant: status === 'success' ? 'mdOutlinedPrimaryButton' : 'mdOutlinedErrorButton',
        action: () => {
          close();
          onExit?.('/');
        },
      },
    ];

    if (status === 'initial') {
      actions.push({
        id: 'initial',
        text: 'modals.sessionConnectionError.action',
        btnVariant: 'mdErrorButton',
        action: () => {
          resetConnectionError();
          setStatus('resetting');
          setTimeout(() => {
            if (isConnectionRestored()) {
              setStatus('success');
            } else {
              setStatus('failed');
            }
          }, RESET_TIMEOUT);
        },
      });
    }

    if (status === 'resetting') {
      actions.push({
        id: 'resetting',
        text: 'modals.sessionConnectionError.action',
        btnVariant: 'mdErrorButton',
        action: () => {},
      });
    }

    if (status === 'success') {
      actions.push({
        id: 'success',
        text: 'emergencyModals.continueTraining',
        btnVariant: 'mdPrimaryButton',
        action: () => {
          close();
          onContinue?.();
        },
      });
    }

    if (status === 'failed') {
      actions.push({
        id: 'failed',
        text: 'modals.alerts.deviceDisconnected.action',
        btnVariant: 'mdErrorButton',
        action: () => {
          const path = `/specialist/help?errorCode=deviceDisconnected&id=${id ?? 'session_connection_error'}`;
          close();
          dispatch(setErrorNavigationForTraining(path));
          onExit?.(path);
        },
      });
    }

    return actions;
  }, [status, close, onExit, resetConnectionError, isConnectionRestored, onContinue, id, dispatch]);

  const modalContent = useMemo(() => {
    if (status === 'initial') {
      return {
        heading: 'modals.sessionConnectionError.heading',
        description: 'modals.sessionConnectionError.description',
        image: sessionErrorImage,
      };
    }

    if (status === 'resetting') {
      return {
        heading: 'modals.sessionConnectionError.heading',
        description: 'modals.sessionConnectionError.resetting_description',
        image: sessionErrorResolvedImage,
      };
    }

    if (status === 'success') {
      return {
        heading: 'modals.sessionConnectionError.reset_success_heading',
        description: 'modals.sessionConnectionError.reset_success_description',
        image: sessionErrorResolvedImage,
      };
    }

    if (status === 'failed') {
      return {
        heading: 'modals.sessionConnectionError.heading',
        description: 'modals.sessionConnectionError.reset_failed_description',
        image: sessionErrorImage,
      };
    }

    return {
      heading: 'modals.sessionConnectionError.heading',
      description: 'modals.sessionConnectionError.description',
      image: sessionErrorImage,
    };
  }, [status]);

  const borderColor = useMemo(() => {
    if (status === 'initial') {
      return 'errorColor';
    }

    if (status === 'resetting') {
      return 'gray.300';
    }

    if (status === 'success') {
      return 'transparent';
    }

    if (status === 'failed') {
      return 'errorColor';
    }

    return 'errorColor';
  }, [status]);

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="2">
      <ModalBody borderWidth="1" borderStyle="solid" borderColor={borderColor} borderRadius="rLg">
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="center"
          py="4"
        >
          <VStack flex="1 0" justify="space-between">
            <Center>
              <MainHeading variant="subheading36" text={modalContent.heading} />
            </Center>
            <Image
              src={modalContent.image}
              maxH={{ base: '3xs', '2xl': 'sm' }}
              maxW={{ base: '3xs', '2xl': 'sm' }}
              mt="4"
            />
            <VStack gap="0">
              <TranslateText variant="openSans24" text={modalContent.description} />
            </VStack>
            <HStack justifyContent="space-evenly" w="full">
              {actions.map(item => (
                <MainButton
                  key={item.id}
                  text={item.text}
                  variant={item.btnVariant}
                  onClick={item.action}
                  minW="32"
                  isDisabled={status === 'resetting'}
                />
              ))}
            </HStack>
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
