import { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { useAppDispatch } from 'hooks/store';
import { setEndDate, setStartDate } from 'slices/clinicReportSlice';

import { FormField } from 'components/form/FormField';
import { InputText } from 'components/form/InputText';

interface Props {
  type?: string;
  noLabel?: boolean;
  margin?: boolean;
  required?: boolean;
  disabled?: boolean;
  pwd?: boolean;
  noHelpBox?: boolean;
  name?: string;
  mb?: boolean;
  mt?: string;
  pr?: string;
  defaultValue?: string;
  value?: string;
  indicator?: boolean;
  uniqueErrorTxt?: string;
  maxChar?: boolean;
  customHeight?: boolean;
  setIsInvalid?: Dispatch<SetStateAction<boolean>>;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick?: () => void;
  children?: ReactNode;
}

export const CreateInput: FC<Props> = ({
  children,
  type,
  noLabel,
  margin,
  required,
  disabled,
  pwd,
  noHelpBox,
  name,
  mb,
  mt,
  pr,
  defaultValue,
  value,
  indicator,
  uniqueErrorTxt,
  maxChar,
  customHeight,
  setIsInvalid,
  onChange,
  onClick,
}) => {
  const inputType = (): string => type ?? 'text';
  const showLabel = (): string => (noLabel ? '' : `labels.${name ? name : inputType()}`);
  const [inputValue, setInputValue] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleDateChange = (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(true);
    switch (e?.target.name) {
      case 'startDate': {
        dispatch(setStartDate(e?.target.value));
        break;
      }
      case 'endDate': {
        dispatch(setEndDate(e?.target.value));
      }
    }
  };

  const handleOnChange = (type?: string, e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    type === 'date' ? handleDateChange(e) : onChange;
  const getCss = (type?: string) => type === 'date' && !inputValue && { color: `gray.300` };

  return (
    <FormField
      mb={mb ? '2' : '0'}
      mt={noLabel ? (margin ? mt : '4') : '0'}
      labelTxt={showLabel()}
      name={name ? name : `${inputType()}`}
      isRequired={required}
      isDisabled={disabled}
      noHelpBox={noHelpBox}
      indicator={indicator}
      w="100%"
      uniqueErrorTxt={uniqueErrorTxt}
      onClick={onClick}
      customHeight={customHeight}
      setIsInvalid={setIsInvalid}
    >
      {children ? (
        children
      ) : (
        <InputText
          type={inputType()}
          placeholder={`placeholders.${name ? name : inputType()}`}
          errorBorderColor="errorMediumColor"
          pwd={pwd}
          h="12"
          fontSize="lg"
          max="2999-12-31"
          maxLength={maxChar ? 255 : undefined}
          defaultValue={defaultValue}
          value={value}
          pr={pr}
          onChange={e => handleOnChange(type, e)}
          css={getCss(type)}
          _focus={{
            borderColor: 'inherit',
            shadow: 'sm',
          }}
        />
      )}
    </FormField>
  );
};
