import { GeneratedEMGGameExerciseDefinition } from '../../../types/GeneratedExerciseDefinition';

export const getPredefinedStellaBioGameEMGExoRunExercises = (): {
  [key: string]: GeneratedEMGGameExerciseDefinition;
} => ({
  exo_run_emg_view_mvc: {
    calibrationFlow: 'exo-run/stella',
    presentation: 'time-increment',
    tags: ['exo-run'],
    type: 'emg-game',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg'],
        emgCalibration: true,
        hasGuideline: false,
        steps: [],
      },
    },
    game: {
      id: 'exo_run',
      program: {
        phases: [],
      },
      parameters: {
        phases: [],
      },
    },
  },
});
