import { Box, keyframes } from '@chakra-ui/react';
import theme from 'config/theme';

interface StrippedProgressBarProps {
  value: number;
  animated?: boolean;
}

const progressAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 5rem 5rem;
  }
`;

const StrippedProgressBar = ({ value, animated = true }: StrippedProgressBarProps) => (
  <Box h="4" borderRadius="rMd" bg="gray.200" overflow="hidden" w="full">
    <Box
      w={`${value}%`}
      h="full"
      backgroundImage={
        animated
          ? `linear-gradient( 315deg, ${theme.colors.whiteAlpha['800']} 25%, transparent 25%, transparent 50%, ${theme.colors.whiteAlpha['800']} 50%, ${theme.colors.whiteAlpha['800']} 75%, transparent 75%, transparent )`
          : undefined
      }
      backgroundSize="2rem 2rem"
      backgroundColor="egzotechPrimaryColor"
      transitionProperty="var(--chakra-transition-property-common)"
      transitionDuration="var(--chakra-transition-duration-slow)"
      animation={`${progressAnimation} 4s linear infinite`}
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={value}
      role="progressbar"
    ></Box>
  </Box>
);

export default StrippedProgressBar;
