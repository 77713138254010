import { CPMProgram } from '@egzotech/exo-session/features/cpm';
import { MovementType } from '@egzotech/exo-session/features/motor';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';

import { DeepWritable, SensorsName, triggeringMethod } from '../types';
import {
  CPMProgramAdditionalConfiguration,
  isGeneratedCPMProgramDefinitionPrimary,
} from '../types/GeneratedCPMProgramDefinition';
import {
  ExerciseDefinition,
  GeneratedCPMExerciseDefinition,
  isSpecificExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';
import { MotorPlacement } from '../types/GeneratedProgramDefinition';

import { isSensorsName } from './CAMSettings';
import {
  CPMComprehensiveParameters,
  CPMPhasicParameters,
  isCPMComprehensiveParameterId,
  isCPMPhasicParameterId,
  SettingsParameters,
  SettingsRequiredMethods,
} from './SettingsBuilder';

export type CPMPhasicSignalParameters = {
  [key in keyof CPMPhasicParameters]: Signal<SettingsParameters<CPMPhasicParameters>[key]>;
};

export type CPMGeneralSignalParameters = {
  [key in keyof CPMComprehensiveParameters]: Signal<SettingsParameters<CPMComprehensiveParameters>[key]>;
};

export type CPMSignalParameters = CPMGeneralSignalParameters & {
  phases: CPMPhasicSignalParameters[];
};

export default class CPMSettings implements SettingsRequiredMethods {
  private _parameters: CPMSignalParameters;

  static readonly logger = Logger.getInstance('ExerciseSettings');

  get movementType() {
    return this._parameters.phases[0].movementType;
  }

  get hasDefinitionParameters() {
    const primaryProgramDefinition = this.originalDefinition.cpm[this.primaryMotorKey];
    return Boolean(
      primaryProgramDefinition &&
        isGeneratedCPMProgramDefinitionPrimary(primaryProgramDefinition) &&
        Object.keys(primaryProgramDefinition.parameters ?? {}).length > 0,
    );
  }

  constructor(
    private readonly originalDefinition: GeneratedCPMExerciseDefinition,
    private readonly primaryMotorKey: MotorPlacement,
  ) {
    let phases: CPMPhasicSignalParameters[] = [];
    let general: CPMGeneralSignalParameters = {
      maxTime: signal(
        {
          default: 0,
          values: [],
          blockAfterStart: false,
        },
        'CPMSettings._parameters.maxTime',
      ),
      maxTorque: signal(
        {
          default: 0,
          values: [],
          blockAfterStart: false,
        },
        'CPMSettings._parameters.maxTorque',
      ),
      maxBackwardForceLimit: signal(
        {
          default: 5,
          values: [],
          blockAfterStart: false,
        },
        'CPMSettings._parameters.maxBackwardForceLimit',
      ),
      triggeringType: signal(
        {
          default: 'uni-directional',
          values: [],
          blockAfterStart: true,
        },
        'CPMSettings._parameters.maxBackwardForceLimit',
      ),
    };
    const primaryProgramDefinition = originalDefinition.cpm[primaryMotorKey];
    if (primaryProgramDefinition && isGeneratedCPMProgramDefinitionPrimary(primaryProgramDefinition)) {
      if (primaryProgramDefinition.parameters?.phases) {
        phases = primaryProgramDefinition.parameters.phases.map((phase, i) => {
          return {
            repetitions: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].repetitions,
                default: phase?.repetitions?.default ?? 0,
                values: phase?.repetitions?.values ?? [],
                blockAfterStart: phase?.repetitions?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].repetitions,
              },
              'CPMSettings._parameters.phases.repetitions',
            ),
            speed: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].speed,
                default: phase?.speed?.default ?? 0,
                values: phase?.speed?.values ?? [],
                blockAfterStart: phase?.speed?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].speed,
              },
              'CPMSettings._parameters.phases.speed',
            ),
            time: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].time,
                default: phase?.time?.default ?? 0,
                values: phase?.time?.values ?? [],
                blockAfterStart: phase?.time?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].time,
              },
              'CPMSettings._parameters.phases.time',
            ),
            pauseTimeInROMMax: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].pauseTimeInROMMax ?? 0,
                default: phase?.pauseTimeInROMMax?.default ?? 0,
                values: phase?.pauseTimeInROMMax?.values ?? [],
                blockAfterStart: phase?.pauseTimeInROMMax?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].pauseTimeInROMMax,
              },
              'CPMSettings._parameters.phases.pauseTimeInROMMax',
            ),
            pauseTimeInROMMin: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].pauseTimeInROMMin ?? 0,
                default: phase?.pauseTimeInROMMin?.default ?? 0,
                values: phase?.pauseTimeInROMMin?.values ?? [],
                blockAfterStart: phase?.pauseTimeInROMMin?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].pauseTimeInROMMin,
              },
              'CPMSettings._parameters.phases.pauseTimeInROMMin',
            ),
            movementType: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].movementType,
                default: phase?.movementType?.default ?? 'normal',
                values: phase?.movementType?.values ?? [],
                blockAfterStart: phase?.movementType?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].movementType,
              },
              'CPMSettings._parameters.phases.movementType',
            ),
            forceTriggerSource: signal(
              {
                currentValue: undefined,
                default: phase?.forceTriggerSource?.default ?? 'knee', // FIXME: All this logic is wrong. What to do here?
                values: phase?.forceTriggerSource?.values ?? [],
                blockAfterStart: phase?.forceTriggerSource?.blockAfterStart ?? false,
              },
              'CPMSettings._parameters.phases.forceTriggerSource',
            ),
            triggeringMethod: signal(
              {
                currentValue: primaryProgramDefinition.program.phases[i].triggeringMethod,
                default: phase?.triggeringMethod?.default ?? 'triggerAndRelease',
                values: phase?.triggeringMethod?.values ?? [],
                blockAfterStart: phase?.triggeringMethod?.blockAfterStart ?? false,
                previousValue: primaryProgramDefinition.program.phases[i].triggeringMethod,
              },
              'CPMSettings._parameters.phases.triggeringMethod',
            ),
          };
        });
      }
      if (primaryProgramDefinition.parameters) {
        general = Object.keys(primaryProgramDefinition.parameters).reduce<CPMGeneralSignalParameters>((acc, curr) => {
          if (isCPMComprehensiveParameterId(curr) && primaryProgramDefinition?.parameters?.[curr]) {
            acc[curr]!.value.currentValue = primaryProgramDefinition.program[curr];
            acc[curr]!.value.default = primaryProgramDefinition.parameters[curr]?.default ?? 0;
            acc[curr]!.value.values = primaryProgramDefinition.parameters[curr]?.values ?? [];
            acc[curr]!.value.previousValue = primaryProgramDefinition.program[curr];
            acc[curr]!.value.blockAfterStart = primaryProgramDefinition.parameters[curr]?.blockAfterStart ?? false;
          }
          return acc;
        }, general);
      }
    }
    this._parameters = {
      phases,
      ...general,
    };
  }

  get parameters() {
    return this._parameters;
  }

  get triggeringType() {
    return this._parameters.triggeringType?.value.currentValue ?? 'uni-directional';
  }

  getPrimaryCPMProgram(definition = this.originalDefinition) {
    const primaryCPMProgram = definition.cpm[this.primaryMotorKey]?.program;
    if (!primaryCPMProgram) {
      throw new Error('Wrong primary motor key - primary CPM program is not defined');
    }
    return primaryCPMProgram as DeepWritable<CPMProgram> & DeepWritable<CPMProgramAdditionalConfiguration>;
  }

  setTriggeringType(triggeringType: 'uni-directional' | 'bi-directional') {
    if (!this._parameters.triggeringType?.peek()?.values?.includes(triggeringType)) {
      throw new Error(
        `Cannot set given triggeringType: ${triggeringType}. It must be one of the value from: [${
          this._parameters.triggeringType?.peek()?.values
        }]`,
      );
    }
    this._parameters.triggeringType.value = {
      ...this._parameters.triggeringType.peek(),
      currentValue: triggeringType,
    };
  }

  setRepetition(repetition: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (repetition < 1) {
      throw new Error(`Given repetition: ${repetition} is invalid. Exercise must have at least 1 repetition`);
    }
    if (!this._parameters.phases[phaseIndex].repetitions?.peek()?.values?.includes(repetition)) {
      throw new Error(
        `Cannot set given repetition: ${repetition}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].repetitions?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].repetitions!.value = {
      ...this._parameters.phases[phaseIndex].repetitions!.peek(),
      currentValue: repetition,
    };
  }

  setTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given duration: ${time} is invalid. Duration must be at least 1 second`);
    }
    if (!this._parameters.phases[phaseIndex].time?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].time?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].time!.value = {
      ...this._parameters.phases[phaseIndex].time!.peek(),
      currentValue: time,
    };
  }

  setSpeed(speed: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (speed < 0) {
      throw new Error(`Given speed: ${speed} is invalid. Speed must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].speed?.peek()?.values?.includes(speed)) {
      throw new Error(
        `Cannot set given speed: ${speed}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].speed?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].speed!.value = {
      ...this._parameters.phases[phaseIndex].speed!.peek(),
      currentValue: speed,
    };
  }

  setPauseTimeInROMMax(pauseTime: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (pauseTime < 0) {
      throw new Error(`Given time: ${pauseTime} is invalid. Pause time must be at least zero`);
    }
    if (!this._parameters.phases[phaseIndex].pauseTimeInROMMax?.peek()?.values?.includes(pauseTime)) {
      throw new Error(
        `Cannot set given time: ${pauseTime}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].pauseTimeInROMMax?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].pauseTimeInROMMax!.value = {
      ...this._parameters.phases[phaseIndex].pauseTimeInROMMax!.peek(),
      currentValue: pauseTime,
    };
  }

  setPauseTimeInROMMin(pauseTime: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (pauseTime < 0) {
      throw new Error(`Given time: ${pauseTime} is invalid. Pause time must be at least zero`);
    }
    if (!this._parameters.phases[phaseIndex].pauseTimeInROMMin?.peek()?.values?.includes(pauseTime)) {
      throw new Error(
        `Cannot set given time: ${pauseTime}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].pauseTimeInROMMin?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].pauseTimeInROMMin!.value = {
      ...this._parameters.phases[phaseIndex].pauseTimeInROMMin!.peek(),
      currentValue: pauseTime,
    };
  }

  setMovementType(movementType: MovementType, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (!this._parameters.phases[phaseIndex].movementType?.peek()?.values?.includes(movementType)) {
      throw new Error(
        `Cannot set given movementType: ${movementType}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].movementType?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].movementType!.value = {
      ...this._parameters.phases[phaseIndex].movementType!.peek(),
      currentValue: movementType,
    };
  }

  setForceTriggerSource(forceTriggerSource: SensorsName, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (!this._parameters.phases[phaseIndex].forceTriggerSource?.peek()?.values?.includes(forceTriggerSource)) {
      throw new Error(
        `Cannot set given forceTriggerSource: ${forceTriggerSource}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].forceTriggerSource?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].forceTriggerSource!.value = {
      ...this._parameters.phases[phaseIndex].forceTriggerSource!.peek(),
      currentValue: forceTriggerSource,
    };
  }

  settriggeringMethod(triggeringMethod: triggeringMethod, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (!this._parameters.phases[phaseIndex].triggeringMethod?.peek()?.values?.includes(triggeringMethod)) {
      throw new Error(
        `Cannot set given triggeringMethod: ${triggeringMethod}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].triggeringMethod?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].triggeringMethod!.value = {
      ...this._parameters.phases[phaseIndex].triggeringMethod!.peek(),
      currentValue: triggeringMethod,
    };
  }

  setMaxTime(time: number) {
    if (time < 0) {
      throw new Error(`Given duration: ${time} is invalid. Duration must be at least 1 second`);
    }
    if (!this._parameters.maxTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.maxTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.maxTime!.value = {
      ...this._parameters.maxTime!.peek(),
      currentValue: time,
    };
  }

  setMaxBackwardForceLimit(maxBackwardForceLimit: number) {
    if (maxBackwardForceLimit < 0) {
      throw new Error(
        `Given maxBackwardForceLimit: ${maxBackwardForceLimit} is invalid. Max maxBackwardForceLimit must be at least greater than zero`,
      );
    }
    this._parameters.maxBackwardForceLimit!.value = {
      ...this._parameters.maxBackwardForceLimit!.peek(),
      currentValue: maxBackwardForceLimit,
    };
  }

  estimateTotalDuration(range: { min: number; max: number }, definition = this.originalDefinition, phaseIndex = 0) {
    if (this.getPrimaryCPMProgram(definition).phases[phaseIndex].time) {
      CPMSettings.logger.debug(
        'estimateTotalDuration',
        'There is no need to estimate total duration time for exercise that is time based',
      );
      return this.getPrimaryCPMProgram(definition).phases[phaseIndex].time!;
    }
    // TODO: All this code below is just a mockup for calculating the time. There is no specification yet how
    // we should do that and from where we should fetch the total duration. This should be generalized for
    // multiple exercise types.

    const repetitionCount =
      this._parameters.phases[phaseIndex].repetitions?.peek()?.currentValue ??
      this._parameters.phases[phaseIndex].repetitions?.peek()?.default ??
      0;

    const pauses =
      ((this._parameters.phases[phaseIndex].pauseTimeInROMMax?.peek()?.currentValue ?? 0) +
        (this._parameters.phases[phaseIndex].pauseTimeInROMMin?.peek()?.currentValue ?? 0)) *
      repetitionCount;

    const estimatedTotalDuration =
      (((range.max - range.min) * 2) / (this.getPrimaryCPMProgram(definition).phases?.[phaseIndex]?.speed ?? 1)) *
        repetitionCount +
      pauses;

    return estimatedTotalDuration;
  }

  updateDefinition(definition: ExerciseDefinition) {
    if (!isSpecificExerciseDefinition(definition, ['cpm', 'cpm-emg', 'cpm-ems', 'cpm-ems-emg', 'cpm-force'])) {
      throw new Error('Cannot update non CPM definition in CPMSettings');
    }

    this._parameters.phases.forEach((parameter, phaseIndex) => {
      Object.entries(parameter).forEach(([k, v]) => {
        if (v?.peek()?.currentValue !== undefined && isCPMPhasicParameterId(k)) {
          // FIXME: this should work
          // this.getPrimaryCPMProgram(definition).phases[phaseIndex][k] = v.currentValue;

          if (
            typeof v?.peek()?.currentValue === 'number' &&
            k !== 'movementType' &&
            k !== 'triggeringMethod' &&
            k !== 'forceTriggerSource'
          ) {
            this.getPrimaryCPMProgram(definition).phases[phaseIndex][k] = v.peek().currentValue as number;
          } else if (typeof v?.peek()?.currentValue === 'string' && k === 'movementType') {
            this.getPrimaryCPMProgram(definition).phases[phaseIndex]['movementType'] =
              (v?.peek()?.currentValue as MovementType) ?? 'normal';
          } else if (typeof v?.peek()?.currentValue === 'string' && k === 'triggeringMethod') {
            this.getPrimaryCPMProgram(definition).phases[phaseIndex]['triggeringMethod'] =
              (v?.peek()?.currentValue as triggeringMethod) ?? 'hold-and-release';
          } else if (
            typeof v?.peek()?.currentValue === 'string' &&
            isSensorsName(v.peek().currentValue as string) &&
            k === 'forceTriggerSource'
          ) {
            this.getPrimaryCPMProgram(definition).phases[phaseIndex][k] = v.peek().currentValue as SensorsName;
          }
        }
      });
      if (typeof parameter.time?.peek()?.currentValue === 'number') {
        delete this.getPrimaryCPMProgram(definition).phases[phaseIndex].repetitions;
      } else if (typeof parameter.repetitions?.peek()?.currentValue === 'number') {
        delete this.getPrimaryCPMProgram(definition).phases[phaseIndex].time;
      }
    });
    Object.keys(this._parameters).forEach(k => {
      if (isCPMComprehensiveParameterId(k) && k === 'triggeringType' && this._parameters[k]?.peek()?.currentValue) {
        this.getPrimaryCPMProgram(definition)[k] = this._parameters[k]?.peek()?.currentValue;
      }
      if (
        isCPMComprehensiveParameterId(k) &&
        typeof this._parameters[k]?.peek()?.currentValue === 'number' &&
        k !== 'triggeringType'
      ) {
        this.getPrimaryCPMProgram(definition)[k] = this._parameters[k]?.peek()?.currentValue;
      }
    });
  }

  private validatePhaseParameter(phaseIndex: number) {
    if (
      phaseIndex < 0 ||
      phaseIndex >= (this.getPrimaryCPMProgram().phases.length ?? 0) ||
      !this.getPrimaryCPMProgram().phases[phaseIndex]
    ) {
      throw new Error(`There is no phaseIndex with given index: ${phaseIndex} for this exercise`);
    }
  }
}
