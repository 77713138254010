import { useContext } from 'react';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';

export const useCAMExercise = () => {
  const cam = useContext(CAMInstanceContext);
  if (cam) {
    return cam;
  }

  throw new Error('useCAMExercise must be used within a Provider which requires CAM feature');
};
