import {
  Box,
  Center,
  Container,
  List,
  ListItem,
  ModalBody,
  ModalContent,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { __ } from 'helpers/i18n';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

const assetType = ['music', 'asset', 'font'] as const;

export type GameAssetType = (typeof assetType)[number];

export type GameAssetLicense =
  | 'Creative Commons 0'
  | 'Attribution 3.0'
  | 'Attribution 4.0'
  | 'Creative Commons Attribution v.4.0 International'
  | 'Freeware'
  | 'SIL Open Font License, Version 1.1';

export const gameAssets: {
  name: string;
  type: GameAssetType;
  url: string;
  license: GameAssetLicense;
  author: string;
}[] = [
  {
    name: 'Ethereal Ambient music.wav',
    author: 'Clacksberg',
    type: 'music',
    url: 'https://freesound.org/s/500218/',
    license: 'Creative Commons 0',
  },
  {
    name: 'Space ambient music fragment.Wav',
    author: 'Clacksberg',
    type: 'music',
    url: 'https://freesound.org/s/491288/',
    license: 'Creative Commons 0',
  },
  {
    name: 'Musket Explosion',
    author: 'Willlewis',
    type: 'music',
    url: 'https://freesound.org/s/244345/',
    license: 'Creative Commons 0',
  },
  {
    name: 'Massive explosion.wav',
    author: 'djfroyd',
    type: 'music',
    url: 'https://freesound.org/s/353318/',
    license: 'Attribution 3.0',
  },
  {
    name: 'Explosion',
    author: 'Aesterial-Arts',
    type: 'music',
    url: 'https://freesound.org/s/633885/',
    license: 'Attribution 4.0',
  },
  {
    name: 'Pennywhistle',
    author: 'Jason Shaw',
    type: 'music',
    url: 'https://audionautix.com/',
    license: 'Creative Commons Attribution v.4.0 International',
  },
  {
    name: 'Desert Conflict',
    author: 'Rafael Krux',
    type: 'music',
    url: ' https://freepd.com/world.php',
    license: 'Creative Commons 0',
  },
  {
    name: 'Sunday Spirit',
    author: 'Jason Shaw',
    type: 'music',
    url: 'https://audionautix.com/free-music/gospel',
    license: 'Creative Commons Attribution v.4.0 International',
  },
  {
    name: 'Drop Point',
    author: 'Bryan Teoh',
    type: 'music',
    url: 'https://freepd.com/misc.php#google_vignette',
    license: 'Creative Commons 0',
  },
  {
    name: 'Alien UFO Pack',
    author: 'Kenney',
    type: 'asset',
    url: 'https://kenney.nl/assets/alien-ufo-pack',
    license: 'Creative Commons 0',
  },
  {
    name: 'Particle Fx',
    author: 'Raphael Hatencia',
    type: 'asset',
    url: 'https://ragnapixel.itch.io/particle-fx',
    license: 'Creative Commons Attribution v.4.0 International',
  },
  {
    name: 'Upheaval',
    author: 'Ænigma',
    type: 'font',
    url: 'https://www.dafont.com/upheaval.font',
    license: 'Freeware',
  },
  {
    name: 'Manolo Mono',
    author: 'Asilos Magdalena',
    type: 'font',
    url: ' https://befonts.com/manolo-mono-font.html',
    license: 'Freeware',
  },
  {
    name: 'League Spartan Bold',
    author: 'Matt Bailey, Tyler Finck',
    type: 'font',
    url: 'https://fonts.google.com/specimen/League+Spartan',
    license: 'SIL Open Font License, Version 1.1',
  },
];

export const LicenseModal = ({ close }: ModalProps) => {
  const assetGroups = gameAssets.reduce(
    (prev, acc) => {
      prev[acc.type].push(acc);
      return prev;
    },
    {
      music: [] as typeof gameAssets,
      asset: [] as typeof gameAssets,
      font: [] as typeof gameAssets,
    },
  );

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="4">
      <ModalBody>
        <Container
          variant="modalBox"
          py="4"
          px="0"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
        >
          <VStack justify="space-between" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text={'licenses.modal.header'} />
            </Center>
            <Box overflow="auto" h="md">
              <UnorderedList pl="4">
                {assetType.map(type => {
                  return (
                    <ListItem key={type}>
                      <TranslateText variant="openSans24Bold" text={`licenses.modal.${type}`} />
                      <List>
                        {assetGroups[type].map(asset => (
                          <ListItem key={asset.name} w="full">
                            <Text w="full" variant="openSans24">
                              - {asset.name} {__('licenses.modal.by')} {asset.author}, {asset.url}
                            </Text>
                          </ListItem>
                        ))}
                      </List>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Box>
            <MainButton text="common.close" variant="mdPrimaryButton" onClick={close} minW="32" mt="4" />
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
