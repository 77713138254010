import { CSSProperties, Fragment, ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { FaBolt } from 'react-icons/fa';
import { Box, Grid, HStack, VStack } from '@chakra-ui/react';
import {
  DummyBatteryFeatureController,
  DummyCableFeatureController,
  DummyEMGFeatureController,
  DummyModuleIdentificationFeatureController,
  DummyMotorFeatureController,
  DummySensorForceFeatureController,
  ExoSession,
  isDummyModule,
} from '@egzotech/exo-session';
import { DummyExtensionFeatureController } from '@egzotech/exo-session/dist/src/dummy/DummyExtensionFeature';
import { DummyRemoteFeatureController } from '@egzotech/exo-session/dist/src/dummy/DummyRemoteFeature';
import { DummySensorAngleFeatureController } from '@egzotech/exo-session/dist/src/dummy/DummySensorAngleFeature';
import { ChannelConnectionQuality, ExoCableFeature } from '@egzotech/exo-session/features/cable';
import styled from '@emotion/styled';
import { useSignals } from '@preact/signals-react/runtime';
import { sensorsRange } from 'config/defaultConfigProps';
import colors, { Channel } from 'config/theme/colors';
import { logger } from 'helpers/logger';
import { BATTERY_MAX, BATTERY_MIN, Device, DeviceManager } from 'libs/exo-session-manager/core';
import { DummyButtonList } from 'libs/exo-session-manager/dev-tools/DummyButtonList';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

import useForceRerender from '../react/hooks/useForceRerender';

interface DeveloperBarProps {
  active: boolean;
  deviceManager: DeviceManager;
  dummyDevice: boolean;
  setDummyDevice: (dummyDeviceFlag: boolean) => void;
}

const Navigation = styled.div`
  flex-grow: 1;
  border: 1px solid black;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 5px;
  column-gap: 5px;
`;

const VerticalAlignment = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

const BarWrapper = styled.div<{ visible: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid black;
  font-size: 12px;
  padding: 5px;
  column-gap: 5px;
  transition: transform 0.2s;
  opacity: 0.95;
  z-index: 999999;
  transform: ${props => (props.visible ? `translateY(0)` : `translateY(-100%)`)};
`;

const DummyCheckboxWrapper = styled.input`
  margin-right: 2.5px;
`;

const ControllerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  border: 1px solid black;
  font-size: 10px;
  padding: 2px;
`;

const CableSelectorWrapper = styled.select`
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

const ExtensionSelectorWrapper = styled.select`
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

const ChannelBoxWrapper = styled.div<{ color: CSSProperties['backgroundColor']; active?: boolean }>`
  border: 1px solid black;
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.active ? 'pointer' : 'default')};
  color: ${props => (props.active ? 'white' : 'black')};
  user-select: none;
`;

const ChannelButtonWrapper = styled.div`
  padding: 2px;
  background: white;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &:active {
    background-color: red;
    color: white;
  }

  &:hover {
    color: #ff8080;
  }
`;

const RemoteButton = styled.button`
  border: 1px solid black;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  width: 3rem;
  height: 3rem;
  user-select: none;

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const RemoteLedring = styled.div`
  width: 3rem;
  height: 3rem;
  border: 4px solid gray;
  border-radius: 100%;
  margin: 0.25rem auto;
`;

const ModuleGroup = styled.button`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
`;

const StatusBarContainer = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

function StatusBar({ modules }: { modules: readonly object[] }) {
  useForceRerender(500);
  const maybeElectrostimModule = modules.find(v => isDummyModule(v, 'electrostim'));
  let electrostimModule;

  if (maybeElectrostimModule && isDummyModule(maybeElectrostimModule, 'electrostim')) {
    electrostimModule = maybeElectrostimModule;
  }

  return (
    <StatusBarContainer data-testid="developer-bar-status">
      <HStack
        borderBottom={electrostimModule?.isElectrostimulating ? '8px solid red' : '8px solid rgba(0, 0, 0, 0.25)'}
      >
        {electrostimModule?.isElectrostimulating && (
          <HStack>
            <HStack color="white" bg="red" h="2rem" align="center" data-testid={`status-electrostim`}>
              <FaBolt />
              <Box>Electrostim</Box>
              <FaBolt />
            </HStack>
            {electrostimModule.channels.map(v => (
              <HStack key={v.index} data-testid={`status-electrostim-channel.${v.index + 1}`}>
                <Box
                  w="1.5rem"
                  h="1.5rem"
                  fontWeight="bold"
                  borderRadius="100%"
                  textAlign="center"
                  color="white"
                  bg={colors[`channel.${(v.index + 1) as Channel}`]}
                >
                  {v.index + 1}
                </Box>
                <Box data-testid={`status-electrostim-channel.${v.index + 1}.current`}>
                  {(v.program.amp ?? 0) * 1000}mA
                </Box>
              </HStack>
            ))}
          </HStack>
        )}
      </HStack>
    </StatusBarContainer>
  );
}

const Bar = ({ children }: { children: ReactNode }) => {
  const [visible, setVisible] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => {
      setVisible(value => {
        if (barRef.current) {
          if (!value && e.clientY < 20) {
            return true;
          }
          if (e.clientY > barRef.current.offsetHeight) {
            return false;
          }
        }
        return value;
      });
    };

    window.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  return (
    <BarWrapper visible={visible} onMouseEnter={() => setVisible(true)} ref={barRef} data-testid="developer-bar">
      {children}
    </BarWrapper>
  );
};

const ChannelBox = ({
  channel,
  module,
  children,
}: {
  channel: number;
  module: DummyCableFeatureController;
  children: ReactNode;
}) => {
  const getColorByStatus = (channel: number): CSSProperties['backgroundColor'] => {
    switch (module.getChannelQuality(channel)) {
      case ChannelConnectionQuality.WELL:
        return 'green';
      case ChannelConnectionQuality.POOR:
        return 'orange';
      case ChannelConnectionQuality.NONE:
        return 'red';
      default:
        return 'red';
    }
  };

  const clickHandler = () => {
    let newStatus: ChannelConnectionQuality;
    switch (module.getChannelQuality(channel)) {
      case ChannelConnectionQuality.WELL:
        newStatus = ChannelConnectionQuality.POOR;
        break;
      case ChannelConnectionQuality.POOR:
        newStatus = ChannelConnectionQuality.NONE;
        break;
      case ChannelConnectionQuality.NONE:
        newStatus = ChannelConnectionQuality.WELL;
        break;
      default:
        newStatus = ChannelConnectionQuality.WELL;
    }
    module.setChannelQuality(channel, newStatus);
  };

  return (
    <ChannelBoxWrapper
      color={getColorByStatus(channel)}
      active={true}
      onClick={clickHandler}
      data-testid="channel-box-dev"
    >
      {children}
    </ChannelBoxWrapper>
  );
};

const DummyCable = ({ module }: { module: DummyCableFeatureController }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    }}
  >
    <CableSelectorWrapper
      data-testid="developer-bar-select-cable"
      value={
        module.getCable()?.id ??
        Object.values(ExoCableFeature.availableCableTypes).find(value => value.description === 'cable.none')?.id
      }
      onChange={e => module.setCable(ExoCableFeature.availableCableTypes[e.target.value])}
    >
      {Object.entries(ExoCableFeature.availableCableTypes).map(([key, value]) => (
        <option key={key} value={value.id}>
          {value.description}
        </option>
      ))}
    </CableSelectorWrapper>
    <div
      style={{
        display: 'flex',
      }}
    >
      {module.getCable()?.channels.map(channel =>
        channel < module.maxSupportedChannels ? (
          <VerticalAlignment key={channel}>
            <ChannelBox channel={channel} module={module}>
              {channel + 1}
            </ChannelBox>
          </VerticalAlignment>
        ) : null,
      )}
    </div>
  </div>
);

const DummyExtension = ({
  module,
  selectedDevice,
}: {
  module: DummyExtensionFeatureController;
  selectedDevice: Device;
}) => {
  const extensions = { ...selectedDevice.session?.options.extension?.types };
  extensions['none'] = {
    id: -1,
    features: [],
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ExtensionSelectorWrapper
        data-testid="extension-box"
        value={selectedDevice.extensionType ?? 'none'}
        onChange={e => {
          module.setExtension(e.target.value !== 'none' ? extensions[e.target.value] : null);
        }}
      >
        {Object.entries(extensions).map(([key, _]) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </ExtensionSelectorWrapper>
    </div>
  );
};

const DummyEMGController = ({
  module,
  selectedDevice,
}: {
  module: DummyEMGFeatureController;
  selectedDevice: Device;
}) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    {selectedDevice?.cableType?.channels.map(channel =>
      channel < module.maxSupportedChannels ? (
        <ChannelButtonWrapper key={channel} onClick={() => module.applyEmgImpulse(channel, 2000)}>
          <VerticalAlignment>
            <div>{channel + 1}</div>
            <FaBolt />
          </VerticalAlignment>
        </ChannelButtonWrapper>
      ) : null,
    )}
  </div>
);

const DummyBattery = ({
  module,
  selectedDevice,
}: {
  module: DummyBatteryFeatureController;
  selectedDevice: Device;
}) => {
  const forceRerender = useForceRerender();

  const normalizeVoltage = (voltage: number) => {
    if (voltage > BATTERY_MAX) {
      logger.warn('DummyBattery.normalizeVoltage', `Cannot set voltage ${voltage}, the max is ${BATTERY_MAX}`);
      return BATTERY_MAX;
    }
    if (voltage < BATTERY_MIN) {
      logger.warn('DummyBattery.normalizeVoltage', `Cannot set voltage ${voltage}, the min is ${BATTERY_MIN}`);
      return BATTERY_MIN;
    }
    return voltage;
  };

  return (
    <ControllerWrapper>
      <div>Battery</div>
      <div>Voltage: {module.getBatteryVoltage()}</div>
      <div>Percent: {selectedDevice?.batteryLevel ? selectedDevice.batteryLevel.toFixed(0) + '%' : '0%'}</div>
      <input
        type="range"
        min={7.0}
        max={8.1}
        step={0.1}
        placeholder="Battery voltage"
        onChange={e => {
          module.setBatteryVoltage(normalizeVoltage(+e.target.value));
          forceRerender();
        }}
      />
    </ControllerWrapper>
  );
};

const DummyModuleIdentification = (props: {
  module: DummyModuleIdentificationFeatureController;
  forceRerender?: () => void;
}) => {
  const { module } = props;
  const [_, forceRerender] = useReducer(v => v + 1, 0);

  return (
    <button
      style={{ border: `1px solid ${module.enabled ? 'green' : 'red'}`, width: '100%', wordBreak: 'break-word' }}
      onClick={e => {
        e.stopPropagation();
        module.enabled ? module.disable() : module.enable();
        module.resetVersion();
        props.forceRerender ? props.forceRerender() : forceRerender();
      }}
    >
      {module.name}
    </button>
  );
};

const DummyMotorRemoteController = ({
  module,
  session,
}: {
  module: DummyMotorFeatureController;
  session: ExoSession;
}) => {
  const [speed, setSpeed] = useState(module.remoteSpeed);

  useForceRerender(1000);

  const formatCoupling = (coupling: (typeof module)['coupling']) => {
    const rows = [];

    for (const force in coupling.force) {
      const cols = [];
      cols.push(force);
      cols.push(coupling.force[force].negative.sensitivity.toFixed(1));
      cols.push(coupling.force[force].negative.deadband.toFixed(1));
      cols.push(coupling.force[force].positive.sensitivity.toFixed(1));
      cols.push(coupling.force[force].positive.deadband.toFixed(1));

      rows.push(
        <tr>
          {cols.map((v, i) => (
            <td style={{ paddingLeft: '5px' }} align="right" key={i}>
              {v}
            </td>
          ))}
        </tr>,
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>f-sensor</th>
            <th>S(n)</th>
            <th>D(n)</th>
            <th>S(p)</th>
            <th>D(p)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <ControllerWrapper data-testid={'motor-controller-' + module.name.toLowerCase()}>
      <div>{module.name.toUpperCase()} motor remote controller:</div>
      <div>
        Current angle: <span>{module.angle.toFixed(2)}°</span>
      </div>
      <div>
        Current coupling: <span>{formatCoupling(module.coupling)}</span>
      </div>
      <div>
        Max speed: {module.maxSpeed.negative}°/s (n), {module.maxSpeed.positive}°/s (p)
      </div>
      <div>
        Range: {module.range.min.toFixed(2)}° - {module.range.max.toFixed(2)}°
      </div>
      <div>Constant speed: {module.constantSpeed}°/s</div>
      {session.features.includes('remote') ? (
        <div style={{ display: 'flex', gap: '0.25rem' }}>
          Remote Speed:
          <select
            style={{ flexGrow: '1' }}
            value={speed}
            onChange={e => {
              module.setRemoteSpeed(+e.target.value);
              setSpeed(+e.target.value);
            }}
            data-testid={`motor-controller-${module.name.toLowerCase()}-remote-speed`}
          >
            {/* TODO: Synchronize with firmware in the future */}
            <option value="1">1 °/s</option>
            <option value="2">2 °/s</option>
            <option value="5">5 °/s</option>
            <option value="10">10 °/s</option>
            <option value="12">12 °/s</option>
            <option value="15">15 °/s</option>
            <option value="20">20 °/s</option>
          </select>
        </div>
      ) : null}
    </ControllerWrapper>
  );
};

const DummyForceSensorController = ({ module }: { module: DummySensorForceFeatureController }) => {
  const [force, setForce] = useState(0);
  useForceRerender(1000);

  const min = sensorsRange[module.name as keyof typeof sensorsRange].min;
  const max = sensorsRange[module.name as keyof typeof sensorsRange].max;
  return (
    <ControllerWrapper>
      <div>{module.name.toUpperCase()} Force Sensor:</div>
      <div>Applied Force: {force.toFixed(3)}</div>
      <div>Measured Force: {module.value.toFixed(3)}</div>
      <input
        data-testid={`${module.name}-force-controller`}
        type="range"
        min={min}
        max={max}
        step="0.05"
        value={force}
        onChange={e => {
          const newValue = +e.target.value;
          setForce(newValue);
          module.applyForce(newValue);
        }}
        style={{
          marginTop: 'auto',
        }}
      />
      <button
        onClick={() => {
          module.applyForce(0);
          setForce(0);
        }}
      >
        Reset force
      </button>
    </ControllerWrapper>
  );
};

const DummyAngleSensorController = ({ module }: { module: DummySensorAngleFeatureController }) => {
  const [angle, setAngle] = useState(0);

  const min = sensorsRange[module.name as keyof typeof sensorsRange].min;
  const max = sensorsRange[module.name as keyof typeof sensorsRange].max;

  useEffect(() => {
    module.setValid(true);
  }, [module]);

  return (
    <ControllerWrapper>
      <div>{module.name.toUpperCase()} Angle</div>
      <p>Current angle: {module.value}</p>
      <input
        data-testid={`${module.name}-angle-controller`}
        type="range"
        min={min}
        max={max}
        step="15"
        value={angle}
        onChange={e => {
          setAngle(+e.target.value);
          module.setAngle(+e.target.value);
        }}
        style={{
          marginTop: 'auto',
        }}
        onMouseDown={() => {
          module.setValid(false);
        }}
        onMouseUp={() => {
          module.setValid(true);
        }}
      />
      <button
        onClick={() => {
          module.setAngle(0);
          setAngle(0);
          module.setValid(true);
        }}
      >
        Reset angle
      </button>
    </ControllerWrapper>
  );
};

const DummyRemote = ({ module }: { module: DummyRemoteFeatureController }) => {
  return (
    <ControllerWrapper data-testid="remote-controller">
      <RemoteLedring />
      <Grid templateColumns="repeat(2, 1fr)" gap="4" p="4">
        {module.buttons.map(v => (
          <RemoteButton
            key={v}
            onContextMenu={e => e.preventDefault()}
            onMouseDown={() => module.press(v)}
            onMouseUp={() => module.release(v)}
            onPointerDown={e => {
              e.preventDefault();
              module.press(v);
            }}
            onPointerUp={e => {
              e.preventDefault();
              module.release(v);
            }}
            onPointerLeave={() => {
              module.release(v);
            }}
            data-testid={`remote-controller-${v}`}
          >
            {v}
          </RemoteButton>
        ))}
      </Grid>
    </ControllerWrapper>
  );
};

const DeveloperBar = ({ active, deviceManager, dummyDevice, setDummyDevice }: DeveloperBarProps) => {
  useSignals();
  const virtualKeyboardActiveInitialState = EGZOTechHostApi.instance?.options?.disableVirtualKeyboard ? false : true;
  const virtualKeyboardActiveLocalState = localStorage.getItem('virtualKeyboardFlag');
  const [virtualKeyboardActive, setVirtualKeyboardActive] = useState<boolean>(virtualKeyboardActiveInitialState);
  const [statusBarActive, setStatusBarActive] = useState<boolean>(true);
  const [_, forceRerender] = useReducer(v => v + 1, 0);

  const [screenDimensions, setScreenDimensions] = useState<{
    screenWidth: number;
    screenHeight: number;
    windowWidth: number;
    windowHeight: number;
  } | null>(null);

  useEffect(() => {
    const readDimensions = () => {
      setScreenDimensions({
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };

    readDimensions();
    window.addEventListener('resize', readDimensions);

    return () => {
      window.removeEventListener('resize', readDimensions);
    };
  }, []);

  useEffect(() => {
    setVirtualKeyboardActive(
      typeof virtualKeyboardActiveLocalState === 'string'
        ? virtualKeyboardActiveLocalState === 'true'
        : virtualKeyboardActiveInitialState,
    );
  }, [virtualKeyboardActiveInitialState, virtualKeyboardActiveLocalState]);

  const handleKeyboardActiveChange = (status: boolean) => {
    setVirtualKeyboardActive(status);
    localStorage.setItem('virtualKeyboardFlag', String(status));
  };

  const elements = dummyDevice ? deviceManager.getDummyDeviceModules() ?? [] : [];

  // moduleGroup configuration allows individual modules to be grouped into a new group.
  // A group can switch off/on all modules in the group simultaneously. Modules can also be switched individually.
  const moduleGroups = {
    ankle: {
      modules: ['motor:ankle', 'sensor-force:ankle'],
      exists: [] as DummyModuleIdentificationFeatureController[],
    },
  };

  return (
    <>
      {statusBarActive && dummyDevice && <StatusBar modules={elements} />}
      <Bar>
        <Navigation>
          <VStack gap="0.25rem" align="flex-start">
            <label htmlFor="dummy">
              <DummyCheckboxWrapper
                type="checkbox"
                id="dummy"
                checked={dummyDevice}
                onChange={() => setDummyDevice(!dummyDevice)}
              />
              Dummy
            </label>
            <label
              htmlFor="virtual-keyboard"
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <input
                type="checkbox"
                id="virtual-keyboard"
                checked={virtualKeyboardActive}
                onChange={() => handleKeyboardActiveChange(!virtualKeyboardActive)}
                style={{
                  marginRight: '2.5px',
                }}
              />
              <div
                style={{
                  lineHeight: '110%',
                }}
              >
                Virtual
                <br />
                Keyboard
              </div>
            </label>
            {dummyDevice && (
              <>
                <label
                  htmlFor="status-bar"
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <input
                    type="checkbox"
                    id="status-bar"
                    checked={statusBarActive}
                    onChange={() => setStatusBarActive(!statusBarActive)}
                    style={{
                      marginRight: '2.5px',
                    }}
                  />
                  <div
                    style={{
                      lineHeight: '110%',
                    }}
                  >
                    Show
                    <br />
                    status bar
                  </div>
                </label>
              </>
            )}
          </VStack>
          <DummyButtonList deviceManager={deviceManager} scannerActive={active} modules={elements} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '4px',
              width: 'auto',
            }}
          >
            {elements.map((module, i) => {
              if (isDummyModule(module, 'cable')) {
                return <DummyCable key={i} module={module} />;
              }
              if (isDummyModule(module, 'emg')) {
                return <DummyEMGController key={i} module={module} selectedDevice={deviceManager.selectedDevice!} />;
              }
              if (isDummyModule(module, 'module-identification')) {
                const groupId = Object.entries(moduleGroups).find(([_, v]) => v.modules.includes(module.name))?.[0];
                if (groupId) {
                  moduleGroups[groupId as keyof typeof moduleGroups].exists.push(module);
                  return null;
                }

                return <DummyModuleIdentification key={i} module={module} />;
              }
              return <></>;
            })}
            {Object.entries(moduleGroups)
              .filter(([_, v]) => v.exists.length)
              .map(([k, v]) => {
                const isDisabled = v.exists.every(m => !m.enabled);
                const isEnabled = v.exists.every(m => m.enabled);
                return (
                  <ModuleGroup
                    key={k}
                    style={{
                      border: `1px solid ${isEnabled ? 'green' : isDisabled ? 'red' : 'orange'}`,
                    }}
                    onClick={_ => {
                      const state = v.exists[0].enabled;
                      v.exists.forEach(module => {
                        state ? module.disable() : module.enable();
                        module.resetVersion();
                      });
                      forceRerender();
                    }}
                  >
                    <>{k}</>
                    <div style={{ display: 'flex', gap: '2px', width: 'auto' }}>
                      {v.exists.map((module, i) => {
                        return (
                          <DummyModuleIdentification key={i} module={module} forceRerender={() => forceRerender()} />
                        );
                      })}
                    </div>
                  </ModuleGroup>
                );
              })}
          </div>
          {elements.map((module, i) => (
            <Fragment key={i}>
              {isDummyModule(module, 'motor') && (
                <DummyMotorRemoteController module={module} session={deviceManager.session!} />
              )}
              {isDummyModule(module, 'sensor-force') && <DummyForceSensorController module={module} />}
              {isDummyModule(module, 'battery') && (
                <DummyBattery module={module} selectedDevice={deviceManager.selectedDevice!} />
              )}
              {isDummyModule(module, 'remote') && <DummyRemote module={module} />}
              {isDummyModule(module, 'sensor-angle') && <DummyAngleSensorController module={module} />}
              {isDummyModule(module, 'extension') && (
                <DummyExtension module={module} selectedDevice={deviceManager.selectedDevice!} />
              )}
            </Fragment>
          ))}
        </Navigation>
        {deviceManager.selectedDevice && (
          <VerticalAlignment>
            <div>
              {deviceManager.selectedDevice.displayName} [{deviceManager.selectedDevice.type}]
            </div>
            <div>Status: {deviceManager.selectedDevice.connectionStatus}</div>
            {screenDimensions && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                onClick={() => {
                  document.querySelector('body')?.requestFullscreen();
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                Screen: {screenDimensions.screenWidth}x{screenDimensions.screenHeight}
                <br />
                Window: {screenDimensions.windowWidth}x{screenDimensions.windowHeight}
              </div>
            )}
            <div>App Version:{(globalThis as any).egzoclinicVersion}</div>
          </VerticalAlignment>
        )}
      </Bar>
    </>
  );
};

export default DeveloperBar;
