import React, { memo, useCallback } from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { CalibrationFlow } from 'libs/exo-session-manager/core';

import { MainButton } from 'components/buttons/MainButton';

export const CalibrationFlowBackButton = memo(
  ({
    flow,
    minW,
    w,
    onClick,
  }: {
    flow: CalibrationFlow;
    minW?: ButtonProps['minW'];
    w?: ButtonProps['w'];
    onClick?: () => void;
  }) => {
    const back = useCallback(() => flow.previous(), [flow]);

    return (
      <MainButton
        variant={{ lg: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
        text="common.back"
        data-testid="back-button"
        onClick={() => (onClick ? onClick() : back())}
        minW={minW}
        w={w}
      />
    );
  },
);

CalibrationFlowBackButton.displayName = 'CalibrationFlowBackButton';
