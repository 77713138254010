import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export const trainingReportApi = createApi({
  reducerPath: 'trainingReportApi',
  baseQuery: fetchBaseQuery({
    fetchFn: queryFetchFn,
    baseUrl: API_URL,
  }),
  endpoints: builder => ({
    calculateEntry: builder.query<
      { processor: ExoClinicBackendOpenApiSchemas['TrainingReportProcessorResponseDto'][] },
      { trainingId: string } & { processor: ExoClinicBackendOpenApiSchemas['TrainingReportProcessorRequestDto'][] }
    >({
      query: ({ trainingId, ...body }) => ({
        url: `training/${trainingId}/report`,
        method: 'POST',
        body,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { trainingId, processor } = queryArgs;
        return { trainingId, processor };
      },
    }),
  }),
});

export const { useCalculateEntryQuery } = trainingReportApi;
