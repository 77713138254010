import { extendTheme, Theme, ThemeOverride } from '@chakra-ui/react';
import breakpoints from 'config/theme/breakpoints';

import borderWidths from './borderWidths';
import colors from './colors';
import components from './components';
import mediaQuerySizes from './mediaQuerySizes';
import radii from './radii';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import styles from './styles';
import typography from './typography';

const overrides = {
  colors,
  components,
  styles,
  sizes,
  mediaQuerySizes,
  space,
  radii,
  shadows,
  borderWidths,
  breakpoints,
  ...typography, // spread is necessary
};

export default extendTheme(overrides as ThemeOverride) as typeof overrides & Theme;
