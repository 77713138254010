import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export type WifiState = {
  name?: string | null;
  signal?: number;
} & ExoClinicBackendOpenApiSchemas['WiFiStatusResponseDto'];

const initialState: WifiState = {
  connected: false,
};

const wifiSlice = createSlice({
  name: 'wifi',
  initialState,
  reducers: {
    setWifiConnect: (state, { payload }: PayloadAction<WifiState>) => ({ ...state, ...payload }),
    setWifiDisconnect: () => ({ connected: false }),
    setWifiStatus: (state, { payload }: PayloadAction<ExoClinicBackendOpenApiSchemas['WiFiStatusResponseDto']>) => ({
      ...state,
      ...payload,
    }),
  },
});
export const { setWifiConnect, setWifiStatus, setWifiDisconnect } = wifiSlice.actions;

export default wifiSlice.reducer;
