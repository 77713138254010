import { Button } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/store';
import { useAppNavigate } from 'hooks/useAppNavigate';

import { ServiceHelpIcon } from 'components/icons';

export function useHelpRoute(id?: string) {
  const user = useAppSelector(state => state.user.userData);

  if (id) {
    return user ? `/specialist/help?id=${id}` : `/help?id=${id}`;
  }

  return user ? '/specialist/help' : '/help';
}

export function ServiceHelpButton() {
  const navigate = useAppNavigate();
  const route = useHelpRoute();

  return (
    <Button
      variant="lightTextButton"
      py="3.5"
      boxSize="16"
      data-testid="service-help-button"
      onClick={() => {
        navigate(route);
      }}
    >
      <ServiceHelpIcon color="whiteAlpha.800" boxSize="8" />
    </Button>
  );
}
