import { deviceTolerances, rangeTolerances } from 'config/defaultConfigProps';

import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';
import { withCondition } from './withCondition';

export function cpmKneeFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-leg-side-selection': {
      prev,
      next: 'leg-basing-set-thigh-length',
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-thigh-length': {
      prev: 'leg-basing-leg-side-selection',
      next: 'leg-basing-set-safe-range-knee',
      stepIndex: stepIndex + 1,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-knee': {
      prev: 'leg-basing-set-thigh-length',
      next: 'leg-basing-move-into-range-conditional-knee-after-safe-range',
      stepIndex: stepIndex + 2,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-move-into-range-conditional-knee-after-safe-range': {
      next: 'leg-basing-set-safe-range-ankle',
      prev: 'leg-basing-set-safe-range-knee',
      ...withCondition('isInRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-set-safe-range-knee',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 3,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-ankle': {
      prev: 'leg-basing-set-safe-range-knee',
      next: 'leg-basing-move-into-range-conditional-ankle-after-safe-range',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 4,
    },
    'leg-basing-move-into-range-conditional-ankle-after-safe-range': {
      next: 'leg-basing-you-can-safely-place-leg',
      prev: 'leg-basing-set-safe-range-ankle',
      ...withCondition('isInRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-set-safe-range-ankle',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 5,
    },
    'leg-basing-you-can-safely-place-leg': {
      prev: 'leg-basing-set-safe-range-ankle',
      next: 'leg-basing-adjust-length-of-the-splint',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 6,
    },
    'leg-basing-adjust-length-of-the-splint': {
      prev: 'leg-basing-you-can-safely-place-leg',
      next: 'leg-basing-adjust-foot-to-tip',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 7,
    },
    'leg-basing-adjust-foot-to-tip': {
      prev: 'leg-basing-adjust-length-of-the-splint',
      next: 'leg-basing-check-bend-points',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 8,
    },
    'leg-basing-check-bend-points': {
      prev: 'leg-basing-adjust-foot-to-tip',
      next: 'leg-basing-move-into-range-conditional-knee-before-passive-range',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 9,
    },
    'leg-basing-move-into-range-conditional-knee-before-passive-range': {
      next: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      prev: 'leg-basing-check-bend-points',
      ...withCondition('isInRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 10,
    },
    'leg-basing-max-flexion-and-extension-measurement-passive-knee': {
      prev: 'leg-basing-check-bend-points',
      next: 'leg-basing-move-into-range-conditional-knee-before-unweight',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 11,
    },
    'leg-basing-move-into-range-conditional-knee-before-unweight': {
      next: 'leg-basing-move-into-range-conditional-ankle-before-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      ...withCondition('isInMiddleRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 12,
    },
    'leg-basing-move-into-range-conditional-ankle-before-unweight': {
      next: 'leg-basing-limb-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      ...withCondition('isInMiddleRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-set-safe-range-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 13,
    },
    'leg-basing-limb-unweight': {
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      next,
      stepIndex: stepIndex + 14,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
  } as const;
}
export function cpmMeissaFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'meissa-basing-set-correct-position': {
      prev: 'meissa-basing-side-selection',
      next: 'detach-extension',
      stepIndex: stepIndex + 1,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
    'detach-extension': {
      condition: 'detachExtensionIfAttached',
      prev: 'meissa-basing-set-correct-position',
      next: 'meissa-extension-unweight',
      stepIndex: stepIndex + 2,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
    'meissa-extension-unweight': {
      condition: 'skipTare',
      prev: 'detach-extension',
      next: 'attach-extension',
      stepIndex: stepIndex + 3,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
    'attach-extension': {
      condition: 'isCorrectExtensionAttached',
      prev: 'meissa-extension-unweight',
      next: 'meissa-relief-correct-position',
      stepIndex: stepIndex + 4,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
    'meissa-relief-correct-position': {
      prev: 'attach-extension',
      next: 'meissa-relief-device',
      condition: 'isInReliefCorrectPosition',
      stepIndex: stepIndex + 5,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'meissa-relief-device': {
      condition: 'skipRelief',
      prev: 'meissa-relief-correct-position',
      next: 'meissa-move-into-center-before-passive-range',
      stepIndex: stepIndex + 6,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'meissa-move-into-center-before-passive-range': {
      prev: 'meissa-relief-device',
      next: 'meissa-basing-range-of-movement',
      ...withCondition('isInRange', {
        motorName: 'main',
        rangeProviderState: 'meissa-basing-range-of-movement',
        // No tolerance by purpose - we dont want to create bigger range of movement
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 7,
    },
    'meissa-basing-range-of-movement': {
      prev: 'meissa-relief-device',
      next: 'meissa-spasticism-armrest',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 8,
    },
    'meissa-spasticism-armrest': {
      prev: 'meissa-basing-range-of-movement',
      next,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 9,
    },
  } as const;
}

export function cpmAnkleFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-leg-side-selection': {
      prev,
      next: 'leg-basing-set-thigh-length',
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-thigh-length': {
      prev: 'leg-basing-leg-side-selection',
      next: 'leg-basing-set-safe-range-knee',
      stepIndex: stepIndex + 1,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-knee': {
      prev: 'leg-basing-set-thigh-length',
      next: 'leg-basing-move-into-range-conditional-knee-after-safe-range',
      stepIndex: stepIndex + 2,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-move-into-range-conditional-knee-after-safe-range': {
      next: 'leg-basing-set-safe-range-ankle',
      prev: 'leg-basing-set-safe-range-knee',
      ...withCondition('isInRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-set-safe-range-knee',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 3,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-ankle': {
      prev: 'leg-basing-set-safe-range-knee',
      next: 'leg-basing-move-into-range-conditional-ankle-after-safe-range',
      stepIndex: stepIndex + 4,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-move-into-range-conditional-ankle-after-safe-range': {
      next: 'leg-basing-you-can-safely-place-leg',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      prev: 'leg-basing-set-safe-range-ankle',
      ...withCondition('isInRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-set-safe-range-ankle',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 5,
    },
    'leg-basing-you-can-safely-place-leg': {
      prev: 'leg-basing-set-safe-range-ankle',
      next: 'leg-basing-adjust-length-of-the-splint',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 6,
    },
    'leg-basing-adjust-length-of-the-splint': {
      prev: 'leg-basing-you-can-safely-place-leg',
      next: 'leg-basing-adjust-foot-to-tip',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 7,
    },
    'leg-basing-adjust-foot-to-tip': {
      prev: 'leg-basing-adjust-length-of-the-splint',
      next: 'leg-basing-check-bend-points',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 8,
    },
    'leg-basing-check-bend-points': {
      prev: 'leg-basing-adjust-foot-to-tip',
      next: 'leg-basing-move-into-range-conditional-ankle-before-passive-range',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 9,
    },
    'leg-basing-move-into-range-conditional-ankle-before-passive-range': {
      next: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      prev: 'leg-basing-check-bend-points',
      ...withCondition('isInRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 10,
    },
    'leg-basing-max-flexion-and-extension-measurement-passive-ankle': {
      prev: 'leg-basing-check-bend-points',
      next: 'leg-basing-move-into-range-conditional-knee-before-unweight',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 11,
    },
    'leg-basing-move-into-range-conditional-knee-before-unweight': {
      next: 'leg-basing-move-into-range-conditional-ankle-before-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      ...withCondition('isInMiddleRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-set-safe-range-knee',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 12,
    },
    'leg-basing-move-into-range-conditional-ankle-before-unweight': {
      next: 'leg-basing-limb-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      ...withCondition('isInMiddleRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 13,
    },
    'leg-basing-limb-unweight': {
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      next,
      stepIndex: stepIndex + 14,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
  } as const;
}

export function cpmKneeAnkleFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-leg-side-selection': {
      prev,
      next: 'leg-basing-set-thigh-length',
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-thigh-length': {
      prev: 'leg-basing-leg-side-selection',
      next: 'leg-basing-set-safe-range-knee',
      stepIndex: stepIndex + 1,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-knee': {
      prev: 'leg-basing-set-thigh-length',
      next: 'leg-basing-move-into-range-conditional-knee-after-safe-range',
      stepIndex: stepIndex + 2,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-move-into-range-conditional-knee-after-safe-range': {
      next: 'leg-basing-set-safe-range-ankle',
      prev: 'leg-basing-set-safe-range-knee',
      ...withCondition('isInRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-set-safe-range-knee',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 3,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-set-safe-range-ankle': {
      prev: 'leg-basing-set-safe-range-knee',
      next: 'leg-basing-move-into-range-conditional-ankle-after-safe-range',
      stepIndex: stepIndex + 4,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
    'leg-basing-move-into-range-conditional-ankle-after-safe-range': {
      next: 'leg-basing-you-can-safely-place-leg',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      prev: 'leg-basing-set-safe-range-ankle',
      ...withCondition('isInRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-set-safe-range-ankle',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 5,
    },
    'leg-basing-you-can-safely-place-leg': {
      prev: 'leg-basing-set-safe-range-ankle',
      next: 'leg-basing-adjust-length-of-the-splint',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 6,
    },
    'leg-basing-adjust-length-of-the-splint': {
      prev: 'leg-basing-you-can-safely-place-leg',
      next: 'leg-basing-adjust-foot-to-tip',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 7,
    },
    'leg-basing-adjust-foot-to-tip': {
      prev: 'leg-basing-adjust-length-of-the-splint',
      next: 'leg-basing-check-bend-points',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 8,
    },
    'leg-basing-check-bend-points': {
      prev: 'leg-basing-adjust-foot-to-tip',
      next: 'leg-basing-move-into-range-conditional-knee-before-passive-range',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 9,
    },
    'leg-basing-move-into-range-conditional-knee-before-passive-range': {
      prev: 'leg-basing-check-bend-points',
      next: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      ...withCondition('isInRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 10,
    },
    'leg-basing-max-flexion-and-extension-measurement-passive-knee': {
      prev: 'leg-basing-check-bend-points',
      next: 'leg-basing-move-into-range-conditional-ankle-before-passive-range',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 11,
    },
    'leg-basing-move-into-range-conditional-ankle-before-passive-range': {
      next: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      ...withCondition('isInRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: deviceTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 12,
    },
    'leg-basing-max-flexion-and-extension-measurement-passive-ankle': {
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
      next: 'leg-basing-move-into-range-conditional-knee-before-unweight',
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 13,
    },
    'leg-basing-move-into-range-conditional-knee-before-unweight': {
      next: 'leg-basing-move-into-range-conditional-ankle-before-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      ...withCondition('isInMiddleRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 14,
    },
    'leg-basing-move-into-range-conditional-ankle-before-unweight': {
      next: 'leg-basing-limb-unweight',
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      ...withCondition('isInMiddleRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
      stepIndex: stepIndex + 15,
    },
    'leg-basing-limb-unweight': {
      prev: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
      next,
      stepIndex: stepIndex + 16,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
  } as const;
}
