export type URLParamValue = string | string[] | number | boolean | Date | undefined | null;

export interface URLParams {
  [key: string | number]: URLParamValue;
}

export function prepareURLParams<T extends { [key in keyof T]: URLParamValue }>(params: T) {
  const result = new URLSearchParams();

  if (!params) {
    return result;
  }

  for (const prop in params) {
    const value = params[prop];

    if (value === undefined) {
      continue;
    }

    if (value === null) {
      result.append(prop, '');
      continue;
    }

    if (typeof value === 'number') {
      result.append(prop, value.toString());
      continue;
    }

    if (typeof value === 'boolean') {
      result.append(prop, value ? 'true' : 'false');
      continue;
    }

    if (value instanceof Date) {
      result.append(prop, value.toISOString());
      continue;
    }

    if (typeof value === 'string') {
      result.append(prop, value);
      continue;
    }

    if (Array.isArray(value)) {
      value.forEach(val => {
        result.append(prop, val);
      });
      continue;
    }

    throw new Error(`Param '${prop}' has invalid type that is not supported by backend API.`);
  }

  return result;
}
