import React from 'react';
import { createIcon, Icon, IconProps } from '@chakra-ui/react';

export const BinIcon = createIcon({
  displayName: 'BinIcon',
  viewBox: '0 0 57 56',
  path: [
    <path
      key={0}
      d="M50.375 10.938A4.375 4.375 0 0 0 46 15.311v34.126a4.375 4.375 0 0 1-4.375 4.374h-26.25A4.375 4.375 0 0 1 11 49.438V15.312a4.375 4.375 0 0 0-4.375-4.374M6.625 10.938h43.75M19.75 19.688v25.375M28.5 19.688v25.375M37.25 19.688v25.375"
      stroke="currentColor"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={1}
      d="M37.25 10.938h-17.5V6.561a4.375 4.375 0 0 1 4.375-4.375h8.75a4.375 4.375 0 0 1 4.375 4.376v4.375z"
      stroke="currentColor"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const CopyIcon = createIcon({
  displayName: 'CopyIcon',
  viewBox: '0 0 57 56',
  path: [
    <g clipPath="url(#f2p6jly4va)" key="0">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.575 8.604a8.562 8.562 0 0 1 8.55 8.562v29.896a8.563 8.563 0 0 1-8.563 8.563h-29.95a8.563 8.563 0 0 1-8.563-8.563v-.042A8.562 8.562 0 0 1 .5 38.458V8.562A8.562 8.562 0 0 1 9.062 0h29.951a8.562 8.562 0 0 1 8.562 8.562v.042zM39.013 43.02a4.562 4.562 0 0 0 4.562-4.562V8.562A4.562 4.562 0 0 0 39.013 4H9.062A4.561 4.561 0 0 0 4.5 8.562v29.896a4.561 4.561 0 0 0 4.562 4.562h29.951zm8.562-30.416v25.854a8.562 8.562 0 0 1-8.562 8.562H13.049v.042a4.563 4.563 0 0 0 4.562 4.563h29.951a4.563 4.563 0 0 0 4.563-4.563V17.166a4.563 4.563 0 0 0-4.55-4.562z"
        fill="currentCOlor"
      />
    </g>,
    <defs key="1">
      <clipPath id="f2p6jly4va">
        <path fill="#fff" transform="translate(.5)" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>,
  ],
});

export const ServiceHelpIcon = createIcon({
  displayName: 'TorqueForceSensorIcon',
  viewBox: '0 0 30 30',
  path: [
    <path
      key="p1"
      d="M15 .85C7.18.85.85 7.179.85 15c0 7.82 6.329 14.15 14.15 14.15 7.82 0 14.15-6.329 14.15-14.15C29.15 7.18 22.821.85 15 .85zm0 26.046C8.44 26.896 3.103 21.56 3.103 15 3.103 8.44 8.44 3.103 15 3.103c6.56 0 11.896 5.337 11.896 11.897 0 6.56-5.336 11.896-11.896 11.896z"
      fill="#fff"
      stroke="#fff"
      strokeWidth=".3"
    />,
    <path
      key="p2"
      d="M14.584 18.565c-.86 0-1.55.714-1.55 1.569 0 .833.67 1.569 1.55 1.569.878 0 1.57-.733 1.57-1.57 0-.856-.713-1.568-1.57-1.568zM14.69 13.293h-.001a3.61 3.61 0 0 0-.852 1.021c-.254.468-.435 1.078-.435 1.894 0 .482.063.84.259 1.073.203.242.516.312.905.312.47 0 .785-.105.983-.286.2-.183.254-.42.254-.62 0-.484.006-.834.127-1.162.118-.322.354-.637.847-1.023.251-.195.779-.614 1.244-1.207.466-.593.878-1.371.878-2.284 0-1.845-1.668-3.19-4.075-3.19-2.543 0-3.78 1.516-3.78 2.62 0 .406.174.71.421.91.244.197.555.29.834.29a.858.858 0 0 0 .648-.267c.075-.076.139-.162.198-.247l.068-.099c.036-.053.07-.104.11-.159.123-.168.264-.326.479-.444.215-.119.516-.204.966-.204.442 0 .823.098 1.09.283.26.18.419.447.419.82 0 .44-.227.796-.548 1.115-.16.16-.34.306-.521.447l-.145.11c-.13.101-.26.2-.373.297z"
      fill="#fff"
      stroke="#fff"
      strokeWidth=".3"
    />,
  ],
});

export const BlockersIcon = createIcon({
  displayName: 'BlockersIcon',
  viewBox: '0 0 96 96',
  path: (
    <path
      d="m71.68 52.178-10.067-7.194-7.896-21.051c-1.863-5.227-9.583-5.24-11.448 0l-7.897 21.051-10.067 7.194a4.935 4.935 0 0 0-2.072 4.018c0 1.593.773 3.092 2.072 4.017L45.12 75.08a4.95 4.95 0 0 0 5.744 0L71.68 60.213a4.963 4.963 0 0 0 2.051-4.017c0-1.59-.763-3.086-2.05-4.018zm-31.283-17.55a24.696 24.696 0 0 0 15.191 0l2.052 5.464a23.434 23.434 0 0 1-19.295 0l2.052-5.463zm20.675 23.383c-7.793 5.608-18.366 5.608-26.16 0a4.132 4.132 0 0 1-1.48-4.812l.94-2.522a25.988 25.988 0 0 0 27.24 0l.941 2.522a4.132 4.132 0 0 1-1.48 4.812z"
      fill="#0295DA"
    />
  ),
});

export const OpponentIcon = createIcon({
  displayName: 'OpponentIcon',
  viewBox: '0 0 96 96',
  path: (
    <path
      d="M64.367 44.548c-1.89-9.1-10.777-14.84-19.875-12.95a16.824 16.824 0 0 0-12.946 12.95c-6.998 2.03-11.547 5.32-11.547 9.03 0 6.16 12.527 11.2 27.993 11.2 15.465 0 27.992-4.97 27.992-11.2 0-3.71-4.549-7-11.617-9.03zm-33.31 12.6a2.18 2.18 0 0 1-2.17-2.17c0-1.19.98-2.17 2.17-2.17 1.189 0 2.168.98 2.168 2.17s-.98 2.17-2.169 2.17zm16.935 2.87a2.18 2.18 0 0 1-2.17-2.17c0-1.19.98-2.17 2.17-2.17s2.169.98 2.169 2.17-.98 2.17-2.17 2.17zm0-9.31c-7.978 0-11.897-2.73-11.897-2.73 0-7 4.898-12.6 11.897-12.6 6.998 0 11.896 5.67 11.896 12.6 0 0-3.919 2.73-11.896 2.73zm16.935 6.44a2.18 2.18 0 0 1-2.17-2.17c0-1.19.98-2.17 2.17-2.17s2.17.98 2.17 2.17-.98 2.17-2.17 2.17z"
      fill="#0295DA"
    />
  ),
});

export const RouteWidthIcon = createIcon({
  displayName: 'RouteWidthIcon',
  viewBox: '0 0 56 56',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.006 55.97h3.642c1.88 0 3.514-1.32 3.916-3.163l4.765-22.07 25.887-.187a4.014 4.014 0 0 0 3.86-3.07l4.702-19.468h1.205a4.011 4.011 0 0 0 4.007-4.006A4.011 4.011 0 0 0 51.983 0h-4.354a4.008 4.008 0 0 0-3.898 3.06L39.03 22.558l-25.969.196a3.99 3.99 0 0 0-3.889 3.155l-4.755 22.05h-.41a4.005 4.005 0 1 0 0 8.013z"
      fill="#0095DA"
    />,
    <path
      key="p2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m33.896 38.693-3.784 3.782a3 3 0 0 1-4.24 0l-3.784-3.782a3 3 0 0 1 4.24-4.242l1.663 1.662 1.664-1.662a3 3 0 0 1 4.24 4.242zM22.087 13.48l3.784-3.783a3 3 0 0 1 4.24 0l3.783 3.782a3 3 0 0 1-4.24 4.242l-1.663-1.662-1.663 1.662a3 3 0 0 1-4.24-4.242z"
      fill="#0094D9"
    />,
  ],
});

export const SpaceshipSpeedIcon = createIcon({
  displayName: 'SpaceshipSpeedIcon',
  viewBox: '0 0 56 56',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m38.232 2.92 3.784 3.785a3 3 0 0 1 0 4.241l-3.784 3.785a3.003 3.003 0 0 1-4.243.001 3.002 3.002 0 0 1-.001-4.243l1.663-1.663-1.663-1.664a3.002 3.002 0 0 1 0-4.243 3.003 3.003 0 0 1 4.244.001zM48.998 2.92l3.784 3.785a3 3 0 0 1 0 4.241l-3.784 3.785a3.003 3.003 0 0 1-4.244.001 3.002 3.002 0 0 1 0-4.243l1.662-1.663-1.663-1.664a3.002 3.002 0 0 1 .001-4.243 3.003 3.003 0 0 1 4.244.001zM38.232 41.283l3.784 3.785a3 3 0 0 1 0 4.241l-3.784 3.785a3.003 3.003 0 0 1-4.243.001 3.002 3.002 0 0 1-.001-4.243l1.663-1.663-1.663-1.664a3.002 3.002 0 0 1 0-4.243 3.003 3.003 0 0 1 4.244.001zM48.998 41.283l3.784 3.785a3 3 0 0 1 0 4.241l-3.784 3.785a3.003 3.003 0 0 1-4.244.001 3.002 3.002 0 0 1 0-4.243l1.662-1.663-1.663-1.664a3.002 3.002 0 0 1 .001-4.243 3.003 3.003 0 0 1 4.244.001z"
      fill="#0094D9"
    />,
    <path
      key="p2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.973 32.226h-1.16a1.138 1.138 0 0 1-1.135-1.137v-.552L7.897 32.02a1.14 1.14 0 0 1-1.06.215l-5.796-1.778a1.136 1.136 0 0 1-.803-1.085v-2.73c0-.498.326-.939.803-1.085l5.796-1.776a1.132 1.132 0 0 1 1.06.212l1.781 1.485v-.555c0-.625.51-1.134 1.135-1.134h1.16v-5.93a2.594 2.594 0 1 1 0-5.19V9.145c0-.628.508-1.136 1.136-1.136h9.45c.46 0 .873.277 1.049.699l.766 1.84 5.215 1.57a.993.993 0 0 1 0 1.903l-5.741 1.73 1.34 4.345 2.368-1.338c.239-.136.52-.18.79-.125l9.27 1.928c.528.112.905.574.905 1.112v2.116h16.07c.628 0 1.136.509 1.136 1.134v3.356c0 .625-.508 1.134-1.136 1.134h-5.799c-.443 0-.845.258-1.03.658l-.694 1.495c-.185.403-.587.661-1.03.661h-7.517v2.113c0 .538-.377 1.003-.905 1.112l-9.27 1.928c-.27.057-.551.014-.79-.122l-2.369-1.338-1.34 4.343 5.741 1.729a.994.994 0 0 1 0 1.904l-5.214 1.571-.766 1.839c-.176.424-.59.698-1.048.698h-9.451a1.136 1.136 0 0 1-1.136-1.136v-3.524a2.594 2.594 0 1 1 0-5.189v-5.928z"
      fill="#0295DA"
    />,
  ],
});

export const CarSpeedIcon = createIcon({
  displayName: 'CarSpeedIcon',
  viewBox: '0 0 96 96',
  path: [
    <path
      key="p1"
      d="M66.242 55.33h-4.49l-1.194-3.104c-1.242-3.2-4.346-5.253-7.785-5.253h-9.457a8.35 8.35 0 0 0-7.785 5.253l-1.241 3.105H29.8a.907.907 0 0 0-.907.907c0 .048 0 .143.047.191l.43 1.815a.917.917 0 0 0 .86.668h1.576c-1.05.908-1.672 2.197-1.672 3.582v3.582c0 1.147.43 2.245 1.194 3.152v4.012a2.365 2.365 0 0 0 2.389 2.388h2.388a2.365 2.365 0 0 0 2.388-2.388v-2.388h19.104v2.388a2.365 2.365 0 0 0 2.388 2.388h2.388a2.365 2.365 0 0 0 2.388-2.388v-4.06a4.702 4.702 0 0 0 1.194-3.152v-3.581c0-1.385-.62-2.675-1.671-3.582h1.48c.43 0 .764-.287.86-.669l.43-1.815a.955.955 0 0 0-.669-1.098c0 .047-.048.047-.143.047zm-26.269-1.337c.526-1.385 1.863-2.244 3.343-2.244h9.457c1.48 0 2.818.907 3.343 2.244l1.481 3.725H38.493l1.48-3.725zm-3.868 12.083c-1.242.096-2.293-.86-2.388-2.101v-.287c-.096-1.241.86-2.292 2.1-2.388h.288c1.432 0 3.582 2.15 3.582 3.582 0 1.433-2.15 1.194-3.582 1.194zm23.88 0c-1.433 0-3.582.24-3.582-1.194 0-1.433 2.15-3.582 3.582-3.582 1.242-.095 2.293.86 2.388 2.102v.286c.096 1.242-.86 2.293-2.101 2.388h-.287z"
      fill="#0295DA"
    />,
    <path
      key="p2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m42.077 37.56 3.785-3.784a3.001 3.001 0 0 1 4.241 0l3.785 3.784a3.002 3.002 0 0 1 .001 4.243 3.003 3.003 0 0 1-4.243 0l-1.663-1.662-1.664 1.663a3.003 3.003 0 0 1-4.243-.001 3.002 3.002 0 0 1 0-4.243zM42.077 24.677l3.785-3.784a3.001 3.001 0 0 1 4.241 0l3.785 3.784a3.002 3.002 0 0 1 .001 4.243 3.003 3.003 0 0 1-4.243.001l-1.663-1.663-1.664 1.663a3.003 3.003 0 0 1-4.243 0 3.002 3.002 0 0 1 0-4.244z"
      fill="#0094D9"
    />,
  ],
});

export const TorqueForceSensorIcon = createIcon({
  displayName: 'TorqueForceSensorIcon',
  viewBox: '0 0 113 112',
  path: [
    <path
      key="p1"
      d="M56.459 111.993c30.905 0 55.957-25.061 55.957-55.975S87.364.043 56.459.043.5 25.104.5 56.018s25.054 55.975 55.959 55.975z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M56.561 66.392c5.746 0 10.404-4.66 10.404-10.408 0-5.748-4.658-10.406-10.404-10.406-5.746 0-10.405 4.658-10.405 10.406s4.659 10.408 10.405 10.408z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.557 40.691c8.433 0 15.29 6.858 15.29 15.294s-6.857 15.296-15.29 15.296-15.29-6.86-15.29-15.296c0-8.435 6.857-15.294 15.29-15.294zm0 9.774a5.517 5.517 0 0 0-5.52 5.52c0 3.052 2.469 5.556 5.52 5.556 3.051 0 5.519-2.504 5.519-5.556a5.516 5.516 0 0 0-5.519-5.52z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.546 34.736c-1.938-1.005-2.967-3.231-2.488-5.385l3.54-15.915a4.86 4.86 0 0 1 5.815-3.686c2.632.575 4.283 3.184 3.708 5.816l-.717 3.23c6.508-3.757 14.068-5.887 22.131-5.887a44.458 44.458 0 0 1 22.801 6.27l-.813-3.613c-.574-2.632 1.077-5.241 3.708-5.816 2.608-.598 5.217 1.077 5.815 3.686l3.54 15.915c.48 2.154-.55 4.38-2.512 5.385l-14.38 7.515c-2.392 1.245-5.335.336-6.579-2.058-1.244-2.393-.335-5.337 2.058-6.581l7.417-3.854c-5.862-4.452-13.159-7.108-21.055-7.108-7.8 0-15.002 2.584-20.816 6.916l7.753 4.046a4.892 4.892 0 0 1 2.057 6.581c-1.244 2.394-4.187 3.303-6.58 2.058l-14.403-7.515z"
      fill="#0596DA"
    />,
  ],
});

export const TorqueForceToMinSensorIcon = createIcon({
  displayName: 'TorqueForceToMinSensorIcon',
  viewBox: '0 0 113 113',
  path: [
    <path
      key="p1"
      d="M56.391 112.244c30.87 0 55.892-25.025 55.892-55.894S87.26.457 56.391.457C25.522.457.498 25.481.498 56.35c0 30.87 25.024 55.894 55.893 55.894z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M56.29 66.737c5.74 0 10.392-4.653 10.392-10.393 0-5.74-4.652-10.39-10.392-10.39-5.739 0-10.392 4.65-10.392 10.39S50.55 66.737 56.29 66.737z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.29 41.074c8.423 0 15.272 6.848 15.272 15.272 0 8.423-6.849 15.273-15.272 15.273S41.02 64.769 41.02 56.346c0-8.424 6.848-15.272 15.271-15.272zm0 9.76a5.51 5.51 0 0 0-5.514 5.512c0 3.047 2.467 5.547 5.514 5.547 3.048 0 5.513-2.5 5.513-5.547a5.508 5.508 0 0 0-5.513-5.513z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.386 35.137c-1.935-1.004-2.963-3.226-2.485-5.377l3.537-15.892a4.855 4.855 0 0 1 5.807-3.68c2.63.573 4.278 3.178 3.704 5.807l-.716 3.226c6.5-3.752 14.052-5.88 22.105-5.88a44.416 44.416 0 0 1 22.774 6.262c8.46 5.043 4.207 13.478-1.744 10.563-5.855-4.445-13.143-7.097-21.03-7.097-7.79 0-14.984 2.58-20.791 6.905l7.743 4.04a4.884 4.884 0 0 1 2.055 6.572c-1.243 2.39-4.182 3.298-6.572 2.055l-14.387-7.504z"
      fill="#0596DA"
    />,
  ],
});

export const TorqueForceToMaxSensorIcon = createIcon({
  displayName: 'TorqueForceToMaxSensorIcon',
  viewBox: '0 0 113 113',
  path: [
    <path
      key="p1"
      d="M56.609 112.244C25.739 112.244.717 87.219.717 56.35S25.74.457 56.609.457c30.869 0 55.893 25.024 55.893 55.893 0 30.87-25.024 55.894-55.893 55.894z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M56.71 66.737c-5.74 0-10.392-4.653-10.392-10.393 0-5.74 4.652-10.39 10.392-10.39 5.739 0 10.392 4.65 10.392 10.39S62.45 66.737 56.71 66.737z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.71 41.074c-8.424 0-15.272 6.848-15.272 15.272 0 8.423 6.848 15.273 15.272 15.273 8.423 0 15.271-6.85 15.271-15.273 0-8.424-6.848-15.272-15.271-15.272zm0 9.76a5.51 5.51 0 0 1 5.514 5.512c0 3.047-2.467 5.547-5.514 5.547-3.048 0-5.513-2.5-5.513-5.547a5.508 5.508 0 0 1 5.513-5.513z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.614 35.137c1.935-1.004 2.963-3.226 2.485-5.377l-3.537-15.892a4.855 4.855 0 0 0-5.807-3.68c-2.63.573-4.278 3.178-3.704 5.807l.716 3.226c-6.5-3.752-14.052-5.88-22.105-5.88a44.416 44.416 0 0 0-22.774 6.262c-8.46 5.043-4.206 13.478 1.744 10.563 5.855-4.445 13.144-7.097 21.03-7.097 7.79 0 14.984 2.58 20.792 6.905l-7.744 4.04a4.884 4.884 0 0 0-2.055 6.572c1.243 2.39 4.182 3.298 6.572 2.055l14.387-7.504z"
      fill="#0596DA"
    />,
  ],
});

export const ExtensionForceSensorIcon = createIcon({
  displayName: 'ExtensionForceSensorIcon',
  viewBox: '0 0 113 112',
  path: [
    <path
      key="p1"
      d="M57.07 99.68c24.13 0 43.691-19.553 43.691-43.672 0-24.12-19.56-43.672-43.69-43.672S13.379 31.889 13.379 56.008c0 24.12 19.562 43.672 43.692 43.672z"
      fill="#C4ECFF"
    />,
    <path key="p2" d="M65.922 19.207H48.21v73.484h17.71V19.207z" fill="#fff" />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.808 19.208v73.48a4.867 4.867 0 0 1-4.88 4.877H48.21c-2.7 0-4.88-2.17-4.88-4.877v-73.48a4.879 4.879 0 0 1 4.88-4.876h17.717c2.7 0 4.88 2.188 4.88 4.876zm-9.762 4.877h-7.955v63.726h7.955V24.085zM6.5 60.895c-2.7 0-4.88-2.18-4.88-4.86a4.874 4.874 0 0 1 4.88-4.879h26.835c2.7 0 4.88 2.18 4.88 4.879 0 2.68-2.18 4.86-4.88 4.86H6.5z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.262 44.776a4.87 4.87 0 0 1 1.793-6.653c2.322-1.34 5.318-.55 6.66 1.774l7.878 13.638c1.342 2.324.53 5.313-1.793 6.654a4.876 4.876 0 0 1-6.66-1.775l-7.878-13.639z"
      fill="#0095DA"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.715 72.095c-1.342 2.324-4.338 3.114-6.66 1.774a4.87 4.87 0 0 1-1.793-6.652l7.877-13.638a4.876 4.876 0 0 1 6.661-1.775c2.322 1.34 3.135 4.33 1.793 6.653l-7.878 13.638zM107.733 51.156c2.699 0 4.88 2.18 4.88 4.879 0 2.68-2.181 4.86-4.88 4.86H80.898c-2.7 0-4.88-2.18-4.88-4.86a4.874 4.874 0 0 1 4.88-4.879h26.835z"
      fill="#0095DA"
    />,
    <path
      key="p6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.517 39.897c1.343-2.324 4.338-3.114 6.66-1.774a4.87 4.87 0 0 1 1.794 6.653l-7.878 13.638a4.876 4.876 0 0 1-6.66 1.774c-2.323-1.34-3.135-4.329-1.793-6.653l7.877-13.638z"
      fill="#0095DA"
    />,
    <path
      key="p7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.975 67.217a4.87 4.87 0 0 1-1.793 6.652c-2.323 1.34-5.319.55-6.66-1.774l-7.878-13.638c-1.343-2.324-.53-5.313 1.792-6.653a4.876 4.876 0 0 1 6.661 1.775l7.878 13.638z"
      fill="#0095DA"
    />,
  ],
});

export const KneeForceToBothSensorIcon = createIcon({
  displayName: 'KneeForceToBothSensorIcon',
  viewBox: '0 0 200 200',
  path: (
    <>
      <path d="M200 0H0v200h200V0z" fill="#fff" />
      <mask
        id="5co2zqixta"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="200"
        height="200"
      >
        <path d="M200 0H0v200h200V0z" fill="#fff" />
      </mask>
      <g mask="url(#5co2zqixta)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.435 174.873c-11.463-7.165-7.108-7.105-25.404-11.326-6.5-1.498-12.217-6.704-15.634-14.674-7.08-16.506 2.183-13.731 27.886-5.647 15.085 4.713 14.551-.64 19.18-6.129 3.38-3.982 12.389-35.434 18.217-51.72 5.539-15.435 8.114-27.22 22.677-56.678 6.926-13.955 26.375-19.535 35.396-6.858 62.379 87.609 50.391 73.667 64.747 95.267 20.15 30.346 3.794 49.419-8.616 56.538-13.425 7.69-36.277-6.807-48.522-29.15-10.304-18.81-19.477-73.744-19.477-73.744s.448 22.485-9.352 36.291c-15.735 22.107-34.303 38.85-36.555 51.872-2.261 13.148-9.98 25.054-24.543 15.958z"
          fill="#C4ECFF"
        />
      </g>
      <path
        d="M131.835 98.857c7.261 7.258 19.032 7.256 26.293-.002 7.261-7.258 7.261-19.022 0-26.28-7.261-7.257-19.032-7.26-26.293-.002-7.261 7.258-7.261 19.027 0 26.284z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.304 66.4c10.657 10.651 10.657 27.97 0 38.622-10.656 10.652-27.985 10.654-38.642.002-10.656-10.651-10.654-27.973.002-38.625 10.657-10.651 27.984-10.651 38.64 0zm-12.346 12.34a9.86 9.86 0 0 0-13.949-.002c-3.856 3.854-3.898 10.134-.043 13.988 3.856 3.853 10.137 3.809 13.992-.045a9.848 9.848 0 0 0 0-13.94zM66.38 118.542c-4.789 0-8.683 3.893-8.683 8.712 0 4.786 3.894 8.678 8.683 8.678h58.103c4.821 0 8.715-3.892 8.715-8.678a8.704 8.704 0 0 0-8.715-8.712H66.38z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.493 101.097c-3.414-3.363-8.947-3.301-12.308.108-3.383 3.432-3.34 8.94.074 12.303l20.04 19.737c3.415 3.363 8.926 3.326 12.309-.106 3.361-3.41 3.34-8.941-.075-12.304l-20.04-19.738z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.495 153.18c-3.414 3.363-8.947 3.302-12.308-.108-3.383-3.432-3.34-8.939.074-12.302l20.04-19.738c3.415-3.363 8.926-3.325 12.309.107 3.361 3.409 3.34 8.941-.075 12.304l-20.04 19.737zM65.231 153.18c3.415 3.363 8.947 3.302 12.308-.108 3.384-3.432 3.341-8.939-.073-12.302l-20.04-19.738c-3.415-3.363-8.926-3.325-12.31.107-3.36 3.409-3.34 8.941.075 12.304l20.04 19.737z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.231 101.097c3.415-3.363 8.947-3.301 12.308.108 3.384 3.432 3.341 8.94-.073 12.303l-20.04 19.737c-3.415 3.363-8.926 3.326-12.31-.106-3.36-3.41-3.34-8.941.075-12.304l20.04-19.738z"
        fill="#0095DA"
      />
    </>
  ),
});

export const KneeForceToMinSensorIcon = createIcon({
  displayName: 'KneeForceToMinSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path key="p1" d="M200 0H0v200h200V0z" fill="#fff" />,
    <mask
      key="p2"
      id="wcfun376va"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path d="M200 0H0v200h200V0z" fill="#fff" />
    </mask>,
    <g key="p3" mask="url(#wcfun376va)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.437 174.873c-11.463-7.165-7.108-7.105-25.404-11.326-6.5-1.498-12.217-6.704-15.634-14.674-7.08-16.506 2.183-13.731 27.886-5.647 15.085 4.713 14.552-.64 19.18-6.129 3.38-3.982 12.389-35.434 18.217-51.72 5.539-15.435 8.114-27.22 22.677-56.678 6.926-13.955 26.375-19.535 35.396-6.858 62.379 87.609 50.391 73.667 64.747 95.267 20.15 30.346 3.794 49.419-8.616 56.538-13.425 7.69-36.277-6.807-48.522-29.15-10.304-18.81-19.477-73.744-19.477-73.744s.448 22.485-9.352 36.291C88.8 129.15 70.232 145.893 67.98 158.915c-2.261 13.148-9.98 25.054-24.543 15.958z"
        fill="#C4ECFF"
      />
    </g>,
    <path
      key="p4"
      d="M131.835 98.857c7.261 7.258 19.032 7.256 26.293-.002 7.261-7.258 7.261-19.022 0-26.28-7.261-7.257-19.032-7.26-26.293-.002-7.261 7.258-7.261 19.027 0 26.284z"
      fill="#fff"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.304 66.4c10.657 10.651 10.657 27.97 0 38.622-10.656 10.652-27.985 10.654-38.642.002-10.656-10.651-10.654-27.973.002-38.625 10.657-10.651 27.984-10.651 38.64 0zm-12.346 12.34a9.86 9.86 0 0 0-13.949-.002c-3.856 3.854-3.898 10.134-.043 13.988 3.856 3.853 10.137 3.809 13.992-.045a9.848 9.848 0 0 0 0-13.94zM92.823 46.091c4.789 0 8.683 3.893 8.683 8.712 0 4.786-3.894 8.678-8.683 8.678H34.72c-4.82 0-8.715-3.892-8.715-8.678a8.704 8.704 0 0 1 8.715-8.712h58.103z"
      fill="#0095DA"
    />,
    <path
      key="p6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.823 46.091c4.789 0 8.683 3.893 8.683 8.712 0 4.786-3.894 8.678-8.683 8.678H34.72c-4.82 0-8.715-3.892-8.715-8.678a8.704 8.704 0 0 1 8.715-8.712h58.103z"
      fill="#0095DA"
    />,
    <path
      key="p7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.706 28.646c3.415-3.363 8.947-3.301 12.308.108 3.383 3.432 3.34 8.94-.074 12.303L40.9 60.794c-3.414 3.363-8.926 3.326-12.31-.106-3.36-3.41-3.338-8.941.076-12.304l20.04-19.738z"
      fill="#0095DA"
    />,
    <path
      key="p8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.706 80.73c3.415 3.364 8.947 3.302 12.308-.107 3.383-3.432 3.34-8.94-.074-12.303L40.9 48.583c-3.414-3.363-8.926-3.326-12.31.106-3.36 3.41-3.338 8.941.076 12.304l20.04 19.738z"
      fill="#0095DA"
    />,
  ],
});

export const KneeForceToMaxSensorIcon = createIcon({
  displayName: 'KneeForceToMaxSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path key="p1" d="M200 0H0v200h200V0z" fill="#fff" />,
    <mask
      key="p2"
      id="jxxtoyc4la"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path d="M200 0H0v200h200V0z" fill="#fff" />
    </mask>,
    <g key="p3" mask="url(#jxxtoyc4la)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.435 174.873c-11.463-7.165-7.108-7.105-25.404-11.326-6.5-1.498-12.217-6.704-15.634-14.674-7.08-16.506 2.183-13.731 27.886-5.647 15.085 4.713 14.551-.64 19.18-6.129 3.38-3.982 12.389-35.434 18.217-51.72 5.539-15.435 8.114-27.22 22.677-56.678 6.926-13.955 26.375-19.535 35.396-6.858 62.379 87.609 50.391 73.667 64.747 95.267 20.15 30.346 3.794 49.419-8.616 56.538-13.425 7.69-36.277-6.807-48.522-29.15-10.304-18.81-19.477-73.744-19.477-73.744s.448 22.485-9.352 36.291c-15.735 22.107-34.303 38.85-36.555 51.872-2.261 13.148-9.98 25.054-24.543 15.958z"
        fill="#C4ECFF"
      />
    </g>,
    <path
      key="p4"
      d="M131.835 98.857c7.261 7.258 19.032 7.256 26.293-.002 7.261-7.258 7.261-19.022 0-26.28-7.261-7.257-19.032-7.26-26.293-.002-7.261 7.258-7.261 19.027 0 26.284z"
      fill="#fff"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.304 66.4c10.657 10.651 10.657 27.97 0 38.622-10.656 10.652-27.985 10.654-38.642.002-10.656-10.651-10.654-27.973.002-38.625 10.657-10.651 27.984-10.651 38.64 0zm-12.346 12.34a9.86 9.86 0 0 0-13.949-.002c-3.856 3.854-3.898 10.134-.043 13.988 3.856 3.853 10.137 3.809 13.992-.045a9.848 9.848 0 0 0 0-13.94zM71.38 135.117c-4.789 0-8.683 3.893-8.683 8.711 0 4.787 3.894 8.679 8.683 8.679h58.103c4.821 0 8.715-3.892 8.715-8.679a8.703 8.703 0 0 0-8.715-8.711H71.38z"
      fill="#0095DA"
    />,
    <path
      key="p6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.493 117.672c-3.414-3.363-8.947-3.302-12.308.108-3.383 3.432-3.34 8.939.074 12.302l20.04 19.738c3.415 3.363 8.926 3.325 12.309-.107 3.361-3.409 3.34-8.941-.075-12.304l-20.04-19.737z"
      fill="#0095DA"
    />,
    <path
      key="p7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.493 169.755c-3.414 3.363-8.947 3.301-12.308-.108-3.383-3.432-3.34-8.94.074-12.303l20.04-19.737c3.415-3.363 8.926-3.326 12.309.106 3.361 3.41 3.34 8.941-.075 12.304l-20.04 19.738z"
      fill="#0095DA"
    />,
  ],
});

export const HeelForceToMaxSensorIcon = createIcon({
  displayName: 'HeelForceToMaxSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.715 87.311s-.302-12.409-.555-23.655c-1.189-53.074-10.368-48.222 29.051-49.966 53.882-2.375 41.493-.91 35.854 35.687-2.022 13.015-2.629 26.005-3.413 36.923a99.254 99.254 0 0 0 8.015 46.529 66.158 66.158 0 0 1 5.689 25.678c.076 13.749-11.075 25.096-24.703 25.146l-10.822.051a64.226 64.226 0 0 1-21.492-3.639 47.93 47.93 0 0 0-28.015-1.213 160.889 160.889 0 0 1-38.813 5.206 186.224 186.224 0 0 1-24.247-2.983 80.333 80.333 0 0 1-16.942 2.957c-12.996.051-17.648-7.202-17.699-22.392l29.736-5.155a532.097 532.097 0 0 1 51.96-27.623c16.131-7.38 26.497-23.708 26.396-41.55z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M159.637 170.203c10.269 0 18.591-8.322 18.591-18.586s-8.322-18.582-18.591-18.582c-10.269 0-18.594 8.318-18.594 18.582 0 10.264 8.325 18.586 18.594 18.586z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.631 124.307c15.071 0 27.323 12.246 27.323 27.31 0 15.064-12.252 27.313-27.323 27.313-15.07 0-27.322-12.249-27.322-27.313 0-15.064 12.252-27.31 27.322-27.31zm0 17.452c-5.452 0-9.865 4.408-9.865 9.858s4.413 9.921 9.865 9.921 9.862-4.471 9.862-9.921c0-5.45-4.41-9.858-9.862-9.858zM140.584 88.617c4.529-1.63 9.514.691 11.144 5.218 1.631 4.527-.706 9.536-5.235 11.167-16.932 6.108-37.119 16.36-36.833 38.375.087 4.81-3.759 8.764-8.556 8.826-4.837.073-8.792-3.771-8.84-8.592-.43-30.295 25.003-46.58 48.32-54.994z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.594 88.208c4.574 1.457 7.081 6.37 5.626 10.933-1.464 4.591-6.344 7.119-10.919 5.662l-19.692-6.274c-4.575-1.458-7.132-6.355-5.668-10.946 1.456-4.564 6.386-7.107 10.96-5.65l19.693 6.275z"
      fill="#0095DA"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.459 93.483c1.755-4.467 6.796-6.657 11.283-4.895 4.46 1.75 6.689 6.795 4.934 11.262l-7.558 19.23c-1.755 4.467-6.839 6.685-11.299 4.934-4.486-1.761-6.673-6.834-4.918-11.301l7.558-19.23z"
      fill="#0095DA"
    />,
  ],
});

export const HeelForceToMinSensorIcon = createIcon({
  displayName: 'HeelForceToMinSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.004 97.528c9.254-.195 22.782.195 16.157-36.155-9.178-50.335-10.367-46.486 29.052-48.167 53.882-2.29 41.493-.877 35.854 34.402-2.023 12.547-2.124 25.143-3.414 35.595-4.601 36.935 4.628 35.084 8.724 50.019 2.579 9.453 3.413 18.369.227 26.312-5.132 12.206-14.285 13.107-20.86 15.081-6.068 1.803-12.87-.951-18.736-3.289-6.169-2.51-12.97-6.798-18.407-11.671a49.658 49.658 0 0 0-25.31-12.01 166.228 166.228 0 0 1-37.674-10.574 187.844 187.844 0 0 1-21.189-12.158 80.456 80.456 0 0 1-16.712-3.996c-11.985-5.043-13.503-13.278-7.839-26.8l29.305 7.091c20.758-.707 28.723-3.217 50.822-3.68z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M156.551 160.94c10.269 0 18.591-8.321 18.591-18.585 0-10.264-8.322-18.583-18.591-18.583-10.269 0-18.594 8.319-18.594 18.583 0 10.264 8.325 18.585 18.594 18.585z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.557 115.05c15.07 0 27.323 12.246 27.323 27.31 0 15.064-12.253 27.313-27.323 27.313s-27.323-12.249-27.323-27.313c0-15.064 12.253-27.31 27.323-27.31zm0 17.452c-5.452 0-9.865 4.408-9.865 9.858s4.413 9.921 9.865 9.921 9.862-4.471 9.862-9.921c0-5.45-4.41-9.858-9.862-9.858zM105.866 137.833c-.287 4.803-4.437 8.456-9.242 8.169-4.805-.288-8.439-4.414-8.151-9.217 1.499-24.733 9.959-53.714 39.159-61.842 4.641-1.314 9.446 1.395 10.74 6.054 1.294 4.618-1.417 9.421-6.058 10.694-21.213 5.932-25.36 28.182-26.448 46.142z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.929 131.95c2.689 3.977 1.639 9.369-2.355 12.068-3.97 2.682-9.391 1.663-12.08-2.314l-11.576-17.115c-2.69-3.977-1.639-9.421 2.331-12.103 3.994-2.699 9.416-1.628 12.105 2.349l11.575 17.115z"
      fill="#0095DA"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.384 144.254c-3.793 2.943-9.263 2.227-12.201-1.557-2.956-3.806-2.277-9.258 1.516-12.201l16.327-12.669c3.793-2.943 9.279-2.276 12.235 1.531 2.938 3.783 2.243 9.284-1.55 12.227l-16.327 12.669z"
      fill="#0095DA"
    />,
  ],
});

export const HeelForceToBothSensorIcon = createIcon({
  displayName: 'ToesForceToBothSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.004 97.528c9.254-.195 22.782.195 16.157-36.155-9.178-50.335-10.367-46.486 29.052-48.167 53.882-2.29 41.493-.877 35.854 34.402-2.023 12.547-2.124 25.143-3.414 35.595-4.601 36.935 4.628 35.084 8.724 50.019 2.579 9.453 3.413 18.369.227 26.312-5.132 12.206-14.285 13.107-20.86 15.081-6.068 1.803-12.87-.951-18.736-3.289-6.169-2.51-12.97-6.798-18.407-11.671a49.658 49.658 0 0 0-25.31-12.01 166.228 166.228 0 0 1-37.674-10.574 187.844 187.844 0 0 1-21.189-12.158 80.456 80.456 0 0 1-16.712-3.996c-11.985-5.043-13.503-13.278-7.839-26.8l29.305 7.091c20.758-.707 28.723-3.217 50.822-3.68z"
        fill="#C4ECFF"
      />
      <path
        d="M156.551 160.94c10.269 0 18.591-8.321 18.591-18.585 0-10.264-8.322-18.583-18.591-18.583-10.269 0-18.594 8.319-18.594 18.583 0 10.264 8.325 18.585 18.594 18.585z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.557 115.05c15.07 0 27.323 12.246 27.323 27.31 0 15.064-12.253 27.313-27.323 27.313s-27.323-12.249-27.323-27.313c0-15.064 12.253-27.31 27.323-27.31zm0 17.452c-5.452 0-9.865 4.408-9.865 9.858s4.413 9.921 9.865 9.921 9.862-4.471 9.862-9.921c0-5.45-4.41-9.858-9.862-9.858zM105.862 137.834c-.287 4.803-4.436 8.457-9.242 8.169-4.805-.287-8.439-4.413-8.151-9.216 1.499-24.734 9.959-53.715 39.159-61.843 4.641-1.314 9.446 1.395 10.74 6.055 1.294 4.618-1.417 9.42-6.058 10.693-21.213 5.932-25.36 28.182-26.448 46.142z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.925 131.952c2.689 3.976 1.639 9.369-2.355 12.067-3.97 2.683-9.391 1.663-12.08-2.313L77.913 124.59c-2.689-3.976-1.639-9.42 2.331-12.103 3.994-2.698 9.416-1.627 12.105 2.349l11.575 17.116z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.38 144.255c-3.792 2.944-9.262 2.227-12.2-1.556-2.957-3.806-2.278-9.258 1.515-12.201l16.327-12.669c3.793-2.943 9.279-2.276 12.235 1.53 2.938 3.783 2.243 9.284-1.55 12.227l-16.327 12.669zM140.652 75.457c3.145-3.626 8.626-4.008 12.266-.848 3.618 3.14 4.037 8.64.892 12.267l-13.537 15.611c-3.144 3.627-8.675 4.021-12.293.881-3.641-3.159-4.009-8.673-.864-12.3l13.536-15.61z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.935 73.765c3.824 2.902 4.548 8.37 1.654 12.188-2.911 3.84-8.353 4.595-12.178 1.693l-16.463-12.49c-3.824-2.903-4.6-8.375-1.688-12.215 2.894-3.818 8.387-4.57 12.212-1.668l16.463 12.492z"
        fill="#0095DA"
      />
    </>,
  ],
});

export const ToesForceToMaxSensorIcon = createIcon({
  displayName: 'ToesForceToMaxSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.715 84.176s-.302-11.963-.555-22.804c-1.189-51.164-10.368-46.486 29.051-48.167 53.882-2.29 41.493-.877 35.854 34.401-2.022 12.548-2.629 25.07-3.413 35.595-1.113 15.35 1.643 30.747 8.015 44.854 3.59 7.796 5.537 16.226 5.689 24.754.076 13.254-11.075 24.192-24.703 24.241l-10.822.049a66.39 66.39 0 0 1-21.492-3.509 49.642 49.642 0 0 0-28.015-1.169 166.555 166.555 0 0 1-38.813 5.019 193.186 193.186 0 0 1-24.247-2.875 83.076 83.076 0 0 1-16.942 2.85c-12.996.049-17.648-6.943-17.699-21.586l29.736-4.97a539.297 539.297 0 0 1 51.96-26.629c16.131-7.114 26.497-22.854 26.396-40.054z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M49.881 177.408c10.269 0 18.591-8.023 18.591-17.917 0-9.895-8.322-17.914-18.59-17.914-10.27 0-18.595 8.019-18.595 17.914 0 9.894 8.325 17.917 18.594 17.917z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.88 133.164c15.07 0 27.322 11.805 27.322 26.327 0 14.521-12.252 26.33-27.323 26.33-15.07 0-27.322-11.809-27.322-26.33 0-14.522 12.252-26.327 27.322-26.327zm0 16.824c-5.453 0-9.866 4.249-9.866 9.503 0 5.254 4.413 9.563 9.865 9.563 5.453 0 9.863-4.309 9.863-9.563 0-5.254-4.41-9.503-9.863-9.503zM64.938 85.783c.266-4.804 4.35-8.485 9.157-8.219 4.806.267 8.508 4.373 8.242 9.177-.984 17.966.578 40.544 20.968 48.874 4.464 1.797 6.605 6.88 4.789 11.319-1.821 4.48-6.905 6.618-11.364 4.78-28.073-11.429-33.146-41.189-31.792-65.93z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.519 81.012c3.128-3.641 8.631-4.03 12.266-.91 3.657 3.14 4.08 8.617.953 12.258L66.27 108.032c-3.128 3.641-8.637 4.083-12.294.943-3.635-3.121-4.053-8.65-.925-12.29L66.52 81.011z"
      fill="#0095DA"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.422 92.398c-3.43-3.36-3.478-8.853-.104-12.294 3.354-3.42 8.87-3.504 12.3-.145l14.761 14.46c3.43 3.36 3.488 8.904.134 12.325-3.374 3.441-8.9 3.474-12.33.114l-14.761-14.46z"
      fill="#0095DA"
    />,
  ],
});

export const ToesForceToMinSensorIcon = createIcon({
  displayName: 'ToesForceToMinSensorIcon',
  viewBox: '0 0 200 200',
  path: [
    <path
      key="p1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.004 97.527c9.254-.195 22.782.195 16.157-36.155-9.178-50.335-10.367-46.486 29.052-48.167 53.882-2.29 41.493-.877 35.854 34.401-2.023 12.548-2.124 25.143-3.414 35.595-4.601 36.935 4.628 35.084 8.724 50.019 2.579 9.453 3.413 18.37.227 26.312-5.132 12.207-14.285 13.108-20.86 15.081-6.068 1.803-12.87-.95-18.736-3.289-6.169-2.509-12.97-6.797-18.407-11.67a49.66 49.66 0 0 0-25.31-12.011 166.277 166.277 0 0 1-37.674-10.573 188.151 188.151 0 0 1-21.189-12.158 80.52 80.52 0 0 1-16.712-3.996c-11.985-5.043-13.503-13.278-7.839-26.8l29.305 7.09c20.758-.706 28.723-3.216 50.822-3.68z"
      fill="#C4ECFF"
    />,
    <path
      key="p2"
      d="M56.172 129.615c10.269 0 18.591-8.022 18.591-17.917 0-9.894-8.322-17.913-18.59-17.913-10.27 0-18.595 8.019-18.595 17.913 0 9.895 8.325 17.917 18.594 17.917z"
      fill="#fff"
    />,
    <path
      key="p3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.17 85.371c15.07 0 27.323 11.806 27.323 26.327 0 14.522-12.252 26.331-27.323 26.331-15.07 0-27.322-11.809-27.322-26.331 0-14.521 12.252-26.327 27.322-26.327zm0 16.824c-5.452 0-9.865 4.25-9.865 9.503 0 5.254 4.413 9.564 9.865 9.564 5.453 0 9.863-4.31 9.863-9.564 0-5.253-4.41-9.503-9.863-9.503zM130.666 173.941c4.017 2.651 5.108 8.069 2.457 12.085s-8.042 5.098-12.06 2.447c-20.679-13.666-41.558-35.481-34.003-64.822 1.182-4.674 5.93-7.479 10.614-6.269 4.648 1.19 7.454 5.938 6.237 10.592-5.465 21.328 11.738 36.044 26.755 45.967z"
      fill="#0095DA"
    />,
    <path
      key="p4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.6 172.676c4.79-.339 8.937 3.267 9.278 8.074.339 4.778-3.254 8.961-8.044 9.301l-20.617 1.461c-4.789.34-8.981-3.292-9.32-8.07-.342-4.807 3.297-8.965 8.086-9.305l20.617-1.461z"
      fill="#0095DA"
    />,
    <path
      key="p5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.489 180.161c.653 4.755-2.702 9.132-7.448 9.784-4.776.655-9.159-2.659-9.813-7.414l-2.814-20.468c-.654-4.755 2.667-9.17 7.442-9.826 4.747-.651 9.165 2.702 9.818 7.457l2.815 20.467z"
      fill="#0095DA"
    />,
  ],
});

export const EMGIcon = createIcon({
  displayName: 'EMGIcon',
  viewBox: '0 0 86 54',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.92 33.354h9.32l7.006 19.38a1.915 1.915 0 0 0 1.905 1.264 1.924 1.924 0 0 0 1.764-1.452L32.72 9.206l9.89 33.19a1.914 1.914 0 0 0 1.49 1.336 1.927 1.927 0 0 0 1.872-.714l6.642-8.57 8.11 9.172a1.921 1.921 0 0 0 2.203.495 1.92 1.92 0 0 0 .65-.459l9.548-10.302h10.955c.51 0 .998-.201 1.359-.56a1.911 1.911 0 0 0 0-2.708 1.924 1.924 0 0 0-1.359-.56h-11.8a1.926 1.926 0 0 0-1.412.614L62.2 39.504l-8.242-9.34a1.92 1.92 0 0 0-1.505-.65 1.926 1.926 0 0 0-1.46.741l-5.77 7.45L34.395 1.368A1.914 1.914 0 0 0 32.511 0a1.925 1.925 0 0 0-1.821 1.452L19.714 45.487l-5.317-14.71a1.914 1.914 0 0 0-1.809-1.263H1.921c-.51 0-.998.201-1.358.56-.36.36-.563.846-.563 1.354a1.91 1.91 0 0 0 1.183 1.78c.234.096.484.146.738.146z"
    />
  ),
  defaultProps: {
    fill: '#008BD1',
  },
});

export const TriggerIcon = createIcon({
  displayName: 'TriggerIcon',
  viewBox: '0 0 62 60',
  path: (
    <path
      d="M2.594 30.096H8.53M36.512 30.096h5.935M19.553 30.096h5.935M53.471 30.096h5.935M3.82 57.087h10.155L50.509 2.912h7.432"
      strokeWidth="4"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  defaultProps: {
    stroke: '#0095DA',
    fill: 'transparent',
  },
});

export const ElectrostimIcon = createIcon({
  displayName: 'ElectrostimIcon',
  viewBox: '0 0 36 61',
  path: (
    <path d="M34.678 20.425H21.136a.86.86 0 0 1-.861-.861.86.86 0 0 1 .073-.34l7.697-17.662a.862.862 0 0 0 .073-.34.861.861 0 0 0-.861-.86H9.514a.86.86 0 0 0-.818.628L.43 32.292a.861.861 0 0 0 0 .177.861.861 0 0 0 .861.86h11.807a.86.86 0 0 1 .861.861.868.868 0 0 1 0 .186L8.855 59.47a.861.861 0 0 0 0 .168.861.861 0 0 0 .86.86.861.861 0 0 0 .711-.382l25.03-38.38a.86.86 0 0 0 .117-.43.862.862 0 0 0-.895-.882z" />
  ),
  defaultProps: {
    fill: '#0095DA',
  },
});

export const LessThanIcon = createIcon({
  displayName: 'LessThanIcon',
  viewBox: '0 0 41 59',
  path: (
    <path
      d="M34.208 53 6.792 29.495 34.208 6"
      fill="none"
      stroke="#0095DA"
      strokeWidth="12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export const StartTrainingIcon = createIcon({
  displayName: 'StartTrainingIcon',
  viewBox: '0 0 49 42',
  path: [
    <path
      key="start-training-1"
      d="m29.322 13.104-12.13 12.13 3.66 3.661 12.13-12.13-3.66-3.661zM36.107 1.586l-4.576 4.576 8.394 8.394L44.5 9.98l-8.394-8.394zM10.248 27.442l-4.576 4.576 8.394 8.393 4.576-4.575-8.394-8.394z"
      stroke="#0095DA"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key="start-training-2"
      d="m26.953 1.586-4.576 4.576 17.545 17.545 4.576-4.576L26.953 1.586zM10.248 18.291l-4.576 4.576 17.546 17.546 4.575-4.576-17.545-17.546z"
      stroke="#0095DA"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const UpdateIcon = createIcon({
  displayName: 'UpdateIcon',
  viewBox: '0 0 50 35',
  path: (
    <>
      <g
        clipPath="url(#zcs2wrvs1a)"
        fill="transparent"
        stroke="#0194D9"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.879 3.815a14.157 14.157 0 0 1 8.26-.282 14.166 14.166 0 0 1 8.6 6.572 14.054 14.054 0 0 1 1.418 10.7M29.056 30.479a14.157 14.157 0 0 1-8.26.282 14.166 14.166 0 0 1-8.6-6.572 14.054 14.054 0 0 1-1.418-10.7M16.736 16.544l-6.412-6.383M3.914 16.544l6.412-6.383M32.23 16.544l6.412 6.383M45.056 16.544l-6.411 6.383" />
      </g>
      <defs>
        <clipPath id="zcs2wrvs1a">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h50v34H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ShowPasswordIcon = ({ showingPassword }: { showingPassword: boolean }) => {
  return showingPassword ? (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 10.282A5.722 5.722 0 0 1 22.72 16a5.722 5.722 0 0 1-9.764 4.044A5.722 5.722 0 0 1 11.28 16 5.722 5.722 0 0 1 17 10.282z"
        stroke="#CBD5E0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.61 16S25.766 25.24 17 25.24C8.232 25.24 1.39 16 1.39 16S8.233 6.76 17 6.76c8.767 0 15.61 9.24 15.61 9.24z"
        stroke="#CBD5E0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 10.282A5.722 5.722 0 0 1 22.72 16a5.722 5.722 0 0 1-9.764 4.044A5.722 5.722 0 0 1 11.28 16 5.722 5.722 0 0 1 17 10.282z"
        stroke="#CBD5E0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.61 16S25.766 25.24 17 25.24C8.232 25.24 1.39 16 1.39 16S8.233 6.76 17 6.76c8.767 0 15.61 9.24 15.61 9.24z"
        stroke="#CBD5E0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4.175" y="4.226" width="2" height="35.223" rx="1" transform="rotate(-45 4.175 4.226)" fill="#CBD5E0" />
    </svg>
  );
};
export const CheckMarkIcon = () => {
  return (
    <svg x="-8%" y="-21%" width="32" height="82" viewBox="0 0 105 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M88.828 0 39.93 49.935 17.02 28.287.928 44.346 39.93 82l64.998-65.96L88.828 0z" fill="#fff" />
    </svg>
  );
};

export const WifiXIcon = ({ isHover }: { isHover: boolean }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m17.17 17.333-14.338-15zm0-15-14.338 15z" fill="#323E4A" />
      <path
        d="m17.17 17.333-14.338-15m14.338 0-14.338 15"
        stroke={isHover ? '#323E4A' : '#fff'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WifiIcon = ({
  signalStrength,
  isHover,
  width,
  height,
}: {
  signalStrength: number;
  isHover: boolean;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      data-testid="wifi-icon"
      width={width ?? '29'}
      height={height ?? '27'}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#r42djl5vfa)">
        <g clipPath="url(#6p39xui78b)" fill={isHover ? '#0095DA' : '#fff'}>
          <path
            d="M2.886 14.039a15.762 15.762 0 0 1 5.258-3.791c2-.889 4.166-1.355 6.359-1.37 2.193.015 4.358.481 6.359 1.37 2 .888 3.79 2.18 5.258 3.79L29 10.945a19.678 19.678 0 0 0-6.562-4.733A19.876 19.876 0 0 0 14.503 4.5c-2.738.018-5.442.6-7.939 1.71A19.678 19.678 0 0 0 0 10.944l2.886 3.095z"
            fillOpacity={signalStrength >= 66 ? '' : '.24'}
          />
          <path
            d="M7.814 19.331a8.944 8.944 0 0 1 3.023-2.19 9.036 9.036 0 0 1 3.666-.776c1.263 0 2.513.264 3.665.775a8.943 8.943 0 0 1 3.023 2.191l2.816-3.02a12.895 12.895 0 0 0-4.302-3.1 13.027 13.027 0 0 0-5.202-1.119c-1.793.01-3.564.39-5.2 1.116A12.894 12.894 0 0 0 5 16.304l2.814 3.027z"
            fillOpacity={signalStrength >= 33 ? '' : '.24'}
          />
          <path
            d="M9.926 21.592a6.12 6.12 0 0 1 2.065-1.501 6.184 6.184 0 0 1 2.506-.533 6.184 6.184 0 0 1 2.506.533 6.12 6.12 0 0 1 2.066 1.5l-4.56 4.91-4.583-4.91z"
            fillOpacity={signalStrength >= 1 ? '' : '.24'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="r42djl5vfa">
          <path fill="#fff" transform="translate(0 .203)" d="M0 0h29v26.297H0z" />
        </clipPath>
        <clipPath id="6p39xui78b">
          <path fill="#fff" transform="translate(0 4.5)" d="M0 0h29v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WifiLockIcon = ({ isHover }: { isHover: boolean }) => {
  return (
    <svg
      data-testid="wifi-lock-icon"
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 14.686c-.002-1.15-.454-2.253-1.258-3.066a4.268 4.268 0 0 0-3.033-1.271H5.56a4.268 4.268 0 0 0-3.032 1.27 4.365 4.365 0 0 0-1.257 3.067v8.675a4.365 4.365 0 0 0 1.258 3.065 4.269 4.269 0 0 0 3.031 1.27h11.15a4.269 4.269 0 0 0 3.033-1.27 4.365 4.365 0 0 0 1.257-3.067v-8.673zM18.045 9.489V4.797c0-.926-.364-1.815-1.012-2.47a3.438 3.438 0 0 0-2.442-1.023H7.679c-.916 0-1.794.369-2.442 1.024a3.515 3.515 0 0 0-1.012 2.469v4.692"
        stroke={isHover ? 'black' : '#fff'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.132 14.692a2.34 2.34 0 0 1 1.662.697 2.393 2.393 0 0 1 0 3.361 2.34 2.34 0 0 1-1.662.697 2.34 2.34 0 0 1-1.661-.697 2.393 2.393 0 0 1 0-3.36 2.34 2.34 0 0 1 1.661-.698zM11.133 23.354v-3.91"
        stroke={isHover ? 'black' : '#fff'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WifiListArrow = ({ isHover }: { isHover: boolean }) => {
  return (
    <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m2 2 10 8.502L2 19"
        stroke={isHover ? 'black' : '#fff'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PauseInROMMinLegVariant = createIcon({
  displayName: 'PauseInROMMin',
  viewBox: '0 0 56 57',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.947 32.852v12.866a4 4 0 0 0 8 0V32.852a4 4 0 0 0-8 0zM30.136 32.852v12.866a4 4 0 0 0 8 0V32.852a4 4 0 1 0-8 0zM27.84 18.841a6.807 6.807 0 0 1 4.245-.938c5.488 1.815 12.118 3.182 14.66 3.061 3.574-.17 7.511-.289 8.755-4.078 1.422-4.33-1.1-9.19-7.994-8.8-2.425.137-7.9.245-12.306.522-1.61.1-4.208-.038-5.247.075-.933.03-1.857.2-2.74.5-.763.2-2.35 1.067-3.58 1.4-5.147 1.38-13.334 3.605-15.7 4.363-1.386.443-3.382-2.93-3.633-3.669C2.854 7.018 2.165 5.918.478 9.184c-.814 1.577-.084 4.427.54 5.334 1.757 2.55 1.42 2.654 2.045 5.05.358 1.371 1 1.8 1.7 1.745.85-.064 1.9-1.149 3.514-1.475a10.921 10.921 0 0 1 5.311-.13c2.107.367 4.236.587 6.372.66 2.657.112 6.38-.575 7.88-1.527z"
      fill="currentColor"
    />
  ),
});

export const PauseInROMMinAnkleVariant = createIcon({
  displayName: 'PauseInROMMinAnkle',
  viewBox: '9 9 65 65',
  path: (
    <svg viewBox="9 9 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5 39.84s-.075-2.95-.137-5.625c-.293-12.621-2.556-11.467 7.164-11.882 13.286-.565 10.231-.216 8.84 8.486-.498 3.095-.648 6.185-.841 8.781a22.86 22.86 0 0 0 1.976 11.065 15.26 15.26 0 0 1 1.403 6.106c.013 2.091-1.11 3.942-2.793 5.018-2.824 1.791-5.524 2.62-8.872 2.62-2.344 0-4.62.872-6.54 2.23a40.856 40.856 0 0 1-8.474 4.61 46.863 46.863 0 0 1-5.835 1.497 19.895 19.895 0 0 1-3.641 2.17c-2.987 1.171-4.67-.025-5.992-3.39l6.39-3.787a133.803 133.803 0 0 1 9.577-10.758c3.074-3.071 7.8-12.898 7.775-17.14z"
        fill="#0094D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.006 27.996v12.87a3.999 3.999 0 1 0 8 0v-12.87a3.999 3.999 0 1 0-8 0zM35.19 27.996v12.87a3.999 3.999 0 1 0 8 0v-12.87a3.999 3.999 0 1 0-8 0z"
        fill="#0095DA"
      />
    </svg>
  ),
});

export const PauseInROMMaxLegVariant = createIcon({
  displayName: 'PauseInROMMax',
  viewBox: '0 0 56 57',
  path: (
    <g clipPath="url(#581zosgora)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M9.044 33.176c2.385 2.663 4.624.727 5.839-1.774 1.2-2.477 5.348-5.575 10.32-8.27a11.662 11.662 0 0 0 5.335-6.8s6.575 9.908 9.486 13.3c3.454 4.028 8.734 6 11.144 3.857 2.225-1.981 4.74-6.576-.674-11.9-3.853-3.788-.225-.28-16.711-15.467-2.386-2.2-4.681-1.891-6.872.5a77.903 77.903 0 0 0-7.825 10.035c-2.114 2.925-5.738 8.7-6.646 9.3-1.247.829-1.45 1.933-4.191.113-4.68-3.106-6.372-4.2-5.911-.484a4.917 4.917 0 0 0 2.28 3.845c3.416 1.908 2.547 1.647 4.426 3.745z" />
      <path d="M18.793 35.492v12.866a4 4 0 1 0 8 0V35.492a4 4 0 0 0-8 0zM29.98 35.492v12.866a4 4 0 1 0 8 0V35.492a4 4 0 0 0-8 0z" />
    </g>
  ),
});
export const PauseInROMMaxAnkleVariant = createIcon({
  displayName: 'PauseInROMMaxAnkle',
  viewBox: '9 9 62 62',
  path: (
    <svg viewBox="9 9 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.005 27.996v12.87a3.999 3.999 0 1 0 8 0v-12.87a3.999 3.999 0 1 0-8 0zM35.19 27.996v12.87a3.999 3.999 0 1 0 8 0v-12.87a3.999 3.999 0 1 0-8 0z"
        fill="#0095DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.208 53.415c2.244-.047 5.525.047 3.918-8.771-2.225-12.21-2.514-11.277 7.045-11.685 13.066-.556 10.062-.213 8.694 8.346-.49 3.044-.515 6.099-.827 8.635-1.116 8.96 1.122 8.51 2.115 12.134.625 2.293.828 4.456.055 6.383-1.244 2.961-3.464 3.18-5.058 3.658-1.472.438-3.121-.23-4.544-.798-1.496-.608-3.145-1.649-4.463-2.83a12.04 12.04 0 0 0-6.138-2.914 40.31 40.31 0 0 1-9.136-2.565 45.602 45.602 0 0 1-5.138-2.95 19.523 19.523 0 0 1-4.052-.97c-2.907-1.223-3.275-3.22-1.901-6.5l7.106 1.72c5.034-.172 6.965-.78 12.324-.893z"
        fill="#0094D9"
      />
    </svg>
  ),
});

export const PauseInROMMinWheelVariant = createIcon({
  displayName: 'PauseInROMMin',
  viewBox: '0 0 56 57',
  path: (
    <g clipPath="url(#qe0bt3lq7a)">
      <g clipPath="url(#py1ohlqxab)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M43.99 23.155a18.58 18.58 0 0 0-17.122-11.382A18.572 18.572 0 0 0 8.306 30.336a4 4 0 0 1-8 0A26.576 26.576 0 0 1 26.868 3.773a26.582 26.582 0 0 1 24.5 16.28 4 4 0 0 1-3.666 5.553 4 4 0 0 1-3.712-2.45zM37.594 37.766v12.866a4 4 0 0 1-8 0V37.766a4 4 0 1 1 8 0zM26.406 37.766v12.866a4 4 0 1 1-8 0V37.766a4 4 0 0 1 8 0z"
          fill="#0094D9"
        />
        <path d="M46.513 12.281a6.4 6.4 0 1 0 0 12.8 6.4 6.4 0 0 0 0-12.8z" fill="#fff" />
        <path
          d="M46.513 9.281a9.406 9.406 0 0 1 9.4 9.4 9.406 9.406 0 0 1-9.4 9.4 9.406 9.406 0 0 1-9.4-9.4 9.406 9.406 0 0 1 9.4-9.4zm0 6a3.4 3.4 0 1 0 0 6.8 3.4 3.4 0 0 0 0-6.8z"
          fill="#0094D9"
        />
      </g>
    </g>
  ),
});

export const PauseInROMMaxWheelVariant = createIcon({
  displayName: 'PauseInROMMax',
  viewBox: '0 0 56 57',
  path: (
    <g clipPath="url(#8s6k2f4h7a)">
      <g clipPath="url(#d01zbvtjmb)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M12.01 23.155a18.58 18.58 0 0 1 17.122-11.382 18.572 18.572 0 0 1 18.562 18.563 4 4 0 1 0 8 0A26.576 26.576 0 0 0 29.132 3.773a26.582 26.582 0 0 0-24.5 16.28 4 4 0 0 0 3.666 5.553 4 4 0 0 0 3.712-2.45zM18.406 37.766v12.866a4 4 0 0 0 8 0V37.766a4 4 0 1 0-8 0zM29.594 37.766v12.866a4 4 0 1 0 8 0V37.766a4 4 0 0 0-8 0z"
          fill="#0094D9"
        />
        <path d="M9.487 12.281a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z" fill="#fff" />
        <path
          d="M9.487 9.281a9.406 9.406 0 0 0-9.4 9.4 9.406 9.406 0 0 0 9.4 9.4 9.406 9.406 0 0 0 9.4-9.4 9.406 9.406 0 0 0-9.4-9.4zm0 6a3.4 3.4 0 1 1 0 6.8 3.4 3.4 0 0 1 0-6.8z"
          fill="#0094D9"
        />
      </g>
    </g>
  ),
});

export const Check2Icon = createIcon({
  displayName: 'Check2Icon',
  viewBox: '0 0 68 53',
  path: (
    <path
      d="M26.243 51.023a3.41 3.41 0 0 1-4.837 0L2.09 31.703a5.131 5.131 0 0 1 0-7.255l2.42-2.419a5.131 5.131 0 0 1 7.255 0l12.06 12.06L56.41 1.501a5.132 5.132 0 0 1 7.257 0l2.418 2.42a5.131 5.131 0 0 1 0 7.255L26.243 51.023z"
      fill="#fff"
    />
  ),
});

export const CancelIcon = createIcon({
  displayName: 'CancelIcon',
  viewBox: '0 0 61 60',
  path: (
    <>
      <path
        d="M59.053 48.563 12.028 1.538a5.248 5.248 0 0 0-7.424 0L2.126 4.013a5.248 5.248 0 0 0 0 7.424l47.025 47.025a5.248 5.248 0 0 0 7.424 0l2.475-2.475a5.244 5.244 0 0 0 .003-7.424z"
        fill="#fff"
      />
      <path
        d="M49.15 1.538 2.127 48.563a5.248 5.248 0 0 0 0 7.424l2.475 2.474a5.248 5.248 0 0 0 7.424 0L59.053 11.44a5.246 5.246 0 0 0 0-7.424l-2.475-2.474a5.249 5.249 0 0 0-7.427-.004z"
        fill="#fff"
      />
    </>
  ),
});

export const PlateauTimeIcon = createIcon({
  displayName: 'PlateauTimeIcon',
  viewBox: '0 0 56 56',
  path: (
    <g clipPath="url(#up4sovj6ba)">
      <g clipPath="url(#cvm4mubsvb)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 55.228h4.27a4 4 0 0 0 3.965-3.475l4.429-33.425 22.22-.284L43.3 51.748a4 4 0 0 0 3.97 3.48h3.954a4 4 0 1 0 0-8h-.444L46.353 13.48A4 4 0 0 0 42.336 10l-29.236.374a4 4 0 0 0-3.913 3.474l-4.422 33.38H4a4 4 0 0 0 0 8z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
});
export const PauseTimeIcon = createIcon({
  displayName: 'PauseTimeIcon',
  viewBox: '0 0 56 56',
  path: (
    <>
      <g clipPath="url(#p0wzgktwya)" fillRule="evenodd" clipRule="evenodd" fill="#0095DA">
        <path d="M17.206 11.39a5.042 5.042 0 0 1 5.034 5.034v23.117a5.041 5.041 0 0 1-5.034 5.033 5.033 5.033 0 0 1-5.034-5.033V16.424a5.033 5.033 0 0 1 5.034-5.033zM38.818 11.39a5.042 5.042 0 0 1 5.034 5.034v23.117a5.041 5.041 0 0 1-5.034 5.033 5.033 5.033 0 0 1-5.034-5.033V16.424a5.033 5.033 0 0 1 5.034-5.033z" />
      </g>
      <defs>
        <clipPath id="p0wzgktwya">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const RiseTimeIcon = createIcon({
  displayName: 'RiseTimeIcon',
  viewBox: '0 0 56 56',
  path: [
    <path
      key="arrow-up"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 56.523h16.197a4 4 0 0 0 3.76-2.633L40.454 8.533h11.545a4.001 4.001 0 1 0 0-8.002H37.652a4 4 0 0 0-3.76 2.634L17.395 48.52H4a4.001 4.001 0 0 0 0 8.002z"
      fill="currentColor"
    />,
    <path
      key="left-slope"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.191 1.117-.617.481-.074.072-6.148 6.15a4.001 4.001 0 0 0 4.977 6.205v17.294a4 4 0 1 0 8.001 0V14.025a4 4 0 0 0 4.977-6.205L13.14 1.652a4.001 4.001 0 0 0-4.949-.535z"
      fill="currentColor"
    />,
  ],
});

export const FallTimeIcon = createIcon({
  displayName: 'FallTimeIcon',
  viewBox: '0 0 56 56',
  path: [
    <path
      key="arrow-down"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8.503h13.395l16.497 45.363a4.001 4.001 0 0 0 3.76 2.634H52a4 4 0 0 0 0-8.003H40.454L23.957 3.134A4.001 4.001 0 0 0 20.197.5H4.001a4 4 0 0 0 0 8.003z"
      fill="currentColor"
    />,
    <path
      key="slope-right"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.67 22.02a4 4 0 0 0-4.977 6.206l6.149 6.149.022.022.015.014a4.001 4.001 0 0 0 4.366.814l.023-.01.023-.01.023-.01.023-.01.023-.01.023-.011.023-.011.023-.011.022-.011.022-.011.022-.011.038-.02.029-.014.036-.02.03-.017.035-.02.03-.018.034-.02.042-.026.02-.013.022-.013.02-.013.022-.014.02-.014.022-.014.02-.014.022-.014.02-.014.022-.014.02-.014.02-.015.02-.015.02-.015.02-.015.02-.015.02-.015.02-.016.02-.016.02-.016.02-.016.02-.016.019-.016.02-.016.019-.017.019-.017.019-.017.019-.017.019-.017.019-.017.019-.017.019-.017.019-.018.019-.018.019-.018.018-.018.018-.018 6.148-6.15a4.001 4.001 0 0 0-4.977-6.206V4.724a4.002 4.002 0 0 0-8.002 0L41.67 22.02z"
      fill="currentColor"
    />,
  ],
});

export const PulseFrequencyIcon = createIcon({
  displayName: 'PulseFrequencyIcon',
  viewBox: '0 0 56 56',
  path: (
    <g clipPath="url(#dnzk45ufla)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .271v9.622a2.467 2.467 0 0 1 2.35-1.851 2.514 2.514 0 0 1 2.45 2.574v13.305H0v8.042h4.8v13.715A10.591 10.591 0 0 0 12.8 56h4.9a10.59 10.59 0 0 0 8-10.322V31.963h4.9v13.715A10.59 10.59 0 0 0 38.593 56h4.9a10.59 10.59 0 0 0 8-10.322V31.963H56v-8.042h-4.51V10.616a2.514 2.514 0 0 1 2.449-2.574A2.414 2.414 0 0 1 56 9.227V.207A10.292 10.292 0 0 0 53.939 0 10.538 10.538 0 0 0 43.49 10.616v13.305h-4.9V10.616A10.538 10.538 0 0 0 28.144 0 10.538 10.538 0 0 0 17.7 10.616v13.305h-4.9V10.616A10.538 10.538 0 0 0 2.35 0C1.559 0 .77.09 0 .271zm43.49 31.692h-4.9v13.715a2.514 2.514 0 0 0 2.449 2.574 2.514 2.514 0 0 0 2.449-2.574l.002-13.715zm-25.795 0H12.8v13.715a2.514 2.514 0 0 0 2.449 2.574 2.514 2.514 0 0 0 2.451-2.574l-.005-13.715zm12.9-8.042V10.616a2.514 2.514 0 0 0-2.449-2.574 2.514 2.514 0 0 0-2.446 2.574v13.305h4.895z"
        fill="currentColor"
      />
    </g>
  ),
});

export const PulseWidthIcon = createIcon({
  displayName: 'PulseWidthIcon',
  viewBox: '0 0 56 56',
  path: (
    <g clipPath="url(#nb6ec3hhna)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.009 32.024a3.989 3.989 0 0 0 .87 2l4.105 5.21a4.006 4.006 0 0 0 4.232 1.376 4 4 0 0 0 2.728-2.641c.243-.763.253-1.58.028-2.349h4.03a4.019 4.019 0 0 0 1.364 4.28 4.002 4.002 0 0 0 5.624-.666l4.105-5.21A4 4 0 0 0 42 31.483a4 4 0 0 0-.905-2.541l-4.105-5.21a4.008 4.008 0 0 0-2.674-1.5 4 4 0 0 0-2.95.835 4.013 4.013 0 0 0-1.281 4.53H25.89a4.018 4.018 0 0 0-1.282-4.53 4.002 4.002 0 0 0-5.624.666l-4.105 5.209a3.99 3.99 0 0 0-.87 2V8.023H42.05v43.965c0 1.064.422 2.085 1.173 2.837A4.001 4.001 0 0 0 46.055 56h5.938c1.062 0 2.081-.423 2.832-1.175a4.015 4.015 0 0 0 0-5.673 4.001 4.001 0 0 0-2.832-1.175H50.06V4.012a4.015 4.015 0 0 0-1.173-2.837A4.001 4.001 0 0 0 46.055 0H10.004c-1.062 0-2.08.423-2.832 1.175A4.015 4.015 0 0 0 6 4.012v43.965H4.005c-1.062 0-2.081.422-2.832 1.175a4.015 4.015 0 0 0 0 5.673A4.001 4.001 0 0 0 4.005 56h5.999c1.062 0 2.08-.423 2.832-1.175a4.015 4.015 0 0 0 1.173-2.837V32.024z"
        fill="currentColor"
      />
    </g>
  ),
});

export const UpIcon = createIcon({
  displayName: 'Up',
  viewBox: '0 0 40 28',
  path: (
    <path d="M38.07 27.5H1.93c-1.71 0-2.582-2.544-1.356-4.055l18.07-22.26c.743-.914 1.97-.914 2.712 0l18.07 22.26c1.226 1.511.355 4.055-1.355 4.055z" />
  ),
  defaultProps: {
    fill: '#0095DA',
  },
});

export const DownIcon = createIcon({
  displayName: 'Down',
  viewBox: '0 0 40 28',
  path: (
    <path d="M38.07.5H1.93C.22.5-.653 3.044.573 4.554l18.07 22.26c.743.915 1.97.915 2.712 0l18.07-22.26C40.652 3.044 39.781.5 38.071.5z" />
  ),
  defaultProps: {
    fill: '#0095DA',
  },
});

export const MinusIcon = createIcon({
  displayName: 'MinusIcon',
  viewBox: '0 -10 29 29',
  path: <path d="M3.66797 4.00488H25.0013" strokeWidth="6.66667" strokeLinecap="square" />,
  defaultProps: {
    stroke: '#0095DA',
  },
});

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 50 51',
  path: <path d="M5 25.5h40M25 5.5v40" strokeWidth="10" strokeLinecap="square" />,
  defaultProps: {
    stroke: '#0095DA',
  },
});

export const RepetitionIcon = createIcon({
  displayName: 'RepetitionIcon',
  viewBox: '0 0 56 57',
  path: (
    <g clipPath="url(#7qnwnahp3a)" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5.338 42.762h45.321M42.232 34.335l8.428 8.427M42.232 51.189l8.428-8.427M50.66 14.24H5.337M13.765 22.666 5.338 14.24M13.765 5.812l-8.427 8.427" />
    </g>
  ),
});

export const AngleSpeedIcon = createIcon({
  displayName: 'AngleSpeedIcon',
  viewBox: '0 0 56 57',
  path: (
    <>
      <g clipPath="url(#xppy7c37ka)" stroke="currentColor" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round">
        <path d="m52 28.5-25.09-24M4 28.5l22.91-24M41.091 18.713a19.574 19.574 0 0 1-3.82 2.837 a 19.849 19.849 0 0 1-12.214 2.44 a 19.724 19.724 0 0 1-11.239-5.308 Q 27 29 41.091 18.713 Z M8.363 45.955h38.182M39 39.5l8 6M39 52.5l8-7M16 52.5l-8-7M16 39.5l-8 6" />
      </g>
      <defs>
        <clipPath id="xppy7c37ka">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const TimerIcon = createIcon({
  displayName: 'TimerIcon',
  viewBox: '0 0 56 57',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.002.5h-.004A28.01 28.01 0 0 0 0 28.495v.005a28.015 28.015 0 0 0 27.998 28h.004A28.01 28.01 0 0 0 56 28.504V28.5a28.019 28.019 0 0 0-8.204-19.795A28.007 28.007 0 0 0 28.002.5zm14.137 13.862A20.012 20.012 0 0 1 48 28.501 20.013 20.013 0 0 1 28 48.5 20.01 20.01 0 0 1 8 28.498 20.016 20.016 0 0 1 28 8.5a20.01 20.01 0 0 1 14.139 5.862zM24.003 28.367a3.986 3.986 0 0 0 1.169 2.961l6.317 6.318a4.002 4.002 0 0 0 5.657 0 4.002 4.002 0 0 0 0-5.657L32 26.843V16.654c0-2.208-1.792-4-4-4s-4 1.792-4 4v11.557c0 .052.001.105.003.156z"
      fill="currentColor"
    />
  ),
});

export const MaxBackwardForceLimitIcon = createIcon({
  displayName: 'maxBackwardForceLimitIcon',
  viewBox: '0 0 56 57',
  path: (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#s7c3kmsm6a)">
        <g clipPath="url(#l59pji5gtb)">
          <path
            d="M19.639 45.565h17.277v-8.514H19.64v8.514zM49.766 34.734h-1.2v12.557h1.2a2.511 2.511 0 0 0 2.51-2.506v-7.543a2.511 2.511 0 0 0-2.51-2.508zM43.866 29.426h-4.76V53.19h4.76a2.512 2.512 0 0 0 2.51-2.51V31.937a2.512 2.512 0 0 0-2.51-2.511zM10.179 31.934v18.744a2.512 2.512 0 0 0 2.506 2.513h4.76V29.426h-4.76a2.511 2.511 0 0 0-2.506 2.508zM4.275 37.243v7.542a2.512 2.512 0 0 0 2.51 2.51h1.2V34.738h-1.2a2.511 2.511 0 0 0-2.51 2.505z"
            fill="#0094D9"
          />
          <path
            d="m18.106 7.426-5.784 5.784M18.106 18.995l-5.784-5.784M12.322 13.21h38.495M5.434 7.426v11.568"
            stroke="#0094D9"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="s7c3kmsm6a">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
        <clipPath id="l59pji5gtb">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
});

export const EndActiveMovementForceIcon = createIcon({
  displayName: 'EndActiveMovementForceIcon',
  viewBox: '0 0 56 57',
  path: (
    <>
      <g clipPath="url(#luhvf6slna)">
        <path
          d="M19.169 44.147h17.277v-8.514H19.17v8.514zM49.298 33.316h-1.2v12.557h1.2a2.512 2.512 0 0 0 2.51-2.506v-7.543a2.511 2.511 0 0 0-2.51-2.508zM43.396 28.008h-4.76v23.765h4.76a2.512 2.512 0 0 0 2.51-2.51V30.519a2.51 2.51 0 0 0-2.51-2.511zM9.71 30.516V49.26a2.511 2.511 0 0 0 2.506 2.513h4.76V28.008h-4.76a2.511 2.511 0 0 0-2.506 2.508zM3.807 35.825v7.542a2.511 2.511 0 0 0 2.51 2.51h1.199V33.32h-1.2a2.511 2.511 0 0 0-2.51 2.505z"
          fill="#0094D9"
        />
        <path
          d="m11.93 8.008-3.785 3.784M11.93 15.578l-3.785-3.785M23.783 8.008 20 11.792M23.783 15.578 20 11.793M35.639 8.008l-3.786 3.785M35.639 15.578l-3.786-3.785M47.491 8.008l-3.784 3.785M47.491 15.578l-3.784-3.785"
          stroke="#0094D9"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="luhvf6slna">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const StartActiveMovementForceIcon = createIcon({
  displayName: 'StartActiveMovementForceIcon',
  viewBox: '0 0 56 57',
  path: (
    <>
      <g clipPath="url(#k4hv9fsmga)">
        <g clipPath="url(#3v8eq0skfb)">
          <path
            d="M19.363 44.14h17.278v-8.515H19.363v8.514zM49.491 33.31h-1.199v12.557h1.2A2.511 2.511 0 0 0 52 43.361v-7.542a2.511 2.511 0 0 0-2.51-2.509zM43.59 28h-4.76v23.765h4.76a2.512 2.512 0 0 0 2.51-2.51V30.511A2.512 2.512 0 0 0 43.59 28zM9.902 30.508v18.744a2.511 2.511 0 0 0 2.507 2.513h4.76V28h-4.76a2.511 2.511 0 0 0-2.507 2.508zM4 35.817v7.543a2.512 2.512 0 0 0 2.51 2.51h1.199V33.312h-1.2A2.511 2.511 0 0 0 4 35.816z"
            fill="#0094D9"
          />
          <path
            d="m18.106 6-5.784 5.784M18.106 17.567l-5.784-5.784M12.322 11.783h31.354"
            stroke="#0094D9"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="k4hv9fsmga">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
        <clipPath id="3v8eq0skfb">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ForceSourceIcon = createIcon({
  displayName: 'ForceSourceIcon',
  viewBox: '0 0 56 57',
  path: (
    <>
      <g clipPath="url(#h0jgecbh5a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.373 45.613a2.583 2.583 0 0 0 3.936.3c1.073-1.677.277-1.653 1.967-4.29A14.488 14.488 0 0 1 19.4 36.58a44.148 44.148 0 0 0 5.741-4.78c3.162-2.847 3.305-5 3.305-5l12.167 14.56c2.72 3.705 7.67 6.62 11.878 4.22 3.412-1.945 5.242-6.115-.719-13.023L31.9 15.63a4.358 4.358 0 0 0-3.457-1.362 4.179 4.179 0 0 0-.243-.007 4.2 4.2 0 0 0-3.625 1.92s-9.32 18.02-14.452 22.5a1.732 1.732 0 0 1-1.726.323c-.74-.35-1.45-.759-2.127-1.22C1.12 34.66-.047 32.87.444 36.934a5.4 5.4 0 0 0 2.431 4.208c3.64 2.082 3.503 2.168 5.498 4.47z"
          fill="#0094D9"
        />
        <path
          d="M22.792 51.87h10.416v-5.134H22.792v5.133zM40.955 45.342h-.723v7.57h.723a1.514 1.514 0 0 0 1.513-1.511v-4.547a1.514 1.514 0 0 0-1.513-1.512zM37.4 42.14h-2.87v14.328h2.87a1.513 1.513 0 0 0 1.513-1.513v-11.3A1.514 1.514 0 0 0 37.4 42.14zM17.089 43.653v11.3a1.514 1.514 0 0 0 1.51 1.515h2.87V42.14H18.6a1.514 1.514 0 0 0-1.51 1.512zM13.532 46.854V51.4a1.514 1.514 0 0 0 1.513 1.513h.723v-7.57h-.723a1.514 1.514 0 0 0-1.513 1.51z"
          fill="#0094D9"
        />
        <path
          d="M11.929 3.258 8.144 7.042M11.929 10.826 8.144 7.041M23.783 3.258 20 7.042M23.783 10.826 20 7.041M35.638 3.258l-3.785 3.785M35.638 10.826l-3.785-3.785M47.492 3.258l-3.784 3.785M47.492 10.826l-3.784-3.785"
          stroke="#0094D9"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="h0jgecbh5a">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export const PlayIcon = createIcon({
  displayName: 'PlayButtonIcon',
  viewBox: '0 0 43 48',
  path: (
    <path d="M41.797 22.543a1.596 1.596 0 0 1 0 2.777L2.382 47.66a1.596 1.596 0 0 1-2.383-1.388V1.596A1.596 1.596 0 0 1 2.382.207l39.414 22.336z" />
  ),
  defaultProps: {
    fill: '#fff',
  },
});

export const AcceptIcon = createIcon({
  displayName: 'AcceptIcon',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#0jkrcgt77a)">
        <g clipPath="url(#3kch4lztsb)">
          <path
            d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32z"
            fill="#6CC"
          />
          <path
            d="M43.631 18.662 27.79 34.901l-7.422-7.04-5.214 5.222L27.79 45.33l21.058-21.45-5.216-5.217z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="0jkrcgt77a">
          <path fill="#fff" d="M0 0h64v64H0z" />
        </clipPath>
        <clipPath id="3kch4lztsb">
          <path fill="#fff" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    fill: '#fff',
  },
});

export const ExclamationMarkIcon = createIcon({
  displayName: 'ExclamationMarkIcon',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#yw5zbyws9a)">
        <path
          d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32z"
          fill="#FF9AA2"
        />
        <path d="M28.574 42.276h6.852v6.852h-6.852v-6.852zm0-27.407h6.852v20.555h-6.852V14.869z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="yw5zbyws9a">
          <path fill="#fff" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    fill: '#fff',
  },
});

export const PauseIcon = createIcon({
  displayName: 'PlayButtonIcon',
  viewBox: '0 0 53 60',
  path: <path d="M8.76 9.84v41.28M44.76 8.88v41.28" stroke="#fff" strokeWidth="16" strokeLinecap="round" />,
});

export const GearIcon = createIcon({
  displayName: 'GearIcon',
  viewBox: '0 0 60 57',
  path: (
    <path
      d="M55.783 19.92A7.718 7.718 0 0 1 49.7 9.485a3.623 3.623 0 0 0-1.082-4.076 29.042 29.042 0 0 0-8.664-5.02 3.642 3.642 0 0 0-4.115 1.141 7.697 7.697 0 0 1-12.128 0A3.643 3.643 0 0 0 19.595.388a29.18 29.18 0 0 0-8.073 4.529A3.662 3.662 0 0 0 10.38 9.11a7.679 7.679 0 0 1-6.399 10.554 3.643 3.643 0 0 0-3.19 2.934A27.841 27.841 0 0 0 .24 28.11c-.003 1.55.122 3.097.374 4.627a3.642 3.642 0 0 0 3.21 3.051 7.678 7.678 0 0 1 6.26 10.85 3.583 3.583 0 0 0 1.005 4.291 29.258 29.258 0 0 0 8.584 4.943c.4.138.818.21 1.24.216a3.624 3.624 0 0 0 2.954-1.536 7.619 7.619 0 0 1 6.3-3.307 7.719 7.719 0 0 1 6.183 3.11 3.622 3.622 0 0 0 4.253 1.201 29.536 29.536 0 0 0 7.876-4.705 3.643 3.643 0 0 0 1.063-4.155 7.678 7.678 0 0 1 6.163-10.612 3.643 3.643 0 0 0 3.09-2.993 28.54 28.54 0 0 0 .513-4.981c0-1.764-.165-3.524-.492-5.257a3.603 3.603 0 0 0-3.033-2.934zM39.62 28.11a9.845 9.845 0 1 1-19.69 0 9.845 9.845 0 0 1 19.69 0z"
      fill="#fff"
    />
  ),
});

export const StopIcon = createIcon({
  displayName: 'StopButtonIcon',
  viewBox: '0 0 44 44',
  path: (
    <path
      d="M.88 2.412v39.83c0 .846.686 1.531 1.532 1.531h39.83c.846 0 1.532-.685 1.532-1.532V2.412c0-.845-.686-1.531-1.532-1.531H2.412C1.566.88.88 1.566.88 2.412z"
      fill="#fff"
    />
  ),
});

export const AddFirstPatientPlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 50 51',
  path: <path d="M5 25.5h40M25 5.5v40" strokeWidth="4" strokeLinecap="square" />,
  defaultProps: {
    stroke: '#0095DA',
  },
});

export const EyeIcon = createIcon({
  displayName: 'EyeIcon',
  viewBox: '0 0 68 40',
  path: (
    <path
      d="M33.552 0C20.73 0 9.104 7.014.525 18.408c-.7.933-.7 2.237 0 3.17C9.105 32.987 20.731 40 33.552 40c12.82 0 24.447-7.014 33.027-18.408.7-.933.7-2.237 0-3.17C57.999 7.013 46.373 0 33.552 0zm.92 34.084c-8.511.535-15.54-6.48-15.004-15.004.44-7.028 6.136-12.724 13.164-13.164 8.51-.535 15.539 6.48 15.004 15.004-.453 7.014-6.15 12.71-13.165 13.164zm-.426-6.507c-4.585.288-8.373-3.486-8.071-8.071.233-3.789 3.308-6.85 7.096-7.097 4.585-.288 8.374 3.487 8.072 8.071-.247 3.803-3.322 6.864-7.097 7.097z"
      fill="#fff"
    />
  ),
});

export const ClosedEyeIcon = createIcon({
  displayName: 'EyeIcon',
  viewBox: '0 0 66 65',
  path: [
    <path key="0" d="M61.08 4 4.92 60.16" stroke="#fff" strokeWidth="8" strokeLinecap="round" />,
    <path
      key="1"
      d="M33.21 13C20.902 13 9.74 19.734 1.504 30.671a2.55 2.55 0 0 0 0 3.044C9.74 44.666 20.902 51.4 33.21 51.4s23.47-6.734 31.705-17.671a2.55 2.55 0 0 0 0-3.044C56.68 19.734 45.518 13 33.21 13zm.883 32.72c-8.17.514-14.918-6.22-14.404-14.403.422-6.747 5.89-12.216 12.638-12.637 8.17-.514 14.917 6.22 14.403 14.403-.435 6.734-5.903 12.203-12.637 12.637zm-.409-6.246c-4.401.277-8.038-3.347-7.748-7.748.224-3.637 3.175-6.576 6.813-6.813 4.4-.277 8.038 3.347 7.748 7.748-.237 3.65-3.189 6.59-6.813 6.813z"
      fill="#fff"
    />,
  ],
});

export const BatteryIcon = createIcon({
  displayName: 'BatteryIcon',
  viewBox: '0 0 32 16',
  path: (
    <path
      d="M24.663,8.6V18.981H2.6V8.6ZM27.26,6H0V21.577H27.26Zm1.3,11.683h.974a1.623,1.623,0,0,0,1.623-1.623V11.517a1.623,1.623,0,0,0-1.623-1.623h-.974ZM23.365,9.894H3.894v7.788H23.365Z"
      transform="translate(0 -6)"
      fill="currentColor"
    />
  ),
});

export const SignalIcon = createIcon({
  displayName: 'SignalIcon',
  viewBox: '0 0 20 16',
  path: (
    <path
      d="M6.948,15.1a4.284,4.284,0,0,1,6.408,0l-3.2,3.475L6.948,15.1ZM5.47,13.5a6.262,6.262,0,0,1,9.364,0l1.971-2.139a9.028,9.028,0,0,0-6.653-2.989A9.025,9.025,0,0,0,3.5,11.357L5.47,13.5ZM2.02,9.753A11.034,11.034,0,0,1,10.152,6.1a11.034,11.034,0,0,1,8.132,3.653L20.3,7.562A13.775,13.775,0,0,0,10.152,3,13.775,13.775,0,0,0,0,7.562L2.02,9.753Z"
      transform="translate(0 -3)"
      fill="currentColor"
    />
  ),
});

export const LineChartIcon = (props: IconProps) => {
  return (
    <Icon as="svg" width="88" height="87" viewBox="0 0 88 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#kf6pda53ga)">
        <path
          d="M44 80c22.091 0 40-17.908 40-40C84 17.91 66.091 0 44 0S4 17.909 4 40c0 22.092 17.909 40 40 40z"
          fill="#EBF8FF"
        />
        <mask id="2ib75mpsvb" mask="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="0" width="80" height="80">
          <path
            d="M82 40c0 20.987-17.013 38-38 38S6 60.988 6 40 23.013 2 44 2s38 17.013 38 38z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="4"
          />
        </mask>
        <g mask="url(#2ib75mpsvb)">
          <path
            d="M82 40c0 20.987-17.013 38-38 38S6 60.988 6 40 23.013 2 44 2s38 17.013 38 38z"
            stroke="#A0AEC0"
            strokeWidth="4"
          />
          <path
            d="M-47.71 57.411c18.704-1.375 42.133-.088 56.113-4.14 13.89-4.024 18.368-20.311 27.791-20.176 9.437.134 14.123 17.029 28.776 20.986 15.041 4.051 40.957 2.224 61.435 3.33"
            stroke="#A0AEC0"
            strokeWidth="4.521"
            strokeMiterlimit="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M-8.316 57.407c14.45-2.04 32.55-.13 43.35-6.14 10.73-5.97 14.19-30.13 21.47-29.93 7.29.2 10.91 25.26 22.23 31.13 11.62 6.01 31.64 3.3 47.46 4.94"
            stroke="#718096"
            strokeWidth="5"
            strokeMiterlimit="1.5"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="kf6pda53ga"
          x="0"
          y="0"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1720_53809" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1720_53809" result="shape" />
        </filter>
      </defs>
    </Icon>
  );
};

export const BarChartIcon = (props: IconProps) => {
  return (
    <Icon as="svg" width="88" height="87" viewBox="0 0 88 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#1lf9jek9ua)">
        <path
          d="M44 80c22.091 0 40-17.908 40-40C84 17.91 66.091 0 44 0S4 17.909 4 40c0 22.092 17.909 40 40 40z"
          fill="#EBF8FF"
        />
        <mask id="no6fhzv4pb" mask="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="0" width="80" height="80">
          <path
            d="M44 80c22.091 0 40-17.908 40-40C84 17.91 66.091 0 44 0S4 17.909 4 40c0 22.092 17.909 40 40 40z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#no6fhzv4pb)">
          <path
            d="M62.563 18.016h-11a4 4 0 0 0-4 4v36a4 4 0 0 0 4 4h11a4 4 0 0 0 4-4v-36a4 4 0 0 0-4-4z"
            fill="#2874B2"
          />
          <path
            d="M34.074 33.453H23.03a4 4 0 0 0-4 4v21.563a4 4 0 0 0 4 4h11.043a4 4 0 0 0 4-4V37.453a4 4 0 0 0-4-4z"
            fill="#AB2524"
          />
          <path
            d="M9.395 60.758h78.06"
            stroke="#0095DA"
            strokeWidth="5"
            strokeMiterlimit="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M82.563 40.516c0 21.263-17.237 38.5-38.5 38.5-21.264 0-38.5-17.237-38.5-38.5s17.236-38.5 38.5-38.5c21.263 0 38.5 17.237 38.5 38.5z"
            stroke="#C4ECFF"
            strokeWidth="4"
          />
        </g>
      </g>
      <defs>
        <filter
          id="1lf9jek9ua"
          x="0"
          y="0"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1720_53810" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1720_53810" result="shape" />
        </filter>
      </defs>
    </Icon>
  );
};

export const StellaGreenIcon = createIcon({
  displayName: 'StellaIcon',
  viewBox: '0 0 48 74',
  path: [
    <path
      key="1"
      id="Path_3681"
      data-name="Path 3681"
      d="M4.673-54.364c.132-.6,1.306-4.7,1.349-4.822.829-2.432,1.421-3.152,4.686-3.6a122.582,122.582,0,0,1,26.722-.154c2.533.266,3.627.426,4.805,3.36.094.233,2.751,10.524,3.01,11.629.246,1.047,2.07,9.367,2.352,10.943.313,1.747.579,3.5.755,5.269q.123,1.228.185,2.462c.031.639.062,3.311.034,4.014A75.415,75.415,0,0,1,46.95-13.784C45.779-7.264,43.526,2.8,42.668,6.3c-.179.734-.3,1.18-.332,1.247-.746,1.961-1.958,2.291-4.514,2.416-1.592.151-3.153.264-3.153.264q.132-.011.259-.028c-7.144.531-23.228-.132-24.248-.229L10.6,9.964c-3.043-.15-4.182-.589-4.9-3.725,0,0-2.6-11.943-4.346-21.87C1.054-17.376.185-23.165.035-25.942a67.612,67.612,0,0,1,.454-9.43c.612-3.808,1.644-8.278,3.1-14.341C3.832-50.746,4.523-53.685,4.673-54.364Z"
      transform="translate(0 63.597)"
      fill="#64cbcc"
      fillRule="evenodd"
    />,
    <path
      key="2"
      id="Path_3682"
      data-name="Path 3682"
      d="M36.635-29.639A13.923,13.923,0,0,1,22.718-15.722,13.923,13.923,0,0,1,8.8-29.639,13.923,13.923,0,0,1,22.718-43.556,13.923,13.923,0,0,1,36.635-29.639Z"
      transform="translate(1.439 66.875)"
      fill="#fff"
      fillRule="evenodd"
    />,
    <path
      key="3"
      id="Path_3683"
      data-name="Path 3683"
      d="M31.543-30.267a9.457,9.457,0,0,1-9.453,9.453,9.457,9.457,0,0,1-9.453-9.453,9.457,9.457,0,0,1,9.453-9.453A9.457,9.457,0,0,1,31.543-30.267Z"
      transform="translate(2.067 67.502)"
      fill="#64cbcc"
      fillRule="evenodd"
    />,
  ],
});

export const StellaWhiteIcon = createIcon({
  displayName: 'StellaIcon',
  viewBox: '0 0 48 74',
  path: [
    <path
      key="1"
      id="Path_3681"
      data-name="Path 3681"
      d="M4.673-54.364c.132-.6,1.306-4.7,1.349-4.822.829-2.432,1.421-3.152,4.686-3.6a122.582,122.582,0,0,1,26.722-.154c2.533.266,3.627.426,4.805,3.36.094.233,2.751,10.524,3.01,11.629.246,1.047,2.07,9.367,2.352,10.943.313,1.747.579,3.5.755,5.269q.123,1.228.185,2.462c.031.639.062,3.311.034,4.014A75.415,75.415,0,0,1,46.95-13.784C45.779-7.264,43.526,2.8,42.668,6.3c-.179.734-.3,1.18-.332,1.247-.746,1.961-1.958,2.291-4.514,2.416-1.592.151-3.153.264-3.153.264q.132-.011.259-.028c-7.144.531-23.228-.132-24.248-.229L10.6,9.964c-3.043-.15-4.182-.589-4.9-3.725,0,0-2.6-11.943-4.346-21.87C1.054-17.376.185-23.165.035-25.942a67.612,67.612,0,0,1,.454-9.43c.612-3.808,1.644-8.278,3.1-14.341C3.832-50.746,4.523-53.685,4.673-54.364Z"
      transform="translate(0 63.597)"
      fill="#fff"
      fillRule="evenodd"
    />,
    <path
      key="2"
      id="Path_3682"
      data-name="Path 3682"
      d="M36.635-29.639A13.923,13.923,0,0,1,22.718-15.722,13.923,13.923,0,0,1,8.8-29.639,13.923,13.923,0,0,1,22.718-43.556,13.923,13.923,0,0,1,36.635-29.639Z"
      transform="translate(1.439 66.875)"
      fill="#6cc"
      fillRule="evenodd"
    />,
    <path
      key="3"
      id="Path_3683"
      data-name="Path 3683"
      d="M31.543-30.267a9.457,9.457,0,0,1-9.453,9.453,9.457,9.457,0,0,1-9.453-9.453,9.457,9.457,0,0,1,9.453-9.453A9.457,9.457,0,0,1,31.543-30.267Z"
      transform="translate(2.067 67.502)"
      fill="#fff"
      fillRule="evenodd"
    />,
  ],
});

export const LeftThightIcon = createIcon({
  displayName: 'LeftThightIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      key="1"
      d="M4.983 0C2.286 0 .997 2.57.997 4.988a16.893 16.893 0 0 0 1.03 5.698.467.467 0 0 0 .437.296h4.031a.467.467 0 0 0 .464-.478c0-1.903 1.004-2.933 1.004-5.523C7.963 2.824 7.605 0 4.983 0zM6.495 12.05H2.471a.468.468 0 0 0-.47.47v1.003a2.48 2.48 0 0 0 4.228 1.75 2.48 2.48 0 0 0 .727-1.75V12.52a.467.467 0 0 0-.46-.47z"
      fill="#4A5568"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.506 10.978h4.02a.468.468 0 0 0 .437-.295 16.887 16.887 0 0 0 1.03-5.695C15.994 2.583 14.706 0 12.008 0c-2.622 0-2.98 2.82-2.98 4.988 0 2.59 1.004 3.617 1.004 5.523a.467.467 0 0 0 .475.467zm3.374-1.374h-2.53a9.592 9.592 0 0 0-.343-1.515 9.422 9.422 0 0 1-.59-3.091 8.958 8.958 0 0 1 .243-2.326c.19-.687.526-1.288 1.375-1.288 1.883 0 2.611 1.924 2.611 3.614a15.653 15.653 0 0 1-.766 4.606zM14.526 12.05h-4.02a.467.467 0 0 0-.468.47v1.003A2.48 2.48 0 0 0 12.516 16a2.481 2.481 0 0 0 2.478-2.477V12.52a.467.467 0 0 0-.468-.47zm-.904 1.374v.1a1.101 1.101 0 0 1-1.106 1.102 1.104 1.104 0 0 1-1.103-1.103v-.1h2.21z"
      fill="#4A5568"
    />,
  ],
});

export const RightTightIcon = createIcon({
  displayName: 'RightTightIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      key="1"
      d="M12.01 0c2.698 0 3.987 2.57 3.987 4.988a16.894 16.894 0 0 1-1.03 5.698.467.467 0 0 1-.437.296h-4.031a.467.467 0 0 1-.464-.478c0-1.903-1.004-2.933-1.004-5.523C9.031 2.824 9.39 0 12.011 0zM10.499 12.05h4.024a.468.468 0 0 1 .47.47v1.003A2.48 2.48 0 0 1 12.517 16a2.482 2.482 0 0 1-2.478-2.477V12.52a.466.466 0 0 1 .46-.47z"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.488 10.978h-4.02a.467.467 0 0 1-.437-.295 16.886 16.886 0 0 1-1.03-5.695C1 2.583 2.288 0 4.986 0c2.622 0 2.98 2.82 2.98 4.988 0 2.59-1.004 3.617-1.004 5.523a.467.467 0 0 1-.475.467zM3.114 9.604h2.529a9.59 9.59 0 0 1 .344-1.515c.369-.99.569-2.035.59-3.091a8.958 8.958 0 0 0-.243-2.326c-.19-.687-.526-1.288-1.375-1.288-1.883 0-2.611 1.924-2.611 3.614a15.65 15.65 0 0 0 .766 4.606zM2.468 12.05h4.02a.467.467 0 0 1 .468.47v1.003a2.48 2.48 0 0 1-4.229 1.75A2.48 2.48 0 0 1 2 13.524V12.52a.467.467 0 0 1 .468-.47zm.904 1.374v.1a1.102 1.102 0 0 0 1.106 1.102 1.104 1.104 0 0 0 1.103-1.103v-.1h-2.21z"
    />,
  ],
});

export const SpecialistIcon = createIcon({
  displayName: 'SpecialistIcon',
  viewBox: '0 0 60 60',
  d: 'M51.929,45.131c-8.577-1.98-16.562-3.716-12.694-11.018C51,11.886,42.355,0,29.927,0,17.253,0,8.816,12.343,20.62,34.112c3.985,7.345-4.3,9.08-12.694,11.018C.5,46.846,0,50.475,0,56.76l.012,3.095h59.83l.012-3.095c0-6.285-.5-9.913-7.926-11.629Zm-4.544,9.736H34.915V49.879h12.47Z',
});

export const StethoscopeIcon = createIcon({
  displayName: 'StethoscopeIcon',
  viewBox: '0 0 60 56',
  path: (
    <path
      d="M34.169,8.617a4.987,4.987,0,1,1,.147-5.006c6.46.736,10.939,4.454,10.558,10.573-.461,7.131-8.216,19-14.967,24.168A9.728,9.728,0,0,1,25.432,45.6a10.458,10.458,0,0,0,10.52,5.205c4.063-.476,7.841-3.407,9.877-6.717a8.725,8.725,0,1,1,4.731,1.778c-2.881,5.317-8.981,10-15.843,10-6.767,0-11.949-3.734-14.765-9.979A11.336,11.336,0,0,1,15,38.337C8.081,33,0,20.452,0,13.488,0,7.547,4.676,4.3,10.575,3.619a4.99,4.99,0,1,1,.16,5.011c-5.113.746-6.365,3.21-5.462,6.814,1.579,6.4,8.991,17.129,14.735,20.255a4.215,4.215,0,0,0,4.888,0C31.7,32,39.813,19,39.906,13.61c.055-3.212-2.566-4.542-5.736-4.993m16.96,32.289a3.741,3.741,0,1,1,3.741-3.741,3.746,3.746,0,0,1-3.741,3.741"
      transform="translate(0 -1)"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const CalendarCardIcon = createIcon({
  displayName: 'CalendarIcon',
  d: 'M20 19h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm16-14v22h-24v-22h24zm-2 6h-20v14h20v-14zm-8 8h-4v4h4v-4zm-6-6h-4v4h4v-4z',
});

export const PhoneIcon = createIcon({
  displayName: 'PhoneIcon',
  d: 'M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z',
});

export const GenderIcon = createIcon({
  displayName: 'GenderIcon',
  d: 'M15 0v2h2.586l-3.399 3.399c-1.169-.875-2.615-1.399-4.187-1.399-3.866 0-7 3.134-7 7 0 3.526 2.609 6.434 6 6.92v2.08h-3v2h3v2h2v-2h3v-2h-3v-2.08c3.391-.486 6-3.395 6-6.92 0-1.572-.524-3.018-1.399-4.187l3.399-3.399v2.586h2v-6h-6zm-5 16c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z',
});

export const AlertIcon = createIcon({
  displayName: 'AlertIcon',
  viewBox: '0 0 30 30',
  path: [
    <path
      key="alert1"
      d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
      fill="#FF9AA2"
    />,
    <path key="alert2" d="M13.395 19.817h3.212v3.212h-3.212v-3.212zm0-12.847h3.212v9.635h-3.212V6.97z" fill="#fff" />,
  ],
});

export const EmailIcon = createIcon({
  displayName: 'EmailIcon',
  d: 'M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z',
});

export const PinIcon = createIcon({
  displayName: 'PinIcon',
  d: 'M11 17h2v5l-2 2v-7zm3.571-12c0-2.903 2.36-3.089 2.429-5h-10c.068 1.911 2.429 2.097 2.429 5 0 3.771-3.429 3.291-3.429 10h12c0-6.709-3.429-6.229-3.429-10z',
});

export const DelIcon = createIcon({
  displayName: 'DelIcon',
  d: 'M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z',
});
const DeleteIcon = () => {
  return (
    <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path id="Path_1514" data-name="Path 1514" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_1515"
        data-name="Path 1515"
        d="M10,10,25.33,25.33m0-15.33L10,25.33"
        transform="translate(-5.665 -5.665)"
        fill="none"
        stroke="#0095da"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const AddIcon = createIcon({
  displayName: 'AddIcon',
  d: 'M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z',
});

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  d: 'M 1 0 H 14.866 L 9.194 9.513 v 5.613 L 6.673 13.236 V 9.513 Z',
});

export const FavoriteIcon = createIcon({
  displayName: 'FavoriteIcon',
  viewBox: '0 0 20.396 40.792',
  d: 'M6,0V40.792l10.2-8.956,10.2,8.956V0ZM20.4,23.456,16.2,21.21,12,23.456l.84-4.689L9.4,15.464l4.72-.651L16.2,10.524l2.079,4.287,4.72.653-3.435,3.3.84,4.689Z',
});

export const TimeIcon = createIcon({
  displayName: 'TimeIcon',
  d: 'M 8.107 0 a 8.107 8.107 0 1 0 8.107 8.107 A 8.107 8.107 0 0 0 8.107 0 Z M 12.16 9.458 H 7.431 v -5.4 H 8.783 V 8.107 H 12.16 Z',
});

export const RepeatIcon = createIcon({
  displayName: 'RepeatIcon',
  viewBox: '0 0 24 24',
  d: 'M5 10v7h10.797l1.594 2h-14.391v-9h-3l4-5 4 5h-3zm14 4v-7h-10.797l-1.594-2h14.391v9h3l-4 5-4-5h3z',
});

export const ChartIcon = createIcon({
  displayName: 'ChartIcon',
  viewBox: '0 0 24 24',
  d: 'M24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0 17.125v2h-24v-22h2v20h22z',
});

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  d: 'M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
});

export const ExclamationIcon = createIcon({
  displayName: 'ExclamationIcon',
  d: 'M10 3h4v12h-4zM10,19a2,2 0 1,0 4,0a2,2 0 1,0 -4,0',
});

export const ErrorOutline = createIcon({
  displayName: 'ExclamationOutlineIcon',
  d: 'M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
});

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  viewBox: '0 0 24 24',
  d: 'M 14.71 6.71 a 0.9959 0.9959 0 0 0 -1.41 0 L 8.71 11.3 c -0.39 0.39 -0.39 1.02 0 1.41 l 4.59 4.59 c 0.39 0.39 1.02 0.39 1.41 0 c 0.39 -0.39 0.39 -1.02 0 -1.41 L 10.83 12 l 3.88 -3.88 c 0.39 -0.39 0.38 -1.03 0 -1.41 Z',
});

export const NextIcon = createIcon({
  displayName: 'NextIcon',
  viewBox: '0 0 24 24',
  d: 'M 5.91 23.8 a 0.5 0.5 0 0 0 0.35 0.15 a 0.51 0.51 0 0 0 0.36 -0.15 L 18.09 12.33 a 0.5 0.5 0 0 0 0 -0.71 L 6.66 0.2 A 0.48 0.48 0 0 0 6 0.2 A 0.48 0.48 0 0 0 6 0.9 L 17 12 L 5.91 23.1 A 0.48 0.48 0 0 0 5.91 23.8 Z',
});

export const BackIcon = createIcon({
  displayName: 'BackIcon',
  viewBox: '0 0 24 24',
  d: 'M 18 0.2 a 0.48 0.48 0 0 0 -0.7 0 L 5.91 11.62 a 0.53 0.53 0 0 0 -0.15 0.36 a 0.51 0.51 0 0 0 0.15 0.35 L 17.38 23.8 a 0.51 0.51 0 0 0 0.36 0.15 a 0.5 0.5 0 0 0 0.35 -0.15 a 0.48 0.48 0 0 0 0 -0.7 L 7 12 L 18 0.9 A 0.48 0.48 0 0 0 18 0.2 Z',
});

export const RemoveIcon = createIcon({
  displayName: 'RemoveIcon',
  viewBox: '0 0 17.165 17.828',
  path: (
    <path
      id="Path_3693"
      data-name="Path 3693"
      d="M15.838-1.5,1.5-16.5m14.338,0L1.5-1.5"
      transform="translate(-0.086 17.914)"
      fill="none"
      stroke="#7b8795"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  ),
});

export const TriangularWarning = createIcon({
  displayName: 'TriangularWarning',
  viewBox: '0 0 200 200',
  path: (
    <g xmlns="http://www.w3.org/2000/svg" id="Group_1115" data-name="Group 1115" transform="translate(-1070 -358.547)">
      <path
        id="Polygon_4"
        data-name="Polygon 4"
        d="M91.2,13.552a9,9,0,0,1,15.6,0L190.236,158.51a9,9,0,0,1-7.8,13.49H15.565a9,9,0,0,1-7.8-13.49Z"
        transform="translate(1070 358.547)"
        fill="#e4cf19"
      />
      <path
        id="Path_3334"
        data-name="Path 3334"
        d="M0,0H18.851L17.084,49.013H1.95Z"
        transform="translate(1160.486 426.865)"
        fill="#fff"
      />
      <ellipse
        id="Ellipse_568"
        data-name="Ellipse 568"
        cx="9.5"
        cy="8.5"
        rx="9.5"
        ry="8.5"
        transform="translate(1160 490.547)"
        fill="#fff"
      />
    </g>
  ),
});

export const Brightness = createIcon({
  displayName: 'Brightness',
  viewBox: '0 0 47 47',
  path: (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.347 36.386c7.205 0 13.046-5.84 13.046-13.044 0-7.203-5.841-13.043-13.046-13.043-7.205 0-13.046 5.84-13.046 13.043 0 7.204 5.84 13.044 13.046 13.044zM23.336 43.275v1.387M23.336 2.004v1.387M3.391 23.334H2.004M44.669 23.334H43.28M9.23 37.434l-.98.98M38.418 8.251l-.98.981M9.23 9.232l-.98-.98M38.418 38.415l-.98-.98M23.336 43.275v1.387M23.336 2.004v1.387M3.391 23.334H2.004M44.669 23.334H43.28M9.23 37.434l-.98.98M38.418 8.251l-.98.981M9.23 9.232l-.98-.98M38.418 38.415l-.98-.98"
        stroke="#718096"
        strokeWidth="3.333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export const LockedMotor = createIcon({
  displayName: 'LockedMotor',
  viewBox: '0 0 70 70',
  path: (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.637 18.789C10.057 9.188 21.685 3 34.747 3c13.348 0 25.199 6.462 32.591 16.425"
        stroke="#fff"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.648 18.805V7.64M67.316 18.805V7.64M67.36 19.44H56.195M2.648 19.44h11.165"
        stroke="#fff"
        strokeWidth="5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.172 42.481h21.13a3.445 3.445 0 0 1 3.445 3.446v17.627a3.445 3.445 0 0 1-3.445 3.445h-21.13a3.445 3.445 0 0 1-3.445-3.445V45.927a3.445 3.445 0 0 1 3.445-3.446z"
        stroke="#fff"
        strokeWidth="5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.23 42.482v-7.005c0-5.803 4.705-10.508 10.508-10.508 5.803 0 10.508 4.705 10.508 10.508v7.005"
        stroke="#fff"
        strokeWidth="5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export const UnlockedMotor = createIcon({
  displayName: 'LockedMotor',
  viewBox: '0 0 70 70',
  path: (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.752 67c11.951 0 21.64-9.686 21.64-21.634 0-11.949-9.689-21.635-21.64-21.635-11.95 0-21.639 9.686-21.639 21.635C13.113 57.314 22.801 67 34.753 67zM2.973 18.625C10.315 9.123 21.823 3 34.75 3c13.21 0 24.938 6.395 32.254 16.254"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.988 18.642V7.594M66.984 18.642V7.594M67.025 19.27H55.977M2.987 19.27h11.048"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.89 45.334a5.138 5.138 0 1 1-10.277 0 5.138 5.138 0 1 1 10.277 0z"
        fill="#A0AEC0"
        stroke="#A0AEC0"
        strokeWidth="5"
      />
    </svg>
  ),
});

export const SoundOn = createIcon({
  displayName: 'SoundOn',
  viewBox: '0 0 78 70',
  path: (
    <svg width="78" height="70" viewBox="0 0 78 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.544 19.768H3.43v30.465h15.114V19.768z"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="m43.817 3-25.27 16.767v30.466L43.817 67V3z"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.203 25.42c3.318.651 6.81 4.291 6.81 9.599 0 5.287-3.044 8.073-6.81 9.599"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.633 11.05c8.574 3.034 16.933 10.67 16.933 23.868 0 13.147-8.467 20.025-16.933 23.868"
        stroke="#A0AEC0"
        strokeWidth="5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export const SoundOff = createIcon({
  displayName: 'SoundOff',
  viewBox: '0 0 52 47',
  path: (
    <svg width="52" height="47" viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m37.016 16.83 13.017 13.017M50.033 16.83 37.016 29.846M12.71 13.178H2.632v20.31h10.076v-20.31z"
        stroke="#fff"
        strokeWidth="3.333"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M29.557 2 12.711 13.178v20.31l16.846 11.179V2z"
        stroke="#fff"
        strokeWidth="3.333"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

const ChatIcon = () => {
  return (
    <svg
      id="Group_671"
      data-name="Group 671"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_1" data-name="Path 1" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M4,21V8A3,3,0,0,1,7,5H17a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3H8L4,21"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_4"
        data-name="Line 4"
        transform="translate(12 11)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_5"
        data-name="Line 5"
        transform="translate(8 11)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_6"
        data-name="Line 6"
        transform="translate(16 11)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg id="tabler-icon-calendar-event" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path id="Path_1507" data-name="Path 1507" d="M0,0H24V24H0Z" fill="none" />
      <rect
        id="Rectangle_755"
        data-name="Rectangle 755"
        width="16"
        height="16"
        rx="2"
        transform="translate(4 5)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_18"
        data-name="Line 18"
        y2="4"
        transform="translate(16 3)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_19"
        data-name="Line 19"
        y2="4"
        transform="translate(8 3)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_20"
        data-name="Line 20"
        x2="16"
        transform="translate(4 11)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect
        id="Rectangle_756"
        data-name="Rectangle 756"
        width="2"
        height="2"
        transform="translate(8 15)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
const NoteListIcon = () => {
  return (
    <svg id="NoteListIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path id="Path_1513" data-name="Path 1513" d="M0,0H24V24H0Z" fill="none" />
      <rect
        id="Rectangle_801"
        data-name="Rectangle 801"
        width="14"
        height="18"
        rx="2"
        transform="translate(5 3)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_21"
        data-name="Line 21"
        x2="6"
        transform="translate(9 7)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_22"
        data-name="Line 22"
        x2="6"
        transform="translate(9 11)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_23"
        data-name="Line 23"
        x2="4"
        transform="translate(9 15)"
        fill="none"
        stroke="#1f2933"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
const TrainingIcon = () => {
  return (
    <svg
      id="Icon_-_weight"
      data-name="Icon - weight"
      xmlns="http://www.w3.org/2000/svg"
      width="24.532"
      height="17.296"
      viewBox="0 0 24.532 17.296"
    >
      <path
        id="Path_3696"
        data-name="Path 3696"
        d="M20.888-1.612V-3.041a1.335,1.335,0,0,0-1.34-1.33H17.726a1.335,1.335,0,0,0-1.34,1.33V2.044H8.146V-3.041a1.335,1.335,0,0,0-1.34-1.33H4.984a1.335,1.335,0,0,0-1.34,1.33v1.429H3.162a1.335,1.335,0,0,0-1.34,1.33V2.044H1.34A1.335,1.335,0,0,0,0,3.373V5.182a1.335,1.335,0,0,0,1.34,1.33h.482V8.838a1.335,1.335,0,0,0,1.34,1.33h.482V11.6a1.335,1.335,0,0,0,1.34,1.33H6.806a1.335,1.335,0,0,0,1.34-1.33V6.511h8.24V11.6a1.335,1.335,0,0,0,1.34,1.33h1.822a1.335,1.335,0,0,0,1.34-1.33V10.167h.482a1.335,1.335,0,0,0,1.34-1.33V6.511h.482a1.335,1.335,0,0,0,1.34-1.33V3.373a1.335,1.335,0,0,0-1.34-1.33H22.71V-.283a1.335,1.335,0,0,0-1.34-1.33Zm-1.34,1.33V-3.041H17.726V3.373H6.806V-3.041H4.984V-.283H3.162V3.373H1.34V5.182H3.162V8.838H4.984V11.6H6.806V5.182h10.92V11.6h1.822V8.838H21.37V5.182h1.822V3.373H21.37V-.283Z"
        transform="translate(0 4.371)"
        fill="#1f2933"
        fillRule="evenodd"
      />
    </svg>
  );
};
const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.855" height="15.509" viewBox="0 0 16.855 15.509">
      <path
        id="iconmonstr-magnifier-2_1_"
        data-name="iconmonstr-magnifier-2 (1)"
        d="M16.855,13.988l-4.393-4.01a5.9,5.9,0,0,0,1.315-3.69C13.777,2.821,10.687,0,6.888,0S0,2.821,0,6.288s3.09,6.288,6.888,6.288a7.317,7.317,0,0,0,3.884-1.1l4.416,4.031,1.666-1.521ZM2.02,6.288A4.676,4.676,0,0,1,6.889,1.844a4.676,4.676,0,0,1,4.868,4.444,4.676,4.676,0,0,1-4.868,4.444A4.676,4.676,0,0,1,2.02,6.288Z"
        fill="#cbd2da"
      />
    </svg>
  );
};

const AnonymousIcon = () => {
  return (
    <svg
      id="Icon_-_anonimize"
      data-name="Icon - anonimize"
      xmlns="http://www.w3.org/2000/svg"
      width="17.25"
      height="19"
      viewBox="0 0 17.25 19"
    >
      <g id="anonimize">
        <rect id="Rectangle_1606" data-name="Rectangle 1606" width="17.25" height="19" fill="none" />
        <g id="Group_2064" data-name="Group 2064">
          <path
            id="Path_3706"
            data-name="Path 3706"
            d="M2.75,7,4.03,5.75,13.26,15,20,21.72,18.73,23l-3-3H4V17c0-1.86,2.61-3.08,5.09-3.64L2.75,7M20,17v2.18l-1.9-1.9V17c0-.26-.5-.65-1.3-1L14,13.18c2.71.45,6,1.73,6,3.82M5.9,17v1.1h7.93L10.72,15c-2.53.3-4.82,1.45-4.82,2M12,4a4,4,0,0,1,.75,7.93L8.07,7.25A4,4,0,0,1,12,4m0,2a2,2,0,1,0,2,2A2.01,2.01,0,0,0,12,6Z"
            transform="translate(-2.75 -4)"
            fill="#1f2933"
          />
        </g>
      </g>
    </svg>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      id="Icon_-_archive"
      data-name="Icon - archive"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="archive">
        <rect id="Rectangle_1605" data-name="Rectangle 1605" width="18" height="18" fill="none" />
        <g id="Group_2063" data-name="Group 2063" transform="translate(0)">
          <path
            id="Path_3705"
            data-name="Path 3705"
            d="M20,21H4V10H6v9H18V10h2V21M3,3H21V9H3V3m6.5,8h5a.5.5,0,0,1,.5.5V13H9V11.5a.5.5,0,0,1,.5-.5M5,5V7H19V5Z"
            transform="translate(-3 -3)"
            fill="#1f2933"
          />
        </g>
      </g>
    </svg>
  );
};

const RestoreIcon = () => {
  return (
    <svg
      id="Icon_-_restore"
      data-name="Icon - restore"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1608" data-name="Rectangle 1608" width="20" height="18" transform="translate(0)" />
        </clipPath>
      </defs>
      <g id="restore" transform="translate(0)">
        <rect
          id="Rectangle_1607"
          data-name="Rectangle 1607"
          width="20"
          height="18"
          transform="translate(0)"
          fill="none"
        />
        <g id="Group_2066" data-name="Group 2066" clipPath="url(#clip-path)">
          <g id="Group_2065" data-name="Group 2065" transform="translate(0.506 0.456)">
            <path
              id="Path_3707"
              data-name="Path 3707"
              d="M10,12A4,4,0,1,0,6,8a4,4,0,0,0,4,4m0-6A2,2,0,1,1,8,8a2,2,0,0,1,2-2m3.25,7.45A13.911,13.911,0,0,0,10,13c-2.67,0-8,1.33-8,4v3H13.09c-.02-.14-.04-.27-.05-.41A2.88,2.88,0,0,1,13,19a5.285,5.285,0,0,1,.08-.9A6.215,6.215,0,0,1,14,15.66a8.393,8.393,0,0,1,.65-.78l.02-.03a6,6,0,0,1,.76-.66,12.16,12.16,0,0,0-2.18-.74m-2.2,4.65H3.9V17c0-.64,3.13-2.1,6.1-2.1a11.889,11.889,0,0,1,2,.21,7.957,7.957,0,0,0-.95,2.99M22,18v2H18v2l-3-3,3-3v2Z"
              transform="translate(-2 -4)"
              fill="#1f2933"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const BlockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.88" height="21.144" viewBox="0 0 15.88 21.144">
      <path
        id="Path_3697"
        data-name="Path 3697"
        d="M7.94,10.439A2.014,2.014,0,1,1,9.925,8.426,2,2,0,0,1,7.94,10.439m5.955,3.021V3.392H1.985V13.46h11.91m0-12.082A2.009,2.009,0,0,1,15.88,3.392V13.46a2.009,2.009,0,0,1-1.985,2.014H1.985A2,2,0,0,1,0,13.46V3.392A1.993,1.993,0,0,1,1.985,1.378h.992V-.636A5.023,5.023,0,0,1,7.94-5.67,5.023,5.023,0,0,1,12.9-.636V1.378h.992M7.94-3.656A3.013,3.013,0,0,0,4.962-.636V1.378h5.955V-.636A3.013,3.013,0,0,0,7.94-3.656Z"
        transform="translate(0 5.67)"
        fill="#1f2933"
      />
    </svg>
  );
};

const ResendEmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15.2" viewBox="0 0 19 15.2">
      <path
        id="Path_3698"
        data-name="Path 3698"
        d="M19-13.3a1.906,1.906,0,0,0-1.9-1.9H1.9A1.906,1.906,0,0,0,0-13.3V-1.9A1.906,1.906,0,0,0,1.9,0H17.1A1.906,1.906,0,0,0,19-1.9V-13.3m-1.9,0L9.5-8.55,1.9-13.3H17.1m0,11.4H1.9v-9.5L9.5-6.65l7.6-4.75Z"
        transform="translate(0 15.2)"
        fill="#1f2933"
      />
    </svg>
  );
};

const BatteryCircuit = ({ percentage, color }: { percentage: number; color: 'dark' | 'light' }) => {
  const clampedPercentage = Math.max(0, Math.min(100, percentage));
  const innerWidth = (clampedPercentage / 100) * 19.34;
  const currentColor = color === 'light' ? '#FFF' : '#000';

  return (
    <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.581 2.474v9.93H2.482v-9.93h21.099zm2.483-2.482H0v14.894h26.064V-.008zm1.236 11.17h.931a1.555 1.555 0 0 0 1.551-1.55V5.266a1.55 1.55 0 0 0-1.551-1.55H27.3v7.445z"
        fill={currentColor}
      />
      <rect x="3.3" y="2.9" width={innerWidth} height="8.6" fill={currentColor} rx="1" />
    </svg>
  );
};

const CloseIcon = ({ fontWeight = '2.171', color = '#1f2933' }: { fontWeight?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.271" height="18.271" viewBox="0 0 18.271 18.271">
      <g id="Icon_-_close" data-name="Icon - close" transform="translate(1.535 1.535)">
        <path
          id="Path_3699"
          data-name="Path 3699"
          d="M16.286-1.086l-15.2-15.2m15.2,0-15.2,15.2"
          transform="translate(-1.086 16.286)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={fontWeight}
        />
      </g>
    </svg>
  );
};

const UnblockIcon = () => {
  return (
    <svg
      id="Icon_-_unlock"
      data-name="Icon - unlock"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        id="Path_3701"
        data-name="Path 3701"
        d="M8-9a2,2,0,0,1,2,2A1.993,1.993,0,0,1,8-5,1.993,1.993,0,0,1,6-7,2,2,0,0,1,8-9m8-12a5,5,0,0,0-5,5v2H2a2.006,2.006,0,0,0-2,2V-2A2.006,2.006,0,0,0,2,0H14a2.006,2.006,0,0,0,2-2V-12a2.006,2.006,0,0,0-2-2H13v-2a3,3,0,0,1,3-3,3,3,0,0,1,3,3v2h2v-2a5,5,0,0,0-5-5m-2,9V-2H2V-12Z"
        transform="translate(0 21)"
        fill="#1f2933"
      />
    </svg>
  );
};

export {
  AnonymousIcon,
  ArchiveIcon,
  BatteryCircuit,
  BlockIcon,
  CalendarIcon,
  ChatIcon,
  CloseIcon,
  DeleteIcon,
  NoteListIcon,
  ResendEmailIcon,
  RestoreIcon,
  SearchIcon,
  TrainingIcon,
  UnblockIcon,
};
