import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

export const useDeviceScanning = () => {
  const activate = useContextSelector(ExoGlobalContext, state => state.device.activate);
  const deactivate = useContextSelector(ExoGlobalContext, state => state.device.deactivate);
  const initialized = useContextSelector(ExoGlobalContext, state => state.device.initialized);

  if (!activate || !deactivate) {
    throw new Error('useDevice must be used within a ExoGlobalProvider');
  }

  useEffect(() => {
    if (initialized) {
      activate();

      return () => {
        deactivate();
      };
    }
  }, [activate, deactivate, initialized]);
};
