import { AlertId, CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';
import { MotorPlacement } from '../types/GeneratedProgramDefinition';

import { calibrationFlowStateDataDefinitions } from './calibrationFlowStateDataDefinitions';
import { CalibrationFlowConditionKey } from './conditionalFunctions';
import { meissaOTCalibrationFlow } from './meissaOTCalibrationFlow';
import { sidraLegCalibrationFlow } from './sidraLegCalibrationFlow';
import { stellaBioCalibrationFlow } from './stellaBioCalibrationFlow';

export interface CalibrationFlowStateDataDefinitionConditions {
  min?: number;
  max?: number;
  minExclusive?: boolean;
  maxExclusive?: boolean;
  equals?: string | number | object | boolean;
  exists?: boolean;
  type?: 'number' | 'string' | 'boolean' | 'object';
  array?: boolean;
  regex?: RegExp;
  computed?: string;
  computedParam?: object | string | number;
}

export type CalibrationFlowDefinitionStateDataDefinition = {
  [key in CalibrationFlowStateIdentifier]?: {
    [key: string]: CalibrationFlowStateDataDefinitionConditions;
  };
};

export interface CalibrationFlowDefinitionState {
  stepIndex?: number;
  next: CalibrationFlowStateIdentifier | null;
  prev: CalibrationFlowStateIdentifier | null;
  condition?: string;
  conditionParam?: any;
  disableAlerts?: readonly AlertId[];
  skippable?: boolean;
}

export type CalibrationFlowDefinitionAlert =
  | {
      type: 'error';
      condition: CalibrationFlowConditionKey;
    }
  | {
      type: 'warning';
      condition: CalibrationFlowConditionKey;
      resolveState: CalibrationFlowStateIdentifier;
    };

export type CalibrationFlowDefinitionStates = {
  [key in CalibrationFlowStateIdentifier]?: CalibrationFlowDefinitionState;
};

export interface CalibrationProgramFlowDefinition {
  initial: CalibrationFlowStateIdentifier;
  alerts: { [key in AlertId]?: CalibrationFlowDefinitionAlert };
  states: CalibrationFlowDefinitionStates;
  stepsNumber: number;
}

export interface CalibrationFlowDefinition {
  [key: string]: CalibrationProgramFlowDefinition;
}

type CalibrationFlowDefinitionStateDataType<T> = T extends 'string'
  ? string
  : T extends 'number'
  ? number
  : T extends 'boolean'
  ? boolean
  : T extends 'object'
  ? object
  : unknown;

export type CalibrationFlowDefinitionStateData<
  T extends CalibrationFlowStateIdentifier,
  TDefs = typeof calibrationFlowStateDataDefinitions,
> = T extends keyof TDefs
  ? {
      [key in keyof TDefs[T]]?: TDefs[T][key] extends CalibrationFlowStateDataDefinitionConditions
        ? TDefs[T][key]['array'] extends true
          ? CalibrationFlowDefinitionStateDataType<TDefs[T][key]['type']>[]
          : CalibrationFlowDefinitionStateDataType<TDefs[T][key]['type']>
        : never;
    }
  : never;

export type MeissaOTTrainingType = keyof typeof meissaOTCalibrationFlow;
export type StellaBioTrainingType = keyof typeof stellaBioCalibrationFlow;
export type SidraLegTrainingType = keyof typeof sidraLegCalibrationFlow;
export type SidraLegTrainingTypeWithGame = `${keyof typeof sidraLegCalibrationFlow}/game`;
export type TrainingType = StellaBioTrainingType | SidraLegTrainingType;

export type ExtractStatesIdentifiersWithData<T> = {
  [key in CalibrationFlowStateIdentifier]: T extends CalibrationFlowDefinitionStateData<key> ? key : never;
}[CalibrationFlowStateIdentifier];

export type RangeFunctionsOptionsDefinition = {
  motorName: MotorPlacement;
  rangeProviderState: ExtractStatesIdentifiersWithData<{ min: number; max: number }>;
  tolerance?: number | { min: number; portion: number };
  minValue?: number;
};

export type RangeFunctionsOptions = RangeFunctionsOptionsDefinition;

export function isStellaBioTrainingType(type: string): type is StellaBioTrainingType {
  return Boolean((stellaBioCalibrationFlow as CalibrationFlowDefinition)[type]);
}

export function isMeissaOTTrainingType(type: string): type is MeissaOTTrainingType {
  return Boolean((meissaOTCalibrationFlow as CalibrationFlowDefinition)[type]);
}

export function isSidraLegTrainingType(type: string): type is SidraLegTrainingType {
  return Boolean((sidraLegCalibrationFlow as CalibrationFlowDefinition)[type]);
}
