import { useEffect, useState } from 'react';
import {
  Button,
  HStack,
  Image,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import EmergencyButtonReady from 'assets/images/EmergencyButtonReady.svg';
import EmergencyButtonUnscrew from 'assets/images/EmergencyButtonUnscrew.svg';
import { useDevice } from 'libs/exo-session-manager/react';

import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

export type EmergencyModalType =
  | 'emergencyStop'
  | 'emergencyAction'
  | 'emergencyTrainingStop'
  | 'emergencyTrainingAction';

type EmergencyModalDefinitions = {
  [key in EmergencyModalType]: {
    title: string;
    image: string;
    description: React.ReactNode;
    canBeClosed: boolean;
    actionButtonText?: string;
    cancelButtonText?: string;
  };
};

const emergencyModalDefinitions: EmergencyModalDefinitions = {
  emergencyStop: {
    title: 'emergencyModals.emergencyButtonPressed',
    image: EmergencyButtonUnscrew,
    description: (
      <Text variant="openSans24" textAlign="center">
        <TranslateText as="span" text="emergencyModals.stopDescription1" />
        <br />
        <TranslateText as="span" text="emergencyModals.stopDescription2.1">
          <TranslateText as="span" fontWeight="bold" text="emergencyModals.stopDescription2.2" />
          <TranslateText as="span" text="emergencyModals.stopDescription2.3" />
        </TranslateText>
      </Text>
    ),
    canBeClosed: false,
    actionButtonText: 'emergencyModals.continue',
  },
  emergencyAction: {
    title: 'emergencyModals.emergencyButtonPressed',
    image: EmergencyButtonReady,
    description: <TranslateText variant="openSans24" text="emergencyModals.ready" textAlign="center" />,
    canBeClosed: true,
    actionButtonText: 'emergencyModals.continue',
  },
  emergencyTrainingStop: {
    title: 'emergencyModals.emergencyButtonPressed',
    image: EmergencyButtonUnscrew,
    description: (
      <Text variant="openSans24" textAlign="center">
        <TranslateText as="span" text="emergencyModals.stopDescription1" />
        <br />
        <TranslateText as="span" text="emergencyModals.stopDescription2.1">
          <TranslateText as="span" fontWeight="bold" text="emergencyModals.stopDescription2.2" />
          <TranslateText as="span" text="emergencyModals.stopDescription2.3" />
        </TranslateText>
      </Text>
    ),
    canBeClosed: false,
    actionButtonText: 'emergencyModals.continueTraining',
    cancelButtonText: 'emergencyModals.cancelTraining',
  },
  emergencyTrainingAction: {
    title: 'emergencyModals.emergencyButtonPressed',
    image: EmergencyButtonReady,
    description: <TranslateText variant="openSans24" text="emergencyModals.ready" textAlign="center" />,
    canBeClosed: true,
    actionButtonText: 'emergencyModals.continueTraining',
    cancelButtonText: 'emergencyModals.cancelTraining',
  },
};

export const EmergencyModal = ({ type, callback, secondCallback }: ModalProps & { type: EmergencyModalType }) => {
  const { firmwareModules } = useDevice();
  // canCotinue is used to wait few seconds before allow for exiting the dialog. This is used
  // because biocan module takes time to disappear, because timeout must happen. After it
  // disappears we should check if it came back online.
  const [canContinue, setCanContinue] = useState(false);
  // Force conitnue is fallback value which allows user to continue even if biocan module is
  // not present. Otherwise the user would be stuck on this screen endlessly.
  const [forceContinue, setForceContinue] = useState(false);

  useEffect(() => {
    setCanContinue(false);
    setForceContinue(false);

    if (type === 'emergencyAction' || type === 'emergencyTrainingAction') {
      setTimeout(
        () =>
          setTimeout(() => {
            setCanContinue(true);
          }),
        2000,
      );
      setTimeout(
        () =>
          setTimeout(() => {
            setForceContinue(true);
          }),
        10000,
      );
    }
  }, [type]);

  let numberOfButtons = 0;
  if (emergencyModalDefinitions[type].actionButtonText) numberOfButtons++;
  if (emergencyModalDefinitions[type].cancelButtonText) numberOfButtons++;

  const allowContinue =
    emergencyModalDefinitions[type].canBeClosed &&
    (forceContinue || (canContinue && firmwareModules.biocan && !firmwareModules.biocan.isMissing));

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '2xl',
    '2xl': '4xl',
  });

  return (
    <ModalContent borderRadius="rLg" p="3" maxW={modalSize}>
      <VStack
        borderWidth="1"
        borderStyle="solid"
        borderColor="errorColor"
        borderRadius="rLg"
        py={{ base: '0', '2xl': '3' }}
        gap={{ base: '1', '2xl': '3' }}
      >
        <ModalHeader data-testid="emergency-title">
          <TranslateText variant="openSans36" fontWeight="bold" text={emergencyModalDefinitions[type].title} />
        </ModalHeader>
        <ModalBody data-testid="emergency-body" mb={{ base: '1', '2xl': '4' }} w="75%">
          <VStack gap="8">
            <Image
              src={emergencyModalDefinitions[type].image}
              width={{ base: '28', '2xl': '44' }}
              data-testid="emergency-image"
            />
            <VStack h={{ base: '12', '2xl': '24' }} justifyContent="center">
              {(type === 'emergencyAction' || type === 'emergencyTrainingAction') && !allowContinue ? (
                <TranslateText variant="openSans24" text="emergencyModals.waiting" textAlign="center" />
              ) : (
                emergencyModalDefinitions[type].description
              )}
            </VStack>
            {(type === 'emergencyAction' || type === 'emergencyTrainingAction') && !allowContinue && (
              <Spinner thickness="1" speed="0.65s" emptyColor="white" color="egzotechPrimaryColor" boxSize="6" />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter w="full">
          <HStack w="full" justifyContent={numberOfButtons === 2 ? 'space-evenly' : 'center'}>
            {emergencyModalDefinitions[type].cancelButtonText && (
              <Button
                isDisabled={!allowContinue}
                variant={{ base: 'smOutlinedErrorButton', '2xl': 'mdOutlinedErrorButton' }}
                onClick={secondCallback}
                data-testid="cancel-button"
              >
                <TranslateText color="" text={emergencyModalDefinitions[type].cancelButtonText} />
              </Button>
            )}
            <Button
              isDisabled={!allowContinue}
              variant={{ md: 'smErrorButton', '2xl': 'mdErrorButton' }}
              backgroundColor="errorColor"
              onClick={callback}
              data-testid="action-button"
            >
              <TranslateText text={emergencyModalDefinitions[type].actionButtonText} />
            </Button>
          </HStack>
        </ModalFooter>
      </VStack>
    </ModalContent>
  );
};
