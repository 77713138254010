import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { ExoClinicBackendOpenApiPaths } from 'services/ExoClinicBackendOpenApi';

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery: retry(
    fetchBaseQuery({
      fetchFn: queryFetchFn,
      baseUrl: EGZOTechHostApi.instance?.options?.deviceApiUrl || API_URL,
    }),
  ),
  tagTypes: ['detect-configuration'],
  endpoints: builder => ({
    expectedFirmwareVersion: builder.query<
      ExoClinicBackendOpenApiPaths['/device/update/firmware/valid-version']['get']['response'],
      string | null
    >({
      // id for query is needed because we want to reload firmware versions when device change.
      // This is needed for dummy device to work correctly.
      query: (id: string | null) => ({
        url: `device/update/firmware/valid-version?${new URLSearchParams({ deviceId: id ?? '' }).toString()}`,
        credentials: 'omit',
      }),
      serializeQueryArgs: ({ queryArgs }) => queryArgs ?? '',
      extraOptions: { maxRetries: 100 },
    }),
    detectConfiguration: builder.query<ExoClinicBackendOpenApiPaths['/device/detect/config']['get']['response'], void>({
      query: () => ({
        url: `device/detect/config`,
        credentials: 'omit',
      }),
      providesTags: ['detect-configuration'],
    }),
    setDetectConfiguration: builder.mutation<
      ExoClinicBackendOpenApiPaths['/device/detect/config']['patch']['response'],
      ExoClinicBackendOpenApiPaths['/device/detect/config']['patch']['body']
    >({
      query: body => ({
        url: 'device/detect/config',
        credentials: 'omit',
        method: 'PATCH',
        body,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ['detect-configuration'],
    }),
  }),
});

export const { useExpectedFirmwareVersionQuery, useDetectConfigurationQuery, useSetDetectConfigurationMutation } =
  deviceApi;
