import { ExoSession, ExoSessionCandidate } from '@egzotech/exo-session';
import {
  Device,
  DeviceState,
  DeviceType,
  GeneratedExerciseDefinition,
  LocalStorageDevice,
} from 'libs/exo-session-manager/core';
import { createContext } from 'use-context-selector';

export interface ProgramSelection {
  programs: Record<string, GeneratedExerciseDefinition>;
  selectedProgramId: string | null;
  selectedProgram: GeneratedExerciseDefinition | null;
  type?: GeneratedExerciseDefinition['type'];
  definition?: GeneratedExerciseDefinition | null;
  isValidProgram: boolean;
  selectProgram: (programId: string) => void;
  endProgram: () => void;
}

export interface DeviceActions {
  activate: () => void;
  deactivate: () => void;
  requestCandidate: () => Promise<void>;
  selectDevice: (id: Device['id']) => Promise<void>;
  resetConnectionError: () => void;
  isConnectionRestored: () => boolean;
  setDeviceAsTared: () => void;
  disposeSelectedDevice: () => void;
}

export interface ExoGlobalData {
  programSelection: ProgramSelection;
  device: {
    deviceType: DeviceType;
    selectedDevice: Device | null;
    lastUsedDevice: LocalStorageDevice | null;
    session: ExoSession | null;
  } & DeviceState &
    DeviceActions;
}

export interface GlobalChangeableData {
  programSelection: {
    selectedProgramId: ProgramSelection['selectedProgramId'];
  };
  deviceState: DeviceState;
}

export const initialGlobalChangeableData: GlobalChangeableData = {
  programSelection: {
    selectedProgramId: null,
  },
  deviceState: {
    candidates: [],
    storedCandidates: {},
    selectedDeviceId: '',
    wantConnectDeviceId: '',
    initialized: false,
    active: false,
    firmwareModules: {},
    isTared: false,
  },
};

export const initialExoGlobalData: ExoGlobalData = {
  programSelection: {
    programs: {},
    selectedProgramId: null,
    selectedProgram: null,
    isValidProgram: false,
    selectProgram: () => {},
    endProgram: () => {},
  },
  device: {
    ...initialGlobalChangeableData.deviceState,
    selectedDevice: {
      id: '',
      displayName: '',
      type: 'unspecified',
      connectionStatus: 'disconnected',
      cableStatus: 'detached',
      channelsConnectionQuality: {},
      updatedDummyFromLocalStorage: false,
      session: null,
      candidate: {} as ExoSessionCandidate,
    },
    session: null,
    lastUsedDevice: null,
    activate: () => {},
    deactivate: () => {},
    requestCandidate: async () => {},
    selectDevice: async _id => {},
    resetConnectionError: () => {},
    isConnectionRestored: () => false,
    setDeviceAsTared: () => {},
    disposeSelectedDevice: () => {},

    get deviceType() {
      return this.selectedDevice?.type ?? this.lastUsedDevice?.type ?? 'unspecified';
    },
  },
};

const ExoGlobalContext = createContext<ExoGlobalData>(initialExoGlobalData);

export default ExoGlobalContext;
