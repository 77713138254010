import { useCallback, useEffect, useState } from 'react';
import { ExoMotorFeature, ExoMotorFirmwareCouplingForce } from '@egzotech/exo-session/features/motor';
import { useDevice } from 'libs/exo-session-manager/react';

import { SystemComponentsButton } from './SystemComponentsButton';

export function approxEqual(a: number, b: number, tolerance: number) {
  return Math.abs(a - b) < tolerance;
}

export const UnlockMeissaMotorButton = () => {
  const { session, selectedDevice } = useDevice();
  const [mainMotorFeature, setMainMotorFeature] = useState<ExoMotorFeature | null>(null);
  const [isLocked, setIsLocked] = useState<boolean | null>(null);

  const switchMotorCoupling = useCallback(() => {
    if (!mainMotorFeature) {
      return;
    }
    isLocked
      ? mainMotorFeature.setFreeRoamCoupling(
          selectedDevice?.extensionType ? `${selectedDevice.extensionType}-torque` : 'default',
        )
      : mainMotorFeature.stop();

    setIsLocked(!isLocked);
  }, [isLocked, mainMotorFeature, selectedDevice?.extensionType]);

  useEffect(() => {
    if (!session) {
      return;
    }

    const motorFeature = session.activate(ExoMotorFeature, { name: 'main', dontStopOnDispose: true });
    let initialCoupling: Record<string, ExoMotorFirmwareCouplingForce> | null = null;

    setTimeout(() => {
      if (!motorFeature) {
        return;
      }

      if (!motorFeature.forceCoupling) {
        setIsLocked(true);
        return;
      }

      initialCoupling = structuredClone(motorFeature.forceCoupling);
      const isCouplingSet =
        // We are comparing floating point numbers, so we need to allow some tolerance.
        // At this point, I used the value 0.1, because it is a very low value for sensitivity,
        // but this parameter can be changed in the future if it turns out to be insufficient.
        !approxEqual(motorFeature.forceCoupling.torque.positive.sensitivity, 0, 0.1) ||
        !approxEqual(motorFeature.forceCoupling.torque.negative?.sensitivity ?? 0, 0, 0.1);

      setIsLocked(!isCouplingSet);
      setMainMotorFeature(motorFeature);
    }, 50);

    return () => {
      if (initialCoupling?.torque) {
        motorFeature?.setCoupling('force', 'torque', initialCoupling.torque);
      }
      motorFeature?.dispose();
    };
  }, [session]);

  return selectedDevice?.type === 'meissa-ot' && session && isLocked !== null ? (
    <SystemComponentsButton
      iconID="blockMotor"
      label="modals.systemComponents.buttons.blockMotor"
      buttonState={isLocked}
      onClick={switchMotorCoupling}
    />
  ) : null;
};
