import { Logger } from '@egzotech/universal-logger-js';

import { CountDownTimer } from './CountDownTimer';
import { Triggerable } from './Triggerable';
import { TriggerCondition } from './TriggerableGroup';

type BaseConditionId =
  // returns true if the an active signal (emg or force) is above threshold
  | 'active-signal/can-trigger'

  // return true if EMS Feature can be triggered
  | 'ems/can-trigger'

  // return true if CPM Feature can be triggered
  | 'cpm/can-trigger'

  // returns true if movement is held
  | 'cpm/is-held'

  // returns true if movement can be held
  | 'cpm/can-hold'

  // returns true if movement can be held during the return
  | 'cpm/can-hold-return'

  // returns true if the movement is returning to start position
  | 'cpm/is-returning';

export type TriggerId =
  // triggered afer emg level reach threshold level AND is resposible for movement resume
  | 'cpm/on-resume'

  // triggered afer emg level is below threshold level AND is resposible for movement resume for return
  | 'cpm/on-resume-return'

  // triggered after EMG level drops below threshold AND is responsible for hold movement
  | 'cpm/on-hold'

  // triggered after EMG level is above threshold AND we are returning AND is responsible for hold movement
  | 'cpm/on-hold-return'

  // DELAYED trigger!, triggered after exo-session calls onDirectionChange and the device is on the range end
  | 'cpm/direction-change-at-start'

  // DELAYED trigger!, triggered after exo-session calls onDirectionChange and the device is on the range start
  | 'cpm/direction-change-at-end'

  // trigger is responsible for all actions when a device starts the first movement phase
  | 'cpm/movement-from-start'

  // trigger is responsible for all actions when a device starts the second movement phase
  | 'cpm/movement-from-end'

  // action is responsible for triggering CPM Feature
  | 'cpm/start-movement'

  // action is responsible for triggering EMS Feature
  | 'ems/start-electrostim'

  // trigger is responsible for all actions (eg. movement), when exercise is triggered by EMG or force
  | 'active-signal/start-action'

  // triggered after exo-sesion calls onRepetitionChange
  | 'cpm/repetition-change';

export type ConditionId = BaseConditionId | `!${BaseConditionId}`;
type TriggerConditionId = ConditionId | `no-timer/${TriggerId}`;

export type ActionType = (id?: TriggerId) => void;

// TODO: should be renamed along with 'TriggerManager', because this structure is more
// complex than a trigger, maybe it could be called 'ConditionalAction' and 'TriggerManager'
// would be 'ConditionalActionManager'. It is just a proposition.
class Trigger {
  constructor(
    public id: TriggerId,
    public conditions: TriggerConditionId[],
    public action: ActionType,
    public preAction?: ActionType,
    public delay?: number,
  ) {}

  whenTriggerIsNotWaiting(triggerId: TriggerId) {
    this.conditions.push(`no-timer/${triggerId}`);
    return this;
  }
}

export class TriggerManager implements Triggerable {
  private conditions: Partial<Record<ConditionId, TriggerCondition>> = {};
  private _triggers: Partial<Record<TriggerId, Trigger>> = {};
  private timers: Record<string, CountDownTimer> = {};
  private logger = Logger.getInstance('TriggerManager');

  constructor() {}

  addCondition(id: ConditionId, condition: TriggerCondition) {
    this.conditions[id] = condition;
  }

  addConditionToTrigger(triggerId: TriggerId, conditionId: ConditionId) {
    this.triggers(triggerId).conditions.push(conditionId);
  }

  addTrigger(id: TriggerId, conditions: ConditionId[], action: ActionType, preAction?: ActionType, delay?: number) {
    const trigger = new Trigger(id, conditions ?? [], action, preAction, delay);
    this._triggers[id] = trigger;
    return trigger;
  }

  setTriggerDelay(id: TriggerId, delay: number) {
    this.printLog(`set delay [${delay}] for trigger ${id}`);
    this.triggers(id).delay = delay;
  }

  trigger() {
    for (const trigger of Object.values(this._triggers)) {
      if (!trigger.conditions.length) {
        continue;
      }
      if (trigger.conditions.every(cId => this.isConditionMet(cId))) {
        this.executeTrigger(trigger.id);
      }
    }
  }

  canTrigger() {
    return Object.keys(this.conditions).length > 0 && Object.keys(this._triggers).length > 0;
  }

  resume() {
    for (const timer of Object.values(this.timers)) {
      timer.resume();
    }
  }

  pause() {
    for (const timer of Object.values(this.timers)) {
      timer.pause();
    }
  }

  executeTrigger(triggerId: TriggerId, noDelay = false) {
    const trigger = this.triggers(triggerId);
    this.executeTriggerAction(trigger, true);
    if (trigger.delay && !noDelay) {
      this.printLog(`triggering ${trigger.id} before delay`);

      this.timers[trigger.id] = new CountDownTimer({
        duration: trigger.delay,
        onFinish: () => {
          delete this.timers[trigger.id];
          this.printLog(`delayed triggering ${trigger.id}`);
          this.executeTriggerAction(trigger, false);
        },
      });
      this.printLog(`delaying ${trigger.id}`);
      this.timers[trigger.id].play();
    } else {
      this.printLog(`triggering ${trigger.id}`);
      this.executeTriggerAction(trigger, false);
    }
  }

  cleanup() {
    Object.values(this.timers).forEach(v => v.dispose());

    this.conditions = {};
    this._triggers = {};
    this.timers = {};
  }

  private triggers(triggerId: TriggerId): Trigger {
    const trigger = this._triggers[triggerId];
    if (!trigger) {
      throw new Error(`Trigger ${triggerId} not found`);
    }
    return trigger;
  }

  private isConditionMet(conditionId: TriggerConditionId) {
    let match = conditionId.match(/^no-timer\/(.*)$/);
    if (match) {
      const timer = this.timers[match[1]];
      return !timer || timer.state === 'finish';
    }
    let negative = false;
    match = conditionId.match(/^!(.*)$/);
    if (match) {
      conditionId = match[1] as ConditionId;
      negative = true;
    }
    const condition = this.conditions[conditionId as ConditionId];

    if (!condition) {
      throw new Error(`Condition ${conditionId} must exists`);
    }

    return negative ? !condition() : condition();
  }

  private printLog(info: string) {
    this.logger.info('TriggerManager', 'Info', info);
  }

  private executeTriggerAction(trigger: Trigger, preTrigger: boolean) {
    try {
      if (preTrigger) {
        trigger.preAction?.(trigger.id);
      } else {
        trigger.action(trigger.id);
      }
    } catch (err) {
      this.logger.error('TriggerManager', 'executeTriggerAction', `Trigger action ${trigger.id} error ${err}`);
    }
  }
}
