import { parseStyleConfig } from './helpers';

const variants = [
  'exo30',
  'openSans60',
  'openSans60Bold',
  'openSans48',
  'openSans48Bold',
  'openSans36',
  'openSans36Bold',
  'openSans30',
  'openSans30Bold',
  'openSans24',
  'openSans24Bold',
  'openSans20',
  'openSans20Bold',
  'openSans18',
  'openSans18Bold',
  'openSans16',
  'openSans16Bold',
] as const;

export type Variants = (typeof variants)[number];

// TODO: Default color for all texts should not be defined. Currently 'headerTextColor' is used what
// is wrong because this is the color for headers. Changing that needs to be validated with the rest
// of application to check if colors are appropriate. Setting the color to 'inherit' sometimes overrides
// the color set by a `color` prop in the component which is not desired.
//
// IMPORTANT!
// New variants should not have defined color
const Text = parseStyleConfig(variants, {
  variants: {
    // Exo font texts
    exo30: () => ({
      fontFamily: 'Exo, SemiBold',
      fontSize: '3xl',
      color: 'headerTextColor',
    }),
    // Open sans font texts
    openSans60: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '4xl', '2xl': '6xl' },
      lineHeight: { base: '4xl', '2xl': '6xl' },
      fontWeight: 400,
    }),
    openSans60Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '4xl', '2xl': '6xl' },
      lineHeight: { base: '4xl', '2xl': '6xl' },
      fontWeight: 700,
    }),
    openSans48: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '3xl', 'xl+': '4xl', '2xl': '5xl' },
      lineHeight: { base: '3xl', 'xl+': '4xl', '2xl': '5xl' },
      fontWeight: 400,
    }),
    openSans48Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '3xl', 'xl+': '4xl', '2xl': '5xl' },
      lineHeight: { base: '3xl', 'xl+': '4xl', '2xl': '5xl' },
      fontWeight: 700,
    }),
    openSans36: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '2xl', '2xl': '4xl' },
      lineHeight: { base: '2xl', '2xl': '4xl' },
      fontWeight: 400,
    }),
    openSans36Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: '2xl', '2xl': '4xl' },
      lineHeight: { base: '2xl', '2xl': '4xl' },
      fontWeight: 700,
    }),
    openSans30: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'xl', '2xl': '3xl' },
      lineHeight: { base: 'xl', '2xl': '3xl' },
      fontWeight: 400,
    }),
    openSans30Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'xl', '2xl': '3xl' },
      lineHeight: { base: 'xl', '2xl': '3xl' },
      fontWeight: 700,
    }),
    openSans24: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'lg', 'xl+': 'xl', '2xl': '2xl' },
      lineHeight: { base: 'lg', 'xl+': 'xl', '2xl': '2xl' },
      fontWeight: 400,
      color: 'headerTextColor',
    }),
    openSans24Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'lg', '2xl': '2xl' },
      lineHeight: { base: 'lg', '2xl': '2xl' },
      fontWeight: 700,
    }),
    openSans20: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'md', 'xl+': 'lg', '2xl': 'xl' },
      lineHeight: { base: 'md', '2xl': 'xl' },
      fontWeight: 400,
    }),
    openSans20Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'md', 'xl+': 'lg', '2xl': 'xl' },
      lineHeight: { base: 'md', 'xl+': 'lg', '2xl': 'xl' },
      fontWeight: 700,
    }),
    openSans18: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'sm', '2xl': 'lg' },
      lineHeight: { base: 'sm', '2xl': 'lg' },
      fontWeight: 400,
    }),
    openSans18Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: { base: 'sm', '2xl': 'lg' },
      lineHeight: { base: 'sm', '2xl': 'lg' },
      fontWeight: 700,
    }),
    openSans16: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: 'md',
      lineHeight: 'md',
      fontWeight: 400,
    }),
    openSans16Bold: () => ({
      fontFamily: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
      fontSize: 'md',
      lineHeight: 'md',
      fontWeight: 700,
    }),
  },
});

export default Text;
