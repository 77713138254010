import {
  memo,
  MouseEventHandler,
  TouchEvent,
  TouchEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import colors from 'config/theme/colors';

interface Props {
  xOffset: number;
  yOffset: number;
  yHeight: number;
  level: number;
  color: string;
  onLevelChange?: (level: number) => void;
  isDisabled?: boolean;
}

type SliderState =
  | {
      type: 'free';
    }
  | {
      type: 'hold';
      barBox: DOMRect;
      dy: number;
    };

export const SVGSlider = memo(({ xOffset, yOffset, level, color, onLevelChange, isDisabled, yHeight }: Props) => {
  const [buttonState, setButtonState] = useState<SliderState>({ type: 'free' });
  const barRef = useRef<SVGRectElement>(null);
  const circleRef = useRef<SVGCircleElement>(null);

  const cy = yHeight + yOffset - yHeight * (level / 100);

  const handleHold = useCallback((yOffset: number) => {
    if (barRef.current && circleRef.current) {
      const circleBox = circleRef.current.getBoundingClientRect();
      setButtonState({
        type: 'hold',
        barBox: barRef.current.getBoundingClientRect(),
        dy: yOffset - (circleBox.top + circleBox.height / 2),
      });
    }
  }, []);

  const handleMove = useCallback(
    (yOffset: number) => {
      if (buttonState.type === 'hold') {
        let lvl = 100 - ((yOffset - buttonState.dy - buttonState.barBox.top) / buttonState.barBox.height) * 100;
        if (lvl < 0) lvl = 0;
        if (lvl > 100) lvl = 100;
        onLevelChange?.(lvl);
      }
    },
    [buttonState, onLevelChange],
  );

  const handleMouseDown: MouseEventHandler<SVGCircleElement> = e => {
    e.preventDefault();
    handleHold(e.clientY);
  };

  const handleTouchDown: TouchEventHandler<SVGCircleElement> = e => {
    handleHold(e.targetTouches[0].pageY);
  };

  const handleTouchMove = (e: TouchEvent) => {
    handleMove(e.targetTouches[0].pageY);
  };

  const handleTouchEnd = (_: TouchEvent) => {
    setButtonState({ type: 'free' });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      handleMove(e.clientY);
    };

    const handleMouseUp = (_: MouseEvent) => {
      setButtonState({
        type: 'free',
      });
    };
    if (buttonState.type === 'hold') {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [buttonState, handleMove, onLevelChange]);

  return (
    <svg overflow="visible">
      <defs>
        <filter id="circle-shadow" width="200%">
          <feDropShadow dx={0} dy={2} stdDeviation={5} floodColor={colors.gray['300']} />
        </filter>
      </defs>
      {/* vertical slider bar */}
      <rect x={xOffset + 30} y={yOffset} width="6" height={yHeight} fill="#D9D9D9" ref={barRef} />
      {!isDisabled && (
        <>
          {/* large circle */}
          <circle
            cx={xOffset + 32.5}
            cy={cy}
            r="32"
            fill="white"
            filter={`url(#circle-shadow)`}
            ref={circleRef}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchDown}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
            cursor="pointer"
          />
          {/* small circle */}
          <circle cx={xOffset + 32.5} cy={cy} r="22" fill={color} pointerEvents="none" />
          {/* text */}
          <text
            x={xOffset + 32.5}
            y={cy}
            fill="white"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize={24}
            textAnchor="middle"
            dominantBaseline="central"
            pointerEvents="none"
          >
            {level.toFixed(0)}
          </text>
        </>
      )}
    </svg>
  );
});

SVGSlider.displayName = 'SVGSlider';
