import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';

export const facilitySpecialistPatientsApi = createApi({
  reducerPath: 'facilitySpecialistPatientsApi',
  baseQuery: fetchBaseQuery({
    fetchFn: queryFetchFn,
    baseUrl: API_URL,
  }),
  endpoints: builder => ({
    checkIfTelemedicineInvititaionTokenIsActive: builder.query<void, string>({
      query: invitationToken => `facility-specialist-patients/telemedicine-invitation/activity/${invitationToken}`,
    }),
  }),
});

export const { useLazyCheckIfTelemedicineInvititaionTokenIsActiveQuery } = facilitySpecialistPatientsApi;
