import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { CalibrationFlow } from 'libs/exo-session-manager/core';

import { MainButton } from 'components/buttons/MainButton';

export interface NextButtonRef {
  clickNext: () => void;
}

interface Props {
  flow: CalibrationFlow;
  validationPassed?: boolean;
  isFormValidation?: boolean;
  text?: string;
  minW?: ButtonProps['minW'];
  onError?: () => void;
  onNext?: () => void;
  variant?: ButtonProps['variant'];
  waitForRequest?: boolean;
  updateRequestSent?: boolean;
  onClick?: () => void;
}
export const CalibrationFlowNextButton = memo(
  forwardRef<NextButtonRef, Props>(
    (
      {
        flow,
        isFormValidation = false,
        validationPassed,
        text = 'common.next',
        minW,
        onError,
        onNext,
        variant = 'mdPrimaryButton',
        waitForRequest = false,
        updateRequestSent,
        onClick,
      }: {
        flow: CalibrationFlow;
        validationPassed?: boolean;
        isFormValidation?: boolean;
        text?: string;
        minW?: ButtonProps['minW'];
        onError?: () => void;
        onNext?: () => void;
        variant?: ButtonProps['variant'];
        waitForRequest?: boolean;
        updateRequestSent?: boolean;
        onClick?: () => void;
      },
      ref,
    ) => {
      const next = useCallback(() => flow.next(), [flow]);
      const [canProceed, setCanProceed] = useState(flow.canProceed);

      useEffect(() => {
        setCanProceed(flow.canProceed);
        flow.events.addHandler('canProceedChange', setCanProceed);

        return () => {
          flow.events.removeHandler('canProceedChange', setCanProceed);
        };
      }, [flow]);

      const handleClick = useCallback(() => {
        if (validationPassed || !isFormValidation) {
          if (!waitForRequest) {
            onNext?.();
            next();
          }
        }
        if (!validationPassed && onError) {
          onError();
        }
      }, [isFormValidation, next, onError, onNext, validationPassed, waitForRequest]);

      useImperativeHandle(
        ref,
        () => ({
          clickNext() {
            handleClick();
          },
        }),
        [handleClick],
      );

      useEffect(() => {
        if (waitForRequest && updateRequestSent) {
          next(); // FIXME: Temp implementation, its should be make in other way
        }
      }, [waitForRequest, updateRequestSent, next]);

      return (
        <MainButton
          type="submit"
          isDisabled={!canProceed || (isFormValidation && !validationPassed)}
          text={text}
          variant={variant}
          onClick={onClick ? onClick : handleClick}
          minW={minW}
          data-testid="next-button"
        />
      );
    },
  ),
);

CalibrationFlowNextButton.displayName = 'CalibrationFlowNextButton';
