import { FC, ReactNode } from 'react';
import { Button } from '@chakra-ui/react';
import { ChakraSize } from 'types/chakra';

import { AppLink } from 'components/common/AppLink';

interface Props {
  icon?: ReactNode;
  children?: ReactNode;
  redirect?: string;
  mr?: string;
  onClick?: () => void;
  px?: string;
  py?: string;
  bg?: string;
  size?: ChakraSize;
  color?: string;
  type?: 'light';
  isDisabled?: boolean;
  testid?: string;
}

export const RoundButton: FC<Props> = ({
  icon,
  children,
  redirect,
  mr,
  onClick,
  px,
  py,
  bg,
  size,
  color,
  type,
  isDisabled = false,
  testid,
}) => (
  <Button
    as={AppLink}
    to={redirect ?? ''}
    onClick={onClick}
    variant={type === 'light' ? 'lightIconButton' : 'iconButton'}
    mr={mr}
    pl={px}
    pr={px}
    pt={py}
    pb={py}
    bg={bg}
    w={size}
    h={size}
    color={color}
    isDisabled={isDisabled}
    data-testid={testid}
  >
    {icon ?? children}
  </Button>
);
