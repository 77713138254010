import { useContext, useMemo } from 'react';
import { MotorPlacement, MotorPlacementFreeCouplingPresets, MotorRange } from 'libs/exo-session-manager/core';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';
import { MultiMotorBasingActions } from '../types';

export const useMultiMotorBasing = (): MultiMotorBasingActions => {
  const cpm = useContext(CPMInstanceContext);
  const cam = useContext(CAMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);

  return useMemo(() => {
    if (cpm) {
      return {
        getMotorRange: motor => cpm.getCPMRange(motor),
        setFreeRoamCoupling: (motor: MotorPlacement, preset: string) =>
          cpm.getMotor(motor)?.setFreeRoamCoupling(preset),
        setMotorRange: (range, motor) => cpm.setCPMRange(range, motor),
        stopMotor: motor => cpm.getMotor(motor)?.stop(),
      };
    }

    if (cpmEms) {
      return {
        getMotorRange: motor => cpmEms.getCPMRange(motor),
        setFreeRoamCoupling: (motor: MotorPlacement, preset: string) =>
          cpmEms.getMotor(motor)?.setFreeRoamCoupling(preset),
        setMotorRange: (range, motor) => cpmEms.setCPMRange(range, motor),
        stopMotor: motor => cpmEms.getMotor(motor)?.stop(),
      };
    }

    if (cam) {
      return {
        getMotorRange: motor => cam.getMotorRange(motor),
        setFreeRoamCoupling: (motor: MotorPlacement, preset: string) =>
          cam.getMotor(motor)?.setFreeRoamCoupling(preset),
        setMotorRange: (range, motor) => cam.setMotorRange(range, motor),
        stopMotor: motor => cam.getMotor(motor)?.stop(),
      };
    }
    throw new Error('useMultiMotorBasing must be used within a Provider which uses MultiMotorBasingActions');
  }, [cam, cpm, cpmEms]);
};

export interface MotorBasingActions<T extends MotorPlacement = MotorPlacement> {
  /**
   * Sets the movement range in degrees that will restrict device movements. The device will keep its angle in specified range.
   */
  setMotorRange: (range: { min: number; max: number }) => void;

  /**
   * Gets the movemnt range for specified motor.
   */
  getMotorRange: () => MotorRange | null;

  /**
   * Set free roam coupling for specified motor.
   */
  setFreeRoamCoupling(preset: MotorPlacementFreeCouplingPresets[T]): void;
  setFreeRoamCoupling(preset: string): void;

  /**
   * Stops specified motor.
   */
  stopMotor: () => void;
}
