import { includesAnyString } from 'helpers/string';
import {
  CalibrationFlowStatesTypedData,
  isCAMComprehensiveParameterId,
  isCAMPhasicParameterId,
  isCPMComprehensiveParameterId,
  isCPMPhasicParameterId,
  isEMSPhasicParameterId,
  isGamePhasicParameterId,
  SettingsParameterId,
} from 'libs/exo-session-manager/core';
import { ExerciseParameter } from 'views/+patientId/training/+trainingId/hooks/useExerciseParameters';

type Overwrite<TBase, TOverrides> = Omit<TBase, keyof TOverrides> & TOverrides;

export type CalibrationFlowStatesTypedData_V_0_10_0 = Overwrite<
  CalibrationFlowStatesTypedData,
  {
    'channel-role-selector': { channelRoles: { [key: string]: string[] } } | undefined;
    'ems-calibration': {
      intensities:
        | {
            [key: string]: number;
          }
        | undefined;
    };
  }
>;

type CalibrationFlowStatesTypedData_V_0_11_0 = CalibrationFlowStatesTypedData; // current version

export type CalibrationFlowParameters_V_0_10_0 = { [key in ExerciseParameter['id']]: string | number };

export type CalibrationFlowParameters_V_0_11_0 = { [key in SettingsParameterId]: string | number };

export type Version = (typeof VersionAdapter.VERSION_HISTORY)[number];

export interface VersionAdapterStrategy<T = any> {
  fromVersion?: Version;
  normalizeCalibrationFlowData: (calibrationData: T) => CalibrationFlowStatesTypedData;
}

class V_0_11_0_Handler implements VersionAdapterStrategy {
  constructor(public fromVersion?: Version) {}

  normalizeCalibrationFlowData(
    calibrationData: CalibrationFlowStatesTypedData_V_0_10_0,
  ): CalibrationFlowStatesTypedData_V_0_11_0 {
    if (this.fromVersion) {
      // do not normalize if version is specified
      return calibrationData as CalibrationFlowStatesTypedData_V_0_11_0;
    }
    const parameters_v_0_10_0 = calibrationData['basing-settings']?.exerciseParameters as
      | CalibrationFlowParameters_V_0_10_0
      | undefined;

    if (!parameters_v_0_10_0 || !calibrationData['basing-settings']?.exerciseParameters) {
      throw new Error('Parameters for calibration flow are not defined');
    }

    const channelRoles = calibrationData?.['channel-role-selector']?.channelRoles;

    const emsCalibrationIntensities = calibrationData?.['ems-calibration']?.intensities;

    const parameters_v_0_11_0 = Object.entries(parameters_v_0_10_0).reduce((prev, [k, v]) => {
      if (isEMSPhasicParameterId(k)) {
        prev[`ems.0.${k}`] = v;
      } else if (isCPMComprehensiveParameterId(k)) {
        prev[`cpm.${k}`] = v;
      } else if (isCPMPhasicParameterId(k)) {
        prev[`cpm.0.${k}`] = v;
      } else if (isCAMComprehensiveParameterId(k)) {
        prev[`cam.${k}`] = v;
      } else if (isCAMPhasicParameterId(k)) {
        prev[`cam.0.${k}`] = v;
      } else if (isGamePhasicParameterId(k)) {
        prev[`game.0.${k}`] = v;
      } else {
        prev[k as keyof CalibrationFlowParameters_V_0_11_0] = v;
      }
      return prev;
    }, {} as CalibrationFlowParameters_V_0_11_0);

    const channelRolesInstances = channelRoles
      ? {
          channelRolesInstances: {
            0: channelRoles,
          },
        }
      : null;

    const intensitiesInstances = emsCalibrationIntensities
      ? {
          intensitiesInstances: {
            0: emsCalibrationIntensities,
          },
        }
      : null;

    return {
      ...calibrationData,
      'basing-settings': {
        ...calibrationData['basing-settings'],
        exerciseParameters: parameters_v_0_11_0,
      },
      'channel-role-selector': {
        ...calibrationData['channel-role-selector'],
        ...channelRolesInstances,
      },
      'ems-calibration': {
        ...calibrationData['ems-calibration'],
        ...intensitiesInstances,
      },
    };
  }
}

// This is an example class demonstrating how to implement the next version handler classes
// class V_0_12_0_Handler implements VersionAdapterStrategy {
//   constructor(public fromVersion?: Version) {}

//   normalizeCalibrationFlowData(
//     calibrationData: CalibrationFlowStatesTypedData_V_0_10_0,
//   ): CalibrationFlowStatesTypedData {
//     // handle normalization from specific version
//     if (this.fromVersion === VersionAdapter.CURRENT_VERSION) {
//       // for current version do nothing
//       return calibrationData as CalibrationFlowStatesTypedData;
//     }
//     if (this.fromVersion === '0.11.0') {
//       this.normalizeV_0_11_0();
//       return calibrationData as CalibrationFlowStatesTypedData_V_0_11_0;
//     }

//     // handle normalization from first version (0.10.0)
//     const v_0_11_0_handler = new V_0_11_0_Handler();
//     const fromFirstVersionToV_0_11_0 = v_0_11_0_handler.normalizeCalibrationFlowData(calibrationData);

//     // logic to handle normalization from 0.11.0 to 0.12.0
//     this.normalizeV_0_11_0();

//     return fromFirstVersionToV_0_11_0;
//   }

//   private normalizeV_0_11_0() {
//     // implement some normalization logic here
//   }
// }

/**
 * This class allows to operate on old JSON field definitions in the database normalizing them to definitions for specified version.
 */
export class VersionAdapter implements VersionAdapterStrategy {
  /**
   * IMPORTANT: This property must be updated whenever any data definition for JSON fields in the database changes.
   */
  static readonly CURRENT_VERSION = '0.11.0';

  static readonly VERSION_HISTORY = ['0.11.0'] as const;

  private strategy: VersionAdapterStrategy;

  constructor(version: { from?: Version; to?: Version } = { to: VersionAdapter.CURRENT_VERSION }) {
    const from = version.from ? this.assertVersion(version.from) : undefined;
    switch (version.to) {
      case '0.11.0':
        this.strategy = new V_0_11_0_Handler(from);
        break;
      // this is an example for handling next version
      // case '0.12.0':
      //   this.strategy = new V_0_12_0_Handler(from);
      //   break;
      default:
        throw new Error(`No data normalization handler exists for version: ${version}`);
    }
  }

  private assertVersion(version: string): Version {
    if (includesAnyString(version, VersionAdapter.VERSION_HISTORY as unknown as Version[])) {
      return version as Version;
    }
    throw new Error(`Specified version ${version} does not exists in ${VersionAdapter.VERSION_HISTORY}`);
  }

  chooseStrategy(version: { from?: string; to?: Version } = { to: VersionAdapter.CURRENT_VERSION }) {
    const from = version.from ? this.assertVersion(version.from) : undefined;
    switch (version.to) {
      case '0.11.0':
        this.strategy = new V_0_11_0_Handler(from);
        break;
      default:
        throw new Error(`No data normalization handler exists for version: ${version}`);
    }
  }

  normalizeCalibrationFlowData<T>(calibrationData: T) {
    return this.strategy.normalizeCalibrationFlowData(calibrationData);
  }
}
