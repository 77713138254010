const typography = {
  fonts: {
    body: 'Open Sans, Noto Sans TC, Noto Sans SC, sans-serif',
    heading: 'exo, Noto Sans TC, Noto Sans SC, sans-serif',
    subheading: '"Open Sans", Noto Sans TC, Noto Sans SC, sans-serif',
    'opensans-semibold': '"Open Sans SemiBold", "Open Sans", Noto Sans TC, Noto Sans SC, sans-serif',
    'opensans-light': '"Open Sans Light", "Open Sans", Noto Sans TC, Noto Sans SC, sans-serif',
  },
  fontSizes: {
    //                   chakra-ui
    xs: '0.75rem', //  xs   12px
    sm: '0.875rem', // sm   14px
    md: '1rem', //        md   16px
    lg: '1.125rem', //    lg   18px
    xl: '1.25rem', //     xl   20px
    '2xl': '1.5rem', //      2xl  24px
    '3xl': '1.875rem', // 3xl  30px
    '4xl': '2.25rem', //  4xl  36px
    '5xl': '3rem', //     5xl  48px
    '6xl': '3.75rem', //  6xl  60px
  },
  lineHeights: {
    // Line heights should be synchronized with font sizes values from mockups
    md: '1.375rem', //     md   22px
    lg: '1.5rem', //       lg   24px
    xl: '1.6875rem', //    xl   27px
    '2xl': '2.125rem', //     2xl  34px
    '3xl': '2.5rem', //    3xl  40px
    '4xl': '2.875rem', //  4xl  46px
    '5xl': '3.5625rem', // 5xl  57px
    '6xl': '5.5625rem', // 6xl  89px
  },
};
export default typography;
