import { parseStyleConfig } from './helpers';

const variants = ['mainLabel'] as const;

const FormLabel = parseStyleConfig(variants, {
  variants: {
    mainLabel: () => ({
      fontSize: ['md', null, null, 'xl'],
    }),
  },
});

export default FormLabel;
