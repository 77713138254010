import { FC } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { DeleteIcon } from 'components/icons';

interface Props {
  item: string;
  onClick: () => void;
}

export const TrainingFilterBar: FC<Props> = ({ item, onClick }: Props) => (
  <Button key={item} variant="smOutlinedTransparentButton" pl="3.5">
    <Box onClick={onClick} p="0" mr="1.5">
      <DeleteIcon />
    </Box>
    {item}
  </Button>
);
