import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalType, ModalTypeDefinition } from 'containers/modals/Modal';
import { DeviceFirmwareModule } from 'libs/exo-session-manager/core';
import { LocalStorageManager } from 'libs/exo-session-manager/core/global/LocalStorageManager';
import { closeModal, forceCloseModal, openModal } from 'slices/modalSlice';

import { useAppDispatch, useAppSelector } from './store';
import { convertVersionToString, DeviceExpectedFirmwareModuleValue, useFirmwareVersion } from './useFirmwareVersion';

const globalConditionForModulesError = /^(?!\/(service-mode|help|specialist\/help|specialist\/devices\/available)).*/;
const trainingConditionForModulesError = /^.*\/training\/.*$/;

type ModulesErrorSource = 'missing-modules' | 'incompatible-modules';
type ModulesErrorData = {
  errorType: ModalTypeDefinition;
  canBeSoftError: boolean;
  errorSource: ModulesErrorSource;
  faultyModules: DeviceExpectedFirmwareModuleValue[];
};

const getFirmwareModulesErrorData = (params: {
  currentModules: (DeviceFirmwareModule | undefined)[];
  missingModules: DeviceExpectedFirmwareModuleValue[];
  incompatibleModules: DeviceExpectedFirmwareModuleValue[];
}): ModulesErrorData | null => {
  let errorType: ModalTypeDefinition | undefined;
  let errorSource: ModulesErrorSource | undefined;
  let canBeSoftError = false;
  let faultyModules: DeviceExpectedFirmwareModuleValue[] = [];

  const ankleDetachedModulesCount = params.missingModules.filter(
    v => v.moduleId === 'motor:ankle' || v.moduleId === 'sensor-force:ankle',
  ).length;
  const remoteDetachedModuleCount = params.missingModules.filter(v => v.moduleId === 'remote').length;
  const ankleDetachedValue = ankleDetachedModulesCount === 2 ? 2 : 0;
  const remoteDetachedValue = remoteDetachedModuleCount ? 1 : 0;

  if (params.missingModules.length && params.missingModules.length !== ankleDetachedValue + remoteDetachedValue) {
    errorSource = 'missing-modules';
    errorType = ModalType.FIRMWARE_MODULES_HARD_ERROR;
  }
  if (
    !errorType &&
    params.missingModules.length &&
    params.missingModules.length === ankleDetachedValue + remoteDetachedValue
  ) {
    errorSource = 'missing-modules';
    errorType = ModalType.FIRMWARE_MODULES_SOFT_ERROR;
  }
  if (!errorType && params.incompatibleModules.length) {
    errorSource = 'incompatible-modules';
    errorType = ModalType.FIRMWARE_MODULES_HARD_ERROR;
  }

  if (errorType === ModalType.FIRMWARE_MODULES_HARD_ERROR && ankleDetachedModulesCount === 1) {
    // If canBeSoftErr is set, there is a possibility of delay of presence status between
    // 'motor:ankle' and  'sensor-force:ankle' and other conditions for 'hard' error aren't met.
    // In this case we delaying a dialog to acquire more recent data from device modules.
    canBeSoftError = true;
  }

  if (!errorSource || !errorType) {
    return null;
  }
  if (errorSource === 'missing-modules') {
    faultyModules = params.missingModules;
  } else {
    faultyModules = params.incompatibleModules;
  }
  return {
    errorType,
    errorSource,
    canBeSoftError,
    faultyModules,
  };
};

const checkFirmwareModalAgainstPath = (errData: ModulesErrorData, locationPathName: string) => {
  if (!locationPathName.match(globalConditionForModulesError)) {
    return false;
  } else {
    if (errData.errorSource === 'missing-modules' && !locationPathName.match(trainingConditionForModulesError)) {
      return false;
    }
  }
  return true;
};

export const useFirmwareModuleCheck = () => {
  const { current, missingModules, incompatibleModules } = useFirmwareVersion();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { name: dialogName } = useAppSelector(state => state.modal);

  useEffect(() => {
    const modulesErrorData = getFirmwareModulesErrorData({
      missingModules,
      incompatibleModules,
      currentModules: current,
    });
    const updateDialogState = () => {
      let errorText = '';
      if (!modulesErrorData) {
        // If conditions for dialogs are not met
        // try to remove non-visible dialogs from the queue
        // Do not close visible dialogs
        dispatch(closeModal(ModalType.FIRMWARE_MODULES_SOFT_ERROR));
        dispatch(closeModal(ModalType.FIRMWARE_MODULES_HARD_ERROR));
        return;
      }
      const faultyModules = modulesErrorData.faultyModules.slice(0, 1);

      if (modulesErrorData.errorSource === 'missing-modules') {
        errorText = `${faultyModules.map(v => v.moduleId).join(', ')} missing module`;
      } else {
        errorText = `${faultyModules.map(v => {
          const currentVersion = current?.find(vv => vv?.moduleId === v.moduleId)?.identification.version;
          return `${v.moduleId}:${convertVersionToString(v.version)}<>${
            currentVersion ? convertVersionToString(currentVersion) : '???'
          }`;
        })} incompatible module`;
      }

      if (checkFirmwareModalAgainstPath(modulesErrorData, location.pathname)) {
        if (modulesErrorData.errorType.name !== dialogName) {
          if (
            modulesErrorData.errorSource === 'incompatible-modules' &&
            LocalStorageManager.payload.disableModuleIncompatibilityCheck.value
          ) {
            return;
          }

          dispatch(
            openModal({
              type: modulesErrorData.errorType,
              id: errorText,
            }),
          );
        }
      } else {
        // If we are outside the allowed path
        // force close the firmware modals
        dispatch(forceCloseModal(ModalType.FIRMWARE_MODULES_SOFT_ERROR.name));
        dispatch(forceCloseModal(ModalType.FIRMWARE_MODULES_HARD_ERROR.name));
      }
    };
    if (modulesErrorData?.canBeSoftError) {
      const handler = setTimeout(() => {
        updateDialogState();
        // 500 ms is to cover the worst-case scenario of reporting a module status when both modules are disconnected at the same time.
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
    updateDialogState();
  }, [current, incompatibleModules, missingModules, location.pathname, dispatch, dialogName]);
};
