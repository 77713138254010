import { FC } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

interface AppSpinnerProps {
  size?: 'small' | 'medium' | 'large';
  isLight?: boolean;
}

export const AppSpinner: FC<AppSpinnerProps> = ({ size, isLight }) => {
  let spinnerSize;
  switch (size) {
    case 'small':
      spinnerSize = '6';
      break;
    case 'large':
      spinnerSize = '16';
      break;
    case 'medium':
    default:
      spinnerSize = '8';
  }
  const color = isLight ? 'white' : 'headerTextColor';

  return (
    <Center width="100%" height="100%" flexGrow={1}>
      <Spinner boxSize={spinnerSize} color={color} />
    </Center>
  );
};
