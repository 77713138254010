import { Logger } from '@egzotech/universal-logger-js';

const logger = Logger.getInstance();

export function withBasicErrorHandling<T = any>(
  callback: () => T,
  customHandler?: (reason: unknown) => void,
  shouldThrow?: boolean,
): T {
  let result: unknown | Promise<unknown>;
  let error = undefined;

  try {
    result = callback();
  } catch (reason: unknown) {
    logger.error('withBasicErrorHandling', 'There was an exception while handling an error: ', reason);
    error = reason;
    customHandler?.(reason);
  }

  if (result && typeof result === 'object' && 'catch' in result) {
    (result as Promise<unknown>).catch(reason => {
      logger.error('withBasicErrorHandling', 'There was an exception while handling an error: ', reason);
      customHandler?.(reason);

      if (shouldThrow) {
        throw reason;
      }
    });
  }

  if (error !== undefined && shouldThrow) {
    throw error;
  }

  return result as T;
}
