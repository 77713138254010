import { useEffect } from 'react';
import { AlertId, CalibrationFlow } from 'libs/exo-session-manager/core';

export function useCalibrationAlerts(
  flow: CalibrationFlow,
  alerts: {
    alertId: AlertId;
    onAlert: (...args: any) => any;
  }[],
) {
  useEffect(() => {
    const alertHandler = (alertId: AlertId) => {
      const alert = alerts.find(alert => alert.alertId === alertId);
      if (alert) {
        alert.onAlert();
      }
    };
    flow.events.addHandler('alert', alertHandler);

    return () => {
      flow.events.removeHandler('alert', alertHandler);
    };
  }, [alerts, flow.events]);
}
