import { FC } from 'react';
import { Box, Container, Flex, Grid } from '@chakra-ui/react';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

interface Props extends ModalProps {
  text?: string;
  additionalText?: string;
  callback?: () => void;
}

export const UserDecisionModal: FC<Props> = ({ text, additionalText, callback, close }) => {
  return (
    <Container variant="modalBox" paddingX="7">
      <Flex flexDirection="column" rowGap="7" my="2.5" alignItems={'center'}>
        <Box maxW="xl">
          <MainHeading variant="heading36" text={text} p="0" mb="5"></MainHeading>
          <TranslateText variant="openSans30" text={additionalText}></TranslateText>
        </Box>
        <Grid templateColumns={['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)']} columnGap="16" rowGap="4" mx="9">
          <MainButton
            text="select.no"
            type="submit"
            variant="mdOutlinedPrimaryButton"
            onClick={() => close()}
            minW="32"
          />
          <MainButton text="select.yes" variant="mdPrimaryButton" onClick={callback} minW="32" />
        </Grid>
      </Flex>
    </Container>
  );
};
