import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, Textarea } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';
import { useFormFieldContext } from 'hooks/forms/useFormFieldContext';
import { BaseInputProps } from 'types';

interface Props extends BaseInputProps {
  variant?: string;
  defaultValue?: string;
  value?: string | number;
  pwd?: string | boolean;
  numberType?: boolean;
  textarea?: boolean;
  noResize?: boolean;
  radius?: boolean;
  radiusSize?: string;
}

export const InputText: FC<Props> = ({
  defaultValue,
  numberType = false,
  value,
  onChange,
  disabled,
  placeholder,
  pwd,
  textarea,
  noResize,
  radius,
  radiusSize,
  errorBorderColor = 'errorMediumColor',
  ...props
}) => {
  const { name, id } = useFormFieldContext();
  const { register, formState, trigger } = useFormContext();
  const { onChange: fieldOnChange, ...rest } = register(name);
  return textarea ? (
    <Textarea
      {...rest}
      name={name}
      id={id}
      pl="5"
      onChange={async e => {
        onChange?.(e);
        await fieldOnChange(e);
        if (typeof pwd === 'string' && formState.dirtyFields[pwd]) {
          await trigger(pwd);
        } else {
          await trigger(name);
        }
      }}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder && __(placeholder)}
      resize={noResize ? 'none' : 'vertical'}
      px="5"
      py="3"
      focusBorderColor={'none'}
      errorBorderColor={errorBorderColor}
    />
  ) : (
    <Input
      autoComplete="off"
      title={__('placeholders.default')}
      h="12"
      pl="5"
      fontSize="md"
      borderRadius={radius ? radiusSize : 'rSm'}
      color="gray.600"
      borderColor="gray.300"
      {...rest}
      type={numberType ? 'number' : pwd ? 'password' : 'text'}
      name={name}
      id={id}
      onChange={async e => {
        onChange?.(e);
        await fieldOnChange(e);
        if (typeof pwd === 'string' && formState.dirtyFields[pwd]) {
          await trigger(pwd);
        } else {
          await trigger(name);
        }
      }}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder && __(placeholder)}
      {...props}
      errorBorderColor={errorBorderColor}
      _focus={{
        borderColor: 'gray.300',
        boxShadow: `none`,
      }}
    />
  );
};
