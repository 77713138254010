import { FC } from 'react';
import { Container } from '@chakra-ui/react';

import { AppSpinner } from './AppSpinner';

export const FullPageSpinner: FC = () => (
  <Container variant="fullPageCenter">
    <AppSpinner isLight size="large" />
  </Container>
);
