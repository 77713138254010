import { keyframes } from '@chakra-ui/react';

const fadeinAnimationFrames = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

export const fadeinAnimation = { animation: `${fadeinAnimationFrames} .5s linear` };
export const fadeEaseInAnimation = (duration = 1) => ({
  animation: `${fadeinAnimationFrames} ${duration}s ease-in`,
});
