import React, { useContext } from 'react';
import { ExoSession } from '@egzotech/exo-session';
import { useSignals } from '@preact/signals-react/runtime';
import { logger } from 'helpers/logger';
import {
  CPMExercise,
  DeviceType,
  GeneratedCPMExerciseDefinition,
  isSpecificGeneratedExerciseDefinition,
} from 'libs/exo-session-manager/core';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

import { CPMInstanceContext } from './CPMInstanceContext';

export function CPMProvider({ children, errorContent }: { children: React.ReactNode; errorContent?: React.ReactNode }) {
  const session = useContextSelector(ExoGlobalContext, state => state.device.session);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);
  const selectedProgramId = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgramId);
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);

  // FIXME Temporary cam implementation
  if (
    !session ||
    !(selectedDeviceType === 'sidra-leg' || selectedDeviceType === 'meissa-ot') ||
    !selectedProgram ||
    !isSpecificGeneratedExerciseDefinition(selectedProgram, ['cpm', 'cpm-emg', 'cpm-force'])
  ) {
    logger.error('CPMProvider', 'Provider was used without selected device or with invalid program type');

    return <>{errorContent}</>;
  }

  return (
    <CPMActiveProvider
      session={session}
      exerciseName={selectedProgramId}
      definition={selectedProgram}
      deviceType={selectedDeviceType}
    >
      {children}
    </CPMActiveProvider>
  );
}

interface CPMActiveProviderProps {
  session: ExoSession;
  exerciseName: string | null;
  definition: GeneratedCPMExerciseDefinition;
  deviceType: DeviceType;
  children: React.ReactNode;
}

function CPMActiveProvider({ session, exerciseName, definition, deviceType, children }: CPMActiveProviderProps) {
  useSignals();

  const cpmExercise = React.useMemo(
    () => new CPMExercise(definition, exerciseName, session, deviceType),
    [exerciseName, definition, deviceType, session],
  );

  React.useEffect(() => {
    cpmExercise.init();
    return () => {
      cpmExercise.destroy();
    };
  }, [cpmExercise]);

  if (!cpmExercise.programCpmData.initialized.value) {
    return <></>;
  }

  return <CPMInstanceContext.Provider value={cpmExercise}>{children}</CPMInstanceContext.Provider>;
}

export const useCPMProgram = () => {
  const cpmInstance = useContext(CPMInstanceContext);

  if (!cpmInstance) {
    throw new Error('useCPMProgram must be used within a CPMProvider');
  }

  return cpmInstance;
};
