import { useEffect, useState } from 'react';
import { useDetectConfigurationQuery, useSetDetectConfigurationMutation } from 'queries/device';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

function useReconfigureBackendDetectMechanismImplementation() {
  const { data } = useDetectConfigurationQuery();
  const [setConfiguration] = useSetDetectConfigurationMutation();
  const [reconfigured, setReconfigured] = useState(false);

  useEffect(() => {
    // The mechanism on backend will do nothing if detection mechanism is correctly configured
    // and if the mechanism failed it backend will reconfigure it after sending the same configuration.
    if (data?.serialNumbers?.length && !reconfigured) {
      setConfiguration({ serialNumbers: data.serialNumbers });
      setReconfigured(true);
    }
  }, [data, reconfigured, setConfiguration]);
}

export const useReconfigureBackendDetectMechanism = EGZOTechHostApi.instance?.options?.enableBackendDetectCandidates
  ? useReconfigureBackendDetectMechanismImplementation
  : () => {};
