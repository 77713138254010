import { Signal, signal } from 'helpers/signal';

import { ChannelIndex, ChannelMap, TimeLinePoint } from './types';

export class ChartDataSource {
  protected _timelines: ChannelMap<TimeLinePoint[]> = new ChannelMap<TimeLinePoint[]>();
  protected _time: Signal<number> = signal(0, '');

  get timelines() {
    return this._timelines;
  }

  constructor() {}
  dispose() {}

  getTimelineValue(channelIndex: ChannelIndex, time: number) {
    if (!this._timelines.channelExists(channelIndex)) {
      return null;
    }
    const index = this.findIndexOfPoint(time, channelIndex);
    if (!index) {
      return null;
    }

    const prevPoint = this._timelines.get(channelIndex)[index - 1];
    const currPoint = this._timelines.get(channelIndex)[index];

    const dy = currPoint[1] - prevPoint[1];
    const dx = currPoint[0] - prevPoint[0];

    const px = time - prevPoint[0];
    const py = dx === 0 ? prevPoint[1] : (dy / dx) * px;

    return py + prevPoint[1];
  }

  private binarySearch(
    data: TimeLinePoint[],
    time: number,
    fn: (data: TimeLinePoint[], index: number, time: number) => number,
  ) {
    let minIndex = 0;
    let maxIndex = data.length - 1;

    while (maxIndex >= minIndex) {
      const index = Math.floor((maxIndex - minIndex) / 2) + minIndex;
      const result = fn(data, index, time);
      if (!result) {
        return index;
      }
      if (result < 0) {
        maxIndex = index - 1;
      } else {
        minIndex = index + 1;
      }
    }
    return null;
  }

  private findIndexOfPoint(time: number, channel: ChannelIndex) {
    if (!this._timelines.get(channel)) {
      return null;
    }
    const timeline = this._timelines.get(channel) ?? [];

    const index = this.binarySearch(timeline, time, (data, index, time) => {
      return time < data[index > 0 ? index - 1 : 0][0] ? -1 : time > data[index][0] ? 1 : 0;
    });
    return index;
  }
}
