export const SAMPLE_FREQUENCY = 20; // 20 Hz

export const CHART_WINDOW_TIME = 30000; // 30 seconds
export const CHART_WINDOW_SAMPLES = (CHART_WINDOW_TIME / 1000) * SAMPLE_FREQUENCY;

export const BATTERY_MIN = 7.0;
export const BATTERY_MAX = 8.1;

export const PROGRAM_REFRESH_INTERVAL = 100;

export const VOLT_TO_MICROVOLT_MULTIPLIER = 1e6;

export const CPM_SAMPLE_FREQUENCY = 80;
export const EMG_SAMPLE_FREQUENCY = 20;
