import { useContext, useMemo } from 'react';
import { Exercise } from 'libs/exo-session-manager/core/exercises/Exercise';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';
import { EMGInstanceContext } from '../providers/EMGInstancecontext';
import { EMSInstanceContext } from '../providers/EMSInstanceContext';

export const useExercise = (): Exercise => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  const cam = useContext(CAMInstanceContext);
  const ems = useContext(EMSInstanceContext);
  const emg = useContext(EMGInstanceContext);

  return useMemo(() => {
    if (cpm) {
      return cpm;
    }

    if (cpmEms) {
      return cpmEms;
    }

    if (cam) {
      return cam;
    }

    if (ems) {
      return ems;
    }

    if (emg) {
      return emg;
    }

    // TODO: add missing exercises type (EMS, EMG) if required

    throw new Error('useExercise must be used within a exercise provider');
  }, [cam, cpm, cpmEms, ems, emg]);
};
