import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
/* eslint-disable @typescript-eslint/no-empty-interface */
// Generated using typescript-generator version 2.0.400 on 2022-10-10 08:18:17.

import { Exercise } from './exercise';
import { Language } from 'config/app';

export type UpdateCurrentVersionResponseDTO = {
  version: AppVersion;
};

export type UpdateAllResponseDTO = {
  version: AppVersion;
  available: {
    version: AppVersion;
    source: AppSource;
  };
};

export type UpdateStatusResponseDTO = {
  progress: number;
  completed: boolean;
  running: boolean;
};

export interface FromToDTO {
  from: Date | string;
  to: Date | string;
}

export interface ApiErrorMessage {
  errorCode: HttpStatus;
  details?: string;
  formErrors: { [index: string]: string };
  errorLogId?: string;
}

export interface Response<T> {
  status: ApiResponseStatus;
  payload: T;
}

export interface VoidResponseDTO {}

export interface SignInDTO {
  username: string;
  password: string;
}

export interface ChatMessageCreateDTO {
  text: string;
}

export interface ChatMessageFilterDTO {
  chatMessageId: string;
  beforeChatMessageCreateDate: boolean;
}

export interface ChatMessageResponseDTO {
  id: string;
  text: string;
  chatMessageType: ChatMessageType;
  postDate: Date;
  author: ConversationParticipantDTO;
  currentUserAuthor: boolean;
}

export interface ConversationParticipantDTO {
  userProfileId: string;
  name: string;
  surname: string;
  photo: string;
}

export interface ConversationResponseDTO {
  id: string;
  lastChatMessage: ChatMessageResponseDTO;
  participant: ConversationParticipantDTO;
  unreadMessages: number;
}

export interface RecipientInfoResponseDTO {
  conversationId: string;
  name: string;
  surname: string;
  photo: string;
}

export interface FacilityPasswordSetDTO {
  password: string;
}

export type FacilityPatientResponseDTO = BasicPatientResponseDTO;

export interface FacilityResponseDTO {
  id: string;
  name: string;
  owner: UserResponseDTO;
}

export interface FacilityStatisticsDTO {
  name: string;
  numberOfPatients: number;
  numberOfSpecialists: number;
  facilityCreateDate: Date;
}

export interface FacilityUpdateDTO {
  name: string;
}

export interface FacilitySpecialistFilterDTO extends BasicNameAndSurnameFilterDTO {
  blocked?: boolean;
}

export interface FacilitySpecialistResponseDTO extends BasicSpecialistResponseDTO {
  id: string;
}

export interface BasicPatientResponseDTO {
  userProfile: UserProfileResponseDTO;
  patientCardId: string;
  patientType: 'telemedicine' | 'stationary' | 'anonymous';
  photo: string;
}

export interface BasicSpecialistResponseDTO {
  user: UserResponseDTO;
}

export interface FacilitySpecialistPatientAddDTO {
  specialistUserProfileId: string;
  patientUserProfileId: string;
  facilityId: string;
}

export interface FacilitySpecialistPatientCreateDTO {
  email?: string;
  language: Language | string;
  profile: FacilitySpecialistPatientUserProfileCreateDTO;
}

export interface FacilitySpecialistPatientEditDTO {
  userProfileUpdateDTO: Partial<UserProfileUpdateDTO>;
  patientCard: Partial<PatientCardUpdateDTO>;
  email: string;
  language: Language | string;
}

export interface FacilitySpecialistPatientFilterDTO extends BasicNameAndSurnameFilterDTO {
  patientFilterType?: PatientFilterType;
}

export interface FacilitySpecialistPatientInformationDTO {
  name: string;
  surname: string;
  gender: Gender;
  dateOfBirth: Date;
  phone: string;
  photo: string;
  patientCard: PatientCardResponseDTO;
  telemedicineInformation: TelemedicineInformationResponseDTO;
  leadFacilitySpecialistId: string;
}

export interface FacilitySpecialistPatientProfileCreateDTO {
  diagnosisIcd10: string;
  goalOfPhysiotherapy?: string;
}

export interface FacilitySpecialistPatientResponseDTO {
  id: string;
  patient: PatientResponseDTO;
}

export interface FacilitySpecialistPatientUserProfileCreateDTO extends BasicUserProfileCreateDTO {
  patientProfile: FacilitySpecialistPatientProfileCreateDTO;
}

export interface PatientFacilitySpecialistResponseDTO {
  id: string;
  facilitySpecialist: FacilitySpecialistResponseDTO;
}

export interface PatientResponseDTO extends BasicPatientResponseDTO {
  conversationId: string;
  unreadMessage: boolean;
}

export type SpecialistResponseDTO = BasicSpecialistResponseDTO;

export interface TelemedicineInformationResponseDTO {
  confirmed: boolean;
  email: string;
}

export interface TelemedicineInvitationAcceptDTO {
  password: string;
  token: string;
}

export interface TelemedicineInvitationEmailChangeDTO {
  telemedicineInvitationId: string;
  email: string;
}

export interface TelemedicineInvitationResponseDTO {
  id: string;
  name: string;
  surname: string;
  email: string;
}

export interface FacilitySpecialistCreateFromInvitationDTO {
  password: string;
  name: string;
  surname: string;
  consents: ConsentsDTO;
}

export interface FacilitySpecialistInvitationResendDTO {
  email: string;
  twoFactorAuthenticationCode: string;
}

export interface FacilitySpecialistInvitationResponseDTO {
  id: string;
  expiryDate: Date;
  email: string;
  expired: boolean;
  used: boolean;
}

export interface FacilitySpecialistInviteDTO {
  email: string;
  language: Language;
}

export type InvitedFacilitySpecialistFilterDTO = BasicNameAndSurnameFilterDTO;

export interface BasicNameAndSurnameFilterDTO {
  searchContent?: string[];
}

export interface FacilityPatientsFilterDTO extends BasicNameAndSurnameFilterDTO {
  archived: boolean;
  pageSize?: number;
  pageNumber?: number;
}

export interface LicenseDTO {
  id: number;
  clinicId: string;
  jwt: string;
}

export interface LicenseDecodedDTO {
  sku: string[];
  productID: number;
  licenseKey: string;
  iat: number;
  aud: string;
  iss: string;
  sub: string;
}

export interface LicenseRequestDTO {
  licenses: LicenseDTO[];
}

export interface MedicalDataCreateDTO {
  bioelectricSignals: string[];
  muscles: string[];
  exerciseDateTime: Date;
  exerciseName: string;
  technicalData: TechnicalDataCreateDTO;
}

export interface MedicalDataResponseDTO {
  id: string;
  bioelectricSignals: string[];
  muscles: string[];
  exerciseDateTime: Date;
  exerciseName: string;
  technicalData: TechnicalDataResponseDTO;
}

export interface MedicalDataUpdateDTO {
  bioelectricSignals: string[];
  muscles: string[];
  date: Date;
  exerciseName: string;
}

export interface TechnicalDataCreateDTO {
  controlDeviceModel: string;
  controlDeviceSystem: string;
  deviceId: string;
  applicationName: string;
  applicationVersion: string;
  deviceTimeOfUse: TimeOfUseDTO;
  latestInternetConnection: Date;
}

export interface TechnicalDataResponseDTO {
  controlDeviceModel: string;
  controlDeviceSystem: string;
  deviceId: string;
  applicationName: string;
  applicationVersion: string;
  deviceTimeOfUse: TimeOfUseResponseDTO;
  latestInternetConnection: Date;
}

export interface TimeOfUseDTO {
  startTime: Date;
  finishTime: Date;
}

export interface TimeOfUseResponseDTO {
  startTime: Date;
  finishTime: Date;
}

export interface PasswordChangeDTO {
  newPassword: string;
  oldPassword: string;
  language: Language;
}

export interface PasswordResetDTO {
  password: string;
  token: string;
}

export interface PasswordResetRequestDTO {
  email: string;
  language: Language;
}

export interface PasswordResetByMasterPassword {
  masterPassword: string;
  email: string;
  newPassword: string;
}

export interface PasswordValidationDTO {
  password: string;
}

export interface CalendarResponseDTO {
  trainings: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'][];
  userProfile: UserProfileResponseDTO;
}

export interface TrainingCreateDTO {
  comment: string;
  dateTime: Date;
  trainingTemplateId: string;
  userProfileId: string;
}

export interface TrainingDayMoveDTO {
  givenDay: Date | string;
  targetDay: Date | string;
}

// to be deleted and replaced by swagger type
export interface TrainingResponseDTO {
  id: string;
  comment: string;
  trainingTemplate: any;
  patientProfile: PatientResponseDTO;
  specialistProfile: SpecialistProfileResponseDTO;
  completeTime: Date;
  startTime: Date;
  dateTime: Date;
  createDate: Date;
  trainingResult: TrainingResult;
  trainingData: TrainingDataResponseDTO[];
}

export interface TrainingUpdateDTO {
  comment: string;
  trainingTemplateId?: string;
  trainingData: { [index: string]: any };
}

export interface CustomTrainingTemplateCreateDTO {
  name: string;
  description: string;
  durationOfMinutes: number;
  trainingTypeId: string;
  exercises: { [index: string]: any };
  classificationPackages: string[];
  bodyParts: string[];
  icd10Diagnosis: string[];
}

export interface FavoriteTrainingTemplateManagingDTO {
  userProfileId: string;
  trainingTemplateId: string;
}

export interface TrainingDataResponseDTO {
  id: string;
  data: { [index: string]: any };
}

export interface TrainingTypeResponseDTO {
  id: string;
  name: string;
}

export interface TwoFactorAuthenticationResponseDTO {
  qr: any;
  secretKey: string;
}

export interface SpecialistCreateDTO {
  email: string;
  password: string;
  language: Language;
  profile: SpecialistUserProfileCreateDTO;
}

export interface UserCreateDTO {
  email: string;
  password: string;
  roleGroup: string;
  enabled: boolean;
  consents: ConsentsDTO;
  profile: UserProfileCreateDTO;
}

export interface UserResponseDTO {
  id: string;
  email: string;
  enabled: boolean;
  roleGroup: RoleGroupEnum;
  facilityId: string;
  language: Language;
  userProfile: UserProfileResponseDTO;
  photo: string;
  twoFactorAuthentication: TwoFactorAuthStatus;
  createDate: Date;
}

export interface UserRoleGroupsResponseDTO {
  roleGroups: RoleGroupEnum[];
}

export interface ConsentsDTO {
  privacyPolicy: boolean;
  termsOfUse: boolean;
}

export interface StationaryPatientConsentsDTO {
  gdpr: boolean;
}

export interface SpecialistPatientCardResponseDTO {
  id: string;
  comments: CommentResponseDTO[];
  name: string;
  surname: string;
  diagnosisIcd10: string;
  goalOfPhysiotherapy: string;
  gender: Gender;
  dateOfBirth: Date;
  phone: string;
  email: string;
  leadSpecialist: CommentAuthorDTO;
  measurements: {
    leftThighLength?: number;
    rightThighLength?: number;
  } | null;
}

export interface BasicUserProfileCreateDTO {
  name: string;
  surname: string;
  dateOfBirth?: Date | string;
  gender: Gender;
  phone: string;
}

export interface PatientProfileCreateDTO {
  patientCard: PatientCardCreateDTO;
}

export interface PatientProfileResponseDTO {
  patientCard: PatientCardResponseDTO;
}

export interface SpecialistProfileResponseDTO {
  id: string;
  name: string;
  surname: string;
}

export interface SpecialistUserProfileCreateDTO {
  name: string;
  surname: string;
  consents: ConsentsDTO;
}

export interface UserProfileCreateDTO extends BasicUserProfileCreateDTO {
  patientProfile: PatientProfileCreateDTO;
}

export interface UserProfileResponseDTO {
  id: string;
  name: string;
  surname: string;
  dateOfBirth: Date | string;
  gender: 'male' | 'female' | 'unspecified' | undefined;
  phone: string;
  identificationCode: string;
  archived: boolean;
  anonymous: boolean;
  consents: ConsentsDTO;
  createDate: Date | string;
  enabled: boolean;
}

export interface BasicUserProfileUpdateDTO {
  name: string;
  surname: string;
  email: string;
  dateOfBirth: Date | string;
  gender: Gender | string;
  phone: string;
}

export interface PatientProfileUpdateDTO {
  patientCard: PatientCardUpdateDTO;
}

export interface SpecialistProfileUpdateDTO {
  title: string;
  description: string;
}

export type UserProfileUpdateDTO = BasicUserProfileUpdateDTO;

export interface EmailChangeDTO {
  email: string;
  language: Language;
}

export interface StockKeepingUnit {}

export interface Duration extends TemporalAmount, Comparable<Duration>, Serializable {
  seconds: number;
  nano: number;
  zero: boolean;
  negative: boolean;
}

export interface Page<T> extends Slice<T> {
  totalPages: number;
  totalElements: number;
}

export interface TemporalUnit {
  dateBased: boolean;
  timeBased: boolean;
  duration: Duration;
  durationEstimated: boolean;
}

export interface TemporalAmount {
  units: TemporalUnit[];
}

export interface Serializable {}

export interface Sort extends Streamable<Order>, Serializable {
  sorted: boolean;
  unsorted: boolean;
}

export interface Pageable {
  offset: number;
  sort: Sort;
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
}

export interface WifiNetworkDTO {
  ssid: string;
  signal: number;
  security: string;
  type: string;
  connected: boolean;
  passwordSaved: boolean;
}
export interface WifiNetworksDTO {
  networks: WifiNetworkDTO[];
  hidden: boolean;
}

export type WifiNetworkStatus =
  | 'connected'
  | 'connectedNoInternet'
  | 'invalidPassword'
  | 'missingPassword'
  | 'unknownNetwork'
  | 'error'
  | 'connecting'
  | 'disconnecting'
  | 'forgetting';

export interface WifiConnectResponseDTO {
  status: WifiNetworkStatus;
}

export interface WifiStatusResponseDTO {
  connected: boolean;
  mac: string;
  ip4: string;
  ip6: string;
  ssid: string;
}

export interface Comparable<T> {}

export interface Slice<T> extends Streamable<T> {
  size: number;
  content: T[];
  number: number;
  sort: Sort;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: Pageable;
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
  empty: boolean;
}

export interface Order extends Serializable {
  direction: Direction;
  property: string;
  ignoreCase: boolean;
  nullHandling: NullHandling;
  ascending: boolean;
  descending: boolean;
}

export interface Iterable<T> {}

export interface Supplier<T> {}

export type Stream<T> = BaseStream<T, Stream<T>>;

export interface BaseStream<T, S> extends AutoCloseable {
  parallel: boolean;
}

export interface AutoCloseable {}

export const enum AuthProvider {
  local = 'local',
  facebook = 'facebook',
  google = 'google',
  apple = 'apple',
}

export const enum TokenType {
  specialist = 'specialist',
  telemedicine = 'telemedicine',
  welcomecard = 'welcomecard',
}

export const enum ApiResponseStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const enum ChatMessageType {
  NORMAL = 'NORMAL',
  TRAINING_SUCCESS = 'TRAINING_SUCCESS',
  TRAINING_UNFINISHED = 'TRAINING_UNFINISHED',
}

export const enum PasswordVerificationRoles {
  PATIENT = 'PATIENT',
  SPECIALIST = 'SPECIALIST',
  OWNER = 'OWNER',
}

export const enum RoleGroupEnum {
  ADMIN = 'ADMIN',
  PATIENT = 'PATIENT',
  SPECIALIST = 'SPECIALIST',
  OWNER = 'OWNER',
}

export const enum TrainingResult {
  DONE = 'DONE',
  UNDONE = 'UNDONE',
  CATCHED_UP = 'CATCHED_UP',
  PLANNED = 'PLANNED',
  STARTED = 'STARTED',
  STARTED_CATCH_UP = 'STARTED_CATCH_UP',
}

export const enum TwoFactorAuthStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  CONFIGURATION_REQUIRED = 'CONFIGURATION_REQUIRED',
  REQUIRED = 'REQUIRED',
}

export const enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export const enum PatientFilterType {
  TELEMEDICINE = 'TELEMEDICINE',
  STATIONARY = 'STATIONARY',
  ALL_PATIENTS = 'ALL_PATIENTS',
}

export const enum PatientType {
  TELEMEDICINE = 'TELEMEDICINE',
  STATIONARY = 'STATIONARY',
  ANONYMOUS = 'ANONYMOUS',
}

export const enum HttpStatus {
  CONTINUE = 'CONTINUE',
  SWITCHING_PROTOCOLS = 'SWITCHING_PROTOCOLS',
  PROCESSING = 'PROCESSING',
  CHECKPOINT = 'CHECKPOINT',
  OK = 'OK',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  NON_AUTHORITATIVE_INFORMATION = 'NON_AUTHORITATIVE_INFORMATION',
  NO_CONTENT = 'NO_CONTENT',
  RESET_CONTENT = 'RESET_CONTENT',
  PARTIAL_CONTENT = 'PARTIAL_CONTENT',
  MULTI_STATUS = 'MULTI_STATUS',
  ALREADY_REPORTED = 'ALREADY_REPORTED',
  IM_USED = 'IM_USED',
  MULTIPLE_CHOICES = 'MULTIPLE_CHOICES',
  MOVED_PERMANENTLY = 'MOVED_PERMANENTLY',
  FOUND = 'FOUND',
  MOVED_TEMPORARILY = 'MOVED_TEMPORARILY',
  SEE_OTHER = 'SEE_OTHER',
  NOT_MODIFIED = 'NOT_MODIFIED',
  USE_PROXY = 'USE_PROXY',
  TEMPORARY_REDIRECT = 'TEMPORARY_REDIRECT',
  PERMANENT_REDIRECT = 'PERMANENT_REDIRECT',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
  PROXY_AUTHENTICATION_REQUIRED = 'PROXY_AUTHENTICATION_REQUIRED',
  REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
  CONFLICT = 'CONFLICT',
  GONE = 'GONE',
  LENGTH_REQUIRED = 'LENGTH_REQUIRED',
  PRECONDITION_FAILED = 'PRECONDITION_FAILED',
  PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
  REQUEST_ENTITY_TOO_LARGE = 'REQUEST_ENTITY_TOO_LARGE',
  URI_TOO_LONG = 'URI_TOO_LONG',
  REQUEST_URI_TOO_LONG = 'REQUEST_URI_TOO_LONG',
  UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
  REQUESTED_RANGE_NOT_SATISFIABLE = 'REQUESTED_RANGE_NOT_SATISFIABLE',
  EXPECTATION_FAILED = 'EXPECTATION_FAILED',
  I_AM_A_TEAPOT = 'I_AM_A_TEAPOT',
  INSUFFICIENT_SPACE_ON_RESOURCE = 'INSUFFICIENT_SPACE_ON_RESOURCE',
  METHOD_FAILURE = 'METHOD_FAILURE',
  DESTINATION_LOCKED = 'DESTINATION_LOCKED',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  LOCKED = 'LOCKED',
  FAILED_DEPENDENCY = 'FAILED_DEPENDENCY',
  TOO_EARLY = 'TOO_EARLY',
  UPGRADE_REQUIRED = 'UPGRADE_REQUIRED',
  PRECONDITION_REQUIRED = 'PRECONDITION_REQUIRED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  REQUEST_HEADER_FIELDS_TOO_LARGE = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
  UNAVAILABLE_FOR_LEGAL_REASONS = 'UNAVAILABLE_FOR_LEGAL_REASONS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  BAD_GATEWAY = 'BAD_GATEWAY',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
  HTTP_VERSION_NOT_SUPPORTED = 'HTTP_VERSION_NOT_SUPPORTED',
  VARIANT_ALSO_NEGOTIATES = 'VARIANT_ALSO_NEGOTIATES',
  INSUFFICIENT_STORAGE = 'INSUFFICIENT_STORAGE',
  LOOP_DETECTED = 'LOOP_DETECTED',
  BANDWIDTH_LIMIT_EXCEEDED = 'BANDWIDTH_LIMIT_EXCEEDED',
  NOT_EXTENDED = 'NOT_EXTENDED',
  NETWORK_AUTHENTICATION_REQUIRED = 'NETWORK_AUTHENTICATION_REQUIRED',
}

export const enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const enum NullHandling {
  NATIVE = 'NATIVE',
  NULLS_FIRST = 'NULLS_FIRST',
  NULLS_LAST = 'NULLS_LAST',
}
