import { Triggerable } from './Triggerable';

export type TriggerCondition = () => boolean;

export class TriggerableGroup implements Triggerable {
  private conditions: { id: string; condition: TriggerCondition }[] = [];
  private triggerables: { id: string; triggerable: Triggerable }[] = [];

  addCondition(id: string, condition: TriggerCondition) {
    const existingCondition = this.conditions.find(condition => condition.id === id);
    if (existingCondition) {
      existingCondition.condition = condition;
    } else {
      this.conditions.push({ id, condition });
    }
  }

  add(id: string, triggerable: Triggerable) {
    const existingTriggerable = this.triggerables.find(triggerable => triggerable.id === id);
    if (existingTriggerable) {
      existingTriggerable.triggerable = triggerable;
    } else {
      this.triggerables.push({ id, triggerable });
    }
  }

  canTrigger() {
    return this.conditions.length === 0 || this.conditions.every(v => v.condition());
  }

  trigger(): void {
    if (!this.canTrigger()) {
      return;
    }

    if (!this.triggerables.every(v => v.triggerable.canTrigger())) {
      return;
    }

    this.triggerables.forEach(v => v.triggerable.trigger());
  }
}
