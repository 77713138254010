import { useContext } from 'react';

import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { EMSInstanceContext } from '../providers/EMSInstanceContext';

export const useExerciseWithEMS = () => {
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  const ems = useContext(EMSInstanceContext);

  if (cpmEms) return cpmEms;
  if (ems) return ems;

  throw new Error('useExerciseWithEMS must be used within a Provider which requires EMS feature');
};
