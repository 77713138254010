import { HStack, VStack } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { useHelpRoute } from 'containers/buttons/ServiceHelpButton';
import { ModalType } from 'containers/modals/Modal';
import { useComputed } from 'helpers/signal';
import { useAppDispatch } from 'hooks/store';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { openModal } from 'slices/modalSlice';

import { MainButton } from 'components/buttons/MainButton';
import { AppLink } from 'components/common/AppLink';
import { TranslateText } from 'components/texts/TranslateText';

export const PEC_SUCCESS_THRESHOLD = 0.6;

export const PECCalibration = () => {
  useSignals();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    dispatch(openModal({ type: ModalType.PEC_CALIBRATION }));
  };

  const isPECUnknown = useComputed(
    () => typeof DeviceManager.pecStandardDeviation.value === 'undefined',
    'PECCalibration.isPECUnknown',
  );

  const isPECNotCalibrated = useComputed(
    () =>
      typeof DeviceManager.pecStandardDeviation.value === 'number' && DeviceManager.pecStandardDeviation.value === 0,
    'PECCalibration.isPECNotCalibrated',
  );

  const isPECFailed = useComputed(
    () =>
      typeof DeviceManager.pecStandardDeviation.value === 'number' &&
      DeviceManager.pecStandardDeviation.value >= PEC_SUCCESS_THRESHOLD,
    'PECCalibration.isPECFailed',
  );

  const helpRoute = useHelpRoute('pec-calibration-failed');

  return (
    <HStack gap="10">
      {isPECUnknown.value ? (
        <TranslateText variant="openSans16Bold" color="gray.400" text="pec.unknown" />
      ) : isPECNotCalibrated.value ? (
        <TranslateText variant="openSans16Bold" color="warningColor" text="pec.notCalibrated" />
      ) : isPECFailed.value ? (
        <span>
          <TranslateText variant="openSans16Bold" color="errorColor" text="pec.failed" />
          <AppLink
            // eslint-disable-next-line react/no-children-prop
            children={
              <TranslateText
                variant="openSans16Bold"
                color="egzotechPrimaryColor"
                text="pec.contactService"
                textDecoration="underline"
                _hover={{ color: 'blue.300' }}
              />
            }
            to={helpRoute}
          />
        </span>
      ) : (
        <TranslateText variant="openSans16Bold" color="stellaBioPrimaryColor" text="pec.pass" />
      )}
      {DeviceManager.pecStandardDeviation.value && (
        <VStack gap="0" align="flex-start">
          <TranslateText w="96" variant="openSans16Bold" color="gray.400" text="pec.result">
            {DeviceManager.pecStandardDeviation.value.toFixed(3)}
          </TranslateText>
        </VStack>
      )}
      <MainButton variant="mdPrimaryButton" onClick={handleClick} text={'pecCalibration.buttonText'}></MainButton>
    </HStack>
  );
};
