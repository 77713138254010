import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { currentVersionOfAdditionalData } from 'config/defaultConfigProps';
import {
  CalibrationFlowStateIdentifier,
  CalibrationFlowStatesTypedData,
  DeviceType,
  replayableSteps,
} from 'libs/exo-session-manager/core';
import { useDevice } from 'libs/exo-session-manager/react';
import { useGetSpecialistPatientCardQuery, useUpdateSpecialistPatientMedicalDataMutation } from 'queries/patientCards';
/**
 * Hook is responsible for store and return basing data.
 * @returns
 * - null if data is not yet available
 * - object of type CalibrationFlowStatesTypedData if data is available (no additional flags needed)
 */
export const useStoredCalibrationData = () => {
  const { patientId } = useParams();
  const { isFetching, data } = useGetSpecialistPatientCardQuery(patientId ?? '');
  const [updateSpecialistPatientData] = useUpdateSpecialistPatientMedicalDataMutation();
  const { selectedDevice } = useDevice();

  const initialAdditionalData = useMemo(() => {
    if (!isFetching) {
      return {
        calibrationData: data?.additionalData?.calibrationData ?? {},
        physicalMeasurements: data?.additionalData?.physicalMeasurements ?? null,
      };
    }
    return null;
    // ignore automatic data change from rtk-query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const initialCalibrationData = useMemo(() => {
    if (selectedDevice?.type && initialAdditionalData?.calibrationData) {
      return initialAdditionalData?.calibrationData?.[selectedDevice.type] ?? {};
    }
    return null;
  }, [initialAdditionalData, selectedDevice?.type]);

  const [calibrationData, setCalibrationData] = useState<CalibrationFlowStatesTypedData | null>(initialCalibrationData);
  const [dataToSave, setDataToSave] = useState<CalibrationFlowStatesTypedData | null>(null);

  const setDataWrapper = useCallback(
    (
      calibrationData:
        | (CalibrationFlowStatesTypedData | null)
        | ((prev: CalibrationFlowStatesTypedData | null) => CalibrationFlowStatesTypedData | null),
    ) => {
      setCalibrationData(calibrationData);
      setDataToSave(calibrationData);
    },
    [],
  );

  const saveCalibrationInDatabase = useCallback(
    (calibrationData: CalibrationFlowStatesTypedData) => {
      if (Object.keys(calibrationData).length > 0 && initialAdditionalData) {
        if (!selectedDevice?.type) {
          throw new Error('Cannot save a calibration data in the database');
        }
        const updatedData = {
          ...initialAdditionalData,
          version: currentVersionOfAdditionalData,
          physicalMeasurements: data?.additionalData?.physicalMeasurements ?? null,
          calibrationData: {
            ...(initialAdditionalData?.calibrationData ? initialAdditionalData?.calibrationData : {}),
            [selectedDevice.type as DeviceType]: calibrationData,
          },
        };

        updateSpecialistPatientData({
          patientCardId: patientId,
          additionalData: updatedData,
        });
      }
    },
    [initialAdditionalData, selectedDevice?.type, updateSpecialistPatientData, patientId, data],
  );

  useEffect(() => {
    if (initialCalibrationData) {
      setCalibrationData(initialCalibrationData);
    }
  }, [initialCalibrationData]);

  useEffect(() => {
    if (dataToSave) {
      let newData: CalibrationFlowStatesTypedData = {};
      for (const [key, value] of Object.entries(dataToSave)) {
        if (replayableSteps[key as CalibrationFlowStateIdentifier]) {
          newData = {
            ...dataToSave,
            [key as CalibrationFlowStateIdentifier]: { ...value },
          };
        }
      }

      setDataToSave(null);
      // delay save to avoid loops
      setTimeout(() => {
        saveCalibrationInDatabase(newData);
      }, 0);
    }
  }, [dataToSave, saveCalibrationInDatabase]);

  return {
    calibrationData,
    setCalibrationData: setDataWrapper,
  };
};
