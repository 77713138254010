import { FC, useMemo } from 'react';
import { FormLabel } from '@chakra-ui/react';

import { TranslateText } from './TranslateText';

interface Props {
  text: string;
  size?: string;
  indicator?: boolean;
}

export const LabelText: FC<Props> = ({ text, size = 'xl', indicator }) => {
  const fontSize = useMemo(() => {
    if (size === 'xl') {
      return ['md', 'lg', 'lg', 'xl'];
    }
    return [size];
  }, [size]);
  return (
    <FormLabel
      display="flex"
      fontSize={fontSize}
      whiteSpace="pre-line"
      mx="0"
      mt="0"
      mb="1"
      requiredIndicator={indicator ? <span>*</span> : <></>}
    >
      <TranslateText text={text} />
    </FormLabel>
  );
};
