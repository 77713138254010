import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { getImageAsset } from 'helpers/assets';
import { logger } from 'helpers/logger';
import { fadeinAnimation } from 'helpers/skeletonFadeInAnimation';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import {
  abortTraining,
  initializeTrainingFromTemplate,
  prepareNewTrainingForPatient,
  startTraining,
} from 'slices/trainingSlice';

import { TranslateText } from 'components/texts/TranslateText';

import { TrainingItemFooter } from './TrainingItemFooter';
import { TrainingItemHeader } from './TrainingItemHeader';

interface Props {
  trainingTemplate: ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDto'];
}

export const TrainingItem: FC<Props> = ({ trainingTemplate }: Props) => {
  const dispatch = useAppDispatch();
  const { setFavoriteInTraining } = useTrainingList();
  const navigate = useAppNavigate();
  const { name, favorite, trainingType, duration, id, image } = trainingTemplate;
  const { patientId } = useParams();
  const { trainingList } = useAppSelector(state => state.trainingList);
  const { status } = useAppSelector(state => state.training);
  const hideFavourites = EGZOTechHostApi.instance?.options?.hideFavourites;

  const handleFavoriteToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!hideFavourites) {
      if (favorite) {
        setFavoriteInTraining(id, !favorite);
        return;
      }
      setFavoriteInTraining(id, !favorite);
    }
  };

  const handleSelectTraining = () => {
    if (status === 'initialized') {
      logger.warn(
        'TrainingItem.handleSelectTraining',
        'Training is already initialized. Cannot initialize another training. Aborting the training.',
      );

      // TODO: check if this should be this way
      dispatch(abortTraining());
    }

    if (status !== 'finished') {
      logger.warn('TrainingItem.handleSelectTraining', 'Last training was not finished. Aborting the training.');
      dispatch(abortTraining());
    }

    if (!id) {
      throw new Error(`'trainingTemplateId is missing`);
    }

    const trainingTemplate = trainingList.find(t => t.id === id);

    if (!trainingTemplate) {
      throw new Error(`Cannot initialize training. Cannot find template with id '${id}'`);
    }

    if (!patientId) {
      throw new Error('Cannot initialize training. Missing patient id.');
    }

    dispatch(initializeTrainingFromTemplate(trainingTemplate));

    try {
      dispatch(prepareNewTrainingForPatient(patientId)).then(() => dispatch(startTraining()));
    } catch (ex) {
      logger.error('TrainingItem.handleSelectTraining', 'Cannot prepare training', ex);
      return;
    }

    navigate(`/${patientId}/training/${id}`);
  };

  return (
    <GridItem
      bg="white"
      key={name}
      borderRadius="rSm"
      shadow="sm"
      minH="40"
      onClick={handleSelectTraining}
      sx={fadeinAnimation}
      data-testid={name}
    >
      <Grid templateColumns="min-content" gap="4" h="100%" gridRow="24">
        <GridItem gridColumn={1}>
          <Box w="20" ml="5" mt="5">
            <Image src={getImageAsset(image ?? '')} h="28" alignSelf="flex-start" objectFit="contain" />
          </Box>
        </GridItem>
        <GridItem gridColumn={2} h="100%" overflow="hidden">
          <Flex direction="column" pt="5" pb="4" mr="5" h="100%">
            <TrainingItemHeader name={name} favorite={!!favorite} onClick={handleFavoriteToggle} />
            <TranslateText
              minH="8"
              overflow="auto"
              overflowWrap="break-word"
              textOverflow="ellipsis"
              mr="2"
              flex="1 0"
            />
            <TrainingItemFooter type={trainingType.name} time={moment.duration(duration).minutes()} />
          </Flex>
        </GridItem>
      </Grid>
    </GridItem>
  );
};
