import { OpenApiPaths } from './OpenApi';
import { RESTClient } from './RESTClient';
import { prepareURLParams } from './URLParams';

type OpenApiGetPaths<T extends OpenApiPaths<any>> = {
  [key in keyof T]: T[key] extends { get: any } ? key & string : never;
}[keyof T];

type OpenApiPostPaths<T extends OpenApiPaths<any>> = {
  [key in keyof T]: T[key] extends { post: any } ? key & string : never;
}[keyof T];

type OpenApiPutPaths<T extends OpenApiPaths<any>> = {
  [key in keyof T]: T[key] extends { put: any } ? key & string : never;
}[keyof T];

type OpenApiPatchPaths<T extends OpenApiPaths<any>> = {
  [key in keyof T]: T[key] extends { patch: any } ? key & string : never;
}[keyof T];

type OpenApiDeletePaths<T extends OpenApiPaths<any>> = {
  [key in keyof T]: T[key] extends { delete: any } ? key & string : never;
}[keyof T];

export class OpenApiRESTClient<TOpenApiPaths extends OpenApiPaths<any>> {
  constructor(private client: RESTClient) {}

  get<TPath extends OpenApiGetPaths<TOpenApiPaths>>(
    path: TPath,
    params?: TOpenApiPaths[TPath]['get']['parameters']['query'] & TOpenApiPaths[TPath]['get']['parameters']['path'],
  ) {
    return this.client.get<TOpenApiPaths[TPath]['get']['response']>(
      this.preparePath(path, params),
      prepareURLParams(params),
    );
  }

  post<TPath extends OpenApiPostPaths<TOpenApiPaths>>(
    path: TPath,
    body?: TOpenApiPaths[TPath]['post']['body'],
    params?: TOpenApiPaths[TPath]['post']['parameters']['query'] & TOpenApiPaths[TPath]['post']['parameters']['path'],
  ) {
    return this.client.post<TOpenApiPaths[TPath]['post']['response']>(
      this.preparePath(path, params),
      body,
      prepareURLParams(params),
    );
  }

  put<TPath extends OpenApiPutPaths<TOpenApiPaths>>(
    path: TPath,
    body?: TOpenApiPaths[TPath]['put']['body'],
    params?: TOpenApiPaths[TPath]['put']['parameters']['query'] & TOpenApiPaths[TPath]['put']['parameters']['path'],
  ) {
    return this.client.put<TOpenApiPaths[TPath]['put']['response']>(
      this.preparePath(path, params),
      body,
      prepareURLParams(params),
    );
  }

  patch<TPath extends OpenApiPatchPaths<TOpenApiPaths>>(
    path: TPath,
    body?: TOpenApiPaths[TPath]['patch']['body'],
    params?: TOpenApiPaths[TPath]['patch']['parameters']['query'] & TOpenApiPaths[TPath]['patch']['parameters']['path'],
  ) {
    return this.client.patch<TOpenApiPaths[TPath]['patch']['response']>(
      this.preparePath(path, params),
      body,
      prepareURLParams(params),
    );
  }

  delete<TPath extends OpenApiDeletePaths<TOpenApiPaths>>(
    path: TPath,
    body?: TOpenApiPaths[TPath]['delete']['body'],
    params?: TOpenApiPaths[TPath]['delete']['parameters']['query'] &
      TOpenApiPaths[TPath]['delete']['parameters']['path'],
  ) {
    return this.client.delete<TOpenApiPaths[TPath]['delete']['response']>(
      this.preparePath(path, params),
      body,
      prepareURLParams(params),
    );
  }

  private preparePath(path: string, params?: { [key: string]: any }) {
    return path.replace(/\{([a-zA-Z0-9-_]+)\}/g, (v, g1) => {
      if (!params) {
        throw new Error(`Path '${path}' requires parameters`);
      }

      return params[g1];
    });
  }
}
