import React from 'react';
import { Box, Button } from '@chakra-ui/react';

import { ShowPasswordIcon } from '../../components/icons';

import { CreateInput } from './CreateInput';

export const PasswordInputWithMask = ({
  name = 'password',
  onClick,
  uniqueErrorTxt,
  required = true,
  boxMt,
  pr,
}: {
  name?: string;
  onClick?: () => void;
  uniqueErrorTxt?: string;
  required?: boolean;
  pr?: string;
  boxMt?: string;
}) => {
  const [hidePasswordMask, setPasswordMask] = React.useState(true);

  return (
    <Box position="relative" mt={boxMt} w="full">
      <CreateInput
        name={name}
        pr={pr}
        margin={true}
        type={hidePasswordMask ? 'password' : 'text'}
        noHelpBox
        required={required}
        pwd
        onClick={onClick}
        uniqueErrorTxt={uniqueErrorTxt}
      />
      <Button
        onClick={() => setPasswordMask(prev => !prev)}
        position="absolute"
        bg="transparent"
        _hover={{ bg: 'transparent' }}
        _focus={{ bg: 'transparent' }}
        _focusVisible={{ outline: 'none' }}
        right={5}
        top="50%"
        transform="translate(44%,-10%)"
        zIndex="1"
        data-testid="button-changes-visiblity-password"
      >
        <ShowPasswordIcon showingPassword={hidePasswordMask} />
      </Button>
    </Box>
  );
};
