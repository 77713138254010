import { DeepReadonly, ExoSession } from '@egzotech/exo-session';
import { ElectrostimProgram, ExoElectrostimFeature, StimPhase } from '@egzotech/exo-session/features/electrostim';
import { Signal, signal } from 'helpers/signal';
import EMSCalibration from 'libs/exo-session-manager/core/common/EMSCalibration';

export class EMSFeatures {
  private _emsInstanceId: Signal<number | null> = signal(0, 'EMSFeatures._emsInstanceId');
  private _emsFeatureInstances: ExoElectrostimFeature[] = [];
  private _emsCalibrationInstances: EMSCalibration[] = [];

  private _numberOfInstances: number | null = null;

  onPhaseChange?: (phase: number, phaseData: DeepReadonly<StimPhase>) => void;
  onRepetitionChange?: (repetition: number) => void;
  onFinish?: () => void;
  onInterrupt?: () => void;

  get emsCalibration() {
    this.assertValidInstanceId(this._emsInstanceId.value);
    const emsCalibrationInstance = this._emsCalibrationInstances[this._emsInstanceId.value];
    if (!emsCalibrationInstance) {
      throw new Error('Calibration instance not found');
    }
    return emsCalibrationInstance;
  }

  get instanceId() {
    if (this._emsInstanceId.value === null) {
      throw new Error('Unknown instance id');
    }
    return this._emsInstanceId;
  }

  get combinedEmsCalibrationData() {
    if (this._numberOfInstances === null) {
      throw new Error('EMS instances are not initialized!');
    }
    const result = [];
    for (let instanceId = 0; instanceId < this._numberOfInstances; instanceId++) {
      result.push(this._emsCalibrationInstances[instanceId].data);
    }

    return result;
  }

  constructor(private _session: ExoSession) {}

  private assertValidInstanceId(value: number | null, requestedInstance?: number | null): asserts value is number {
    if (!Number.isInteger(value) || value === null) {
      throw new Error(`Assert failed, ${value} is not integer`);
    }
    if (value < 0 || value >= this._emsFeatureInstances.length) {
      throw new Error(`Assert failed, ${value} is exceed the range <0;${this._emsFeatureInstances.length - 1}>}`);
    }
    if (typeof requestedInstance === 'number' && this._emsInstanceId.value !== requestedInstance) {
      throw new Error(`Cannot handle callback for instace ${requestedInstance} while ${this._emsInstanceId} is active`);
    }
  }

  private handleOnPhaseChange(instance: number, phase: number, phaseData: DeepReadonly<StimPhase>) {
    this.assertValidInstanceId(this._emsInstanceId.value, instance);
    this.onPhaseChange?.(phase, phaseData);
  }

  private handleOnRepetitionChange(instance: number, repetition: number) {
    this.assertValidInstanceId(this._emsInstanceId.value, instance);
    this.onRepetitionChange?.(repetition);
  }

  private handleOnFinish(instance: number) {
    this.assertValidInstanceId(this._emsInstanceId.value, instance);
    this.onFinish?.();
  }

  private forActiveInstance(callback: (_: ExoElectrostimFeature) => unknown, onlySingleInstance = false) {
    if (typeof this._emsInstanceId.value === 'number') {
      return callback(this._emsFeatureInstances[this._emsInstanceId.value]);
    } else {
      if (!onlySingleInstance) {
        for (const instance of this._emsFeatureInstances) {
          callback(instance);
        }
      } else {
        throw new Error('Method is available only in single instance mode');
      }
    }
  }

  /**
   *
   * @param numberOfInstances number of independent ExoElectrostimFeatures instances
   */

  init(numberOfInstances: number) {
    this._numberOfInstances = numberOfInstances;
    for (let i = 0; i < numberOfInstances; i++) {
      this._emsFeatureInstances[i] = this._session.activate(ExoElectrostimFeature);
      this._emsCalibrationInstances[i] = new EMSCalibration();
      this._emsCalibrationInstances[i].setFeature(this._emsFeatureInstances[i]);

      this._emsFeatureInstances[i].onPhaseChange = (phase, phaseData) => this.handleOnPhaseChange(i, phase, phaseData);
      this._emsFeatureInstances[i].onRepetitionChange = repetition => this.handleOnRepetitionChange(i, repetition);
      this._emsFeatureInstances[i].onFinish = () => this.handleOnFinish(i);
    }
  }

  selectInstance(instanceId: number) {
    this.assertValidInstanceId(instanceId);
    this._emsInstanceId.value = instanceId;
  }

  setProgram(definition: DeepReadonly<ElectrostimProgram>) {
    Object.entries(this._emsFeatureInstances).forEach(([id, instance]) => {
      const onePhaseProgram = {
        ...definition,
        phases: [definition.phases[+id]],
        stimCalibration: [definition.stimCalibration[+id]],
      };
      instance.setProgram(onePhaseProgram);
    });
  }

  start() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      console.log('starting EMS', this._emsInstanceId.value);
      instance.start();
    });
  }

  stop() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      instance.stop();
    });
  }

  reset() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      instance.reset();
    });
  }

  dispose() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      instance.dispose();
    });
  }

  trigger() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      console.log('triggering EMS', this._emsInstanceId.value);
      instance.trigger();
    });
  }

  canTrigger() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      return instance.canTrigger();
    });
  }

  clearInterrupt() {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      instance.clearInterrupt();
    });
  }

  setChannelMapping(channelMapping: { [key: number]: number } | null) {
    this.forActiveInstance((instance: ExoElectrostimFeature) => {
      instance.setChannelMapping(channelMapping);
    });
  }
}
