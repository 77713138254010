import { FC } from 'react';
import { AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';
import { BiErrorAlt } from 'react-icons/bi';
import { BsCheck2 } from 'react-icons/bs';
import { Text, theme, VStack } from '@chakra-ui/react';
import colors from 'config/theme/colors';
import { __ } from 'helpers/i18n';
import Polyglot from 'node-polyglot';

import { UpdateIcon } from 'components/icons';

interface Props {
  title: string;
  subtitle?: string;
  replace?: Polyglot.InterpolationOptions;
}

export const Info: FC<Props> = ({ title, replace }) => (
  <>
    <AiOutlineInfoCircle size={theme.sizes['9']} color={colors.egzotechPrimaryColor} />
    <Text fontSize="lg" my="0" mx="4" color="egzotechPrimaryColor" flex="1 0">
      {__(title, replace)}
    </Text>
  </>
);

export const Error: FC<Props> = ({ title, replace }) => (
  <>
    <BiErrorAlt size={theme.sizes['9']} color={colors.errorColor} />
    <Text fontSize="lg" my="0" mx="4" color="errorColor" flex="1 0">
      {__(title, replace)}
    </Text>
  </>
);

export const Success: FC<Props> = ({ title, replace }) => (
  <>
    <BsCheck2 size={theme.sizes['9']} strokeWidth="0.5px" transform="scale(1, 1.2)" color={colors.successColor} />
    <Text fontSize="lg" my="0" mx="4" color="successColor" flex="1 0">
      {__(title, replace)}
    </Text>
  </>
);

export const Warning: FC<Props> = ({ title, replace }) => (
  <>
    <AiOutlineWarning size={theme.sizes['9']} color={colors.warningNotificationColor} />
    <Text fontSize="lg" my="0" mx="4" color={colors.warningNotificationColor} flex="1 0">
      {__(title, replace)}
    </Text>
  </>
);

export const Update: FC<Props> = ({ title, subtitle, replace }) => {
  return (
    <>
      <UpdateIcon boxSize={theme.sizes['12']} color={colors.egzotechPrimaryColor} />
      <VStack gap="0">
        <Text fontSize="lg" my="0" mx="4" color="egzotechPrimaryColor" whiteSpace="nowrap">
          {__(title, replace)}
        </Text>
        {subtitle && (
          <Text fontSize="lg" my="0" mx="4" color="egzotechPrimaryColor">
            {__(subtitle, replace)}
          </Text>
        )}
      </VStack>
    </>
  );
};
