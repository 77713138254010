import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

export type ReplayableSteps = Partial<
  Record<
    CalibrationFlowStateIdentifier,
    {
      skippable: boolean;
      dependant?: CalibrationFlowStateIdentifier;
      onlySkippableIfRepeated?: boolean;
    }
  >
>;

/**
 * List of the calibration flow steps, the values of which
 * can be restored from previous trainings.
 * Each step can be additionaly be skipped - see the 'skippable' flag
 */
export const replayableSteps: ReplayableSteps = {
  'meissa-basing-side-selection': {
    skippable: true,
  },
  'meissa-basing-range-of-movement': {
    skippable: false,
  },
  'meissa-basing-set-correct-position': {
    skippable: false,
  },
  'leg-basing-leg-side-selection': {
    skippable: true,
  },
  'meissa-spasticism-armrest': {
    skippable: true,
  },
  'leg-basing-set-thigh-length': {
    skippable: true,
  },
  'leg-basing-set-safe-range-knee': {
    skippable: true,
    // When move into range is dependant then it does not check its condition
    // and even if we are outside safe range it does not show the screen
    // dependant: 'leg-basing-move-into-range-conditional-knee-after-safe-range',
  },
  'leg-basing-set-safe-range-ankle': {
    skippable: true,
    // When move into range is dependant then it does not check its condition
    // and even if we are outside safe range it does not show the screen
    // dependant: 'leg-basing-move-into-range-conditional-ankle-after-safe-range',
  },
  'leg-basing-max-flexion-and-extension-measurement-passive-knee': {
    skippable: false,
  },
  'leg-basing-max-flexion-and-extension-measurement-passive-ankle': {
    skippable: false,
  },
  'leg-basing-adjust-foot-to-tip': {
    skippable: true,
  },
  'leg-basing-adjust-length-of-the-splint': {
    skippable: true,
  },
  'leg-basing-check-bend-points': {
    skippable: true,
  },
  'leg-basing-you-can-safely-place-leg': {
    skippable: true,
  },
  'game-select-control': {
    skippable: true, // this is not mistake, if you want to skip step when exercise is not repeated you have to also pass skippable
    onlySkippableIfRepeated: true,
  },
  'game-select-level': {
    skippable: true,
    onlySkippableIfRepeated: true,
  },
};
