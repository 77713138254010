import { useContext, useMemo } from 'react';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';

import { useEffectWithEmergencyButton } from './useEffectWithEmergencyButton';

/**
 * The emgSignal works properly if possibility of change maxValue is disabled after EMG Calibration
 * In order to achieve this effects, the emgSignal is disabled after EMG Calibration, and reenabled with changed parameters
 * in the exercise
 */

export const useEMGExerciseSignal = () => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  // const emg = useContextSelector(EMGContext, state => state.emgSignal);
  const channels = useContextSelector(
    ExoGlobalContext,
    state => state.device.selectedDevice?.channelsConnectionQuality,
  );

  const activeFeature = useMemo(() => {
    if (cpm) {
      return {
        ...cpm.emgSignal?.data,
        enable: (channels: Record<number, ChannelConnectionQuality>) => cpm.emgSignal?.enable(channels),
        disable: () => cpm.emgSignal?.disable(),
        setPortSlider: (port: number, slider: number) => cpm.emgSignal?.setPortSlider(port, slider),
      };
    }

    if (cpmEms) {
      return {
        ...cpmEms.emgSignal?.data,
        enable: (channels: Record<number, ChannelConnectionQuality>) => cpmEms.emgSignal?.enable(channels),
        disable: () => cpmEms.emgSignal?.disable(),
        setPortSlider: (port: number, slider: number) => cpmEms.emgSignal?.setPortSlider(port, slider),
      };
    }

    // if (emg) {
    //   return emg;
    // }
    throw new Error('useEMGExerciseSignal must be used within a Provider which requires EMG feature');
  }, [cpm, cpmEms]);

  useEffectWithEmergencyButton(() => {
    if (channels && activeFeature.calibrated?.value) {
      activeFeature.enable(channels);

      return () => {
        try {
          activeFeature.disable();
        } catch (err) {
          console.error(err);
        }
      };
    }
  }, [channels, activeFeature.calibrated?.value, activeFeature.disable, activeFeature.enable]);

  return activeFeature;
};
