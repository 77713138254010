import sizes from '../sizes';

import { parseStyleConfig } from './helpers';

const variants = ['modalWithKeyboard', 'hidden'] as const;

export type Variants = (typeof variants)[number];

const Modal = parseStyleConfig(variants, {
  variants: {
    modalWithKeyboard: () => ({
      dialogContainer: {
        h: `calc(100dvh - ${sizes.keyboardHeight})`,
        py: '2',
      },
    }),
    hidden: () => ({
      overlay: {
        display: 'none',
      },
      dialogContainer: {
        display: 'none',
      },
    }),
  },
});

export default Modal;
