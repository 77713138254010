import { useMemo } from 'react';
import {
  Center,
  Container,
  HStack,
  Image,
  ListItem,
  ModalBody,
  ModalContent,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import imageSrc from 'assets/images/ElectrostimWarning.svg';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

export const OvervoltageModal = ({ callback, close }: ModalProps) => {
  const actions = useMemo(() => {
    const actions: {
      text: string;
      action: () => void;
    }[] = [];
    actions.push({
      text: 'modals.overvoltage.approve',
      action: () => {
        callback?.();
        close();
      },
    });
    return actions;
  }, [callback, close]);

  return (
    <ModalContent maxW={{ base: '3xl', '2xl': '5xl' }} borderRadius="rLg" p="2">
      <ModalBody borderWidth="1" borderStyle="solid" borderColor="warningColor" borderRadius="rLg">
        <Container
          variant="modalBox"
          py="4"
          px="0"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
        >
          <VStack justify="space-between" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text={'modals.overvoltage.heading'} />
            </Center>
            <HStack textAlign="start">
              <Image src={imageSrc} maxH={{ base: '3xs', '2xl': 'sm' }} maxW={{ base: '3xs', '2xl': 'sm' }} mt="4" />
              <VStack gap="0" alignItems="flex-start">
                <TranslateText variant="openSans24" text={'modals.overvoltage.description'} mt="2" />
                <UnorderedList textAlign="start" mt="4">
                  <ListItem mb="2">
                    <TranslateText
                      variant="openSans24"
                      letterSpacing="0"
                      text={'modals.overvoltage.description-list-1'}
                    />
                  </ListItem>
                  <ListItem mb="2">
                    <TranslateText
                      variant="openSans24"
                      letterSpacing="0"
                      text={'modals.overvoltage.description-list-2'}
                    />
                  </ListItem>
                  <ListItem mb="2">
                    <TranslateText
                      variant="openSans24"
                      letterSpacing="0"
                      text={'modals.overvoltage.description-list-3'}
                    />
                  </ListItem>
                  <ListItem mb="2">
                    <TranslateText
                      variant="openSans24"
                      letterSpacing="0"
                      text={'modals.overvoltage.description-list-4'}
                    />
                  </ListItem>
                  <ListItem mb="2">
                    <TranslateText
                      variant="openSans24"
                      letterSpacing="0"
                      text={'modals.overvoltage.description-list-5'}
                    />
                  </ListItem>
                </UnorderedList>
                <TranslateText variant="openSans24Bold" text={'modals.overvoltage.description-2'} mt="4" />
              </VStack>
            </HStack>
            <HStack w="full" justifyContent="space-evenly" mt="6">
              {actions.map(item => (
                <MainButton
                  key={item.text}
                  text={item.text}
                  isDisabled={false}
                  variant="mdWarningButton"
                  onClick={item.action}
                  minW="32"
                />
              ))}
            </HStack>
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
