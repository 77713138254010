import { FC, ReactNode } from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';
import Polyglot from 'node-polyglot';

export interface MainHeadingProps extends HeadingProps {
  text?: string;
  children?: ReactNode;
  size?: string;
  lineHeight?: string;
  dataTest?: string;
  replace?: Polyglot.InterpolationOptions;
}

export const MainHeading: FC<MainHeadingProps> = ({ children, text, variant, size, lineHeight, replace, ...props }) => (
  <Heading {...props} variant={variant ? variant : 'heading'} fontSize={size} lineHeight={size ?? lineHeight}>
    {text ? __(text, replace) : ''}
    {children}
  </Heading>
);
