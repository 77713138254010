import { calculateSensitivity } from 'helpers/units';

import { GeneratedCAMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOtCAMExercises = (): { [key: string]: GeneratedCAMExerciseDefinition } => ({
  cam_isokinetic_classic_meissa: {
    type: 'cam-isokinetic',
    calibrationFlow: 'cam-isokinetic/meissa',
    presentation: 'time',
    tags: [],
    cam: {
      main: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 90,
              activeMovementDirection: 'both',
              trigger: true,
              coupling: {
                force: {
                  extension: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                  torque: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'torque',
                unit: 'none',
                values: ['torque', 'extension'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
  },
  cam_torque_classic_meissa: {
    type: 'cam-torque',
    calibrationFlow: 'cam-torque/meissa',
    presentation: 'time',
    tags: [],
    cam: {
      main: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 90,
              activeMovementDirection: 'both',
              trigger: true,
              coupling: {
                force: {
                  extension: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                  torque: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'torque',
                unit: 'none',
                values: ['torque', 'extension'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
  },
});
