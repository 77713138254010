import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MenuState {
  isOpened: boolean;
}

const initialState: MenuState = {
  isOpened: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setIsOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpened = payload;
    },
  },
});

export const { setIsOpened } = menuSlice.actions;

export default menuSlice.reducer;
