import { FC, ReactNode } from 'react';
import { TableCellProps, Td } from '@chakra-ui/react';

interface Props {
  textAlign?: boolean;
  children?: ReactNode;
  p?: TableCellProps['p'];
  px?: TableCellProps['px'];
  py?: TableCellProps['py'];
  width?: TableCellProps['width'];
}

export const TableItem: FC<Props> = ({ textAlign, children, p, px, py, width }) => (
  <Td p={p ?? '1'} px={px} py={py} textAlign={textAlign ? 'left' : 'center'} fontSize="lg" width={width}>
    {children}
  </Td>
);
