export const getSearchFromString = (inputValue: string) => {
  const valuesArray = inputValue.split(' ');
  const lastElementIndex = valuesArray.length - 1;
  const filteredArray = valuesArray.filter((value, index) => {
    if (index === lastElementIndex) {
      return true;
    }

    return value !== '';
  });

  return filteredArray;
};

export const getStringFromSearch = (search: string) => search.replaceAll(',', ' ');
