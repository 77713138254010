import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Center, Container, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import incompatibleErrorImage from 'assets/images/firmware-incompatible-module-error.svg';
import unexpectedErrorImage from 'assets/images/firmware-one-module-error.svg';
import { useAppNavigate } from 'hooks/useAppNavigate';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { useFirmwareVersion } from '../../hooks/useFirmwareVersion';

import { ModalProps } from './Modal';

export const FirmwareModulesHardErrorModal = ({ id, close }: ModalProps) => {
  const [missingModules] = useState(useFirmwareVersion().missingModules);
  const isMissing = !!missingModules[0];

  const navigate = useAppNavigate();
  const location = useLocation();

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="2">
      <ModalBody borderWidth="1" borderStyle="solid" borderColor="errorColor" borderRadius="rLg">
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          pb="4"
          pt="0"
        >
          <VStack justify="space-between" gap="5" flex="1 0">
            {id ? (
              <Center left="0" right="0">
                <TranslateText variant="openSans16Bold" color="gray.300">
                  {id}
                </TranslateText>
              </Center>
            ) : null}
            <Center>
              <MainHeading
                variant="subheading36"
                text={`modals.firmwareModule.${isMissing ? 'lost' : 'incombatible'}.heading`}
                mt={id ? -6 : 0}
              />
            </Center>
            <VStack gap="0" pb="5">
              <Image
                src={isMissing ? unexpectedErrorImage : incompatibleErrorImage}
                maxH={{ base: '32', '2xl': '2xs' }}
              />
              <TranslateText
                variant="openSans24"
                mt={isMissing ? '' : '6'}
                text={`modals.firmwareModule.${isMissing ? 'lost' : 'incombatible'}.description`}
              />
              <TranslateText variant="openSans24" fontWeight="bold" text={`modals.firmwareModule.lost.contactDesc`} />
            </VStack>
            <MainButton
              text="modals.firmwareModule.lost.contactBtn"
              variant="mdErrorButton"
              onClick={() => {
                close();
                navigate(location.pathname.includes('/login') ? `/help?id=${id}` : `/specialist/help?id=${id}`);
              }}
              minW="32"
            />
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
