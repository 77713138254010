import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function emgStepsFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'emg-steps': {
      prev,
      next,
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode'],
    },
  } as const;
}
