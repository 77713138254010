import { GeneratedCPMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOtCPMEMSEMGExercises = (): { [key: string]: GeneratedCPMExerciseDefinition } => ({
  cpm_ems_emg_classic_meissa: {
    type: 'cpm-ems-emg',
    calibrationFlow: 'cpm-ems-emg/meissa',
    presentation: 'repetition',
    tags: ['emg', 'ems'],
    cpm: {
      main: {
        program: {
          phases: [
            {
              repetitions: 10,
              speed: 90,
              trigger: 'direction-change',
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            blockAfterStart: false,
          },
        },
      },
    },
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
              blockAfterStart: false,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: false,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: false,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
});
