import sizes from '../sizes';

import { parseStyleConfig } from './helpers';

const disabledProps = {
  _disabled: {
    backgroundColor: 'gray.300',
    opacity: 1,
    color: 'gray.500',
    border: 'none',
  },
  _hover: {
    opacity: 0.8,
    _disabled: {
      opacity: 1,
      backgroundColor: 'gray.300',
      transform: 'none',
    },
  },
};

const disabledOutlineProps = {
  _disabled: {
    opacity: 1,
    color: 'gray.500',
    borderColor: 'gray.500',
  },
};

const smBaseButtonStyles = {
  display: 'flex',
  borderRadius: 'rMd',
  px: '5',
  py: '2.5',
  minWidth: '28',
  height: '12',
  _focus: {
    boxShadow: 'none',
  },
};
const mdBaseButtonStyles = {
  display: 'flex',
  borderRadius: 'rMd',
  px: '5',
  py: { base: '2.5', '2xl': '3' },
  minWidth: { base: '28', '2xl': '40' },
  height: { base: '12', '2xl': '16' },
  fontSize: { base: 'xl', '2xl': '2xl' },
  _focus: {
    boxShadow: 'none',
  },
};

export const variants = [
  'smPrimaryButton',
  'smWarningButton',
  'smErrorButton',
  'smPrimaryLightButton',
  'smOutlinedPrimaryButton',
  'smOutlinedErrorButton',
  'smOutlinedTransparentButton',
  'smOutlinedLightTransparentButton',
  'mdTextButton',
  'mdPrimaryButton',
  'mdErrorButton',
  'mdWarningButton',
  'mdOutlinedPrimaryButton',
  'mdOutlinedErrorButton',
  'mdLightTransparentButton',
  'mdOutlinedLightTransparentButton',
  'lightTextButton',
  'grayTextButton',
  'inactiveTextButton',
  'activeTextButton',
  'iconButton',
  'lightIconButton',
  'flatIconButton',
  'googleButton',
  'facebookButton',
  'appleButton',
  'systemComponentsBtn',
  'headerBackBtn',
  'mobileBackBtn',
  'exerciseButton',
] as const;

export type Variants = (typeof variants)[number];

const Button = parseStyleConfig(variants, {
  baseStyle: {},
  variants: {
    // standard buttons
    smPrimaryButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'egzotechPrimaryColor',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'white',
      ...disabledProps,
    }),
    smWarningButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'warningColor',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'textColor',
      ...disabledProps,
    }),
    smErrorButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'errorColor',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'white',
      ...disabledProps,
    }),
    smPrimaryLightButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'white',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'egzotechPrimaryColor',
      _focus: {
        boxShadow: 'sm',
      },
      ...disabledProps,
    }),

    smOutlinedPrimaryButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'white',
      borderWidth: '0.5',
      borderColor: 'egzotechPrimaryColor',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'egzotechPrimaryColor',
    }),
    smOutlinedErrorButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'white',
      borderWidth: '0.5',
      borderColor: 'errorColor',
      shadow: 'smDark',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'errorColor',
      ...disabledProps,
    }),
    smOutlinedTransparentButton: () => ({
      ...smBaseButtonStyles,
      backgroundColor: 'transparent',
      borderWidth: '0.5',
      borderColor: 'egzotechPrimaryColor',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'egzotechPrimaryColor',
    }),
    smOutlinedLightTransparentButton: () => ({
      ...smBaseButtonStyles,
      fontFamily: 'opensans-semibold',
      backgroundColor: 'transparent',
      borderWidth: '0.5',
      borderColor: 'white',
      fontSize: 'xl',
      fontWeight: '600',
      color: 'white',
      _hover: {
        backgroundColor: 'whiteAlpha.400',
      },
    }),
    mdPrimaryButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'egzotechPrimaryColor',
      shadow: 'smDark',
      fontWeight: 'normal',
      color: 'white',
      ...disabledProps,
    }),
    mdErrorButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'errorColor',
      shadow: 'smDark',
      fontWeight: 'normal',
      color: 'white',
      ...disabledProps,
    }),
    mdWarningButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'warningColor',
      shadow: 'smDark',
      fontWeight: 'normal',
      color: 'textColor',
      ...disabledProps,
    }),
    mdOutlinedPrimaryButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'white',
      borderWidth: '0.5',
      borderColor: 'egzotechPrimaryColor',
      fontWeight: 'normal',
      color: 'egzotechPrimaryColor',
      ...disabledProps,
    }),
    mdTextButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'white',
      fontWeight: 'normal',
      textDecorationLine: 'underline',
      color: 'egzotechPrimaryColor',
      ...disabledProps,
    }),
    mdOutlinedErrorButton: () => ({
      ...mdBaseButtonStyles,
      backgroundColor: 'white',
      borderWidth: '0.5',
      borderColor: 'errorColor',
      shadow: 'smDark',
      fontWeight: 'normal',
      color: 'errorColor',
      ...disabledOutlineProps,
    }),
    mdLightTransparentButton: () => ({
      ...mdBaseButtonStyles,
      fontFamily: 'opensans-semibold',
      backgroundColor: 'transparent',
      borderWidth: '0px',
      borderColor: 'white',
      fontWeight: '600',
      color: 'white',
      _hover: {
        backgroundColor: 'whiteAlpha.400',
      },
    }),
    mdOutlinedLightTransparentButton: () => ({
      ...mdBaseButtonStyles,
      fontFamily: 'opensans-semibold',
      backgroundColor: 'transparent',
      borderWidth: '0.5',
      borderColor: 'white',
      fontWeight: '600',
      color: 'white',
      _hover: {
        backgroundColor: 'whiteAlpha.400',
      },
    }),

    // text buttons
    lightTextButton: () => ({
      display: 'inline-flex',
      backgroundColor: 'transparent',
      borderWidth: '0',
      borderColor: 'white',
      borderRadius: 'rMd',
      fontSize: 'xl',
      fontWeight: 'normal',
      color: 'white',
      padding: 0,
      width: 'auto',
      height: 'auto',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'whiteAlpha.400',
      },
    }),
    grayTextButton: () => ({
      color: 'gray.300',
      display: 'flex',
      width: 'unset',
      gap: '2',
      fontSize: 'md',
      fontWeight: '400',
      p: '0',
      m: '0',
      alignItems: 'center',
      _hover: {
        cursor: 'pointer',
        color: 'gray.500',
      },
    }),
    inactiveTextButton: () => ({
      h: 'auto',
      fontSize: ['md', 'lg', null, 'xl'],
      lineHeight: '27px',
      fontWeight: 'bold',
      color: 'gray.500',
      _focus: {
        boxShadow: 'none',
      },
    }),
    activeTextButton: () => ({
      h: 'auto',
      fontSize: ['md', 'lg', null, 'xl'],
      lineHeight: '27px',
      bg: 'white',
      color: 'egzotechPrimaryColor',
      shadow: 'sm',
      _focus: {
        shadow: 'sm',
      },
    }),

    // icon buttons
    iconButton: () => ({
      display: 'inline-flex',
      bg: 'egzotechPrimaryColor',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'md',
      shadow: 'smDark',
      minW: 'initial',
      w: '25px',
      h: '25px',
      p: 0,
      borderRadius: 'oval',
      _focus: {
        shadow: 'smDark',
      },
      _active: {
        shadow: 'smDark',
      },
      _hover: {
        opacity: '0.8',
        _disabled: {
          opacity: 1,
        },
      },
    }),
    lightIconButton: () => ({
      display: 'inline-flex',
      bg: 'white',
      color: 'egzotechPrimaryColor',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'md',
      shadow: 'smDark',
      minW: 'initial',
      w: '25px',
      h: '25px',
      p: 0,
      borderRadius: 'oval',
      _focus: {
        shadow: 'smDark',
      },
      _active: {
        shadow: 'smDark',
      },
      _hover: {
        bg: 'gray.50',
        boxShadow: '0px 2px 10px #0000002A',
        _disabled: {
          bg: 'unset',
          boxShadow: 'unset',
        },
      },
    }),
    flatIconButton: () => ({
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'md',
      minW: 'initial',
      w: '25px',
      h: '25px',
      p: 0,
      borderRadius: 'oval',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'whiteAlpha.400',
      },
    }),

    exerciseButton: () => ({
      position: 'relative',
      width: { base: '24', '2xl': '36' },
      height: { base: '24', '2xl': '36' },
      backgroundColor: 'egzotechPrimaryColor',
      borderRadius: 'xl',
      fontSize: { base: '3xl', '2xl': '5xl' },
      _before: {
        position: 'absolute',
        content: '""',
        width: 'full',
        height: 'full',
        transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)',
        backgroundColor: 'transparent',
      },
      ...disabledProps,
    }),

    // social media buttons
    googleButton: () => ({
      fontSize: ['md', 'lg', null, 'xl'],
      fontWeight: 'normal',
      borderWidth: '1px',
      borderColor: 'gray.300',
      borderRadius: 'rSm',
      h: '12',
      p: '0px',
      textAlign: 'center',
      alignSelf: 'center',
      width: '100%',
      backgroundColor: 'white',
      color: 'headerTextColor',
      _focus: {
        boxShadow: 'none',
      },
    }),
    facebookButton: () => ({
      fontSize: ['md', 'lg', null, 'xl'],
      h: '12',
      p: '0px',
      borderRadius: 'rSm',
      borderWidth: '1px',
      borderColor: 'facebook.400',
      textAlign: 'center',
      fontWeight: 'normal',
      alignSelf: 'center',
      width: '100%',
      backgroundColor: 'facebook.400',
      color: 'white',
      _focus: {
        boxShadow: 'none',
      },
    }),
    appleButton: () => ({
      fontSize: ['md', 'lg', null, 'xl'],
      h: '12',
      p: '0px',
      borderRadius: 'rSm',
      borderWidth: '1px',
      borderColor: 'appleColor',
      fontWeight: 'normal',
      textAlign: 'center',
      alignSelf: 'center',
      width: '100%',
      backgroundColor: 'appleColor',
      color: 'white',
      _focus: {
        boxShadow: 'none',
      },
    }),
    systemComponentsBtn: () => ({
      width: { base: '20', '2xl': '32' },
      height: { base: '20', '2xl': '32' },
      borderRadius: 'full',
      _focus: { boxShadow: 'none' },
    }),

    // OLD BUTTONS, DO NOT USE OR CREATE MORE
    headerBackBtn: () => ({
      background: 'whiteAlpha.200',
      color: 'white',
      borderRadius: 'rMd',
      p: '2',
      boxSize: '20',
      fontSize: '64px',
      fontWeight: 'bold',
      transition: '250ms ease-in-out',
      _hover: {
        background: 'whiteAlpha.400',
      },
    }),
    mobileBackBtn: () => ({
      alignSelf: 'flex-start',
      background: 'whiteAlpha.200',
      display: ['flex', null, null, 'none'],
      boxShadow: '0px 0px 20px #00000005',
      borderRadius: 'rSm',
      fontSize: '3xl',
      color: 'white',
      padding: '6',
      fontWeight: 'hairline',
      margin: '2',
      width: sizes.navbarHeight,
    }),
  },
});

export default Button;
