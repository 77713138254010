import { substituteRuntimeEnvironment } from 'helpers/substituteRuntimeEnvironment';

const OVERRIDE_API_URL = substituteRuntimeEnvironment('::@@{ENVIRONMENT_SUBSTITUTION:OVERRIDE_API_URL}');

export const API_URL =
  OVERRIDE_API_URL ??
  (typeof process.env.REACT_APP_API_URL === 'string'
    ? process.env.REACT_APP_API_URL.startsWith('/')
      ? `${location.origin}${process.env.REACT_APP_API_URL.trim()}/`
      : `${process.env.REACT_APP_API_URL.trim()}/`
    : 'http://localhost:3000/api/');

export const SOCIAL_AUTHORIZATION = API_URL + 'oauth2/authorization/';
