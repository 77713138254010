import {
  maxDeviceRangeAnkle,
  maxDeviceRangeKnee,
  minDeviceRangeAnkle,
  minDeviceRangeKnee,
} from 'config/defaultConfigProps';
import { CalibrationFlow } from 'libs/exo-session-manager/core';

export const getAlteredRangeForMotor = (flow: CalibrationFlow, motor: 'knee' | 'ankle') => {
  switch (motor) {
    case 'ankle':
      return {
        min: minDeviceRangeAnkle,
        max: maxDeviceRangeAnkle,
      };
    case 'knee': {
      const thighLength = flow.getStateData('leg-basing-set-thigh-length')?.thighLength;
      return {
        min: minDeviceRangeKnee + (thighLength === 49 ? 5 : 0),
        max: maxDeviceRangeKnee,
      };
    }
  }
};
