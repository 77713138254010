import React, { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import { fadeinAnimation } from 'helpers/skeletonFadeInAnimation';
import { useDevice } from 'libs/exo-session-manager/react';
import { useGetRecentTrainingsQuery } from 'queries/trainings';

import { InfiniteScrollBox } from 'components/common/InfiniteScrollBox';
import RecentTrainingSkeleton from 'components/skeletons/trainings/RecentTrainingSkeleton';

import { RecentTrainingHeader } from './_components/RecentTrainingHeader';
import { RecentTrainingItem } from './_components/RecentTrainingItem';

const RecentTraining = () => {
  const { patientId } = useParams();
  const { selectedDevice } = useDevice();
  const [page, nextPage] = useReducer(x => x + 1, 0);
  const { currentData, isFetching } = useGetRecentTrainingsQuery({
    path: { userProfileId: patientId ?? '' },
    query: { pageNumber: page, pageSize: 20 },
  });

  const handleLoadMore = () => {
    if (!currentData?.last && currentData?.pageable?.pageNumber === page) {
      nextPage();
    }
  };

  return (
    <InfiniteScrollBox
      CustomLoadMoreElement={() =>
        !currentData?.last ? (
          <Table mt={2} gap={2}>
            <Tbody>
              <RecentTrainingSkeleton />
              <RecentTrainingSkeleton />
            </Tbody>
          </Table>
        ) : null
      }
      loadMore={handleLoadMore}
      hasMore={!currentData?.last}
      loading={isFetching}
      px="0"
      py="0"
    >
      <>
        <TableContainer bg="white" borderRadius="rMd" shadow="sm" overflowY="auto">
          <Table variant="simple">
            <RecentTrainingHeader />
            <Tbody fontSize="xl" sx={fadeinAnimation}>
              {currentData?.content?.map(item => {
                if (item.trainingResult === 'done' || item.trainingResult === 'catchedUp') {
                  return (
                    <RecentTrainingItem
                      key={item.id}
                      training={item}
                      deviceType={selectedDevice?.type ?? 'unspecified'}
                    />
                  );
                }
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    </InfiniteScrollBox>
  );
};

export default RecentTraining;
