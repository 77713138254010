import { useLocation } from 'react-router-dom';
import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  ModalBody,
  ModalContent,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import detachedModuleWhichCanBeFixedByUserImage from 'assets/images/firmware-module-error.svg';
import { useAppNavigate } from 'hooks/useAppNavigate';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { AcceptIcon, ExclamationMarkIcon } from '../../components/icons';
import colors from '../../config/theme/colors';
import { useFirmwareVersion } from '../../hooks/useFirmwareVersion';
import { SidraLegModuleId } from '../../libs/exo-session-manager/core/global/modules-configurations';

import { ModalProps } from './Modal';

const ModuleStatusImage = ({
  remoteModuleIsMissing,
  ankleModuleIsMissing,
}: {
  remoteModuleIsMissing?: boolean;
  ankleModuleIsMissing?: boolean;
}) => {
  const iconSize = useBreakpointValue({ base: '8', '2xl': '16' });
  return (
    <Box position="relative">
      {/*remote*/}
      <Box position="absolute" bottom="5%" left="8%">
        {remoteModuleIsMissing ? <ExclamationMarkIcon boxSize={iconSize} /> : <AcceptIcon boxSize={iconSize} />}
      </Box>
      {/*ankle:motor*/}
      <Box position="absolute" bottom="5%" right="8%">
        {ankleModuleIsMissing ? <ExclamationMarkIcon boxSize={iconSize} /> : <AcceptIcon boxSize={iconSize} />}
      </Box>
      <Image src={detachedModuleWhichCanBeFixedByUserImage} maxH={{ base: '32', '2xl': '2xs' }} />
    </Box>
  );
};

export const FirmwareModulesSoftErrorModal = ({ id, close }: ModalProps) => {
  const { current: firmwareModules, missingModules } = useFirmwareVersion();
  const isMissing = !!missingModules[0];

  const navigate = useAppNavigate();
  const location = useLocation();

  const isModuleMissing = (moduleId: SidraLegModuleId) =>
    firmwareModules.find(m => m?.moduleId === moduleId)?.isMissing;

  const remoteModuleIsMissing = isModuleMissing('remote');
  const ankleModuleIsMissing = isModuleMissing('motor:ankle') || isModuleMissing('sensor-force:ankle');

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="2">
      <ModalBody>
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          py="4"
        >
          <VStack justify="space-between" gap="5" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text={`modals.firmwareModule.lost.heading`} />
            </Center>
            <VStack gap="0" textAlign="center">
              <ModuleStatusImage
                remoteModuleIsMissing={remoteModuleIsMissing}
                ankleModuleIsMissing={ankleModuleIsMissing}
              />
              <TranslateText variant="openSans24">
                <TranslateText as="span" text={`modals.firmwareModule.lostButCanFix.description1`} />
                <TranslateText
                  as="span"
                  variant="openSans24Bold"
                  color={colors.errorColor}
                  text={`modals.firmwareModule.lostButCanFix.description2`}
                />
                <TranslateText
                  as="span"
                  variant="openSans24"
                  text={`modals.firmwareModule.lostButCanFix.description3`}
                />
              </TranslateText>
              <TranslateText
                mt={10}
                variant="openSans24Bold"
                fontWeight="bold"
                text={`modals.firmwareModule.lostButCanFix.description4`}
              />
            </VStack>
            <Flex width="100%" justifyContent="space-around">
              <MainButton
                text="modals.firmwareModule.lostButCanFix.contactBtn"
                variant="mdOutlinedPrimaryButton"
                onClick={() => {
                  close();
                  navigate(location.pathname.includes('/login') ? `/help?id=${id}` : `/specialist/help?id=${id}`);
                }}
                minW="32"
              />
              <MainButton
                text="modals.firmwareModule.lostButCanFix.next"
                variant="mdPrimaryButton"
                isDisabled={isMissing}
                onClick={() => {
                  close();
                }}
                minW="32"
              />
            </Flex>
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
