import { InterpolationOptions } from 'node-polyglot';

import { notification } from 'components/common/Notification';

export const successNotification = (title: string, replace?: InterpolationOptions) => {
  return notification(title, 'success', replace);
};

export const errorNotification = (title: string, replace?: InterpolationOptions) => {
  return notification(title, 'error', replace);
};

export const warningNotification = (title: string, replace?: InterpolationOptions) => {
  return notification(title, 'warning', replace);
};
