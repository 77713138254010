import { compareAsc, format, formatISO, getDayOfYear, isBefore, isEqual } from 'date-fns';

import CONFIG from '../config/app';

import { __ } from './i18n';

export const getToday = () => {
  return new Date();
};
export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getFirstDayInMonth = () => {
  const date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
};

export const changeDateToUTC = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getDateWithoutHours = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getDateFromBackEnd = (date: Date | string): Date => new Date(date);

export const getFullISOStringFromDate = (date: Date): string => date.toISOString();

export const getISOStringFromDate = (date: Date): string => formatISO(date, { representation: 'date' });

export const getISOStringFromTime = (date: Date): string => formatISO(date, { representation: 'time' });

export const formatDate = (
  date: Date | string | number,
  type:
    | 'date'
    | 'time'
    | 'day'
    | 'longDatePl'
    | 'month'
    | 'longDayFormat'
    | 'longDayYearsFormat'
    | 'presentationDateFormat'
    | 'timeAndDate',
) => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  try {
    switch (type) {
      case 'date':
        return format(newDate, CONFIG.DATE_FORMAT);
      case 'presentationDateFormat':
        return format(newDate, CONFIG.PRESENTATION_DATE_FORMAT);
      case 'longDatePl':
        return format(newDate, CONFIG.LONG_DATE_FORMAT);
      case 'time':
        return format(newDate, CONFIG.TIME_FORMAT);
      case 'day':
        return format(newDate, CONFIG.DAY_FORMAT);
      case 'month':
        return format(newDate, CONFIG.MONTH_FORMAT);
      case 'longDayFormat':
        return format(newDate, CONFIG.LONG_DAY_FORMAT);
      case 'longDayYearsFormat':
        return format(newDate, CONFIG.LONG_DAY_YEARS_FORMAT);
      case 'timeAndDate':
        return format(newDate, CONFIG.TIME_AND_DATE_FORMAT);
      default:
        return '';
    }
  } catch (e) {
    return '';
  }
};

export const compareWithCurrentDate = (date: string | Date) => {
  const currentDate = new Date().setHours(12, 0, 0, 0);
  const checkedDate = new Date(date).setHours(12, 0, 0, 0);
  const currentDateYear = new Date().getFullYear();
  const checkedDateYear = new Date(date).getFullYear();

  if (currentDateYear === checkedDateYear) {
    return isEqual(currentDate, checkedDate);
  }
};

export const compareDatesDays = (firstDate: string | Date, secondDate: string | Date) => {
  const preparedFirstDate = new Date(firstDate);
  const preparedSecondDate = new Date(secondDate);

  return (
    preparedFirstDate.getFullYear() === preparedSecondDate.getFullYear() &&
    isEqual(getDayOfYear(preparedFirstDate), getDayOfYear(preparedSecondDate))
  );
};

export const checkDateIsBeforeToday = (date: string | Date) =>
  !(isBefore(new Date(), new Date(date)) || compareWithCurrentDate(date));

export const compareDatesFull = (firstDate: string | Date, secondDate: string | Date) =>
  isEqual(new Date(firstDate), new Date(secondDate));

export const firstDateIsOlder = (firstDate: string | Date, secondDate: string | Date) =>
  compareAsc(new Date(firstDate), new Date(secondDate)) > 0;

export const checkDateIsNull = (date: string | Date) => {
  return compareDatesDays(date, new Date(0));
};

export const convertDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return [day, month, year].join('.');
};
export const generateYearsArray = (range = 120) => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - range))
    .fill('')
    .map((v, idx) => now - idx) as Array<number>;
};

export function formatSeconds(totalSeconds: number): string {
  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / 3600);

  const result = [];
  if (hours) {
    result.push(__('time.hour', { smart_count: hours }));
  }
  if (minutes) {
    result.push(__('time.min', { smart_count: minutes }));
  }
  if (seconds) {
    result.push(__('time.sec', { smart_count: seconds }));
  }

  return result.join(' ');
}
