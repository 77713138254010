import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

export const useDevice = () => {
  const device = useContextSelector(ExoGlobalContext, state => state.device);

  if (!device) {
    throw new Error('useDevice must be used within a ExoGlobalProvider');
  }

  return device;
};
