import { BATTERY_MAX, BATTERY_MIN } from '../constants';
import { ScatterDataPoint } from '../types';

import { EMGPortState } from './EMGSignal';

export function calculateProgramDisplayData(values: Float32Array, data: ScatterDataPoint[]) {
  const offset = data.length * 2; // data is received in pack of 100 values, offset defined the last ms from which we are incrementally adding each pack to existing array
  const newDataPoints = Array.from(values).map<ScatterDataPoint>((record, i) => ({
    x: offset + i * 2,
    y: record,
  }));
  newDataPoints.forEach(newDataPoint => data.push(newDataPoint));
}

export function calculateSliderRatio(x: number) {
  return Math.pow(x, 2) + 0.5 * x + 0.5;
}

export function calculatePortStateVoltage(
  portState: EMGPortState,
  programThresholdFactor: number,
  blockMaxValue = false,
) {
  portState.calculatedThresholdValue = portState.maxVoltage * portState.slider;
  if (portState.calculatedThresholdValue > portState.maxVoltage) {
    portState.calculatedThresholdValue = portState.maxVoltage;
  }

  if (portState.currentVoltage > portState.maxVoltage && !blockMaxValue) {
    portState.maxVoltage = portState.currentVoltage;
  }
  return portState;
}

export function calculateBatteryLevel(voltage: number) {
  const battery = {
    low: BATTERY_MIN,
    high: BATTERY_MAX,
    voltage,
  };
  const A = 100 / (battery.high - battery.low);
  const B = 0 - A * battery.low;

  return Math.min(Math.max(A * battery.voltage + B, 0), 100);
}
