import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/store';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { repeatTraining } from 'slices/trainingSlice';
import { ChakraSize } from 'types/chakra';

import { RoundButton } from '../../components/buttons/RoundButton';

interface RoundButtonWithTooltipProps {
  patientId: string;
  training: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'];
  type?: 'light';
  size?: ChakraSize;
  icon?: ReactNode;
  mr?: string;
  setLoading: (val: boolean) => void;
  isDisabled: boolean;
}

const RepeatTrainingButton = ({
  patientId,
  training,
  type,
  size,
  icon,
  mr,
  setLoading,
  isDisabled,
}: RoundButtonWithTooltipProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRepeatTraining = useCallback(async () => {
    if (isDisabled) {
      return;
    }
    if (!patientId) {
      throw new Error('Patient id is missing');
    }

    setLoading(true);

    dispatch(repeatTraining({ patientId, trainingId: training.id }))
      .then(({ payload }) => {
        navigate(`/${patientId}/training/${payload}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, isDisabled, navigate, patientId, setLoading, training.id]);

  return (
    <RoundButton
      type={type}
      size={size}
      icon={icon}
      mr={mr}
      onClick={handleRepeatTraining}
      isDisabled={isDisabled}
      testid="repeat-training-button"
    />
  );
};

export default RepeatTrainingButton;
