import { ReactElement } from 'react';
import { ButtonProps, IconButton, VStack } from '@chakra-ui/react';

import { LockedMotor, UnlockedMotor } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

export type IconID = 'blockMotor';

const iconSrc: Record<IconID, { clicked: ReactElement; unclicked: ReactElement }> = {
  blockMotor: {
    clicked: <LockedMotor boxSize="55%" />,
    unclicked: <UnlockedMotor boxSize="55%" />,
  },
};

interface SystemComponentsButtonProps extends ButtonProps {
  iconID: IconID;
  label?: string;
  buttonState?: boolean;
  onClick?: () => void;
}

export const SystemComponentsButton = ({
  iconID,
  label,
  buttonState = false,
  onClick,
  ...props
}: SystemComponentsButtonProps) => {
  const handlePress = () => {
    onClick?.();
  };

  return (
    <VStack w="20">
      <IconButton
        variant="systemComponentsBtn"
        aria-label="system-components-button"
        icon={buttonState ? iconSrc[iconID].clicked : iconSrc[iconID].unclicked}
        onClick={handlePress}
        backgroundColor={buttonState ? 'egzotechPrimaryColor' : 'white'}
        _hover={{ backgroundColor: buttonState ? 'egzotechPrimaryColor' : 'white' }}
        {...props}
      />
      {label && <TranslateText text={label} fontSize="md" color="gray.600" fontWeight="semibold" textAlign="center" />}
    </VStack>
  );
};
