import { RESTResponse } from './RESTClient';

export type Paged<T> = T & {
  size?: number;
  page?: number;
};

export type AllowStringArrayAsSeparatedString<T extends object> = {
  [key in keyof T]: Required<T>[key] extends string[] ? Exclude<T[key], string[]> | string : T[key];
};

export type WithOptionalProperty<T, TKey extends keyof T> = Omit<T, TKey> & Partial<T>;

export class ResponseError extends Error {
  constructor(readonly response: Response) {
    super('The request response has resulted in an error.');
  }
}

export class BackendApiBase {
  private errorHandlers: ((reason: unknown) => void)[];

  constructor() {
    this.errorHandlers = [];
  }

  addGlobalErrorHandler(handler: (reason: unknown) => void) {
    this.errorHandlers.push(handler);
  }

  protected async extractJsonBody<T>(response: Promise<RESTResponse<T>>) {
    return (await response).json().catch(reason => {
      this.handleError(reason);
      throw reason;
    });
  }

  protected handleError(reason: unknown) {
    for (let i = 0; i < this.errorHandlers.length; i++) {
      this.errorHandlers[i](reason);
    }
  }

  protected handleResponseError(response: Response) {
    if (!response.ok) {
      throw new ResponseError(response);
    }

    return response;
  }
}
