import { useEffect } from 'react';
// Cantonese sounds
import CantoneseContract from 'assets/sounds/cantonese/Contract.mp3';
import CantoneseContractAndHold from 'assets/sounds/cantonese/ContractAndHold.mp3';
import CantoneseQuickFlick from 'assets/sounds/cantonese/QuickFlick.mp3';
import CantoneseRelax from 'assets/sounds/cantonese/Relax.mp3';
// Code below could be uncommented when we add Czech lng to type Language
// Czech sounds
// import CsContract from 'assets/sounds/cs/Contract.mp3';
// import CsContractAndHold from 'assets/sounds/cs/ContractAndHold.mp3';
// import CsQuickFlick from 'assets/sounds/cs/QuickFlick.mp3';
// import CsRelax from 'assets/sounds/cs/Relax.mp3';
// Germany sounds
import DeContract from 'assets/sounds/de/Contract.mp3';
import DeContractAndHold from 'assets/sounds/de/ContractAndHold.mp3';
import DeQuickFlick from 'assets/sounds/de/QuickFlick.mp3';
import DeRelax from 'assets/sounds/de/Relax.mp3';
import EmergencyStop from 'assets/sounds/emergencyStop.wav';
// English sounds
import EnContract from 'assets/sounds/en/Contract.mp3';
import EnContractAndHold from 'assets/sounds/en/ContractAndHold.mp3';
import EnQuickFlick from 'assets/sounds/en/QuickFlick.mp3';
import EnRelax from 'assets/sounds/en/Relax.mp3';
// Mandarin sounds
import MandarinContract from 'assets/sounds/mandarin/Contract.mp3';
import MandarinContractAndHold from 'assets/sounds/mandarin/ContractAndHold.mp3';
import MandarinQuickFlick from 'assets/sounds/mandarin/QuickFlick.mp3';
import MandarinRelax from 'assets/sounds/mandarin/Relax.mp3';
// Polish sounds
import PlContract from 'assets/sounds/pl/Contract.mp3';
import PlContractAndHold from 'assets/sounds/pl/ContractAndHold.mp3';
import PlQuickFlick from 'assets/sounds/pl/QuickFlick.mp3';
import PlRelax from 'assets/sounds/pl/Relax.mp3';

// Code below could be uncommented when we add Ukraine lng to type Language
//Ukraine sounds
// import UkContract from 'assets/sounds/uk/Contract.mp3';
// import UkContractAndHold from 'assets/sounds/uk/ContractAndHold.mp3';
// import UkQuickFlick from 'assets/sounds/uk/QuickFlick.mp3';
// import UkRelax from 'assets/sounds/uk/Relax.mp3';
import { globalAudioCached } from '../globalSignals/globalAudioCached';
import { LocalStorageManager } from '../libs/exo-session-manager/core/global/LocalStorageManager';
import { Language } from '../types/language';

import { SoundsName } from './useSound';

const sounds: { [key in Language]: { [key in SoundsName]: string } } = {
  pl: {
    contract: PlContract,
    contractAndHold: PlContractAndHold,
    quickFlick: PlQuickFlick,
    relax: PlRelax,
    emergencyStop: EmergencyStop,
  },
  de: {
    contract: DeContract,
    contractAndHold: DeContractAndHold,
    quickFlick: DeQuickFlick,
    relax: DeRelax,
    emergencyStop: EmergencyStop,
  },
  en: {
    contract: EnContract,
    contractAndHold: EnContractAndHold,
    quickFlick: EnQuickFlick,
    relax: EnRelax,
    emergencyStop: EmergencyStop,
  },
  // Code below could be uncommented when we add Ukraine lng to type Language
  // uk: {
  //   contract: UkContract,
  //   contractAndHold: UkContractAndHold,
  //   quickFlick: UkQuickFlick,
  //   relax: UkRelax,
  //   emergencyStop: EmergencyStop,
  // },
  // Code below could be uncommented when we add Czech lng to type Language
  // cs: {
  //   contract: CsContract,
  //   contractAndHold: CsContractAndHold,
  //   quickFlick: CsQuickFlick,
  //   relax: CsRelax,
  //   emergencyStop: EmergencyStop,
  // },
  zh_Hans: {
    contract: MandarinContract,
    contractAndHold: MandarinContractAndHold,
    quickFlick: MandarinQuickFlick,
    relax: MandarinRelax,
    emergencyStop: EmergencyStop,
  },
  zh_Hant: {
    contract: CantoneseContract,
    contractAndHold: CantoneseContractAndHold,
    quickFlick: CantoneseQuickFlick,
    relax: CantoneseRelax,
    emergencyStop: EmergencyStop,
  },
};

export const usePreloadSounds = () => {
  const language = LocalStorageManager.payload.language.value ?? 'en';
  useEffect(() => {
    globalAudioCached.value = [];
    const currentSounds = sounds[language];
    if (currentSounds) {
      for (const [key, value] of Object.entries(currentSounds)) {
        const audio = new Audio(value);
        audio.load();
        globalAudioCached.value = [...globalAudioCached.peek(), { name: key as SoundsName, sound: audio }];
      }
    }
  }, [language]);
};
