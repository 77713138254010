import React, { useContext } from 'react';
import { ExoSession } from '@egzotech/exo-session';
import { useSignals } from '@preact/signals-react/runtime';
import { logger } from 'helpers/logger';
import {
  CalibrationFlowStatesTypedData,
  CPMElectrostimExercise,
  DeviceType,
  GeneratedCPMExerciseDefinition,
  isSpecificGeneratedExerciseDefinition,
} from 'libs/exo-session-manager/core';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

import { CPMElectrostimInstanceContext } from './CPMElectrostimInstanceContext';

export function CPMElectrostimProvider({
  children,
  errorContent,
  calibrationData = null,
}: {
  children: React.ReactNode;
  errorContent?: React.ReactNode;
  calibrationData?: CalibrationFlowStatesTypedData | null;
}) {
  const session = useContextSelector(ExoGlobalContext, state => state.device.session);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);
  const selectedProgramId = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgramId);
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);

  if (
    !session ||
    !(selectedDeviceType === 'sidra-leg' || selectedDeviceType === 'meissa-ot') ||
    !selectedProgram ||
    !isSpecificGeneratedExerciseDefinition(selectedProgram, ['cpm-ems', 'cpm-ems-emg'])
  ) {
    logger.error('CPMElectrostimProvider', 'Provider was used without selected device or with invalid program type');

    return <>{errorContent}</>;
  }

  return (
    <CPMElectrostimActiveProvider
      session={session}
      exerciseName={selectedProgramId}
      definition={selectedProgram}
      deviceType={selectedDeviceType}
      calibrationData={calibrationData}
    >
      {children}
    </CPMElectrostimActiveProvider>
  );
}

interface CPMActiveProviderProps {
  session: ExoSession;
  exerciseName: string | null;
  definition: GeneratedCPMExerciseDefinition;
  deviceType: DeviceType;
  children: React.ReactNode;
  calibrationData: CalibrationFlowStatesTypedData | null;
}

function CPMElectrostimActiveProvider({
  session,
  exerciseName,
  definition,
  deviceType,
  children,
  calibrationData,
}: CPMActiveProviderProps) {
  useSignals();
  const cpmElectrostimExercise = React.useMemo(
    () => new CPMElectrostimExercise(definition, exerciseName, session, deviceType, calibrationData),
    [exerciseName, session, definition, deviceType, calibrationData],
  );

  React.useEffect(() => {
    cpmElectrostimExercise.init();
    return () => {
      cpmElectrostimExercise.destroy();
    };
  }, [cpmElectrostimExercise]);

  if (!cpmElectrostimExercise.programCpmData.initialized.value) {
    return <></>;
  }

  return (
    <CPMElectrostimInstanceContext.Provider value={cpmElectrostimExercise}>
      {children}
    </CPMElectrostimInstanceContext.Provider>
  );
}
export const useCPMElectrostimExercise = () => {
  const cpmEms = useContext(CPMElectrostimInstanceContext);

  if (cpmEms) {
    return cpmEms;
  }

  throw new Error('useCPMElectrostimExercise must be used within a CPMElectostimProvider.');
};
