import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function gameFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'game-select-control': {
      disableAlerts: ['extensionDetached', 'cableDetached', 'disconnectedElectrode'],
      prev,
      next: 'game-select-level',
      stepIndex,
    },
    'game-select-level': {
      disableAlerts: ['extensionDetached', 'cableDetached', 'disconnectedElectrode'],
      prev: 'game-select-control',
      next,
      stepIndex: stepIndex + 1,
    },
  } as const;
}
