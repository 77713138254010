import Button from './button';
import Container from './container';
import Heading from './heading';
import FormLabel from './label';
import Modal from './modal';
import Progress from './progress';
import Switch from './switch';
import Text from './text';

const components = {
  Button,
  Container,
  Heading,
  Text,
  FormLabel,
  Switch,
  Progress,
  Modal,
};

export default components;
