import { parseStyleConfig } from './helpers';

const Progress = parseStyleConfig([], {
  baseStyle: {
    filledTrack: {
      bg: 'progressBarColor',
    },
  },
});

export default Progress;
