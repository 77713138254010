import { useContext } from 'react';
import { CPMExerciseData, CPMProgramDefinitionPrimary } from 'libs/exo-session-manager/core';
import { useEMGExerciseSignal } from 'libs/exo-session-manager/react';

import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';

export const useCPMExercise = () => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  useEMGExerciseSignal();

  if (cpm) {
    return {
      exerciseName: cpm.exerciseData.exerciseName,
      totalDuration: cpm.exerciseData.totalDuration,
      totalRepetition: cpm.exerciseData.totalRepetition,
      definition: cpm.definition,
      primaryProgramDefinition: structuredClone(cpm.primaryProgramDefinition) as CPMProgramDefinitionPrimary,
      recordings: cpm.recordings,
    } satisfies CPMExerciseData;
  }

  if (cpmEms) {
    return {
      ...cpmEms.exerciseData,
      exerciseName: cpmEms.exerciseData.exerciseName,
      totalDuration: cpmEms.exerciseData.totalDuration,
      totalRepetition: cpmEms.exerciseData.totalRepetition,
      definition: cpmEms.definition,
      primaryProgramDefinition: structuredClone(cpmEms.primaryProgramDefinition) as CPMProgramDefinitionPrimary,
      recordings: cpmEms.recordings,
    } satisfies CPMExerciseData;
  }

  throw new Error('useCPMExercise must be used within a Provider which requires CPM feature');
};
