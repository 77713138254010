import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';
import { objectEquals } from 'helpers/object';
import { mergePagedPayloads } from 'helpers/query';
import { ExoClinicBackendOpenApiPaths, ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export const trainingsApi = createApi({
  reducerPath: 'trainingsApi',
  baseQuery: fetchBaseQuery({
    fetchFn: queryFetchFn,
    baseUrl: API_URL,
  }),
  endpoints: builder => ({
    getTraining: builder.query<ExoClinicBackendOpenApiSchemas['TrainingResponseDto'], string>({
      query: id => `training/${id}`,
    }),
    getRecentTrainings: builder.query<
      ExoClinicBackendOpenApiPaths['/training/recent/{userProfileId}']['get']['response'],
      {
        path: ExoClinicBackendOpenApiPaths['/training/recent/{userProfileId}']['get']['parameters']['path'];
        query: ExoClinicBackendOpenApiPaths['/training/recent/{userProfileId}']['get']['parameters']['query'];
      }
    >({
      query: ({ path, query }) =>
        `training/recent/${path.userProfileId}?${new URLSearchParams({
          pageNumber: query.pageNumber?.toFixed(0) ?? '',
          pageSize: query.pageSize?.toFixed(0) ?? '',
          from: query.from ?? '',
          to: query.to ?? '',
          trainingDurationQuery: query.trainingDurationQuery ?? "$['exercise']['duration']",
        }).toString()}`,
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}/${queryArgs.path.userProfileId}/${queryArgs.query.from}/${queryArgs.query.to}`,
      merge: mergePagedPayloads,
      keepUnusedDataFor: 0,
      forceRefetch({ currentArg, previousArg }) {
        return !currentArg || !previousArg || !objectEquals(currentArg, previousArg);
      },
    }),
  }),
});

export const { useGetTrainingQuery, useGetRecentTrainingsQuery } = trainingsApi;
