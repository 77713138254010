import { useCallback, useContext } from 'react';
import { CAMBasingData } from 'libs/exo-session-manager/core';

import { CAMBasingActions } from '../contexts/CAMContext';
import { CAMInstanceContext } from '../providers/CAMInstanceContext';

type CAMBasingHook = {
  (): CAMBasingData & CAMBasingActions;
  (optional: true): Partial<CAMBasingData & CAMBasingActions>;
};

export const useCAMBasing = ((optional?: boolean) => {
  const cam = useContext(CAMInstanceContext);
  const tare = useCallback(() => {
    cam?.tare();
  }, [cam]);

  if (cam) {
    return {
      ...cam.basingData.value,
      tare,
    };
  }

  if (!optional) {
    throw new Error('useCAMBasing must be used within a Provider which requires CAM feature');
  }

  return {};
}) as CAMBasingHook;
