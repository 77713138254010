const styles = {
  global: {
    '::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '4px',
      height: '6px',
      backgroundColor: 'scrollbarBackgroundColor',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'scrollbarGutterColor',
      borderRadius: '4px',
    },
    html: {},
    body: {
      fontSize: 'md',
      color: 'textColor',
    },
    a: {
      _hover: {
        textDecoration: 'none !important',
      },
    },
    img: {
      fontSize: 'lg',
      overflow: 'hidden',
    },
  },
};

export default styles;
