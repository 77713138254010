import { FC, ReactElement } from 'react';
import { Container } from '@chakra-ui/react';

import { GridMobile } from './GridMobile';

interface Props {
  children: ReactElement;
  text: string;
}

export const FiltersWrapper: FC<Props> = ({ children }: Props) => {
  return (
    <Container variant="filterBox">
      <GridMobile>{children}</GridMobile>
    </Container>
  );
};
