/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEventHandler, TouchEventHandler, useState } from 'react';
import {
  DummyElectrostimFeatureController,
  DummyEmergencyButtonFeatureController,
  DummySensorForceFeatureController,
  isDummyModule,
  MeissaOTDummyControllerInternal,
  SidraLEGDummyControllerInternal,
  StellaBIODummyControllerInternal,
} from '@egzotech/exo-session';
import { useSignals } from '@preact/signals-react/runtime';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { LocalStorageManager } from 'libs/exo-session-manager/core/global/LocalStorageManager';
import { useSignalR } from 'libs/exo-session-manager/react/hooks/useSignalRConnection';

type DummyButtonType =
  | 'device-scanner'
  | 'module-checking'
  | 'extension-checking'
  | 'display-button'
  | 'emergency-button'
  | 'power-button'
  | 'pec-failing'
  | 'overvoltage-flag';

type DummyButtonDefinition = {
  enableText: string;
  disableText: string;
  colorMode: 'black' | 'green-red' | 'red-green';
};

const dummyButtons: Record<DummyButtonType, DummyButtonDefinition> = {
  'device-scanner': {
    enableText: 'Start scanner',
    disableText: 'Stop scanner',
    colorMode: 'black',
  },
  'module-checking': {
    enableText: 'Enable module checking',
    disableText: 'Disable module checking',
    colorMode: 'green-red',
  },
  'extension-checking': {
    enableText: 'Enable extension checking',
    disableText: 'Disable extension checking',
    colorMode: 'green-red',
  },
  'display-button': {
    enableText: 'Press display button',
    disableText: 'Release display button',
    colorMode: 'red-green',
  },
  'emergency-button': {
    enableText: 'Press emergency button',
    disableText: 'Release emergency button',
    colorMode: 'red-green',
  },
  'power-button': {
    enableText: 'Power On',
    disableText: 'Power Off',
    colorMode: 'green-red',
  },
  'pec-failing': {
    enableText: 'Set PEC Failing',
    disableText: 'Set PEC Passing ',
    colorMode: 'red-green',
  },
  'overvoltage-flag': {
    enableText: 'Send Overvoltage Flag',
    disableText: 'Send Overvoltage Flag',
    colorMode: 'black',
  },
};

const DummyButton = (
  props: DummyButtonDefinition & {
    isEnabled: boolean;
    'data-testid'?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onPress?: MouseEventHandler<HTMLButtonElement>;
    onRelease?: MouseEventHandler<HTMLButtonElement>;
    onTouchStart?: TouchEventHandler<HTMLButtonElement>;
    onTouchEnd?: TouchEventHandler<HTMLButtonElement>;
  },
) => {
  const { enableText, disableText, colorMode, isEnabled, onClick, onPress, onRelease, onTouchStart, onTouchEnd } =
    props;

  let currentColor = '';
  switch (colorMode) {
    case 'black':
      currentColor = 'black';
      break;
    case 'green-red':
      currentColor = isEnabled ? 'green' : 'red';
      break;
    case 'red-green':
      currentColor = isEnabled ? 'red' : 'green';
      break;
  }

  return (
    <button
      data-testid={props['data-testid']}
      style={{
        border: `1px solid ${currentColor}`,
        textTransform: 'uppercase',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '2px',
      }}
      onClick={onClick}
      onMouseDown={onPress}
      onMouseUp={onRelease}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {isEnabled ? disableText : enableText}
    </button>
  );
};
type DummyButtonProps = {
  deviceManager: DeviceManager;
};

const ModuleCheckingButton = ({ ..._ }: DummyButtonProps) => {
  const isEnabled = !LocalStorageManager.payload.disableModuleIdentificationOnLost.value;
  const onClick = () => {
    LocalStorageManager.payload.disableModuleIdentificationOnLost.value =
      !LocalStorageManager.payload.disableModuleIdentificationOnLost.peek();
  };
  return (
    <DummyButton
      isEnabled={isEnabled}
      onClick={onClick}
      {...dummyButtons['module-checking']}
      data-testid="developer-bar-detect-fw-modules"
    />
  );
};

const ExtensionLostButton = ({ ..._ }: DummyButtonProps) => {
  const isEnabled = !LocalStorageManager.payload.disableExtensionDetached.value;
  const onClick = () => {
    LocalStorageManager.payload.disableExtensionDetached.value =
      !LocalStorageManager.payload.disableExtensionDetached.peek();
  };
  return (
    <DummyButton
      isEnabled={isEnabled}
      onClick={onClick}
      {...dummyButtons['extension-checking']}
      data-testid="developer-bar-detect-extension"
    />
  );
};

const DeviceScannerButton = ({ scannerActive, ...props }: DummyButtonProps & { scannerActive: boolean }) => {
  const { deviceManager } = props;
  const isEnabled = scannerActive;
  const onClick = () => {
    if (scannerActive) {
      deviceManager.deactivate();
    } else {
      deviceManager.activate();
    }
  };
  return <DummyButton isEnabled={isEnabled} {...props} onClick={onClick} {...dummyButtons['device-scanner']} />;
};
const DummyEmergencyButton = ({
  module,
  ...props
}: DummyButtonProps & { module: DummyEmergencyButtonFeatureController }) => {
  const [isEnabled, setIsEnabled] = useState(module.isPressed);

  const onClick = () => {
    if (isEnabled) {
      module.releaseEmergencyButton();
      setIsEnabled(false);
    } else {
      module.pressEmergencyButton();
      setIsEnabled(true);
    }
  };

  const onPress = () => {
    module.pressEmergencyButton();
    setIsEnabled(true);
  };
  const onRelease = () => {
    module.releaseEmergencyButton();
    setIsEnabled(false);
  };

  if (module.isMomentary) {
    return (
      <DummyButton
        isEnabled={isEnabled}
        onPress={onPress}
        onRelease={onRelease}
        {...props}
        {...dummyButtons['emergency-button']}
      />
    );
  }

  return <DummyButton isEnabled={isEnabled} {...props} onClick={onClick} {...dummyButtons['emergency-button']} />;
};

const DummyOvervoltageFlag = ({
  module,
  ...props
}: DummyButtonProps & { module: DummyElectrostimFeatureController }) => {
  const onClick = () => {
    module.setOvervoltageFlag();
  };

  return <DummyButton isEnabled={true} onClick={onClick} {...props} {...dummyButtons['overvoltage-flag']} />;
};

const DummyPowerButton = ({
  module,
  ...props
}: DummyButtonProps & {
  module: SidraLEGDummyControllerInternal | MeissaOTDummyControllerInternal | StellaBIODummyControllerInternal;
}) => {
  const isPowered = module.isPowered();

  const onClick = () => {
    isPowered ? module.turnOff() : module.turnOn();
  };
  return <DummyButton isEnabled={isPowered} {...props} onClick={onClick} {...dummyButtons['power-button']} />;
};

const DummyDisplayButton = ({ ...props }: DummyButtonProps) => {
  useSignals();
  const { displayButton } = useSignalR();

  const onPress = () => {
    displayButton.value = 'Pressed';
  };
  const onRelease = () => {
    displayButton.value = 'Released';
  };

  return (
    <DummyButton
      isEnabled={displayButton.value === 'Pressed'}
      onPress={onPress}
      onTouchStart={onPress}
      onRelease={onRelease}
      onTouchEnd={onRelease}
      {...dummyButtons['display-button']}
      {...props}
    />
  );
};

interface DummyPECCalibratiobButtonProps extends DummyButtonProps {
  module: DummySensorForceFeatureController;
}

const DummyPECCalibrationButton = ({ module, ...props }: DummyPECCalibratiobButtonProps) => {
  useSignals();
  const isPECFailing = DeviceManager.makeDummyPECCalibrationFailing.value;

  const onClick = () => {
    DeviceManager.makeDummyPECCalibrationFailing.value = !DeviceManager.makeDummyPECCalibrationFailing.peek();
    if (DeviceManager.makeDummyPECCalibrationFailing.peek()) {
      module.setDummyPECStandardDeviationResult(0.7);
    } else {
      module.setDummyPECStandardDeviationResult(0.1);
    }
  };

  return <DummyButton isEnabled={isPECFailing} onClick={onClick} {...dummyButtons['pec-failing']} {...props} />;
};

export const DummyButtonList = (props: {
  deviceManager: DeviceManager;
  scannerActive: boolean;
  modules: readonly object[];
}) => {
  const { deviceManager, modules } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <DeviceScannerButton deviceManager={deviceManager} scannerActive={props.scannerActive} />
      {deviceManager.session?.features.includes('module-identification') && (
        <ModuleCheckingButton deviceManager={deviceManager} />
      )}
      {deviceManager.session?.features.includes('extension') && <ExtensionLostButton deviceManager={deviceManager} />}
      {modules.map((module, i) => {
        if (
          isDummyModule(module, 'sensor-force') &&
          deviceManager.session?.options['sensor-force']?.sensors[module.name]?.features.includes('pecCalibration')
        ) {
          return <DummyPECCalibrationButton key={i} deviceManager={deviceManager} module={module} />;
        }
        if (isDummyModule(module, 'emergency-button')) {
          return <DummyEmergencyButton key={i} deviceManager={deviceManager} module={module} />;
        }
        if (isDummyModule(module, 'electrostim')) {
          return <DummyOvervoltageFlag key={i} deviceManager={deviceManager} module={module} />;
        }
        if (
          module instanceof SidraLEGDummyControllerInternal ||
          module instanceof MeissaOTDummyControllerInternal ||
          module instanceof StellaBIODummyControllerInternal
        ) {
          return (
            <>
              <DummyDisplayButton deviceManager={deviceManager} />
              <DummyPowerButton key={i} deviceManager={deviceManager} module={module} />
            </>
          );
        }
      })}
    </div>
  );
};
