import React from 'react';
import { Box, Flex, Grid, GridItem, Skeleton, SkeletonCircle } from '@chakra-ui/react';

const TrainingSkeletonCard = () => {
  return (
    <GridItem bg="white" borderRadius="rSm" shadow="sm" zIndex={1}>
      <Grid templateColumns="min-content" gap="4" h="100%" gridRow="24">
        <GridItem gridColumn={1}>
          <Box w="20" ml="5" mt="5">
            <SkeletonCircle
              m="0 auto"
              startColor="skeletonStartColor"
              endColor="skeletonEndColor"
              width={20}
              height={20}
            />
          </Box>
        </GridItem>
        <GridItem gridColumn={2} h="100%">
          <Flex direction="column" pt="5" pb="4" mr="5" h="100%">
            <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={8} width={40} />
            <Skeleton mt={2} startColor="skeletonStartColor" endColor="skeletonEndColor" height={8} maxWidth={96} />

            <Box mt={7} display="flex" flexDirection="row" justifyContent="space-between">
              <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={6} width={20} />
              <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={6} width={20} />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </GridItem>
  );
};
export default TrainingSkeletonCard;
