import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { TimeIcon } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

interface Props {
  type: string;
  time: string | number;
}

export const TrainingItemFooter: FC<Props> = ({ type, time }: Props) => (
  <Flex justify="space-between" align="flex-end" mt="4" mb="0" color="gray.500">
    <TranslateText text={`training.types.${type}`} />
    <Flex align="flex-end">
      <Flex whiteSpace="pre" align="flex-end">
        {time}
        <Text>{` `}</Text>
        <TranslateText text="training.min" />
      </Flex>
      <TimeIcon ml="2.5" fontSize="3xl" transform="translate(2px, 7px)" color="gray.300" />
    </Flex>
  </Flex>
);
