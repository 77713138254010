import { useEffect, useRef, useState } from 'react';
import { logger } from 'helpers/logger';

import { defaultSensorNameByDevice } from '../config/defaultConfigProps';
import { SensorForcePlugin } from '../libs/exo-session-manager/core/global/SensorForcePlugin';
import { useDevice } from '../libs/exo-session-manager/react';

export const useAutoTareMeissa = () => {
  const { selectedDevice, session, isTared, setDeviceAsTared } = useDevice();
  const sensorForcePluginRef = useRef<SensorForcePlugin | null>(null);
  const [allowAutoTare, setAllowAutoTare] = useState(false);

  useEffect(() => {
    if (!session) {
      setAllowAutoTare(false);
    } else {
      if (!session?.features.includes('sensor-force')) {
        return;
      }

      // Allow tare only after the initial device state has been received. Device sends extension
      // data in at least 1 second after it was connected, so we give oursevelves 3 seconds wait to be sure
      // that the messages have been received
      setTimeout(() => {
        setAllowAutoTare(true);
        logger.info('useAutoTareMeissa', 'Allowing for auto tarring');
      }, 3000);
    }
  }, [session]);

  useEffect(() => {
    if (allowAutoTare && session && !isTared && selectedDevice?.type === 'meissa-ot') {
      const extension = selectedDevice?.extensionType;

      sensorForcePluginRef.current = new SensorForcePlugin(session, [
        defaultSensorNameByDevice['meissa-ot'],
      ] as string[]);

      if (!extension) {
        sensorForcePluginRef.current.startTare();
        sensorForcePluginRef.current.onTareCompleted = () => {
          setDeviceAsTared();
        };
      }
    }

    return () => {
      sensorForcePluginRef.current?.dispose();
    };
  }, [
    allowAutoTare,
    isTared,
    selectedDevice,
    selectedDevice?.extensionType,
    selectedDevice?.type,
    session,
    setDeviceAsTared,
  ]);
};
