import { useEffect, useRef, useState } from 'react';
import { Flex, ModalBody, ModalContent, Spinner, VStack } from '@chakra-ui/react';
import colors from 'config/theme/colors';

import { CheckIcon } from 'components/icons';

import { MainButton } from '../../components/buttons/MainButton';
import { MainHeading } from '../../components/texts/MainHeading';
import { TranslateText } from '../../components/texts/TranslateText';
import { exoClinicApi } from '../../services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from '../../services/ExoClinicBackendOpenApi';

import { ModalProps } from './Modal';

const ImportDatabaseModal = ({ close }: ModalProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [importInProgress, setImportInProgress] = useState(false);
  const [status, setStatus] = useState<ExoClinicBackendOpenApiSchemas['ImportDatabaseStatusDto'] | ''>('');
  const [importSuccess, setImportSuccess] = useState(false);

  const restartDeviceAfterImport = async () => {
    await exoClinicApi.database.restartAfterImport();
  };

  useEffect(() => {
    const checkStatusOfImporting = async () => {
      try {
        const currentStatusOfImport = await exoClinicApi.database.importStatus();
        setStatus(currentStatusOfImport.status);
        const isFinished = currentStatusOfImport.status === 'finished';
        const isInProgress = ['decrypting', 'decompressing', 'creatingBackup', 'copying'].includes(
          currentStatusOfImport.status,
        );

        if (isFinished || !isInProgress) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          setImportInProgress(false);

          if (isFinished) {
            setImportSuccess(true);
            restartDeviceAfterImport();
          } else {
            setImportSuccess(false);
          }
        }
      } catch (err) {
        console.log(err);
        setImportInProgress(false);
        setStatus('error');
      }
    };

    const startImport = async () => {
      try {
        await exoClinicApi.database.startImport();
        checkStatusOfImporting();
        intervalRef.current = setInterval(checkStatusOfImporting, 2000);
      } catch (error) {
        setImportInProgress(false);
        checkStatusOfImporting();
      }
    };

    if (importInProgress) {
      startImport();
    }
  }, [importInProgress]);

  let currentErrorText;
  switch (status) {
    case 'cancelled':
    case 'error': {
      currentErrorText = 'importDatabase.modal.importErrorUnknownError';
      break;
    }
    case 'missingDrive': {
      currentErrorText = 'importDatabase.modal.importErrorUSBDriverUnplugged';
      break;
    }
    case 'missingFile': {
      currentErrorText = 'importDatabase.modal.importErrorDatabaseFileMissing';
      break;
    }
    default: {
      currentErrorText = '';
      break;
    }
  }

  return (
    <ModalContent minWidth={{ base: '37.5rem', '2xl': '43rem' }} borderRadius="rMd" py="8" px={4}>
      <ModalBody>
        <MainHeading variant="subheading36" text="importDatabase.modal.header" />

        {importSuccess ? (
          <Flex justifyContent="center" alignItems={'center'} my={16}>
            <CheckIcon
              bgColor="stellaBioPrimaryColor"
              color="white"
              borderRadius="full"
              p="1"
              fontWeight="bold"
              mr="2"
              width={7}
              height={7}
            />
            <TranslateText
              variant="openSans24Bold"
              text="importDatabase.modal.importSuccess"
              color={colors.stellaBioPrimaryColor}
            />
          </Flex>
        ) : currentErrorText === '' ? (
          <VStack my={12}>
            <TranslateText variant="openSans24" text="importDatabase.modal.description"></TranslateText>
          </VStack>
        ) : (
          <VStack mb={10} mt={16}>
            <TranslateText color={colors.errorColor} variant="openSans24Bold" text={currentErrorText} />
          </VStack>
        )}

        {importInProgress ? (
          <Flex width="100%" justifyContent="center" gap={5} alignItems="center">
            <Spinner thickness="1" speed="1s" emptyColor="white" color="egzotechPrimaryColor" boxSize="5" />
            <TranslateText
              color={colors.egzotechPrimaryColor}
              variant="openSans24"
              fontWeight="bold"
              as="span"
              text="importDatabase.modal.importInProgress"
            />
          </Flex>
        ) : importSuccess ? (
          <Flex width="100%" justifyContent="center" gap={5} alignItems="center">
            <Spinner thickness="1" speed="1s" emptyColor="white" color="egzotechPrimaryColor" boxSize="5" />
            <TranslateText
              color={colors.egzotechPrimaryColor}
              variant="openSans24"
              fontWeight="bold"
              as="span"
              text="importDatabase.modal.restartDevice"
            />
          </Flex>
        ) : currentErrorText === '' ? (
          <Flex justifyContent="space-between" px={5}>
            <MainButton
              type="button"
              text="importDatabase.modal.cancel"
              variant={{ base: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
              onClick={() => close()}
              minW={32}
            />
            <MainButton
              type="submit"
              text="importDatabase.modal.import"
              variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
              width={32}
              onClick={() => {
                setImportInProgress(true);
              }}
            />
          </Flex>
        ) : (
          <Flex justifyContent="center">
            <MainButton
              type="submit"
              text="importDatabase.modal.close"
              variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
              width={32}
              onClick={() => close()}
            />
          </Flex>
        )}
      </ModalBody>
    </ModalContent>
  );
};
export default ImportDatabaseModal;
