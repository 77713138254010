import { useCallback, useMemo } from 'react';
import { logger } from 'helpers/logger';
import EMGSignal from 'libs/exo-session-manager/core/common/EMGSignal';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

export const useEMGCalibration = (emgSignal: EMGSignal) => {
  const channels = useContextSelector(
    ExoGlobalContext,
    state => state.device.selectedDevice?.channelsConnectionQuality,
  );

  const start = useCallback(() => {
    if (!emgSignal) {
      throw new Error('activeFeatureRef is not set');
    }
    if (!channels) {
      logger.warn('useEMGCalibration', 'Channels are not mapped. Check if cable is connected to the device');
    }

    if (channels) {
      emgSignal.setCalibrated(false);
      emgSignal.enable(channels);
    }
  }, [channels, emgSignal]);

  const end = useCallback(() => {
    if (!emgSignal.data.enabled.peek()) {
      logger.debug('useEMGCalibration.end', 'EMG monitoring is disabled');
      return;
    }
    if (emgSignal.data.calibrated.peek()) {
      logger.info('useEMGCalibration.end', 'EMG calibration is already calibrated');
      return;
    }
    emgSignal.setCalibrated(true);
    emgSignal.disable();

    logger.info('useEMGCalibration.end', 'EMG Calibration ended');
  }, [emgSignal]);

  const activatePort = useMemo(() => emgSignal.activatePort.bind(emgSignal), [emgSignal]);
  const setPortSlider = useMemo(() => emgSignal.setPortSlider.bind(emgSignal), [emgSignal]);
  const resetMVC = useMemo(() => emgSignal.resetMVC.bind(emgSignal), [emgSignal]);

  return {
    active: !emgSignal.data.calibrated.value && emgSignal.data.enabled.value,
    portsStates: emgSignal.data.portsStates.map(v => v.value),
    programThresholdFactor: emgSignal.data.programThresholdFactor,
    start,
    end,
    activatePort,
    setPortSlider,
    resetMVC,
  };
};
