import { createContext } from 'react';
import { Signal } from 'helpers/signal';

import { ButtonStatus } from '../providers/SignalRProvider';

interface SignalRContextData {
  displayButton: Signal<ButtonStatus>;
}

export const SignalRContext = createContext<SignalRContextData | null>(null);
