import { ExoCPMFeature, MovementDirection } from '@egzotech/exo-session/features/cpm';
import { MovementType } from '@egzotech/exo-session/features/motor';

import { Trigger } from '../common/Trigger';
import { SensorChange, SensorsName } from '../types';

type TriggeringMethod = 'triggerAndRelease' | 'triggerAndHold' | undefined;

export class SpasticismPlugin {
  private _spasticismForceSource: SensorsName | null = null;
  private _maxBackwardForceLimit: number | null = 5;
  private _movementType: MovementType | undefined = undefined;
  private _currentDirection: MovementDirection = 'toStart';
  private _isSpasticismDetected = false;
  private _cpmPrimaryFeature: ExoCPMFeature | null = null;
  private _maxSpeed: number | null = null;
  private _spasticismMechanismEnabled: boolean | null = false;
  private _triggeringMethod: TriggeringMethod = undefined;
  private _trigger: Trigger | null = null;
  onStop?: () => void;
  onDetectSpasticism?: (reason: 'emergency' | 'spasticism') => void;
  onReleaseSpasticism?: () => void;
  get isTriggerMethodHold() {
    return this._triggeringMethod;
  }

  constructor(
    spasticismForceSource: SensorsName | undefined,
    maxBackwardForceLimit: number | undefined,
    cpmPrimaryFeature: ExoCPMFeature | null = null,
    maxSpeed: number | null = null,
    movementType: MovementType,
    triggeringMethod: TriggeringMethod,
  ) {
    this._spasticismForceSource = spasticismForceSource ?? null;
    this._maxBackwardForceLimit = maxBackwardForceLimit ?? null;
    this._cpmPrimaryFeature = cpmPrimaryFeature;
    this._maxSpeed = maxSpeed;
    this._movementType = movementType;
    this._triggeringMethod = triggeringMethod;
  }
  setCurrentDirection(direction: MovementDirection) {
    this._currentDirection = direction;
  }
  setMaxBackwardForceLimit(maxBackwardForceLimit: number) {
    this._maxBackwardForceLimit = maxBackwardForceLimit;
  }
  setSpasticismMechanismEnabled(spasticismMechanismEnabled: boolean) {
    this._spasticismMechanismEnabled = spasticismMechanismEnabled;
  }
  setTriggeringMethod(triggeringMethod: TriggeringMethod) {
    this._triggeringMethod = triggeringMethod;
  }
  setSpasticismTrigger(trigger: Trigger) {
    this._trigger = trigger;
  }

  handleSensorData(sensorData: SensorChange) {
    if (!this._spasticismForceSource || !this._maxBackwardForceLimit || !this._cpmPrimaryFeature || !this._maxSpeed) {
      return;
    }

    const currentSensorValue = sensorData[this._spasticismForceSource].current;
    const maxBackwardForceLimitReached =
      this._currentDirection === 'toStart'
        ? this._movementType === 'normal'
          ? currentSensorValue > this._maxBackwardForceLimit
          : currentSensorValue < -this._maxBackwardForceLimit
        : this._movementType === 'normal'
        ? currentSensorValue < -this._maxBackwardForceLimit
        : currentSensorValue > this._maxBackwardForceLimit;

    if (
      !this._spasticismMechanismEnabled &&
      this._cpmPrimaryFeature.currentTriggerStateType !== 'onEdge' &&
      !this._trigger?.isTriggered() &&
      this.isTriggerMethodHold
    ) {
      // This mechanism fixed problem in exercise with hold method trigger. When we have reached the end of the range then spasticism modal was shown because currentDirection changed straight despite the fact that the trigger has no fallen and this caused that spasticism mechanism did not work well.
      this.setSpasticismMechanismEnabled(true);
    }

    if (maxBackwardForceLimitReached && this._spasticismMechanismEnabled) {
      if (!this._isSpasticismDetected) {
        this._cpmPrimaryFeature.stop();
        this._isSpasticismDetected = true;
        if (this._cpmPrimaryFeature!.speedSetpoint < 0) {
          this._cpmPrimaryFeature?.motorFeature.setCoupling('force', this._spasticismForceSource, {
            positive: {
              sensitivity: 500,
              deadband: 0.2,
            },
            negative: {
              sensitivity: 500,
              deadband: 0.2,
            },
          });
        } else {
          this._cpmPrimaryFeature?.motorFeature.setCoupling('force', this._spasticismForceSource, {
            positive: {
              sensitivity: 500,
              deadband: 0.2,
            },
            negative: {
              sensitivity: 500,
              deadband: 0.2,
            },
          });
        }
        if (this._maxSpeed) {
          this._cpmPrimaryFeature?.motorFeature.setMaxSpeed(this._maxSpeed);
        } else {
          throw new Error('Speed is null');
        }
        this.onDetectSpasticism?.('spasticism');
      }
    } else {
      if (this._isSpasticismDetected) {
        this.onReleaseSpasticism?.();
        this._isSpasticismDetected = false;
      }
    }
  }
}
