import React from 'react';
import { Flex, Image, ModalContent } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import MeissaIllustration from 'assets/images/SpasticismModalIlustrationMeissa.svg';
import SidraIllustration from 'assets/images/SpasticismModalIlustrationSidra.svg';
import { ReadonlySignal, Signal } from 'helpers/signal';

import { MainButton } from '../../components/buttons/MainButton';
import { MainHeading } from '../../components/texts/MainHeading';
import { TranslateText } from '../../components/texts/TranslateText';
import colors from '../../config/theme/colors';
import { useDevice } from '../../libs/exo-session-manager/react';

import { ModalProps } from './Modal';

interface SpasticismModalProps extends ModalProps {
  spasticismMechanismActived?: ReadonlySignal<boolean>;
  spasticismModalActive?: Signal<boolean>;
}

const SpasticismModal = ({
  callback: onFinish,
  spasticismMechanismActived,
  spasticismModalActive,
  close,
}: SpasticismModalProps) => {
  useSignals();
  const { selectedDevice } = useDevice();

  if (!selectedDevice) {
    throw new Error('Device is not available');
  }

  return (
    <ModalContent borderRadius="rLg" minWidth="56rem">
      <Flex flexDirection="column" m={5} borderWidth={1} borderColor={colors.errorColor} borderRadius="rLg" p={8}>
        <MainHeading variant="heading36" text="modals.spasticism.heading" pb={5} />
        <Image my={10} src={selectedDevice.type === 'sidra-leg' ? SidraIllustration : MeissaIllustration} h={72} />
        <TranslateText variant="openSans24" text="modals.spasticism.description" />
        <Flex w="100%" justifyContent="space-around" mt={10}>
          <MainButton
            data-testid="spasticismModal-finish-training"
            type="button"
            text="modals.spasticism.finish"
            variant="mdOutlinedErrorButton"
            onClick={() => {
              close();
              if (spasticismModalActive) {
                spasticismModalActive.value = false;
              } else {
                throw new Error('additionalData.spasticismModalActive is undefined');
              }
              onFinish?.();
            }}
            minW={32}
          />
          <MainButton
            data-testid="spasticismModal-continue-training"
            type="submit"
            isDisabled={spasticismMechanismActived?.value}
            text="modals.spasticism.action"
            variant="mdErrorButton"
            onClick={() => {
              if (spasticismModalActive) {
                spasticismModalActive.value = false;
              } else {
                throw new Error('additionalData.spasticismModalActive is undefined');
              }
              close();
            }}
          />
        </Flex>
      </Flex>
    </ModalContent>
  );
};
export default SpasticismModal;
