import { memo } from 'react';
import colors from 'config/theme/colors';

interface Props {
  xOffset: number;
  yOffset: number;
  yHeight: number;
  level: number;
  width?: number;
}

export const SVGLevelBar = memo(({ xOffset, yOffset, level, yHeight, width = 196 }: Props) => {
  const maxHeight = yHeight - 4;

  const yPos = maxHeight + yOffset - maxHeight * (level / 100);

  return (
    <svg>
      <defs>
        <filter id="shadow" height="500%">
          <feDropShadow dx="0" dy="2" stdDeviation="3" floodOpacity="0.5" floodColor={colors.gray['300']} />
        </filter>
      </defs>
      <rect x={xOffset} y={yPos} width={width} height={4} filter={`url(#shadow)`} fill="white" />
    </svg>
  );
});

SVGLevelBar.displayName = 'SVGLevelBar';
