import { NavigateOptions, To } from 'react-router-dom';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * NavigationSlice is responsible for blocking navigation in the application.
 * If navigation is blocked, you cannot change the page using useNavigate,
 * and each attempt causes callback call
 */
export type NavigationSlice = {
  navigationDisabled: boolean;
  callback?: (to: To | number, options?: NavigateOptions) => void;
};

const initialState: NavigationSlice = {
  navigationDisabled: false,
};

const naviationSlice = createSlice({
  name: 'navigation',
  initialState,

  reducers: {
    enableNavigation: state => {
      state.navigationDisabled = false;
      state.callback = undefined;
    },

    disableNavigation: (state, { payload }: PayloadAction<NavigationSlice['callback']>) => {
      state.navigationDisabled = true;
      state.callback = payload;
    },
  },
});

export const { enableNavigation, disableNavigation } = naviationSlice.actions;

export default naviationSlice.reducer;
