import { ReactNode } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';

import { TranslateText } from 'components/texts/TranslateText';

export interface BasingSignalBarTextInfoProps {
  color: string;
  children: ReactNode;
  label: string;
  active: boolean;
  thresholdLevel: number;
  isThresholdDisabled?: boolean;
}

export const BasingSignalBarTextInfo = ({
  color,
  children,
  label,
  active,
  thresholdLevel,
  isThresholdDisabled,
}: BasingSignalBarTextInfoProps) => {
  return (
    <HStack mt="-2" width="full" justifyContent="center" gap="0" opacity={active ? 1 : 0}>
      <VStack flexBasis="42%" gap="0">
        <TranslateText variant="openSans24" color={color} text={label} />
        <Text variant="openSans24Bold" color={color} whiteSpace="nowrap">
          {children}
        </Text>
      </VStack>
      {!isThresholdDisabled && (
        <VStack flexBasis="42%" gap="0">
          <TranslateText variant="openSans24" color={color}>
            Threshold
          </TranslateText>

          <TranslateText
            variant="openSans24Bold"
            color={color}
            text="exercise.calibration.emg.percentageMVC"
            replace={{ value: thresholdLevel.toFixed(0) }}
          />
        </VStack>
      )}
    </HStack>
  );
};
