import { createContext, ReactElement, useContext } from 'react';

import { FormFieldContextType, FormFieldProviderType } from '../../types';

const FormFieldContext = createContext<FormFieldContextType | undefined>(undefined);

const FormFieldProvider = ({ children, value }: FormFieldProviderType): ReactElement => (
  <FormFieldContext.Provider value={value}>{children}</FormFieldContext.Provider>
);

const useFormFieldContext = (): FormFieldContextType => {
  const context = useContext(FormFieldContext);
  if (context === undefined) {
    throw new Error('useFormFieldContext must be used within a FormFieldProvider');
  }
  return context;
};

export { FormFieldProvider, useFormFieldContext };
