import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns';
import { getDateFromBackEnd, getToday } from 'helpers/formatDate';
import { handleError } from 'helpers/handleError';
import { __ } from 'helpers/i18n';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import {
  ChatMessageCreateDTO,
  ChatMessageFilterDTO,
  ChatMessageResponseDTO,
  ChatMessageType,
  ConversationParticipantDTO,
  ConversationResponseDTO,
  Page,
} from 'types';

export interface Draft {
  message: string;
  time: Date;
}
export interface Card extends ConversationResponseDTO {
  draft?: Draft;
}

export interface ChatConversation {
  conversationId: string;
  card: Card;
  messageList: ChatMessageResponseDTO[];
  isEndOfOlderMessages: boolean;
}

export interface ChatState {
  conversationList: ChatConversation[];
  nextConversationPage: number;
  isEndOfOlderConversation: boolean;
  inProgress: boolean;
}

const initialState: ChatState = {
  conversationList: [],
  nextConversationPage: 0,
  isEndOfOlderConversation: false,
  inProgress: false,
};

export interface getNewMessagesArgs extends ChatMessageFilterDTO {
  conversationId: string;
}

export interface GetConversationArgs {
  conversationId: string;
  errorCallback: () => void;
}
export interface AddConversationArgs {
  userProfileId: string;
  data: ChatMessageCreateDTO;
  successCallback: (conversationId: string) => void;
}

export interface SetDraftArgs {
  message: string;
  conversationId: string;
}

interface SendMessageArgs {
  conversationId: string;
  message: ChatMessageCreateDTO;
}

type MessageListResponse = {
  conversationId: string;
  page: Page<ChatMessageResponseDTO>;
};

type NewMessageListResponse = {
  beforeChatMessageCreateDate: boolean;
} & MessageListResponse;

const createConversationList = (conversations: ConversationResponseDTO[]): ChatConversation[] =>
  conversations.map(item => getChatConversationObject(item));

const getChatConversationObject = (conversation: ConversationResponseDTO): ChatConversation => {
  return {
    conversationId: conversation.id,
    card: prepareCard(conversation),
    messageList: [],
    isEndOfOlderMessages: false,
  };
};

const prepareCard = (conversation: ConversationResponseDTO): Card => {
  const lastChatMessage = prepareMessage(conversation.lastChatMessage);
  return { ...conversation, lastChatMessage };
};

const prepareMessage = (message: ChatMessageResponseDTO): ChatMessageResponseDTO => {
  const postDate = getDateFromBackEnd(message.postDate);
  return { ...message, postDate };
};

const prepareMessages = (messages: ChatMessageResponseDTO[]): ChatMessageResponseDTO[] =>
  messages.map(message => prepareMessage(message));

const getLastMessage = (messages: ChatMessageResponseDTO[]) => {
  const length = messages?.length;

  if (!length) {
    return;
  }

  return messages[length - 1];
};

const updateConversationList = (state: ChatState, conversation: ConversationResponseDTO) => {
  const conversationItem = getConversationFromList(state.conversationList, conversation.id);

  if (conversationItem) {
    const { card } = conversationItem;
    const storeDraft = card.draft;
    const storeLastChatMessage = card.lastChatMessage;
    const preparedCard = prepareCard(conversation);
    preparedCard.draft = storeDraft;

    if (storeLastChatMessage.id === preparedCard.lastChatMessage.id) {
      card.unreadMessages = !storeLastChatMessage.currentUserAuthor ? preparedCard.unreadMessages : 0;
      return;
    }
    conversationItem.card = preparedCard;
  } else {
    state.conversationList.push(getChatConversationObject(conversation));
    state.isEndOfOlderConversation = false;
  }
};

const sortConversationList = (conversationList: ChatConversation[]) => {
  conversationList.sort((b, a) => compareAsc(a.card.lastChatMessage.postDate, b.card.lastChatMessage.postDate));
};

const replaceConversation = (conversation: ChatConversation, newConversation: ConversationResponseDTO) => {
  const storeDraft = conversation.card.draft;
  conversation.conversationId = newConversation.id;
  conversation.isEndOfOlderMessages = false;
  conversation.card = newConversation;
  conversation.card.draft = storeDraft;
};

const getConversationFromList = (conversationList: ChatConversation[], conversationId: string) =>
  conversationList.find(item => item.conversationId === conversationId);

export const conversationsPageSize = 30;

export const getConversations = createAsyncThunk('conversations', async (pageNumber?: number | undefined) => {
  const params = { size: conversationsPageSize, page: pageNumber };

  const conversations = await exoClinicApi.conversations.all(params).catch(err => {
    handleError(err.response);
    throw err;
  });

  return conversations;
});

export const getConversation = createAsyncThunk('conversation', async (args: GetConversationArgs) => {
  const { conversationId, errorCallback } = args;

  const conversation = await exoClinicApi.conversations.single(conversationId).catch(err => {
    errorCallback();
    handleError(err.response);
    throw err;
  });

  return conversation;
});

export const getMessages = createAsyncThunk('conversations/messages', async (conversationId: string) => {
  const conversations = await exoClinicApi.conversations
    .conversation(conversationId)
    .messages({ pageSize: 15 })
    .catch(err => {
      handleError(err.response);
      throw err;
    });
  return { page: conversations, conversationId } as MessageListResponse;
});

export const getNewMessages = createAsyncThunk('conversations/messages/update', async (args: getNewMessagesArgs) => {
  const { conversationId, chatMessageId, beforeChatMessageCreateDate } = args;
  const params: ChatMessageFilterDTO = { chatMessageId, beforeChatMessageCreateDate };
  const conversations = await exoClinicApi.conversations
    .conversation(conversationId)
    .newMessages(params)
    .catch(err => {
      handleError(err.response);
      throw err;
    });
  return {
    page: conversations,
    conversationId,
    beforeChatMessageCreateDate,
  } as NewMessageListResponse;
});

export const sendMessage = createAsyncThunk('conversations/send', async (args: SendMessageArgs) => {
  const { conversationId, message } = args;
  const messageResponse = await exoClinicApi.conversations
    .conversation(conversationId)
    .send(message)
    .catch(err => {
      handleError(err.response);
      throw err;
    });
  return { messageResponse, conversationId };
});

export const addConversation = createAsyncThunk('conversations/new', async (args: AddConversationArgs) => {
  const { userProfileId, data, successCallback } = args;
  const messageResponse = await exoClinicApi.conversations
    .create(userProfileId, data)
    .then(res => {
      successCallback(res.id);
      return res;
    })
    .catch(err => {
      handleError(err.response);
      throw err;
    });

  return messageResponse;
});

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addCard: (state, { payload }: PayloadAction<ConversationParticipantDTO>) => {
      const emptyMessage: ChatMessageResponseDTO = {
        id: '',
        text: __('application.toStartWriteNewMessage'),
        chatMessageType: 'NORMAL' as ChatMessageType,
        postDate: getToday(),
        author: {} as ConversationParticipantDTO,
        currentUserAuthor: true,
      };

      const card: Card = {
        id: `add/${payload.userProfileId}`,
        lastChatMessage: emptyMessage,
        participant: payload,
        unreadMessages: 0,
      };

      const conversation: ChatConversation = {
        conversationId: `add/${payload.userProfileId}`,
        card,
        messageList: [],
        isEndOfOlderMessages: true,
      };

      if (!state.conversationList.length) {
        state.conversationList.push(conversation);
      } else {
        const conversationItem = getConversationFromList(state.conversationList, conversation.conversationId);

        if (conversationItem) {
          return;
        }

        state.conversationList.push(conversation);
      }

      sortConversationList(state.conversationList);
    },

    setDraft: (state, { payload }: PayloadAction<SetDraftArgs>) => {
      const { conversationId, message } = payload;
      const conversationItem = getConversationFromList(state.conversationList, conversationId);

      if (!conversationItem) {
        return;
      }

      const draft: Draft = {
        message,
        time: new Date(),
      };

      conversationItem.card.draft = draft;
    },

    removeDraft: (state, { payload }: PayloadAction<string>) => {
      const conversationItem = getConversationFromList(state.conversationList, payload);

      if (!conversationItem) {
        return;
      }

      conversationItem.card.draft = undefined;
    },

    removeUnread: (state, { payload }: PayloadAction<string>) => {
      const conversationItem = getConversationFromList(state.conversationList, payload);

      if (!conversationItem) {
        return;
      }

      conversationItem.card.unreadMessages = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(getConversations.fulfilled, (state, { payload }) => {
      if (!state.conversationList.length) {
        state.conversationList = createConversationList(payload.content);
      } else {
        state.conversationList.forEach(storeConversation => {
          const upgradedConversation = payload.content.find(
            newConversation => `add/${newConversation.participant.userProfileId}` === storeConversation.conversationId,
          );
          if (upgradedConversation) {
            replaceConversation(storeConversation, upgradedConversation);
          }
        });

        payload.content.forEach(conversation => {
          updateConversationList(state, conversation);
        });
      }

      const { content, pageable } = payload;

      if (content.length < conversationsPageSize) {
        state.isEndOfOlderConversation = true;
      } else if (state.nextConversationPage === pageable.pageNumber) {
        state.isEndOfOlderConversation = false;
        state.nextConversationPage++;
      }

      sortConversationList(state.conversationList);
    });

    builder.addCase(getConversation.fulfilled, (state, { payload }) => {
      if (!state.conversationList.length) {
        state.conversationList.push(getChatConversationObject(payload));
      } else {
        state.conversationList.forEach(storeConversation => {
          if (`add/${payload.participant.userProfileId}` === storeConversation.conversationId) {
            replaceConversation(storeConversation, payload);
          }
        });

        updateConversationList(state, payload);
      }

      sortConversationList(state.conversationList);
    });

    builder.addCase(getMessages.fulfilled, (state: ChatState, { payload }) => {
      const conversationItem = getConversationFromList(state.conversationList, payload.conversationId);

      const messages = payload.page.content;

      if (conversationItem) {
        const stateLastMessage = getLastMessage(conversationItem.messageList);
        const responseLastMessage = getLastMessage(messages);

        if (stateLastMessage && responseLastMessage && stateLastMessage.id === responseLastMessage.id) {
          return;
        }

        conversationItem.messageList = prepareMessages(messages);
        conversationItem.isEndOfOlderMessages = payload.page.totalElements < 15;
      }
    });

    builder.addCase(getNewMessages.pending, (state: ChatState) => {
      state.inProgress = true;
    });

    builder.addCase(getNewMessages.fulfilled, (state: ChatState, { payload }) => {
      const { conversationId, beforeChatMessageCreateDate, page } = payload;
      const conversationItem = getConversationFromList(state.conversationList, conversationId);

      if (!conversationItem) {
        return;
      }

      const preparedMessages = prepareMessages(page.content);

      if (!beforeChatMessageCreateDate) {
        conversationItem.messageList = [...conversationItem.messageList, ...preparedMessages];
        return;
      }

      conversationItem.messageList = [...preparedMessages, ...conversationItem.messageList];
      conversationItem.isEndOfOlderMessages = page.totalElements < 10;
      state.inProgress = false;
    });

    builder.addCase(sendMessage.fulfilled, (state: ChatState, { payload }) => {
      const { messageResponse, conversationId } = payload;
      const conversationItem = getConversationFromList(state.conversationList, conversationId);

      if (conversationItem) {
        const preparedMessage = prepareMessage(messageResponse);
        conversationItem.messageList.push(preparedMessage);
        conversationItem.card.lastChatMessage = preparedMessage;
      }

      sortConversationList(state.conversationList);
    });

    builder.addCase(addConversation.fulfilled, (state: ChatState, { payload }) => {
      const conversationItem = getConversationFromList(
        state.conversationList,
        `add/${payload.participant.userProfileId}`,
      );

      if (conversationItem) {
        conversationItem.conversationId = payload.id;
        conversationItem.card = prepareCard(payload);
      } else {
        state.conversationList.push(getChatConversationObject(payload));
        state.isEndOfOlderConversation = false;
      }

      sortConversationList(state.conversationList);
    });
  },
});

export const { addCard, setDraft, removeDraft, removeUnread } = chatSlice.actions;

export default chatSlice.reducer;
