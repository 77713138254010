import { substituteRuntimeEnvironment } from 'helpers/substituteRuntimeEnvironment';

export abstract class EGZOTechHostApi {
  abstract readonly type?: string;
  abstract readonly options?: {
    enableOfflinePasswordReset?: boolean;
    disableAvailableDevicesScreen?: boolean;
    enableAvailableWifiScreen?: boolean;
    disallowChangingWifiAfterLoggingIn?: boolean;
    disableLostWifiNotification?: boolean;
    autoConnectDeviceType?: string;
    licenseCodeStub?: string; // Hides the 'Welcome Card' input in the clinic creation form using the license code defined in the variable
    hideChangeAvatar?: boolean; // This functionality should be hidden on our devices since it open device file manager window
    showTrainingWarning?: boolean; // Possibility to show warning about the need to train staff before using the device.
    showSuggestTraining?: boolean; // Hide possibility to duggest training for patient since functionality is not ready at the moment TODO: Remove this flag with functionality release
    showTelemedicine?: boolean; // Hide possibility to create telemedicine patient since device is offline for this moment TODO: Remove this flag with functionality release
    showClinicStatistics?: boolean; // Hide possibility to generate clinic statistics since functionality is not ready at the moment TODO: Remove this flag with functionality release
    showTwoFactorAuth?: boolean; // Hide possibility to request Two Factor Authroization for account since functionality is not ready at the moment TODO: Remove this flag with functionality release
    showSocialLoginButtons?: boolean; // Hide possibility to log in with Facebook / Google since functionality is not ready at the moment and device is offline TODO: Remove this flag with functionality release
    showClinicPanel?: boolean; // Hide possibility to show clinic panel TODO: Remove this flag with functionality release
    demoUserEmail?: string; // Automatically login user to the app, also password is required
    demoUserPassword?: string; // Automatically login user to the app
    demoPatientUuid?: string; // Uuid of patient which be automatically selected on the patient’s list
    disableAutoLogout?: boolean; // disable auto logout functionality after 20 minutes of user inactivity
    hideLoginForm?: boolean; // Hide login form. It can be used when demoUserEmail and demoUserPassword are valid
    hideCreateNewClinic?: boolean; // Hide link to create new clinic on login page.
    hideFavourites?: boolean; // Hide favourites trainings since it does not work now TODO: Remove this flag with functionality release
    mailOverlay?: string; // Overlay mail in Navbar with any string
    deviceNameOverlay?: string; // Overlay device name in Navbar with any string
    enableDeveloperBar?: boolean; // Enables developer bar
    showCreateAnonymousPatientOption?: boolean; // In add patient screen show option to create anonymous patient.
    debugGame?: boolean; // switch game to the debug mode
    cosmicMissionUrl?: string; // template url for cosmic mission
    drivingGameUrl?: string; // template url for driving game
    exoRunUrl?: string; // template url for exo-run
    debugSignals?: boolean; // Turns on signals debugging in DevTools console
    debugSessionBroker?: boolean; // Turns on session broker debugging in DevTools console
    meissaOtDisableHorizontalSensor?: boolean; // Disable horizontal sensor measurement on calibration flow
    meissaOtDontBlockOnPositionSensors?: boolean; // Allow user to proceed independently from head position sensor measurement
    enableDirectHubCandidates?: boolean; // Turns on receiving device candidates from direct-hub
    enableBackendDetectCandidates?: boolean; // Turns on receiving device candidates from backend detection mechanism
    can2wsUrl?: string; // Sets url to can2ws
    deviceApiUrl?: string; // API Url for device endpoints /device/...
    disableVirtualKeyboard?: boolean; // Disables virtual keyboard by default
    mockedApi?: boolean; // Uses mocked api to simulate device updates
    zebraPresent?: boolean; // Zebra tablet is used with this device
    showSerialNumber?: boolean; //Show device serial number on loginScreen,serviceSettings,appSettings
  };

  static get instance() {
    return ((globalThis as any).egzotechHostApi ?? null) as EGZOTechHostApi | null;
  }
}

if (!EGZOTechHostApi.instance) {
  const instance: EGZOTechHostApi = {
    type: 'development-host-api',
    options: {},
  };

  for (const prop in process.env) {
    if (prop.startsWith('REACT_APP_HOST_API_')) {
      const option = prop
        .substring('REACT_APP_HOST_API_'.length)
        .toLowerCase()
        .replace(/_([A-Za-z0-9])/g, (_, v: string) => v.toUpperCase());

      (instance.options! as { [key: string]: any })[option] =
        process.env[prop] === 'true' ? true : process.env[prop] === 'false' ? false : process.env[prop];
    }
  }

  const overrideHostApiOptions = (
    substituteRuntimeEnvironment('::@@{ENVIRONMENT_SUBSTITUTION:OVERRIDE_HOST_API_OPTIONS}') ?? ''
  )
    .split('\n')
    .map(v => v.trim())
    .filter(v => v)
    .map(v => v.split(':'));

  for (let i = 0; i < overrideHostApiOptions.length; i++) {
    const option = overrideHostApiOptions[i];
    (instance.options! as { [key: string]: any })[option[0].trim()] = JSON.parse(option.slice(1).join(':').trim());
  }

  (globalThis as any).egzotechHostApi = instance;
}
