import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Container, Flex, Grid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import theme from 'config/theme';
import { passwordConfirmation } from 'schemas/passwordConfirmation';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';

import { PasswordInputWithMask } from '../common/PasswordInputWithMask';

import { ModalProps } from './Modal';

interface Props extends ModalProps {
  text?: string;
}

interface PasswordConfirmation {
  currentPassword: string;
}

export const PasswordConfirmationModal: FC<Props> = ({ callback, close }) => {
  const submit = async (_: PasswordConfirmation) => {
    // await patch('/verification', undefined, callback, undefined, { password }); // temporary comment according to the password verification arrangements
    if (callback) {
      callback();
    }
  };

  const methods = useForm<PasswordConfirmation>({
    mode: 'onSubmit',
    resolver: yupResolver(passwordConfirmation),
  });

  const { handleSubmit } = methods;

  return (
    <Container variant="modalBox">
      <Flex flexDirection="column" rowGap="5" my="2.5">
        <Box maxW={theme.sizes.container.sm}>
          <MainHeading text="account.saveAccountChanges" />
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            <PasswordInputWithMask name="currentPassword" pr="12" required />
            <Grid
              templateColumns={['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)']}
              columnGap="16"
              rowGap="4"
              mt="8"
              mx="9"
            >
              <MainButton text="select.cancel" variant="mdOutlinedPrimaryButton" onClick={() => close()} minW={154} />
              <MainButton type="submit" text="select.next" variant="mdPrimaryButton" minW={154} />
            </Grid>
          </form>
        </FormProvider>
      </Flex>
    </Container>
  );
};
