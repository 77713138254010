import { isRejectedWithValue } from '@reduxjs/toolkit';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { handleError } from 'helpers/handleError';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';

export const globalErrorHandlerMiddleware: Middleware = (_api: MiddlewareAPI) => next => (action: AnyAction) => {
  if (isRejectedWithValue(action)) {
    // baseQueryMeta is only available from RTK fetchBaseQuery, we inject handling an error
    // when request fail, this is needed because we want to show a notification that error occured
    if (
      'baseQueryMeta' in action.meta &&
      typeof action.meta.baseQueryMeta === 'object' &&
      action.meta.baseQueryMeta &&
      'response' in action.meta.baseQueryMeta
    ) {
      const meta = action.meta.baseQueryMeta as FetchBaseQueryMeta;

      if (meta.response) {
        // TODO: add possibility to opt out from this global error handler, some of the requests
        // do not need additional notification because they are handled within the UI, this can
        // be done checking 'action.payload' property
        handleError(meta.response);
      }
    }

    // TODO: in the future handle other rejected actions, for example we can handle here
    // errors from createAsyncThunk in slices
    // console.warn('We got a rejected action!', action);
  }

  return next(action);
};
