import { logger } from 'helpers/logger';
import { computed, ReadonlySignal } from 'helpers/signal';

import EMGSignal from './EMGSignal';
import { TriggerThreshold } from './TriggerThreshold';

export class TriggerThresholdEMG implements TriggerThreshold {
  readonly threshold: ReadonlySignal<number>;

  get name() {
    return this.channel.toString();
  }

  constructor(private readonly emgSignal: EMGSignal, private readonly channel: number) {
    this.threshold = computed(
      () => this.emgSignal.data.portsStates.find(v => v.peek().channelNumber === this.channel)?.value.slider ?? 0.5,
      `TriggerThresholdEMG.${channel}.threshold`,
    );
  }

  setThreshold(threshold: number) {
    const port = this.emgSignal.data.portsStates.findIndex(v => v.peek().channelNumber === this.channel);

    if (port < 0) {
      logger.warn(
        'setThreshold',
        `Cannot set threshold for channel '${this.channel}' because there is no portState for this channel`,
      );
      return;
    }

    this.emgSignal.setPortSlider(port, threshold);
  }

  isTriggered() {
    return this.emgSignal.isThresholdReached(this.channel);
  }
}
