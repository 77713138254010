import type { MotorPlacement } from 'libs/exo-session-manager/core';
import {
  isGeneratedCAMProgramDefinitionPrimary,
  isGeneratedCAMProgramDefinitionSynchronized,
} from 'libs/exo-session-manager/core/types/GeneratedCAMProgramDefinition';
import {
  isGeneratedCPMProgramDefinitionPrimary,
  isGeneratedCPMProgramDefinitionSynchronized,
} from 'libs/exo-session-manager/core/types/GeneratedCPMProgramDefinition';
import {
  GeneratedExerciseDefinition,
  isCAMExerciseDefinition,
  isCPMExerciseDefinition,
} from 'libs/exo-session-manager/core/types/GeneratedExerciseDefinition';

import { findKeyInObject } from './object';

export const getMotorNameByProgram = (
  program: GeneratedExerciseDefinition | null,
  whichMotor: 'primary' | 'synchronized',
) => {
  if (!program) {
    return null;
  }
  if (isCAMExerciseDefinition(program)) {
    return findKeyInObject(program.cam, (_, v) =>
      whichMotor === 'primary'
        ? isGeneratedCAMProgramDefinitionPrimary(v)
        : isGeneratedCAMProgramDefinitionSynchronized(v),
    ) as MotorPlacement;
  }
  if (isCPMExerciseDefinition(program)) {
    return findKeyInObject(program.cpm, (_, v) =>
      whichMotor === 'primary'
        ? isGeneratedCPMProgramDefinitionPrimary(v)
        : isGeneratedCPMProgramDefinitionSynchronized(v),
    ) as MotorPlacement;
  }

  return null;
};
