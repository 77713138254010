import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { FacilitySpecialistResponseDTO, Page, PatientResponseDTO, TrainingResponseDTO } from 'types';

import { formatDate } from '../helpers/formatDate';

type SpecialistPatientsResponse = Page<PatientResponseDTO>;
export interface TimeProps {
  days: string;
  hours: string;
  minutes: string;
}

export interface ExerciseDetailsProps {
  exerciseType: string;
  count: number;
}

export interface SpecialistsPatientsListsProps {
  specialistId: string;
  patients?: SpecialistPatientsResponse;
}

export interface PatientsRecentTrainingsProps {
  specialistId: string;
  patientId?: string;
  recentTrainings?: ExoClinicBackendOpenApiSchemas['TrainingResponseDtoPagedPayloadDto'];
}

export interface TrainingsDetailsProps {
  specialistId: string;
  trainingId?: string;
  patientId?: string;
  trainingsDetails?: TrainingResponseDTO;
}

export interface ReportPDFProps {
  newPatients?: number;
  attendedPatients?: number;
  completedTrainings?: number;
  totalTime?: TimeProps;
  exerciseDetails?: ExerciseDetailsProps[];
  mainStatistics?: boolean;
  specialistId?: string;
}

export interface ClinicReportState {
  fromBeginning: boolean;
  allSpecialists: boolean;
  specialistsList: FacilitySpecialistResponseDTO[];
  startDate: string;
  endDate: string;
  patients: SpecialistsPatientsListsProps[];
  patientsRecentTrainings: PatientsRecentTrainingsProps[];
  trainingsDetails: TrainingsDetailsProps[];
  reportPDFDetails: ReportPDFProps[];
}

const initialState: ClinicReportState = {
  fromBeginning: false,
  allSpecialists: false,
  specialistsList: [],
  startDate: formatDate(new Date(), 'date'),
  endDate: formatDate(new Date(), 'date'),
  patients: [],
  patientsRecentTrainings: [],
  trainingsDetails: [],
  reportPDFDetails: [],
};

const clinicReportSlice = createSlice({
  name: 'clinicReport',
  initialState,
  reducers: {
    setFromBeginning: (state, { payload }: PayloadAction<boolean>) => {
      state.fromBeginning = payload;
    },
    setAllSpecialists: (state, { payload }: PayloadAction<boolean>) => {
      state.allSpecialists = payload;
    },
    setSpecialistsList: (state, { payload }: PayloadAction<FacilitySpecialistResponseDTO[]>) => {
      state.specialistsList = payload;
    },
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setPatients: (state, { payload }: PayloadAction<SpecialistsPatientsListsProps>) => {
      !payload.specialistId ? (state.patients = []) : state.patients.push(payload);
    },
    setPatientsRecentTrainings: (state, { payload }: PayloadAction<PatientsRecentTrainingsProps>) => {
      const patientId = payload.patientId;

      if (!payload.specialistId) {
        state.patientsRecentTrainings = [];
      } else {
        if (patientId) {
          const patientFound = state.patientsRecentTrainings.find(item => item.patientId === patientId);
          if (patientFound === undefined) {
            state.patientsRecentTrainings.push(payload);
          }
        }
      }
    },
    setTrainingsDetails: (state, { payload }: PayloadAction<TrainingsDetailsProps>) => {
      const trainingId = payload.trainingId;
      if (!payload.specialistId) {
        state.trainingsDetails = [];
      } else {
        if (trainingId) {
          const trainingFound = state.trainingsDetails.find(item => item.trainingId === trainingId);
          if (trainingFound === undefined) {
            state.trainingsDetails.push(payload);
          }
        }
      }
    },
    setReportPDFDetails: (state, { payload }: PayloadAction<ReportPDFProps>) => {
      if (payload.specialistId === '') {
        state.reportPDFDetails = [];
      } else {
        if (!state.reportPDFDetails.length) {
          state.reportPDFDetails.push(payload);
        } else {
          if (payload.mainStatistics) {
            state.reportPDFDetails = state.reportPDFDetails.map(item => (item.mainStatistics ? payload : item));
          } else {
            state.reportPDFDetails.find(item => item.specialistId === payload.specialistId)
              ? (state.reportPDFDetails = state.reportPDFDetails.map(item =>
                  item.specialistId === payload.specialistId ? payload : item,
                ))
              : state.reportPDFDetails.push(payload);
          }
        }
      }
    },
  },
});

export const {
  setFromBeginning,
  setAllSpecialists,
  setSpecialistsList,
  setStartDate,
  setEndDate,
  setPatients,
  setPatientsRecentTrainings,
  setTrainingsDetails,
  setReportPDFDetails,
} = clinicReportSlice.actions;

export default clinicReportSlice.reducer;
