import { Logger } from '@egzotech/universal-logger-js';

import { Timer, TimerState } from './TimerGroup';

export type CountDownTimerOptions = {
  /**
   * The duration of the timer in milliseconds. `0` by default.
   */
  duration: number;

  /**
   * Callback function called on each tick of the timer.
   * @param remainingTime - The remaining time in milliseconds.
   */
  onTick?: (remainingTime: number) => void;

  /**
   * Callback function called when the timer countdown completes.
   */
  onFinish?: () => void;
};

export class CountDownTimer implements CountDownTimerOptions, Timer {
  static logger = Logger.getInstance('Timer');

  private _startTime = 0;
  private _pauseTime: number | null = null;
  private _state: TimerState = 'idle';
  private _intervalId: NodeJS.Timeout | null = null;

  duration = 0;

  readonly INTERVAL_TIME = this.duration > 0 ? 1000 : 0; // this ensures that tick function inside setInterval is executed at the end of event loop

  onTick?: (remainingTime: number) => void;
  onFinish?: () => void;

  get state() {
    return this._state;
  }

  constructor(options?: CountDownTimerOptions) {
    if (options) {
      this.setup(options);
    }
  }

  /**
   * Sets the timer options.
   * @param options - Options for configuring the timer.
   */
  setup(options: CountDownTimerOptions) {
    this.duration = options.duration;
    if (options.onTick) {
      this.onTick = options.onTick;
    }
    if (options.onFinish) {
      this.onFinish = options.onFinish;
    }
  }

  /**
   * Starts the timer.
   */
  play() {
    if (this._state === 'running') {
      // do nothing if already running
      CountDownTimer.logger.debug('start', 'Timer cannot start if is already running.');
      return;
    }
    if (this._state === 'paused') {
      this.resume();
      return;
    }
    if (this._state === 'finish') {
      // reset timer if count down is completed
      this.reset();
    }

    this._startTime = Date.now();
    this._intervalId = setInterval(() => this.tick(), this.INTERVAL_TIME);
    this._state = 'running';
  }

  /**
   * Pauses the timer.
   */
  pause() {
    if (this._state !== 'running') {
      // do nothing if not running
      CountDownTimer.logger.debug('pause', 'Timer cannot pause if not running.');
      return;
    }
    if (this._intervalId !== null) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
    if (this._startTime > 0) {
      this._pauseTime = Date.now();
      this._state = 'paused';
    }
  }

  /**
   * Resumes the timer from where it was paused.
   */
  resume() {
    if (this._state !== 'paused' || this._pauseTime === null) {
      CountDownTimer.logger.debug('resume', 'Timer cannot resume if not paused.');
      return;
    }
    const pausedDuration = Date.now() - this._pauseTime;
    this._startTime += pausedDuration;
    this._intervalId = setInterval(() => this.tick(), this.INTERVAL_TIME);
    this._pauseTime = null;
    this._state = 'running';
  }

  dispose() {
    this.reset();
  }

  private reset() {
    this._startTime = 0;
    this._pauseTime = null;
    this._state = 'idle';

    if (this._intervalId !== null) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  private tick() {
    const elapsedTime = Date.now() - this._startTime;

    // prevent negative value
    const remainingTime = Math.max(this.duration - elapsedTime, 0);

    this.onTick?.(remainingTime);

    if (remainingTime === 0 || this.duration === 0) {
      this.pause();
      this._state = 'finish';
      this.onFinish?.();
    }
  }
}
