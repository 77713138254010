import { MotorPlacement, MotorPlacementFreeCouplingPresets, MotorRange } from '../core';

/**
 * Type for mapping payload to actions
 */
type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

/**
 * Type for mapping events to payload
 */
type PayloadMap<TEvents extends Record<string, (payload: any) => void>> = {
  [Key in keyof TEvents]: Parameters<TEvents[Key]>[0];
};

/**
 * Action type based on events
 */
export type Action<TEvents extends Record<string, (payload: any) => void>> = ActionMap<
  PayloadMap<TEvents>
>[keyof ActionMap<PayloadMap<TEvents>>];

export const channelColors = ['red', 'blue', 'green', 'violet', 'darkblue', 'darkgreen', 'yellow', 'orange'] as const;

export type ChannelColor = (typeof channelColors)[number];

export interface MultiMotorBasingActions {
  /**
   * Sets the movement range in degrees that will restrict device movements. The device will keep its angle in specified range.
   */
  setMotorRange: (range: { min: number; max: number }, motorId: MotorPlacement) => void;

  /**
   * Gets the movemnt range for specified motor.
   */
  getMotorRange: (motorId: MotorPlacement) => MotorRange | null;

  /**
   * Set free roam coupling for specified motor.
   */
  setFreeRoamCoupling<T extends MotorPlacement>(motorId: T, preset: MotorPlacementFreeCouplingPresets[T]): void;
  setFreeRoamCoupling(motorId: MotorPlacement, preset: string): void;

  /**
   * Stops specified motor.
   */
  stopMotor: (motorId: MotorPlacement) => void;
}
