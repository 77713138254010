import { ElementType, FC, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Container } from '@chakra-ui/react';

import { AppSpinner } from './AppSpinner';

interface CustomInfiniteScrollProps {
  loading: boolean;
  loadMore: () => void;
  hasMore: boolean;
  inverse?: boolean;
  CustomLoadMoreElement?: ElementType;
  px?: string;
  py?: string;
  children?: ReactNode;
  closePopoversOnScroll?: () => void;
  cypressAttribute?: string;
}

export const InfiniteScrollBox: FC<CustomInfiniteScrollProps> = ({
  loading,
  loadMore,
  hasMore,
  children,
  inverse,
  CustomLoadMoreElement,
  px,
  py,
  closePopoversOnScroll,
  cypressAttribute,
}) => {
  const handleLoadMore = () => {
    if (!loading) {
      loadMore();
    }
  };
  const getLoader = () => {
    return CustomLoadMoreElement ? (
      <CustomLoadMoreElement key="loadMoreCustomElement" />
    ) : (
      <AppSpinner key={0} size="large" />
    );
  };

  return (
    <Container data-cy={cypressAttribute ?? ''} variant="scrollBox" px={px} py={py} onScroll={closePopoversOnScroll}>
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMore}
        loader={getLoader()}
        useWindow={false}
        isReverse={inverse}
        initialLoad
      >
        <>{children}</>
      </InfiniteScroll>
    </Container>
  );
};
