import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ExoSession } from '@egzotech/exo-session';
import { logger } from 'helpers/logger';
import {
  DeviceType,
  GeneratedEMGExerciseDefinition,
  isSpecificGeneratedExerciseDefinition,
} from 'libs/exo-session-manager/core';
import { EMGExercise } from 'libs/exo-session-manager/core/exercises/EMGExercise';
import { useContextSelector } from 'use-context-selector';

import ExoGlobalContext from '../contexts/ExoGlobalContext';

import { EMGInstanceContext } from './EMGInstancecontext';

export const EMGProvider = ({
  children,
  errorContent,
}: {
  children: React.ReactNode;
  errorContent?: React.ReactNode;
}) => {
  const session = useContextSelector(ExoGlobalContext, state => state.device.session);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);
  const selectedProgramId = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgramId);

  if (
    !session ||
    !selectedProgram ||
    !(selectedDeviceType === 'stella-bio') ||
    !isSpecificGeneratedExerciseDefinition(selectedProgram, ['emg', 'emg-pelvic', 'emg-game'])
  ) {
    logger.error('EMGProvider', 'Provider was used without selected device or with invalid program type');

    return <>{errorContent}</>;
  }

  return (
    <EMGActiveProvider
      exerciseName={selectedProgramId}
      session={session}
      definition={selectedProgram}
      deviceType={selectedDeviceType}
    >
      {children}
    </EMGActiveProvider>
  );
};

interface EMGActiveProviderProps {
  session: ExoSession;
  exerciseName: string | null;
  definition: GeneratedEMGExerciseDefinition;
  deviceType: DeviceType;
  children: React.ReactNode;
}

function EMGActiveProvider({ session, definition, deviceType, children, exerciseName }: EMGActiveProviderProps) {
  const [ready, setReady] = useState(false);
  const emgExercise = useMemo(
    () => new EMGExercise(definition, exerciseName, session, deviceType),
    [definition, exerciseName, session, deviceType],
  );

  useEffect(() => {
    setReady(true);

    return () => {
      emgExercise.destroy();
    };
  }, [emgExercise]);

  if (!ready) {
    return <></>;
  }

  return <EMGInstanceContext.Provider value={emgExercise}>{children}</EMGInstanceContext.Provider>;
}

export const useEMGExerciseInstance = () => {
  const emgInstance = useContext(EMGInstanceContext);

  if (!emgInstance) {
    throw new Error('useEMGProgram must be used within a EMGProvider');
  }

  return emgInstance;
};
