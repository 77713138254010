import { conditionalFunctions } from './conditionalFunctions';

export type ConditionalFunctionParameters<T extends keyof typeof conditionalFunctions> = Parameters<
  (typeof conditionalFunctions)[T]
>[1];

export function withCondition<TKey extends keyof typeof conditionalFunctions>(
  condition: TKey,
  ...params: [...[param: Parameters<(typeof conditionalFunctions)[TKey]>[1]]]
) {
  return {
    condition,
    conditionParam: params[0],
  };
}
