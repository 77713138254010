export const formatInterval = (milisenconds: number, type?: 'minutesAndSeconds' | 'hourMinutesAndSeconds') => {
  const totalSeconds = Math.floor(milisenconds / 1000);

  const time = {
    hours: Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(1, '0'),
    minutes: Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    seconds: (totalSeconds % 60).toString().padStart(2, '0'),
  };

  switch (type) {
    case 'minutesAndSeconds':
      return time.minutes + ':' + time.seconds;
    case 'hourMinutesAndSeconds':
      return time.hours + ':' + time.minutes + ':' + time.seconds;
    default:
      return (Number(time.hours) > 0 ? time.hours + ':' : '') + time.minutes + ':' + time.seconds;
  }
};
