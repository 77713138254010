import { MotorPlacement, SensorsName } from 'libs/exo-session-manager/core';

import { useDevice } from './useDevice';
import { useEffectWithEmergencyButton } from './useEffectWithEmergencyButton';
import { useMotorBasing } from './useMotorBasing';

export const useFreeRoamCoupling = (
  motorPlacement: MotorPlacement,
  forceSource: SensorsName,
  activeCoupling = true,
) => {
  const { selectedDevice } = useDevice();
  const { setFreeRoamCoupling, stopMotor } = useMotorBasing(motorPlacement);

  useEffectWithEmergencyButton(() => {
    const source: SensorsName = selectedDevice?.type !== 'meissa-ot' ? forceSource : 'torque';

    if (activeCoupling) {
      setFreeRoamCoupling(selectedDevice?.extensionType ? `${selectedDevice.extensionType}-${source}` : 'default');
    }
    return () => stopMotor();
  }, [setFreeRoamCoupling, activeCoupling, stopMotor, motorPlacement, selectedDevice?.extensionType]);
};
