import { StimChannelConfiguration, StimPhase } from '@egzotech/exo-session/features/electrostim';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';

import { getRealPhases } from '../exercises/EMSExercise';
import {
  ExerciseDefinition,
  GeneratedCPMExerciseDefinition,
  GeneratedEMSExerciseDefinition,
  isSpecificExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';

import {
  EMSParameterId,
  EMSPhasicParameterId,
  EMSPhasicParameters,
  emsPhasicParameters,
  EMSSettingsParameters,
  isEMSPhasicParameterId,
  SettingsParameters,
  SettingsRequiredMethods,
} from './SettingsBuilder';

export type EMSPhasicSignalParameters = {
  [key in keyof EMSPhasicParameters]: Signal<SettingsParameters<EMSPhasicParameters>[key]>;
};

export interface EMSSignalParameters {
  phases: EMSPhasicSignalParameters[];
  phaseRepetition: Signal<EMSSettingsParameters['phaseRepetition']>;
}

export default class EMSSettings implements SettingsRequiredMethods {
  private _parameters: EMSSignalParameters;

  static readonly logger = Logger.getInstance('ExerciseSettings');

  get hasDefinitionParameters() {
    return Object.keys(this.originalDefinition.ems?.parameters ?? {}).length > 0;
  }

  constructor(
    private readonly originalDefinition: GeneratedCPMExerciseDefinition | GeneratedEMSExerciseDefinition,
    private _numberOfPhases = 1,
  ) {
    let phases: EMSPhasicSignalParameters[] = [];
    if (this.originalDefinition.ems?.parameters?.phases) {
      const phase0 = this.originalDefinition.ems?.parameters?.phases?.[0];
      phases = Array<typeof phase0>(this._numberOfPhases)
        .fill(phase0)
        .map((phase, i) => {
          // this is hack to use parameters from phase 0
          const phaseId = 0;
          const currentValues = this.originalDefinition.ems?.program.phases[i];

          const result: EMSPhasicSignalParameters = {};
          for (const param of emsPhasicParameters as EMSPhasicParameterId[]) {
            result[param] = signal(
              {
                currentValue:
                  currentValues?.channels[phaseId]?.[param] ??
                  this.originalDefinition.ems?.program.defaultChannelValues[phaseId]?.[param],
                default: phase?.[param]?.default ?? 0,
                values: phase?.[param]?.values ?? [],
                blockAfterStart: phase?.[param]?.blockAfterStart ?? false,
                previousValue:
                  currentValues?.channels[phaseId]?.[param] ??
                  this.originalDefinition.ems?.program.defaultChannelValues[phaseId]?.[param],
              },
              `EMSSettings._parameters.phases.${param}`,
            );
          }
          return result;
        });
    }
    const phaseRepetition: EMSSignalParameters['phaseRepetition'] = signal(
      {
        currentValue: this.originalDefinition.ems?.program.phasesRepetition,
        default: this.originalDefinition.ems?.parameters?.phaseRepetition?.default ?? 0,
        values: this.originalDefinition.ems?.parameters?.phaseRepetition?.values ?? [],
        blockAfterStart: this.originalDefinition.ems?.parameters?.phaseRepetition?.blockAfterStart ?? false,
        previousValue: this.originalDefinition.ems?.parameters?.phaseRepetition?.default ?? 0,
      },
      'EMSSettings._parameters.phaseRepetition',
    );

    this._parameters = {
      phases,
      phaseRepetition,
    };
  }

  get parameters() {
    return this._parameters;
  }

  setPlateauTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given time: ${time} is invalid. Time must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].plateauTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].plateauTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].plateauTime!.value = {
      ...this._parameters.phases[phaseIndex].plateauTime!.peek(),
      currentValue: time,
    };
  }

  setRiseTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given time: ${time} is invalid. Time must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].riseTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].riseTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].riseTime!.value = {
      ...this._parameters.phases[phaseIndex].riseTime!.peek(),
      currentValue: time,
    };
  }

  setFallTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given time: ${time} is invalid. Time must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].fallTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].fallTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].fallTime!.value = {
      ...this._parameters.phases[phaseIndex].fallTime!.peek(),
      currentValue: time,
    };
  }

  setPauseTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given time: ${time} is invalid. Time must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].pauseTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].pauseTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].pauseTime!.value = {
      ...this._parameters.phases[phaseIndex].pauseTime!.peek(),
      currentValue: time,
    };
  }

  setRunTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given time: ${time} is invalid. Time must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].runTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].runTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].runTime!.value = {
      ...this._parameters.phases[phaseIndex].runTime!.peek(),
      currentValue: time,
    };
  }

  setPulseFrequency(pulseFrequency: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (pulseFrequency < 0) {
      throw new Error(
        `Given pulse frequency: ${pulseFrequency} is invalid. Pulse frequency must be at least greater than zero`,
      );
    }
    if (!this._parameters.phases[phaseIndex].pulseFrequency?.peek()?.values?.includes(pulseFrequency)) {
      throw new Error(
        `Cannot set given pulse frequency: ${pulseFrequency}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].pulseFrequency?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].pulseFrequency!.value = {
      ...this._parameters.phases[phaseIndex].pulseFrequency!.peek(),
      currentValue: pulseFrequency,
    };
  }

  setPulseWidth(pulseWidth: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (pulseWidth < 0) {
      throw new Error(`Given pulse width: ${pulseWidth} is invalid. Pulse width must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].pulseWidth?.peek()?.values?.includes(pulseWidth)) {
      throw new Error(
        `Cannot set given pulse width: ${pulseWidth}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].pulseWidth?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].pulseWidth!.value = {
      ...this._parameters.phases[phaseIndex].pulseWidth!.peek(),
      currentValue: pulseWidth,
    };
  }

  setPhaseRepetition(phaseRepetition: number) {
    if (phaseRepetition < 1) {
      throw new Error(`Given repetition: ${phaseRepetition} is invalid. Exercise must have at least 1 repetition`);
    }
    if (!this._parameters.phaseRepetition?.peek()?.values?.includes(phaseRepetition)) {
      throw new Error(
        `Cannot set given repetition: ${phaseRepetition}. It must be one of the value from: [${
          this._parameters.phaseRepetition?.peek()?.values
        }]`,
      );
    }
    this._parameters.phaseRepetition.value = {
      ...this._parameters.phaseRepetition.peek(),
      currentValue: phaseRepetition,
    };
  }

  updateDefinition(definition: ExerciseDefinition) {
    if (
      !isSpecificExerciseDefinition(definition, ['ems', 'ems-emg']) &&
      !isSpecificExerciseDefinition(definition, ['cpm-ems', 'cpm-ems-emg'])
    ) {
      throw new Error('Cannot update non electrostim definition in EMSSettings');
    }

    this._parameters.phases.forEach((phase, phaseIndex) => {
      const changedParameters = Object.fromEntries(
        Object.entries(phase)
          .map(([k, v]) => (v.peek().currentValue === undefined ? [] : [k, v.peek().currentValue]))
          .filter(([k]) => typeof k === 'string' && isEMSPhasicParameterId(k)),
      ) as Record<EMSParameterId, number>;

      const definitionChannels: StimPhase['channels'] = [];
      // TODO: currently we don't support changing parameters for specific phase of ems exercise (ex. FES), so only parameters that can be changed for all phases at once are: pulseWidth, pulseFrequency and runtime.
      if (definition.ems?.program.minRequiredChannels && definition.ems.program.minRequiredChannels > 1) {
        definition.ems?.program.defaultChannelValues.forEach(defaultChannelVal => {
          definitionChannels.push({
            ...defaultChannelVal,
            pulseWidth: changedParameters.pulseWidth ?? defaultChannelVal.pulseWidth,
            pulseFrequency: changedParameters.pulseFrequency ?? defaultChannelVal.pulseFrequency,
            runTime: changedParameters.runTime ?? defaultChannelVal.runTime,
          });
        });
      } else {
        definitionChannels.push({
          channelIndex: 0,
          ...changedParameters,
        });
      }

      const phases = definition!.ems!.program.phases;
      if (!phases[phaseIndex] && phaseIndex) {
        // just copy phase 0 to the definition
        phases[phaseIndex] = structuredClone(phases[0]);
      }

      definition!.ems!.program.phases[phaseIndex]!.channels = definitionChannels;
    });

    if (typeof this._parameters.phaseRepetition.peek().currentValue === 'number') {
      definition!.ems!.program.phasesRepetition = this._parameters.phaseRepetition.peek().currentValue!;
    }

    definition!.ems!.program.programTime =
      getRealPhases(definition).reduce((acc, curr) => {
        const runTimeMax = Math.max(...curr.channels.map(ch => ch.runTime));
        acc += runTimeMax;
        return acc;
      }, 0) * (definition?.ems?.program.phasesRepetition ?? 1);

    // TODO: Rethink this logic, currently we assume that stimCalibration is common for each channel and divided by phases
    // but in reality stimCalibration is one per all phases but can be different for each channel. This needs to be somehow
    // included when stella bio programs will be implemented.

    // TODO: This condition is a fix that allows programs in stella bio that have more than one channel defined in
    // calibration (like FES). This really should be done in other way
    if (definition?.ems?.program.stimCalibration.length === 1) {
      //update stimCalibration with parameters changed on parameter setting screen
      definition.ems.program.stimCalibration = definition.ems.program.phases.map((p, index) => {
        if (definition.ems) {
          const obj: StimChannelConfiguration & { [key: string]: unknown } = {
            ...definition.ems.program.stimCalibration[index],
          };

          for (const [key, value] of Object.entries({
            ...definition.ems.program.defaultChannelValues[0],
            ...p.channels?.[0],
          })) {
            obj[key] = value;
          }

          const burstOnDuration = (obj.riseTime ?? 0) + (obj.plateauTime ?? 0) + (obj.fallTime ?? 0);

          // Recalculate runtime for calibration
          obj.delay = 0;
          obj.pauseTime = 0;
          obj.runTime = burstOnDuration ? (burstOnDuration < 5000000 ? 5000000 : burstOnDuration) : 5000000;

          return obj;
        }
      }) as StimChannelConfiguration[];
    } else {
      // TODO: How to handle parameters for stim calibration in stella bio?
    }
  }

  private validatePhaseParameter(phaseIndex: number) {
    const fakeIndex = 0;
    if (
      phaseIndex < 0 ||
      phaseIndex >= (this._numberOfPhases ?? 0) ||
      !this.originalDefinition.ems?.program.phases[fakeIndex]
    ) {
      throw new Error(`There is no phaseIndex with given index: ${phaseIndex} for this exercise`);
    }
  }
}
