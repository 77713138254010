import { DeviceEvents, ExerciseSelectorEvents } from 'libs/exo-session-manager/core';

import { GlobalChangeableData } from '../contexts/ExoGlobalContext';
import { Action } from '../types';

type ExoAction = Action<ExerciseSelectorEvents> | Action<DeviceEvents>;

export function globalReducer(state: GlobalChangeableData, action: ExoAction): GlobalChangeableData {
  switch (action.type) {
    case 'onSelectedExerciseChange':
      return {
        ...state,
        programSelection: {
          selectedProgramId: action.payload,
        },
      };
    case 'onDeviceActivate':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          active: true,
        },
      };
    case 'onDeviceInit':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          initialized: true,
          active: false,
        },
      };
    case 'onDeviceDestroy':
      return {
        ...state,
        deviceState: {
          candidates: [],
          storedCandidates: {},
          selectedDeviceId: null,
          wantConnectDeviceId: null,
          initialized: false,
          active: false,
          firmwareModules: {},
          isTared: false,
        },
      };
    case 'onCandidatesChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          candidates: action.payload,
        },
      };
    case 'onStoredCandidatesChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          storedCandidates: action.payload,
        },
      };
    case 'onSelectedDeviceIdChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          selectedDeviceId: action.payload,
        },
      };
    case 'onWantConnectDeviceIdChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          wantConnectDeviceId: action.payload,
        },
      };
    case 'onDeviceActiveChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          active: action.payload,
        },
      };
    case 'onDeviceConnectionErrorChange':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          connectionError: action.payload,
        },
      };
    case 'onModuleIdentify':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          firmwareModules: {
            ...state.deviceState.firmwareModules,
            [action.payload.moduleId]: {
              ...action.payload,
              isMissing: false,
            },
          },
        },
      };
    case 'onModuleLost':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          firmwareModules: {
            ...state.deviceState.firmwareModules,
            [action.payload]: {
              ...state.deviceState.firmwareModules[action.payload],
              isMissing: true,
            },
          },
        },
      };
    case 'onDeviceChange':
      // This needs to return copy of the state, because returning old state will cancel the dispatch
      return { ...state };
    case 'onTareDevice':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          isTared: true,
        },
      };
    default:
      return state;
  }
}
