import { exoClinicApi } from 'services/ExoClinicBackendApi';

import packageJson from '../../package.json';

export const consoleLogBackEndVersion = () => {
  exoClinicApi.info
    .git()
    .then(res => {
      const { buildVersion } = res;
      console.log('Back-End version: ', buildVersion);
    })
    .catch(err => {
      console.error(err.response);
    });
};

export const consoleLogFrontEndVersion = () => {
  console.log(
    'App Version: ' +
      packageJson.version +
      ", you can read app version at any time using 'egzoclinicVersion' variable.",
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (globalThis as any).egzoclinicVersion = packageJson.version;
};
