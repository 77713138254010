import { FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { SelectData } from 'types';

import { MainSearchSelect } from 'components/form/MainSearchSelect';
import { TranslateText } from 'components/texts/TranslateText';

interface FilterProps {
  name: string;
  options: SelectData[];
  onChange?: (value: SingleValue<SelectData>) => void;
}

interface Props {
  item: FilterProps;
}

export const TrainingFilterItem: FC<Props> = ({ item }: Props) => {
  const { name, options, onChange } = item;
  return (
    <GridItem key={name}>
      <TranslateText
        variant={['openSans16', 'openSans18', 'openSans18', 'openSans20']}
        text={`training.${name}`}
        color="gray.600"
        mb="1"
      />
      <MainSearchSelect
        placeholder="placeholders.select"
        value={null}
        options={options}
        width="100%"
        onChange={onChange}
      />
    </GridItem>
  );
};
