// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Link, LinkProps } from 'react-router-dom';
import { useAppNavigate } from 'hooks/useAppNavigate';

export const AppLink = ({ to, ...props }: LinkProps) => {
  const navigate = useAppNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.onClick?.(event);
    navigate(to, { ...props });
  };
  return <Link to={to} {...props} onClick={handleClick} />;
};
