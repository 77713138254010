import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { exoClinicApi, Paged } from 'services/ExoClinicBackendApi';
import { FacilitySpecialistPatientFilterDTO, Page, PatientFilterType, PatientResponseDTO } from 'types';

import { handleError } from '../helpers/handleError';

export interface SpecialistPatientsState {
  specialistPatients: PatientResponseDTO[];
  filter?: PatientFilterType;
  nextPage: number;
  isEndOfPages: boolean;
  search: string;
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
}

const initialState: SpecialistPatientsState = {
  specialistPatients: [],
  search: '',
  nextPage: 0,
  isEndOfPages: false,
  inProgress: false,
  isError: false,
  isLoaded: false,
};

type SpecialistPatientsResponse = Page<PatientResponseDTO>;

export interface GetSpecialistPatientsParams {
  searchContent?: string;
  patientFilterType?: string;
  page?: number;
}

export const getSpecialistPatientsParamsDefault: GetSpecialistPatientsParams = {
  page: 0,
};

export interface GetSpecialistPatientsArguments {
  facilityId: string;
  params?: Paged<FacilitySpecialistPatientFilterDTO>;
}

export const getSpecialistPatients = createAsyncThunk(
  'facility-specialist-patients',
  async (arg: GetSpecialistPatientsArguments) => {
    const { facilityId, params } = arg;
    const specialistPatients = await exoClinicApi
      .facility(facilityId)
      .patients(params)
      .catch(err => {
        handleError(err.response);
      });
    return specialistPatients as SpecialistPatientsResponse;
  },
);

const specialistPatientsSlice = createSlice({
  name: 'specialistPatients',
  initialState,
  reducers: {
    setSpecialistPatients: (state, { payload }: PayloadAction<PatientResponseDTO[]>) => {
      state.specialistPatients = payload;
    },
    setFilter: (state, { payload }: PayloadAction<PatientFilterType>) => {
      state.filter = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSpecialistPatients.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getSpecialistPatients.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.specialistPatients.length || !pageable.pageNumber) {
        state.specialistPatients = content;
        state.nextPage = 0;
      } else {
        content.forEach(payloadPatient => {
          const indexIfExist = state.specialistPatients.findIndex(
            storePatient => storePatient.patientCardId === payloadPatient.patientCardId,
          );

          if (indexIfExist === -1) {
            state.specialistPatients.push(payloadPatient);
            return;
          }
          state.specialistPatients[indexIfExist] = payloadPatient;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getSpecialistPatients.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });
  },
});

export const { setSpecialistPatients, setSearch, setFilter } = specialistPatientsSlice.actions;

export default specialistPatientsSlice.reducer;
