import { ExoSession, Timer } from '@egzotech/exo-session';
import { ExoEMGFeature } from '@egzotech/exo-session/features/emg';
import { ChartDataSource as ChartDataSource } from 'libs/chart-datasources/ChartDataSource';
import { ChannelIndex, TimeLinePoint } from 'libs/chart-datasources/types';

/**
 * ElectrostimChartDataSource is responsible for:
 *  - preparing live data from Echart based on events from ExoEMGFeature
 *
 *
 * Output data format is suited for EChart library
 */
export class EMGChartDataSource extends ChartDataSource {
  private _interval: NodeJS.Timeout | null = null;
  private _emgFeature: ExoEMGFeature;

  get timeSource() {
    return this._timeSource;
  }

  constructor(
    private _session: ExoSession,
    private _timeSource: Timer,
    private _channels: number[],
    private _windowWidth: number,
  ) {
    super();
    this._emgFeature = this._session.activate(ExoEMGFeature);
    this._emgFeature.enable(_channels);
    for (const channelIndex of this._channels) {
      const array: TimeLinePoint[] = [];
      (array as any)[Symbol.toStringTag] = 'Function';
      this._timelines.set(channelIndex as ChannelIndex, array);
    }
    this._emgFeature.onEmg = (_data, _timeDelta) => {
      for (const [channelIndex, sample] of Object.entries(_data)) {
        const dataSource = this._timelines.get(Number(channelIndex) as ChannelIndex);
        dataSource.push([this._timeSource.duration, sample * 1000 * 1000]);

        // remove not visible data from the dataset
        const lastTimeStamp = dataSource.at(-1)?.[0] ?? 0;
        let index = 0;
        while (index < dataSource.length && dataSource[index][0] < lastTimeStamp - this._windowWidth / 2) {
          dataSource.splice(index++, 1);
        }
      }
    };
  }

  dispose() {
    this._emgFeature.dispose();
    if (this._interval !== null) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }
}
