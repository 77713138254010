import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function emsFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'ems-warnings': {
      prev,
      next: 'ems-calibration',
      stepIndex,
    },
    'ems-calibration': {
      prev: 'ems-warnings',
      next,
      disableAlerts: ['disconnectedElectrode'],
      stepIndex: stepIndex + 1,
    },
  } as const;
}
