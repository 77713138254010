import { rangeTolerances } from 'config/defaultConfigProps';

import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';
import { withCondition } from './withCondition';

export function moveToCenterMeissaFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'meissa-move-into-center-before-game': {
      prev,
      next,
      ...withCondition('isInMiddleRange', {
        motorName: 'main',
        rangeProviderState: 'meissa-basing-range-of-movement',
        tolerance: rangeTolerances['meissa-ot'],
      }),
      stepIndex,
    },
  } as const;
}

export function moveToCenterKneeFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-move-center-knee': {
      prev,
      next,
      ...withCondition('isInMiddleRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      stepIndex,
    },
  } as const;
}

export function moveToCenterAnkleFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-move-center-ankle': {
      prev,
      next,
      ...withCondition('isInMiddleRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      stepIndex,
    },
  } as const;
}

export function moveToCenterKneeAnkleFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'leg-basing-move-center-knee': {
      prev,
      next: 'leg-basing-move-center-ankle',
      ...withCondition('isInMiddleRange', {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      stepIndex,
    },
    'leg-basing-move-center-ankle': {
      prev: 'leg-basing-move-center-knee',
      next,
      ...withCondition('isInMiddleRange', {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      }),
      stepIndex: stepIndex + 1,
    },
  } as const;
}
