import { useEffect } from 'react';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppSelector } from 'hooks/store';

import { TrainingFilter } from '../_containers/TrainingFilter';
import { TrainingList } from '../_containers/TrainingList';

const AllTraining = () => {
  const { loadTrainings, setFavoriteCategory } = useTrainingList();
  const { isFiltersAvailable } = useAppSelector(state => state.trainingList);

  useEffect(() => {
    if (isFiltersAvailable) {
      loadTrainings({ isFavoriteTraining: false });
      setFavoriteCategory(false);
    }
    // Get the list only once, but in case we have already got information about available devices from the backend
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltersAvailable]);

  return (
    <>
      <TrainingFilter />
      <TrainingList />
    </>
  );
};

export default AllTraining;
