import { useEffect, useReducer } from 'react';

/**
 * This hook forces component to rerender every given milliseconds
 * @param {number} milliseconds default 1500ms
 */
const useForceRerender = (milliseconds = 1500) => {
  const [_, dispatch] = useReducer(state => !state, false);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch();
    }, milliseconds);

    return () => clearInterval(interval);
  }, [milliseconds]);

  return dispatch;
};

export default useForceRerender;
