import { getErrorMessage } from 'helpers/getErrorMessage';
import { errorNotification } from 'helpers/handleNotifications';
import { getSearchFromString, getStringFromSearch } from 'helpers/search';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  getActiveBlockedList,
  getInvitedList,
  GetSpecialistListArguments,
  setFilter,
  setSearch,
  SpecialistFilterType,
} from 'slices/specialistListSlice';
import { FacilitySpecialistResponseDTO, SelectData } from 'types';

interface UseSpecialistListReturn {
  loadSpecialistList: (facilityId?: string, params?: SpecialistListParams) => void;
  getCurrentSearchPlaceholder: () => string;
  getCurrentFilter: () => SpecialistFilterType;
  getOptionsFromList: (filterItems?: FacilitySpecialistResponseDTO[]) => SelectData[];
}

export interface SpecialistListParams {
  searchValue?: string;
  selectedFilter?: SpecialistFilterType;
  page?: number;
}

export const useSpecialistList = (): UseSpecialistListReturn => {
  const { specialistList } = useAppSelector(state => state);
  const { filter, search } = specialistList;
  const dispatch = useAppDispatch();

  const loadSpecialistList = (facilityId?: string, params?: SpecialistListParams) => {
    if (!facilityId) {
      errorNotification(getErrorMessage(''));
      return;
    }

    let currentFilter = filter;
    let currentSearch = search;
    let currentPage = 0;

    if (params) {
      const { searchValue, selectedFilter, page } = params;

      if (page) {
        currentPage = page;
      }

      if (selectedFilter && selectedFilter !== filter) {
        currentFilter = selectedFilter;
        dispatch(setFilter(selectedFilter));
      }

      if (searchValue !== undefined && searchValue !== search) {
        currentSearch = getSearchFromString(searchValue).toString();
        dispatch(setSearch(currentSearch));
      }
    }

    const args: GetSpecialistListArguments = {
      facilityId,
      searchValue: currentSearch,
      page: currentPage,
    };

    switch (currentFilter) {
      case SpecialistFilterType.ACTIVE:
        dispatch(getActiveBlockedList({ ...args, isBlocked: false }));
        break;
      case SpecialistFilterType.BLOCKED:
        dispatch(getActiveBlockedList({ ...args, isBlocked: true }));
        break;
      case SpecialistFilterType.INVITED:
        dispatch(getInvitedList(args));
        break;
    }
  };

  const getCurrentFilter = () => filter;

  const getCurrentSearchPlaceholder = () => getStringFromSearch(search);

  const getOptionsFromList = (filterItems?: FacilitySpecialistResponseDTO[]): SelectData[] => {
    return (
      filterItems?.map(item => ({
        label: `${item.user.userProfile.name} ${item.user.userProfile.surname}`,
        value: item.id,
      })) || []
    );
  };

  return {
    loadSpecialistList,
    getCurrentFilter,
    getCurrentSearchPlaceholder,
    getOptionsFromList,
  };
};
