const adductorMagnusLeft = 'lower.left.adductorMagnus';
const adductorMagnusRight = 'lower.right.adductorMagnus';
const adductorPollicisLeft = 'upper.left.adductorPollicis';
const adductorPollicisRight = 'upper.right.adductorPollicis';
const bicepsBrachiiLeft = 'upper.left.bicepsBrachii';
const bicepsBrachiiRight = 'upper.right.bicepsBrachii';
const brachioradialisLeft = 'upper.left.brachioradialis';
const brachioradialisRight = 'upper.right.brachioradialis';
const deltoideusClavicularPartLeft = 'upper.left.deltoideusClavicularPart';
const deltoideusClavicularPartRight = 'upper.right.deltoideusClavicularPart';
const extensorCarpiRadialisBrevisLeft = 'upper.left.extensorCarpiRadialisBrevis';
const extensorCarpiRadialisBrevisRight = 'upper.right.extensorCarpiRadialisBrevis';
const extensorCarpiRadialisLongusLeft = 'upper.left.extensorCarpiRadialisLongus';
const extensorCarpiRadialisLongusRight = 'upper.right.extensorCarpiRadialisLongus';
const extensorCarpiUlnarisLeft = 'upper.left.extensorCarpiUlnaris';
const extensorCarpiUlnarisRight = 'upper.right.extensorCarpiUlnaris';
const extensorDigitorumLeft = 'upper.left.extensorDigitorum';
const extensorDigitorumRight = 'upper.right.extensorDigitorum';
const flexorCarpiRadialisLeft = 'upper.left.flexorCarpiRadialis';
const flexorCarpiRadialisRight = 'upper.right.flexorCarpiRadialis';
const flexorCarpiUlnarisLeft = 'upper.left.flexorCarpiUlnaris';
const flexorCarpiUlnarisRight = 'upper.right.flexorCarpiUlnaris';
const flexorDigitorumSuperficialisLeft = 'upper.left.flexorDigitorumSuperficialis';
const flexorDigitorumSuperficialisRight = 'upper.right.flexorDigitorumSuperficialis';
const gastrocnemiusCaputLateraleLeft = 'lower.left.gastrocnemiusCaputLaterale';
const gastrocnemiusCaputLateraleRight = 'lower.right.gastrocnemiusCaputLaterale';
const gastrocnemiusCaputMedialeLeft = 'lower.left.gastrocnemiusCaputMediale';
const gastrocnemiusCaputMedialeRight = 'lower.right.gastrocnemiusCaputMediale';
const iliopsoasLeft = 'back.left.iliopsoas';
const iliopsoasRight = 'back.right.iliopsoas';
const latissimusDorsiLeft = 'back.left.latissimusDorsi';
const latissimusDorsiRight = 'back.right.latissimusDorsi';
const palmarisLongusLeft = 'upper.left.palmarisLongus';
const palmarisLongusRight = 'upper.right.palmarisLongus';
const popliteusLeft = 'lower.left.popliteus';
const popliteusRight = 'lower.right.popliteus';
const pronatorTeresLeft = 'upper.left.pronatorTeres';
const pronatorTeresRight = 'upper.right.pronatorTeres';
const quadricepsFemorisLeft = 'lower.left.quadricepsFemoris';
const quadricepsFemorisRight = 'lower.right.quadricepsFemoris';
const semimembranosusLeft = 'lower.left.semimembranosus';
const semimembranosusRight = 'lower.right.semimembranosus';
const semitendinosusLeft = 'lower.left.semitendinosus';
const semitendinosusRight = 'lower.right.semitendinosus';
const serratusAnteriorLeft = 'pectoral.left.serratusAnterior';
const serratusAnteriorRight = 'pectoral.right.serratusAnterior';
const soleusLeft = 'lower.left.soleus';
const soleusRight = 'lower.right.soleus';
const tibialisAnteriorLeft = 'lower.left.tibialisAnterior';
const tibialisAnteriorRight = 'lower.right.tibialisAnterior';
const tricepsBrachiiLeft = 'back.left.tricepsBrachii';
const tricepsBrachiiRight = 'back.right.tricepsBrachii';
const tricepsSuraeCaputLateraleLeft = 'lower.left.tricepsSuraeCaputLaterale';
const tricepsSuraeCaputLateraleRight = 'lower.right.tricepsSuraeCaputLaterale';
const tricepsSuraeCaputMedialeLeft = 'lower.left.tricepsSuraeCaputMediale';
const tricepsSuraeCaputMedialeRight = 'lower.right.tricepsSuraeCaputMediale';
const frontalisLeft = 'upper.left.frontalis';
const frontalisRight = 'upper.right.frontalis';
const sternocleidomastoidLeft = 'upper.left.sternocleidomastoid';
const sternocleidomastoidRight = 'upper.right.sternocleidomastoid';
const temporalLeft = 'upper.left.temporal';
const temporalRight = 'upper.right.temporal';
const masseterLeft = 'upper.left.masseter';
const masseterRight = 'upper.right.masseter';
const neckExtensorsLeft = 'upper.left.neckExtensors';
const neckExtensorsRight = 'upper.right.neckExtensors';
const trapeziusAscendensLeft = 'back.left.trapeziusAscendens';
const trapeziusAscendensRight = 'back.right.trapeziusAscendens';
const trapeziusDescendesLeft = 'back.left.trapeziusDescendes';
const trapeziusDescendesRight = 'back.right.trapeziusDescendes';
const trapeziusTransversalisLeft = 'upper.left.trapeziusTransversalis';
const trapeziusTransversalisRight = 'upper.right.trapeziusTransversalis';
const flexorPollicisLongusLeft = 'upper.left.flexorPollicisLongus';
const flexorPollicisLongusRight = 'upper.right.flexorPollicisLongus';
const supraspinatusLeft = 'back.left.supraspinatus';
const supraspinatusRight = 'back.right.supraspinatus';
const erectorSpinaeIliocostalisLeft = 'back.left.erectorSpinaeIliocostalis';
const erectorSpinaeIliocostalisRight = 'back.right.erectorSpinaeIliocostalis';
const erectorSpinaeLongissimusLeft = 'back.left.erectorSpinaeLongissimus';
const erectorSpinaeLongissimusRight = 'back.right.erectorSpinaeLongissimus';
const multifidusLeft = 'back.left.multifidus';
const multifidusRight = 'back.right.multifidus';
const deltoideusAnteriorLeft = 'pectoral.left.deltoideusAnterior';
const deltoideusAnteriorRight = 'pectoral.right.deltoideusAnterior';
const deltoideusMediumLeft = 'upper.left.deltoideusMedium';
const deltoideusMediumRight = 'upper.right.deltoideusMedium';
const deltoideusPosteriorLeft = 'upper.left.deltoideusPosterior';
const deltoideusPosteriorRight = 'upper.right.deltoideusPosterior';
const infraspinatusLeft = 'back.left.infraspinatus';
const infraspinatusRight = 'back.right.infraspinatus';
const tricepsBrachiiCLatLeft = 'upper.left.tricepsBrachiiCLat';
const tricepsBrachiiCLatRight = 'upper.right.tricepsBrachiiCLat';
const tricepsBrachiiCLongLeft = 'upper.left.tricepsBrachiiCLong';
const tricepsBrachiiCLongRight = 'upper.right.tricepsBrachiiCLong';
const pectoralisMajorLeft = 'pectoral.left.pectoralisMajor';
const pectoralisMajorRight = 'pectoral.right.pectoralisMajor';
const pectoralis2MajorLeft = 'pectoral.left.pectoralisMajor2';
const pectoralis2MajorRight = 'pectoral.right.pectoralisMajor2';
const obliquusExternusAbdominisLeft = 'pectoral.left.obliquusExternusAbdominis';
const obliquusExternusAbdominisRight = 'pectoral.right.obliquusExternusAbdominis';
const rectusAbdominisLeft = 'pectoral.left.rectusAbdominis';
const rectusAbdominisRight = 'pectoral.right.rectusAbdominis';
const transversusAbdominisLeft = 'lower.left.transversusAbdominis';
const transversusAbdominisRight = 'lower.right.transversusAbdominis';
const tensorFasciaLataeLeft = 'lower.left.tensorFasciaLatae';
const tensorFasciaLataeRight = 'lower.right.tensorFasciaLatae';
const gluteusMaximusLeft = 'lower.left.gluteusMaximus';
const gluteusMaximusRight = 'lower.right.gluteusMaximus';
const gluteusMediusLeft = 'lower.left.gluteusMedius';
const gluteusMediusRight = 'lower.right.gluteusMedius';
const adductoresLeft = 'lower.left.adductores';
const adductoresRight = 'lower.right.adductores';
const rectusFemorisLeft = 'lower.left.rectusFemoris';
const rectusFemorisRight = 'lower.right.rectusFemoris';
const vastusMedialisLeft = 'lower.left.vastusMedialis';
const vastusMedialisRight = 'lower.right.vastusMedialis';
const vastusLateralisLeft = 'lower.left.vastusLateralis';
const vastusLateralisRight = 'lower.right.vastusLateralis';
const bicepsFemorisLeft = 'lower.left.bicepsFemoris';
const bicepsFemorisRight = 'lower.right.bicepsFemoris';
const gastrocnemiusMedialisLeft = 'lower.left.gastrocnemiusMedialis';
const gastrocnemiusMedialisRight = 'lower.right.gastrocnemiusMedialis';
const peroneusLeft = 'lower.left.peroneus';
const peroneusRight = 'lower.right.peroneus';
const tensAnkleLeft = 'lower.left.tensAnkle';
const tensAnkleRight = 'lower.right.tensAnkle';
const tensElbowLeft = 'upper.left.tensElbow.channels1to2';
const tensElbowRight = 'upper.right.tensElbow.channels1to2';
const tensFootLeft = 'lower.left.tensFoot';
const tensFootRight = 'lower.right.tensFoot';
const tensHandLeft = 'upper.left.tensHand';
const tensHandRight = 'upper.right.tensHand';
const tensLowBack = 'back.no-side.tensLowBack.channels1to2';
const tensUpperBack = 'back.no-side.tensUpperBack.channels1to2';
const tensShoulderLeft = 'upper.left.tensShoulder.channels1to2';
const tensShoulderRight = 'upper.right.tensShoulder.channels1to2';
const tensWristLeft = 'upper.left.tensWrist';
const tensWristRight = 'upper.right.tensWrist';
const tensKneeLeft = 'lower.left.tensKnee.channels1to2';
const tensKneeRight = 'lower.right.tensKnee.channels1to2';
const tensHipLeft = 'lower.left.tensHip.channels1to2';
const tensHipRight = 'lower.right.tensHip.channels1to2';
const vagina = 'lower.no-side.vagina';
const analMale = 'lower.no-side.analMale';
const analFemale = 'lower.no-side.analFemale';
const fesHandToMouthLeftChannel1 = 'fes.left.handToMouth.channel1';
const fesHandToMouthRightChannel1 = 'fes.right.handToMouth.channel1';
const fesHandToMouthLeftChannels2to4 = 'fes.left.handToMouth.channels2to4';
const fesHandToMouthRightChannels2to4 = 'fes.right.handToMouth.channels2to4';
const fesArmSupportLeft = 'fes.left.armSupport.channels1to3';
const fesArmSupportRight = 'fes.right.armSupport.channels1to3';
const fesGraspAndReleaseLeftChannel1 = 'fes.left.graspAndRelease.channel1';
const fesGraspAndReleaseLeftChannel2 = 'fes.left.graspAndRelease.channel2';
const fesGraspAndReleaseLeftChannel3 = 'fes.left.graspAndRelease.channel3';
const fesGraspAndReleaseRightChannel1 = 'fes.right.graspAndRelease.channel1';
const fesGraspAndReleaseRightChannel2 = 'fes.right.graspAndRelease.channel2';
const fesGraspAndReleaseRightChannel3 = 'fes.right.graspAndRelease.channel3';

export const regions = ['back', 'lower', 'pectoral', 'upper'] as const;

export type RegionId = (typeof regions)[number];

export const muscles = {
  all: [
    vagina,
    analMale,
    analFemale,
    fesHandToMouthLeftChannel1,
    fesHandToMouthRightChannel1,
    fesHandToMouthLeftChannels2to4,
    fesHandToMouthRightChannels2to4,
    fesGraspAndReleaseLeftChannel1,
    fesGraspAndReleaseLeftChannel2,
    fesGraspAndReleaseLeftChannel3,
    fesGraspAndReleaseRightChannel1,
    fesGraspAndReleaseRightChannel2,
    fesGraspAndReleaseRightChannel3,
    fesArmSupportLeft,
    fesArmSupportRight,
    adductorMagnusLeft,
    adductorMagnusRight,
    adductorPollicisLeft,
    adductorPollicisRight,
    bicepsBrachiiLeft,
    bicepsBrachiiRight,
    tricepsBrachiiLeft,
    tricepsBrachiiRight,
    brachioradialisLeft,
    brachioradialisRight,
    deltoideusClavicularPartLeft,
    deltoideusClavicularPartRight,
    extensorCarpiRadialisBrevisLeft,
    extensorCarpiRadialisBrevisRight,
    extensorCarpiRadialisLongusLeft,
    extensorCarpiRadialisLongusRight,
    extensorCarpiUlnarisLeft,
    extensorCarpiUlnarisRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    flexorCarpiRadialisLeft,
    flexorCarpiRadialisRight,
    flexorCarpiUlnarisLeft,
    flexorCarpiUlnarisRight,
    flexorDigitorumSuperficialisLeft,
    flexorDigitorumSuperficialisRight,
    gastrocnemiusCaputLateraleLeft,
    gastrocnemiusCaputLateraleRight,
    gastrocnemiusCaputMedialeLeft,
    gastrocnemiusCaputMedialeRight,
    gastrocnemiusMedialisLeft,
    gastrocnemiusMedialisRight,
    iliopsoasLeft,
    iliopsoasRight,
    latissimusDorsiLeft,
    latissimusDorsiRight,
    palmarisLongusLeft,
    palmarisLongusRight,
    popliteusLeft,
    popliteusRight,
    pronatorTeresLeft,
    pronatorTeresRight,
    quadricepsFemorisLeft,
    quadricepsFemorisRight,
    semimembranosusLeft,
    semimembranosusRight,
    semitendinosusLeft,
    semitendinosusRight,
    serratusAnteriorLeft,
    serratusAnteriorRight,
    soleusLeft,
    soleusRight,
    tibialisAnteriorLeft,
    tibialisAnteriorRight,
    tricepsSuraeCaputLateraleLeft,
    tricepsSuraeCaputLateraleRight,
    tricepsSuraeCaputMedialeLeft,
    tricepsSuraeCaputMedialeRight,
    frontalisLeft,
    frontalisRight,
    sternocleidomastoidLeft,
    sternocleidomastoidRight,
    temporalLeft,
    temporalRight,
    masseterLeft,
    masseterRight,
    neckExtensorsLeft,
    neckExtensorsRight,
    trapeziusAscendensLeft,
    trapeziusAscendensRight,
    trapeziusDescendesLeft,
    trapeziusDescendesRight,
    trapeziusTransversalisLeft,
    trapeziusTransversalisRight,
    flexorPollicisLongusLeft,
    flexorPollicisLongusRight,
    supraspinatusLeft,
    supraspinatusRight,
    erectorSpinaeIliocostalisLeft,
    erectorSpinaeIliocostalisRight,
    erectorSpinaeLongissimusLeft,
    erectorSpinaeLongissimusRight,
    multifidusLeft,
    multifidusRight,
    deltoideusAnteriorLeft,
    deltoideusAnteriorRight,
    deltoideusMediumLeft,
    deltoideusMediumRight,
    deltoideusPosteriorLeft,
    deltoideusPosteriorRight,
    infraspinatusLeft,
    infraspinatusRight,
    tricepsBrachiiCLatLeft,
    tricepsBrachiiCLatRight,
    tricepsBrachiiCLongLeft,
    tricepsBrachiiCLongRight,
    pectoralisMajorLeft,
    pectoralisMajorRight,
    pectoralis2MajorLeft,
    pectoralis2MajorRight,
    obliquusExternusAbdominisLeft,
    obliquusExternusAbdominisRight,
    rectusAbdominisLeft,
    rectusAbdominisRight,
    transversusAbdominisLeft,
    transversusAbdominisRight,
    tensorFasciaLataeLeft,
    tensorFasciaLataeRight,
    gluteusMaximusLeft,
    gluteusMaximusRight,
    gluteusMediusLeft,
    gluteusMediusRight,
    adductoresLeft,
    adductoresRight,
    rectusFemorisLeft,
    rectusFemorisRight,
    vastusLateralisLeft,
    vastusLateralisRight,
    vastusMedialisLeft,
    vastusMedialisRight,
    bicepsFemorisLeft,
    bicepsFemorisRight,
    peroneusLeft,
    peroneusRight,
    tensAnkleLeft,
    tensAnkleRight,
    tensElbowLeft,
    tensElbowRight,
    tensFootLeft,
    tensFootRight,
    tensHandLeft,
    tensHandRight,
    tensLowBack,
    tensUpperBack,
    tensShoulderLeft,
    tensShoulderRight,
    tensWristLeft,
    tensWristRight,
    tensKneeLeft,
    tensKneeRight,
    tensHipLeft,
    tensHipRight,
  ],
  'sidra-leg': [
    adductorMagnusLeft,
    adductorMagnusRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    gastrocnemiusCaputLateraleLeft,
    gastrocnemiusCaputLateraleRight,
    gastrocnemiusCaputMedialeLeft,
    gastrocnemiusCaputMedialeRight,
    iliopsoasLeft,
    iliopsoasRight,
    popliteusLeft,
    popliteusRight,
    quadricepsFemorisLeft,
    quadricepsFemorisRight,
    semimembranosusLeft,
    semimembranosusRight,
    semitendinosusLeft,
    semitendinosusRight,
    soleusLeft,
    soleusRight,
    tibialisAnteriorLeft,
    tibialisAnteriorRight,
    tricepsSuraeCaputLateraleLeft,
    tricepsSuraeCaputLateraleRight,
    tricepsSuraeCaputMedialeLeft,
    tricepsSuraeCaputMedialeRight,
  ],
  'meissa-ot': [
    adductorPollicisLeft,
    adductorPollicisRight,
    bicepsBrachiiLeft,
    bicepsBrachiiRight,
    brachioradialisLeft,
    brachioradialisRight,
    deltoideusClavicularPartLeft,
    deltoideusClavicularPartRight,
    extensorCarpiRadialisBrevisLeft,
    extensorCarpiRadialisBrevisRight,
    extensorCarpiRadialisLongusLeft,
    extensorCarpiRadialisLongusRight,
    extensorCarpiUlnarisLeft,
    extensorCarpiUlnarisRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    flexorCarpiRadialisLeft,
    flexorCarpiRadialisRight,
    flexorCarpiUlnarisLeft,
    flexorCarpiUlnarisRight,
    flexorDigitorumSuperficialisLeft,
    flexorDigitorumSuperficialisRight,
    latissimusDorsiLeft,
    latissimusDorsiRight,
    palmarisLongusLeft,
    palmarisLongusRight,
    pronatorTeresLeft,
    pronatorTeresRight,
    serratusAnteriorLeft,
    serratusAnteriorRight,
    tricepsBrachiiLeft,
    tricepsBrachiiRight,
  ],
  'stella-bio': [
    frontalisLeft,
    frontalisRight,
    temporalLeft,
    temporalRight,
    masseterLeft,
    masseterRight,
    sternocleidomastoidLeft,
    sternocleidomastoidRight,
    neckExtensorsLeft,
    neckExtensorsRight,
    trapeziusDescendesLeft,
    trapeziusDescendesRight,
    trapeziusTransversalisLeft,
    trapeziusTransversalisRight,
    trapeziusAscendensLeft,
    trapeziusAscendensRight,
    pectoralisMajorLeft,
    pectoralisMajorRight,
    pectoralis2MajorLeft,
    pectoralis2MajorRight,
    deltoideusAnteriorLeft,
    deltoideusAnteriorRight,
    deltoideusMediumLeft,
    deltoideusMediumRight,
    deltoideusPosteriorLeft,
    deltoideusPosteriorRight,
    supraspinatusLeft,
    supraspinatusRight,
    infraspinatusLeft,
    infraspinatusRight,
    erectorSpinaeIliocostalisLeft,
    erectorSpinaeIliocostalisRight,
    erectorSpinaeLongissimusLeft,
    erectorSpinaeLongissimusRight,
    latissimusDorsiLeft,
    latissimusDorsiRight,
    multifidusLeft,
    multifidusRight,
    serratusAnteriorLeft,
    serratusAnteriorRight,
    obliquusExternusAbdominisLeft,
    obliquusExternusAbdominisRight,
    rectusAbdominisLeft,
    rectusAbdominisRight,
    bicepsBrachiiLeft,
    bicepsBrachiiRight,
    tricepsBrachiiLeft,
    tricepsBrachiiRight,
    tricepsBrachiiCLatLeft,
    tricepsBrachiiCLatRight,
    tricepsBrachiiCLongLeft,
    tricepsBrachiiCLongRight,
    brachioradialisLeft,
    brachioradialisRight,
    deltoideusClavicularPartLeft,
    deltoideusClavicularPartRight,
    extensorCarpiRadialisBrevisLeft,
    extensorCarpiRadialisBrevisRight,
    extensorCarpiRadialisLongusLeft,
    extensorCarpiRadialisLongusRight,
    extensorCarpiUlnarisLeft,
    extensorCarpiUlnarisRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    popliteusLeft,
    popliteusRight,
    pronatorTeresLeft,
    pronatorTeresRight,
    semimembranosusLeft,
    semimembranosusRight,
    flexorCarpiRadialisLeft,
    flexorCarpiRadialisRight,
    flexorCarpiUlnarisLeft,
    flexorCarpiUlnarisRight,
    flexorDigitorumSuperficialisLeft,
    flexorDigitorumSuperficialisRight,
    flexorPollicisLongusLeft,
    flexorPollicisLongusRight,
    tricepsSuraeCaputLateraleLeft,
    tricepsSuraeCaputLateraleRight,
    tricepsSuraeCaputMedialeLeft,
    tricepsSuraeCaputMedialeRight,
    transversusAbdominisLeft,
    transversusAbdominisRight,
    tensorFasciaLataeLeft,
    tensorFasciaLataeRight,
    gluteusMaximusLeft,
    gluteusMaximusRight,
    gluteusMediusLeft,
    gluteusMediusRight,
    adductoresLeft,
    adductoresRight,
    rectusFemorisLeft,
    rectusFemorisRight,
    vastusLateralisLeft,
    vastusLateralisRight,
    vastusMedialisLeft,
    vastusMedialisRight,
    bicepsFemorisLeft,
    bicepsFemorisRight,
    semitendinosusLeft,
    semitendinosusRight,
    adductorMagnusLeft,
    adductorMagnusRight,
    gastrocnemiusCaputLateraleLeft,
    gastrocnemiusCaputLateraleRight,
    gastrocnemiusMedialisLeft,
    gastrocnemiusMedialisRight,
    iliopsoasLeft,
    iliopsoasRight,
    palmarisLongusLeft,
    palmarisLongusRight,
    quadricepsFemorisLeft,
    quadricepsFemorisRight,
    tibialisAnteriorLeft,
    tibialisAnteriorRight,
    peroneusLeft,
    peroneusRight,
    soleusLeft,
    soleusRight,
    fesHandToMouthLeftChannel1,
    fesHandToMouthRightChannel1,
    fesHandToMouthLeftChannels2to4,
    fesHandToMouthRightChannels2to4,
    fesGraspAndReleaseLeftChannel1,
    fesGraspAndReleaseLeftChannel2,
    fesGraspAndReleaseLeftChannel3,
    fesGraspAndReleaseRightChannel1,
    fesGraspAndReleaseRightChannel2,
    fesGraspAndReleaseRightChannel3,
    fesArmSupportLeft,
    fesArmSupportRight,
    tensUpperBack,
    tensLowBack,
    tensShoulderLeft,
    tensShoulderRight,
    tensElbowLeft,
    tensElbowRight,
    tensHandLeft,
    tensHandRight,
    tensWristLeft,
    tensWristRight,
    tensHipLeft,
    tensHipRight,
    tensKneeLeft,
    tensKneeRight,
    tensAnkleLeft,
    tensAnkleRight,
    tensFootLeft,
    tensFootRight,
    analMale,
    analFemale,
    vagina,
  ],
  unspecified: [],
} as const;

export type MuscleId = (typeof muscles)['all'][number];

export function getMuscleName(id: MuscleId) {
  const hasChannel = id.split('.').at(-1)?.includes('channel');
  const end = id.split('.').at(-1);
  return (hasChannel ? `${id.split('.').at(-2)}.${end}` : end) ?? 'unknown';
}

export function getMuscleIdByName(name: string) {
  return muscles.all.find(v => v.includes(name));
}

export function extractChannelCount(id: MuscleId | RegionId) {
  const channelsPart = id.split('channels')[1];

  if (channelsPart) {
    const [start, end] = channelsPart.split('to').map(Number);
    return [start, end - start + 1] as const;
  }

  return [1, 1] as const;
}

export function isMuscleValidForChannelsRange(muscleId: MuscleId | RegionId, comparedId: MuscleId | RegionId) {
  const baseMuscle1 = muscleId.split('.').slice(0, -1).join('.');
  const baseMuscle2 = comparedId.split('.').slice(0, -1).join('.');

  if (baseMuscle1 !== baseMuscle2) {
    return false;
  }

  const match1 = muscleId.match(/channel(\d+)/);
  if (!match1) {
    return false;
  }

  const channel1 = parseInt(match1[1], 10);
  const match2 = comparedId.match(/channels(\d+)to(\d+)/);
  if (!match2) {
    return false;
  }

  const startChannel = parseInt(match2[1], 10);
  const endChannel = parseInt(match2[2], 10);

  return channel1 >= startChannel && channel1 <= endChannel;
}
