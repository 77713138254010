import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { FavoriteIcon } from 'components/icons';
import { MainHeading } from 'components/texts/MainHeading';

interface Props {
  name: string;
  favorite: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const TrainingItemHeader: FC<Props> = ({ name, favorite, onClick }: Props) => (
  <Flex justify="space-between">
    <MainHeading variant="subheading" pt="2.5" pb="1" text={`training.headings.title.${name}`} overflow="hidden" />
    <FavoriteIcon
      onClick={onClick}
      fontSize="2.5rem"
      lineHeight="3.4375rem"
      color={favorite ? 'stellaBioPrimaryColor' : 'gray.300'}
      mt="-5"
      mr="1"
      _hover={{
        cursor: 'pointer',
      }}
    />
  </Flex>
);
