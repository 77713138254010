import React from 'react';
import { createRoot } from 'react-dom/client';
import { Logger, LoggerConsoleNode, LoggerLevels } from '@egzotech/universal-logger-js';

import App from './App';
import reportWebVitals from './reportWebVitals';
Logger.setGlobalLogger(
  new Logger([new LoggerConsoleNode()], {
    level: process.env.REACT_APP_LOG_LEVEL
      ? (Object.entries(LoggerLevels).find(([k]) => k === process.env.REACT_APP_LOG_LEVEL)?.[1] as LoggerLevels)
      : LoggerLevels.Info,
  }),
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);

if (!process.env.REACT_APP_SHOW_INTRO) {
  const introBackground = document.getElementById('intro-background');

  if (introBackground) {
    introBackground.style.opacity = '0';
    introBackground.style.pointerEvents = 'none';
  }
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function

reportWebVitals();
