import { FC } from 'react';
import { DeepMap, FieldError, FieldValues, get } from 'react-hook-form';
import { Box, FormErrorMessage, FormHelperText } from '@chakra-ui/react';

import { TranslateText } from 'components/texts/TranslateText';

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> = DeepMap<TFieldValues, FieldError>;

interface Props {
  name: string;
  errors?: FieldErrors;
  helperTxt?: string;
  errorTxt?: string | null;
  uniqueErrorTxt?: string;
  customHeight?: boolean;
  isCheckbox?: boolean;
  fontSize?: string;
}

export const FieldHelpBox: FC<Props> = ({
  helperTxt,
  errors,
  name,
  errorTxt,
  uniqueErrorTxt,
  customHeight,
  isCheckbox,
  fontSize = 'md',
}: Props) => {
  const getHeight = () => {
    if (isCheckbox && (get(errors, name)?.message ?? errorTxt) === undefined) return '2';
    if (get(errors, name)?.message ?? errorTxt) return 'auto';
    else if (customHeight) return '2';
    else return '4';
  };

  return (
    <Box h={getHeight()} role="status">
      {helperTxt && !errors?.[name] && (
        <FormHelperText mt={0} fontSize={fontSize} id={name}>
          {helperTxt}
        </FormHelperText>
      )}
      <FormErrorMessage
        role="alert"
        mt="0.5"
        mb="2"
        fontSize={fontSize}
        lineHeight={fontSize}
        color="errorMediumColor"
        data-cy="form-error"
      >
        {uniqueErrorTxt ? (
          <TranslateText text={uniqueErrorTxt} />
        ) : (
          <>{get(errors, name)?.message ?? (errorTxt && <TranslateText text={errorTxt} />)}</>
        )}
      </FormErrorMessage>
    </Box>
  );
};
