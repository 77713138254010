import breakpoints from './breakpoints';

const mediaQuerySizes = {
  lessThanSm: `(max-width: ${breakpoints.sm})`,
  lessThanMd: `(max-width: ${breakpoints.md})`,
  lessThanLg: `(max-width: ${breakpoints.lg})`,
  lessThanXl: `(max-width: ${breakpoints.xl})`,
};

export default mediaQuerySizes;
