import { __ } from 'helpers/i18n';
import { useSignal, useSignalEffect } from 'helpers/signal';
import { EMGChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';
import { EMGExerciseDefinition } from 'libs/exo-session-manager/core';

import { TimelineChart } from 'components/timeline-charts/TimelineChart';

/**
 * Width in seconds of emg chart
 */
export const EMG_WINDOW_WIDTH = 20;

export const EMGTimelineChart = (props: {
  chartDataSource: EMGChartDataSource;
  emgExerciseDefinition?: EMGExerciseDefinition;
  yMax: number;
  yMaxMvc: number;
  hideChannels?: number[];
}) => {
  const { chartDataSource, yMax, yMaxMvc, hideChannels, emgExerciseDefinition } = props;
  const time = useSignal(chartDataSource.timeSource.duration ?? 0, '');

  useSignalEffect(() => {
    const interval = setInterval(() => {
      time.value = chartDataSource.timeSource.duration ?? 0;
    }, 50);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <TimelineChart
      chartDataSource={chartDataSource}
      time={time}
      options={{
        shiftDatasetsOnAxisY: false,
        showTimeMark: false,
        windowWidth: EMG_WINDOW_WIDTH,
        yAxisUnit: __('units.microvolts'),
        yAxisMax: yMax,
        yAxisMvc: yMaxMvc,
        hideChannels,
        emgExerciseDefinition,
      }}
    />
  );
};
