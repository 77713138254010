import { Center, Container, HStack, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import { useAppNavigate } from 'hooks/useAppNavigate';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from '../Modal';

interface AlertModalProps extends ModalProps {
  heading: string;
  description: string;
  imageSrc: string;
}

export const AlertModal = ({ heading, description, imageSrc, callback, close }: AlertModalProps) => {
  const navigate = useAppNavigate();
  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="4">
      <ModalBody>
        <Container
          variant="modalBox"
          py="4"
          px="0"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
        >
          <VStack justify="space-between" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text={heading} />
            </Center>
            <Image src={imageSrc} maxH={{ base: '2xs', '2xl': 'sm' }} maxW={{ base: '2xs', '2xl': 'sm' }} />
            <TranslateText variant="openSans24" text={description} />
            <HStack w="full" justifyContent="space-evenly">
              <MainButton
                text="modals.alerts.exit"
                variant="mdOutlinedPrimaryButton"
                onClick={() => {
                  close();
                  navigate('/', { replace: true });
                }}
                minW="32"
              />
              <MainButton
                text="modals.alerts.solve"
                variant="mdPrimaryButton"
                onClick={() => {
                  close();
                  callback?.();
                }}
                minW="32"
              />
            </HStack>
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
