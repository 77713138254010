import { useMemo } from 'react';
import { CalibrationFlow, GeneratedExerciseDefinition } from 'libs/exo-session-manager/core';
import { SettingsBuilder } from 'libs/exo-session-manager/core/settings/SettingsBuilder';
import { useCombinedInitialCalibrationData } from 'libs/exo-session-manager/react';

import { useProgramSelection } from '../providers/ExoGlobalProvider';

import { useCalibrationFlowState } from './useCalibrationFlowState';

export const useBasingSettings = (flow: CalibrationFlow) => {
  const [basingSettingsData] = useCalibrationFlowState(flow, 'basing-settings');
  const storedProgram = useCombinedInitialCalibrationData();
  const { selectedProgram } = useProgramSelection();

  const definition =
    flow.definition.states['basing-settings'] && basingSettingsData
      ? // If we have data from the parameters screen, let's use it
        (basingSettingsData.definition as GeneratedExerciseDefinition)
      : // If there was no parameter screen in the flow or it was skipped, then let's take the data from the previous exercise if it was repeated
      storedProgram?.['basing-settings']
      ? (storedProgram?.['basing-settings'].definition as GeneratedExerciseDefinition)
      : // In other cases, let's take the basic definition of exercise
        selectedProgram;

  if (!definition) {
    throw new Error('useBasingSettings hook needs to be used with selected program');
  }

  const settings = useMemo(() => new SettingsBuilder(definition), [definition]);

  return {
    settings,
    definition,
  };
};
