import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleError } from 'helpers/handleError';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiPaths, ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

import { PatientsRecentTrainingsProps, setPatientsRecentTrainings } from './clinicReportSlice';

export enum TrainingFilterType {
  PACKAGE = 'package',
  TRAINING = 'training',
  BODY = 'body',
  ICD = 'icd',
}

export interface TrainingListState {
  trainingList: ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDto'][];
  recentList: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'][];
  availableFilters?: ExoClinicBackendOpenApiSchemas['TrainingTemplateFiltersResponseDto'];
  currentFilters: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query'];
  isFiltersAvailable: boolean;
  isFavorite: boolean;
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
  isEndOfPages: boolean;
  nextPage: number;
}

export const emptyFilters: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query'] = {
  userProfileId: '',
  name: '',
  classificationPackageId: [],
  trainingTypeId: [],
  bodyPartId: [],
  icd10DiagnoseId: [],
  deviceTypeId: [],
};

const initialState: TrainingListState = {
  trainingList: [],
  recentList: [],
  currentFilters: emptyFilters,
  isFiltersAvailable: false,
  isFavorite: false,
  inProgress: false,
  isError: false,
  isLoaded: false,
  isEndOfPages: false,
  nextPage: 0,
};

export type RecentListResponse = ExoClinicBackendOpenApiSchemas['TrainingResponseDtoPagedPayloadDto'];

export interface GetRecentTrainingsListArguments {
  userId: string;
  specialistId?: string;
  page?: number;
}

export interface SetFavoriteTrainingArgs {
  templateId: string;
  favorite: boolean;
}

export const getTrainingList = createAsyncThunk(
  'training-templates',
  async (params: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query']) => {
    const trainingList = await exoClinicApi.trainingTemplates.all(params).catch(err => {
      handleError(err.response);
    });
    return trainingList as ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDtoPagedPayloadDto'];
  },
);

export const getFavoriteTrainingList = createAsyncThunk(
  'training-templates/favorite',
  async (params: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query']) => {
    const favoriteTrainingList = await exoClinicApi.trainingTemplates.favorite(params).catch(err => {
      handleError(err.response);
    });

    return favoriteTrainingList as ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDtoPagedPayloadDto'];
  },
);

export const getRecentList = createAsyncThunk(
  'trainings/recent',
  async (arg: GetRecentTrainingsListArguments, thunkAPI) => {
    const recentList = await exoClinicApi.trainings
      .recent({
        path: { userProfileId: arg.userId },
        query: { pageNumber: arg.page ?? 0, pageSize: 10 },
      })
      .catch(err => {
        handleError(err.response);
      });

    if (arg.specialistId) {
      if (recentList) {
        const payload: PatientsRecentTrainingsProps = {
          specialistId: arg.specialistId,
          patientId: arg.userId,
          recentTrainings: recentList,
        };
        thunkAPI.dispatch(setPatientsRecentTrainings(payload));
      }
    }

    return recentList as RecentListResponse;
  },
);

export const getAvailablePackages = createAsyncThunk('training-templates/packages', async () => {
  const availablePackages = await exoClinicApi.trainingTemplates.packages().catch(err => {
    handleError(err.response);
  });
  return availablePackages as ExoClinicBackendOpenApiSchemas['ClassificationPackageResponseDto'][];
});

export const getAvailableDeviceTypes = createAsyncThunk('training-templates/device-types', async () => {
  const availableDeviceTypes = await exoClinicApi.trainingTemplates.deviceTypes().catch(err => {
    handleError(err.response);
  });
  return availableDeviceTypes as ExoClinicBackendOpenApiSchemas['DeviceTypeResponseDto'][];
});

export const getAvailableFilters = createAsyncThunk('training-templates/filters', async () => {
  const availableFilters = await exoClinicApi.trainingTemplates.filters().catch(err => {
    handleError(err.response);
  });
  return availableFilters as ExoClinicBackendOpenApiSchemas['TrainingTemplateFiltersResponseDto'];
});

export const trainingListSlice = createSlice({
  name: 'trainingList',
  initialState,
  reducers: {
    setFilters: (
      state,
      { payload }: PayloadAction<ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query']>,
    ) => {
      state.currentFilters = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.currentFilters.name = payload;
    },
    setPatientId: (state, { payload }: PayloadAction<string>) => {
      state.currentFilters.userProfileId = payload;
    },
    setFavoriteCategory: (state, { payload }: PayloadAction<boolean>) => {
      state.isFavorite = payload;
    },
    setFavoriteTraining: (state, { payload }: PayloadAction<SetFavoriteTrainingArgs>) => {
      const { favorite, templateId } = payload;
      const trainingIndex = state.trainingList.findIndex(item => item.id === templateId);

      if (trainingIndex === -1) {
        return;
      }

      if (state.isFavorite) {
        state.trainingList.splice(trainingIndex, 1);
        return;
      }

      state.trainingList[trainingIndex].favorite = favorite;
    },
    setTrainingList: (
      state,
      { payload }: PayloadAction<ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDto'][]>,
    ) => {
      state.inProgress = false;
      state.isLoaded = true;
      state.trainingList = payload;
      state.nextPage = 0;
      state.isEndOfPages = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getTrainingList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getTrainingList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.trainingList.length || !pageable?.pageNumber) {
        state.trainingList = content;
        state.nextPage = 0;
      } else {
        content.forEach(payloadPatient => {
          const indexIfExist = state.trainingList.findIndex(storePatient => storePatient.id === payloadPatient.id);

          if (indexIfExist === -1) {
            state.trainingList.push(payloadPatient);
            return;
          }

          state.trainingList[indexIfExist] = payloadPatient;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getTrainingList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });

    builder.addCase(getFavoriteTrainingList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getFavoriteTrainingList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.trainingList.length || !pageable?.pageNumber) {
        state.trainingList = content;
        state.nextPage = 0;
      } else {
        content.forEach(payloadPatient => {
          const indexIfExist = state.trainingList.findIndex(storePatient => storePatient.id === payloadPatient.id);

          if (indexIfExist === -1) {
            state.trainingList.push(payloadPatient);
            return;
          }

          state.trainingList[indexIfExist] = payloadPatient;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getFavoriteTrainingList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });

    builder.addCase(getRecentList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getRecentList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;
      const { pageable, content, last } = payload;

      if (!state.recentList.length || !pageable?.pageNumber) {
        state.nextPage = 0;
        state.recentList = content ?? [];
      } else {
        content?.forEach(payloadPatient => {
          const indexIfExist = state.recentList.findIndex(storePatient => storePatient.id === payloadPatient.id);

          if (indexIfExist === -1) {
            state.recentList.push(payloadPatient);
            return;
          }

          state.recentList[indexIfExist] = payloadPatient;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getRecentList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });

    builder.addCase(getAvailableFilters.pending, state => {
      state.isFiltersAvailable = false;
    });

    builder.addCase(getAvailableFilters.fulfilled, (state, { payload }) => {
      state.isFiltersAvailable = true;
      state.availableFilters = payload;
    });

    builder.addCase(getAvailableFilters.rejected, state => {
      state.isFiltersAvailable = false;
    });
  },
});

export const { setFilters, setSearch, setPatientId, setFavoriteCategory, setFavoriteTraining, setTrainingList } =
  trainingListSlice.actions;

export default trainingListSlice.reducer;
