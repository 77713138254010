import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ExoElectrostimFeature, ExoElectrostimInterrupt } from '@egzotech/exo-session/features/electrostim';
import { useSignals } from '@preact/signals-react/runtime';
import { ModalType } from 'containers/modals/Modal';
import { logger } from 'helpers/logger';
import { useSignal } from 'helpers/signal';
import { useAppDispatch } from 'hooks/store';
import {
  CalibrationFlow,
  GeneratedElectrostimLikeExerciseDefinition,
  GeneratedExerciseDefinition,
  isEMSExerciseDefinition,
} from 'libs/exo-session-manager/core';
import EMSCalibration from 'libs/exo-session-manager/core/common/EMSCalibration';
import { openModal } from 'slices/modalSlice';
import { ChannelToRoleMap } from 'views/+patientId/training/+trainingId/_components/ChannelRoleSelector';
import { useChannelRoleSelector } from 'views/+patientId/training/+trainingId/_components/ConnectElectrodes';

import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { EMSInstanceContext } from '../providers/EMSInstanceContext';

import { useDevice } from './useDevice';
import { useExerciseWithEMS } from './useExerciseWithEMS';

export interface EMSCalibrationInstance {
  definition: GeneratedElectrostimLikeExerciseDefinition;
  calibration: EMSCalibration;
}

export const useFlowEMSCalibration = (
  flow: CalibrationFlow,
  definition: GeneratedExerciseDefinition,
  instanceCount: number,
  instanceIndex: number,
) => {
  if (!isEMSExerciseDefinition(definition)) {
    throw new Error('Cannot use useFlowEMSCalibration for a program that does not contain electrostimulation');
  }

  const [channelRoleSelectorData] = useChannelRoleSelector(flow, instanceIndex);

  if (!channelRoleSelectorData) {
    throw new Error('Cannot use useFlowEMSCalibration hook without setting channel roles first');
  }

  const dispatch = useAppDispatch();
  useSignals();
  const { session } = useDevice();
  const electrostimFeature = useSignal<ExoElectrostimFeature | null>(null, 'useFlowEMSCalibration.electrostimFeature');

  const [instances, setInstances] = useState<EMSCalibrationInstance[]>([
    { definition, calibration: new EMSCalibration() },
  ]);

  const onElectrostimInterrupt = useCallback(
    (type: ExoElectrostimInterrupt) => {
      switch (type) {
        case ExoElectrostimInterrupt.Overvoltage:
          logger.info('onElectrostimInterrupt', 'Interrupting the exericse due to electrostim overvoltage');
          dispatch(
            openModal({
              type: ModalType.OVERVOLTAGE_FLAG,
              callback: () => electrostimFeature.value?.clearInterrupt(),
            }),
          );
      }
    },
    // electrostimFeature.value will create a circular dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  useEffect(() => {
    const instances = Array(instanceCount)
      .fill(null)
      .map(() => {
        const calibration = new EMSCalibration();

        if (session) {
          electrostimFeature.value = session.activate(ExoElectrostimFeature);
          electrostimFeature.value.onInterrupt = onElectrostimInterrupt;
          calibration.setFeature(electrostimFeature.value);
        }

        return { definition, calibration };
      });

    setInstances(instances);

    return () => instances.forEach(v => v.calibration.feature?.dispose());
  }, [instanceCount, session, definition, onElectrostimInterrupt, electrostimFeature]);

  return {
    ...useEMSCalibration(channelRoleSelectorData.channelRoles, instances, instanceIndex),
    channelRoles: channelRoleSelectorData.channelRoles,
    instances: instances,
  };
};

export const useEMSCalibration = (
  channelRoles: ChannelToRoleMap,
  instances?: EMSCalibrationInstance[],
  instanceIndex?: number,
) => {
  const emsCalibration = useBaseEMSCalibration(channelRoles, instanceIndex, instances);
  if (!emsCalibration) {
    throw new Error('useEMSCalibration must be used within a Provider which requires EMS feature');
  }
  return emsCalibration;
};

export const useBaseEMSCalibration = (
  channelRoles: ChannelToRoleMap | null,
  selectedEMSInstanceId?: number,
  instances?: EMSCalibrationInstance[],
) => {
  const cpmEmsInstance = useContext(CPMElectrostimInstanceContext);
  const emsInstance = useContext(EMSInstanceContext);

  const activeInstance = useMemo(() => {
    if (instances) {
      return {
        setProgram: () => {
          const id = selectedEMSInstanceId ?? 0;
          const definition = instances[id].definition.ems.program;

          if (instances.length > 1) {
            const onePhaseProgram = {
              ...definition,
              phases: [definition.phases[id]],
              stimCalibration: [definition.stimCalibration[id]],
            };

            instances[id].calibration.feature?.setProgram(onePhaseProgram);
          } else {
            instances[id].calibration.feature?.setProgram(definition);
          }
        },
        emsCalibration: instances[selectedEMSInstanceId ?? 0].calibration,
      };
    }
    if (cpmEmsInstance) {
      if (selectedEMSInstanceId !== undefined) {
        cpmEmsInstance.selectEMSInstance(selectedEMSInstanceId);
      }
      return {
        setProgram: cpmEmsInstance.setProgram.bind(cpmEmsInstance),
        emsCalibration: cpmEmsInstance.emsCalibration,
      };
    }
    if (emsInstance) {
      return {
        setProgram: emsInstance.setProgram.bind(emsInstance),
        emsCalibration: emsInstance.emsCalibration,
      };
    }

    // TODO: add missing contexts (Electrostim, ElectrostimEMG)

    return null;
  }, [cpmEmsInstance, emsInstance, instances, selectedEMSInstanceId]);

  const start = useCallback(() => {
    if (!channelRoles) {
      logger.warn('useEMSCalibration', 'Channels are not mapped. Check if electrodes are connected to the muscles');
    }
    if (!activeInstance) {
      logger.warn('useEMSCalibration', 'EMSCalibration cannot be started without EMS Feature');
      return;
    }
    if (channelRoles && !activeInstance.emsCalibration.data.peek().active && activeInstance.emsCalibration.feature) {
      const channels = Object.entries(channelRoles)
        .filter(([_, v]) => v.includes('electrostim'))
        .map(([k]) => +k);

      if (!activeInstance.emsCalibration.data.peek().calibrated) {
        activeInstance.setProgram();
        activeInstance.emsCalibration.start(channels);
      } else {
        activeInstance.emsCalibration.resume(channels);
      }
    }
  }, [activeInstance, channelRoles]);

  if (!channelRoles) {
    return null;
  }

  if (activeInstance) {
    return {
      ...activeInstance.emsCalibration.data.value,
      start,
      selectPort: activeInstance.emsCalibration.selectPort.bind(activeInstance.emsCalibration),
      setIntensity: activeInstance.emsCalibration.setIntensity.bind(activeInstance.emsCalibration),
      storeCalibrationData: activeInstance.emsCalibration.storeCalibrationData.bind(activeInstance.emsCalibration),
      restoreCalibrationData: activeInstance.emsCalibration.restoreCalibrationData.bind(activeInstance.emsCalibration),
      end: activeInstance.emsCalibration.end.bind(activeInstance.emsCalibration),
    };
  }

  return null;
};

export const useStoreCalibrationDataOnInit = (channelRoles: ChannelToRoleMap | null) => {
  const exercise = useExerciseWithEMS();

  const start = useCallback(() => {
    if (!channelRoles) {
      logger.warn(
        'useStoreCalibrationDataOnInit',
        'Channels are not mapped. Check if electrodes are connected to the muscles',
      );
    }
    if (channelRoles && !exercise.emsCalibration.data.peek().active) {
      const channels = Object.entries(channelRoles)
        .filter(([_, v]) => v.includes('electrostim'))
        .map(([k]) => +k);

      if (!exercise.emsCalibration.data.peek().calibrated) {
        exercise.setProgram();
        exercise.emsCalibration.start(channels);
      } else {
        exercise.emsCalibration.resume(channels);
      }
    }
  }, [channelRoles, exercise]);

  const restoreCalibrationData = useCallback(() => {
    start();
    exercise.emsCalibration.restoreCalibrationData();
    exercise.emsCalibration.end();
  }, [exercise.emsCalibration, start]);

  useEffect(() => {
    start();
    exercise.emsCalibration.storeCalibrationData();
    exercise.emsCalibration.end();

    // store data only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { restoreCalibrationData };
};
