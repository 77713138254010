const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280
  'xl+': '94em', // 1504 for zebra
  '2xl': '120em', // for 1920
};

export default breakpoints;
