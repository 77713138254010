import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';
import { logger } from 'helpers/logger';
import { DeepPartial } from 'redux';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export type Comment = ExoClinicBackendOpenApiSchemas['PatientCommentResponseDto'] & {
  patientId: string;
};

export type FacilityPatientInfoResponseDtoOptional = DeepPartial<
  ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto']
>;

export const patientCardsApi = createApi({
  reducerPath: 'patientCardsApi',
  baseQuery: fetchBaseQuery({
    fetchFn: queryFetchFn,
    baseUrl: API_URL,
  }),
  tagTypes: ['PatientCard'],
  endpoints: builder => ({
    getSpecialistPatientCard: builder.query<ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto'], string>({
      query: id => `patient/${id}`,
      providesTags: (result, error, id) => [{ type: 'PatientCard', id }],
    }),
    getPatientStatistics: builder.query<
      ExoClinicBackendOpenApiSchemas['PatientStatisticsResponseDto'],
      { patientId: string } & ExoClinicBackendOpenApiSchemas['PatientStatisticsRequestDto']
    >({
      query: ({ patientId, ...body }) => ({
        url: `patient/${patientId}/statistics`,
        method: 'POST',
        body,
      }),
      providesTags: result => [{ type: 'PatientCard', id: JSON.stringify(result) }],
    }),
    updateSpecialistPatientData: builder.mutation<
      ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto']['userProfile'],
      DeepPartial<ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto']['userProfile']> &
        Pick<ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto'], 'patientCardId'>
    >({
      query: ({ patientCardId, ...body }) => ({
        url: `user/${patientCardId}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ patientCardId, ...patch }, { dispatch, queryFulfilled }) {
        // instead of cache invalidation perform optimistic update
        const patchResult = dispatch(
          patientCardsApi.util.updateQueryData('getSpecialistPatientCard', patientCardId, draft => {
            Object.assign(draft, { userProfile: patch });
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('patientCardsApi.updateSpecialistPatientCard.onQueryStarted', 'Error: ', error);
          patchResult.undo();
        }
      },
      // cache invalidation cause immediate refetch
      //invalidatesTags: (result, error, { id }) => [{ type: 'PatientCard', id }],
    }),
    updateSpecialistPatientMedicalData: builder.mutation<
      ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto'],
      FacilityPatientInfoResponseDtoOptional
    >({
      query: ({ patientCardId, ...body }) => ({
        url: `patient/${patientCardId}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ patientCardId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          patientCardsApi.util.updateQueryData('getSpecialistPatientCard', patientCardId!, draft => {
            Object.assign(draft, patch);
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('patientCardsApi.updateSpecialistPatientCard.onQueryStarted', 'Error: ', error);
          patchResult.undo();
        }
      },
    }),
    addComment: builder.mutation<Comment, Partial<Comment> & Pick<Comment, 'content' | 'patientId'>>({
      query: ({ patientId, ...body }) => ({
        url: `patient/comment/${patientId}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ patientId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            patientCardsApi.util.updateQueryData('getSpecialistPatientCard', patientId, draft => {
              draft.comments.unshift(updatedPost);
            }),
          );
        } catch (error) {
          logger.error('patientCardsApi.addComment.onQueryStarted', 'Error: ', error);
        }
      },
    }),
    deleteComment: builder.mutation<Comment, Pick<Comment, 'patientId' | 'id'>>({
      query: ({ id }) => ({
        url: `patient/comment/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ patientId, id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          patientCardsApi.util.updateQueryData('getSpecialistPatientCard', patientId, draft => {
            draft.comments.splice(
              draft.comments.findIndex(comment => comment.id === id),
              1,
            );
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('patientCardsApi.deleteComment.onQueryStarted', 'Error: ', error);
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetSpecialistPatientCardQuery,
  useGetPatientStatisticsQuery,
  useUpdateSpecialistPatientDataMutation,
  useUpdateSpecialistPatientMedicalDataMutation,
  useAddCommentMutation,
  useDeleteCommentMutation,
} = patientCardsApi;
