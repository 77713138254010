import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

import { logger } from './logger';

// Backend does not exports just PagedPayloadDto, so currently we extract it from any other
// dto containg paged payload
type PagedPayloadDto = Omit<ExoClinicBackendOpenApiSchemas['TrainingResponseDtoPagedPayloadDto'], 'content'> & {
  content?: unknown[] | unknown;
};

/**
 * This function can be used currently to infinitely load paged data into common array.
 * It can be useful for creating lists which where more elements can be loaded dynamically.
 */
export function mergePagedPayloads(oldData: PagedPayloadDto, newData: PagedPayloadDto) {
  oldData.first = newData.first;
  oldData.last = newData.last;
  oldData.totalElements = newData.totalElements;
  oldData.totalPages = newData.totalPages;

  if (
    !oldData.pageable ||
    !newData.pageable ||
    typeof oldData.pageable.pageNumber !== 'number' ||
    typeof newData.pageable.pageNumber !== 'number'
  ) {
    // Without pageable we cannot determine the order of data
    oldData.pageable = newData.pageable;
    oldData.content = newData.content;
    return;
  }

  if (oldData.pageable.pageSize !== newData.pageable.pageSize) {
    // If page sizes are different then we cannot assure that merging will be successfull
    oldData.pageable = newData.pageable;
    oldData.content = newData.content;
    logger.warn('mergePagedPayloads', 'Page size have changed. Discarding data from cache.');
    return;
  }

  const pageDiff = newData.pageable.pageNumber - oldData.pageable.pageNumber;

  if (Math.abs(pageDiff) !== 1) {
    // If we skipped a page then we cannot assure that ther order of elements will be valid
    oldData.pageable = newData.pageable;
    oldData.content = newData.content;
    logger.warn('mergePagedPayloads', 'Page skip has occured. Discarding data from cache.');
    return;
  }

  oldData.pageable = newData.pageable;
  if (Array.isArray(oldData.content) && Array.isArray(newData.content)) {
    oldData.content.push(...newData.content);
  }
}
