import { FunctionComponent, useMemo } from 'react';
import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import cableDetachedImage from 'assets/images/CableDetached.svg';
import criticalErrorImage from 'assets/images/CriticalError.svg';
import deviceDisconnectedImage from 'assets/images/DeviceDisconnectedError.svg';
import disconnectedElectrode from 'assets/images/DisconnectedElectrode.svg';
import egzoTechProfesional from 'assets/images/EGZOTechProfessional.svg';
import meissaExtensionDetachedImage from 'assets/images/meissa-extensions/Meissa_extension_detached_modal.svg';
import WifiConnectNetworkModal from 'containers/modals/WiFiConnectNetworkModal';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { forceCloseModal, ModalSeverity } from 'slices/modalSlice';
import { ExerciseModal } from 'views/+patientId/training/+trainingId/_containers/ExerciseModal';
import { virtualKeyboardVisible } from 'virtualKeyboardVisible';

import { AlertModal } from './alerts/AlertModal';
import { ErrorModal } from './alerts/ErrorModal';
import { UnexpectedErrorModal } from './alerts/UnexpectedErrorModal';
import { WarningModal } from './alerts/WarningModal';
import { ConnectionErrorModal } from './ConnectionErrorModal';
import { DeviceNotSelectedModal } from './DeviceNotSelectedModal';
import { EmergencyModal } from './EmergencyModal';
import ExportDatabaseModal from './ExportDatabaseModal';
import { FirmwareModulesHardErrorModal } from './FirmwareModulesHardErrorModal';
import { FirmwareModulesSoftErrorModal } from './FirmwareModulesSoftErrorModal';
import ImportDatabaseModal from './ImportDatabaseModal';
import { InviteSpecialistModal } from './InviteSpecialistModal';
import { LicenseModal } from './LicenseModal';
import { OvervoltageModal } from './OvervoltageModal';
import { PasswordConfirmationModal } from './PasswordConfirmationModal';
import { PECCalibrationModal } from './PECCalibrationModal';
import { ServiceHelpModal } from './ServiceHelpModal';
import SpasticismModal from './SpasticismModal';
import { SystemComponentsModal } from './SystemComponentsModal';
import { UpdateModal } from './UpdateModal';
import { UserDecisionModal } from './UserDecisionModal';

export interface ModalProps {
  id?: string;
  /** @deprecated Use custom named callbacks instead */
  callback?: (data?: any) => void;
  /** @deprecated Use custom named callbacks instead */
  secondCallback?: (data?: any) => void;
  close: () => void;
  containerProps?: {
    onClick?: () => void;
  };
}

export interface ModalTypeDefinition<T extends ModalProps = ModalProps> {
  name: string;
  component: FunctionComponent<T>;
  options?: {
    priority?: number;
    /**
     * Specifies if the modal can be ommitted if another modal is displayed. When true the modal
     * will be ignored, when false or undefined the modal will be added to modal queue and displayed
     * after current modal is resolved. When some other modal with higher priority will be display
     * this modal will be closed.
     */
    canBeOmitted?: boolean;
    /**
     * Use ModalOverlay with backdrop blur
     */
    useBlurBackdrop?: boolean;
    /**
     * Wrap additionally component in ModalContent and ModalBody
     */
    includesOnlyModalBody?: boolean;
    /**
     * Default action for clicking on overlay or esc button
     */
    doNotCloseOnOverlayClickOrEsc?: boolean;
    /**
     * Severity for modal which describes how app behaves and what user should do
     */
    severity?: ModalSeverity;
    defaultProps?: Partial<T>;
  };
}

function createModalType<T extends ModalProps>(
  name: string,
  component: FunctionComponent<T>,
  options?: ModalTypeDefinition<T>['options'],
) {
  return {
    name,
    component,
    options,
  } as ModalTypeDefinition<T>;
}

export const ModalType = {
  INVITE_SPECIALIST: createModalType('inviteSpecialist', InviteSpecialistModal, {
    includesOnlyModalBody: true,
  }),
  USER_DECISION: createModalType('userDecision', UserDecisionModal, {
    includesOnlyModalBody: true,
  }),
  PASSWORD_CONFIRMATION: createModalType('passwordConfirmation', PasswordConfirmationModal, {
    includesOnlyModalBody: true,
  }),
  UNEXPECTED_ERROR: createModalType('unexpectedError', UnexpectedErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
  }),
  CABLE_DETACHED_ERROR: createModalType('cableDetachedError', ErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.cableDetached.heading',
      description: 'modals.alerts.cableDetached.description',
      imageSrc: cableDetachedImage,
    },
  }),
  SERVICE_HELP_MODAL: createModalType('serviceHelpModal', ServiceHelpModal, {
    doNotCloseOnOverlayClickOrEsc: true,
  }),
  STAFF_TRAINING_WARNING: createModalType('staffTrainingWarning', WarningModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.staffTrainingWarning.heading',
      description: 'modals.alerts.staffTrainingWarning.description',
      description2: 'modals.alerts.staffTrainingWarning.description2',
      imageSrc: egzoTechProfesional,
      acceptRiskButton: true,
    },
  }),
  CABLE_DETACHED_WARNING: createModalType('cableDetachedWarning', AlertModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.cableDetached.heading',
      description: 'modals.alerts.cableDetached.description',
      imageSrc: cableDetachedImage,
    },
  }),
  EXTENSION_DETACHED_WARNING: createModalType('extensionDetachedWarning', AlertModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.extensionDetached.heading',
      description: 'modals.alerts.extensionDetached.description',
      imageSrc: meissaExtensionDetachedImage,
    },
  }),
  EXTENSION_DETACHED_ERROR: createModalType('extensionDetachedError', ErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.extensionDetached.heading',
      description: 'modals.alerts.extensionDetached.description',
      imageSrc: meissaExtensionDetachedImage,
    },
  }),
  DISCONNECTED_ELECTRODE_WARNING: createModalType('disconnectedElectrodeWarning', AlertModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.disconnectedElectrode.heading',
      description: 'modals.alerts.disconnectedElectrode.description',
      imageSrc: disconnectedElectrode,
    },
  }),
  CRITICAL_ERROR: createModalType('criticalError', AlertModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    defaultProps: {
      heading: 'modals.alerts.criticalError.heading',
      description: 'modals.alerts.criticalError.description',
      imageSrc: criticalErrorImage,
    },
  }),
  UPDATE_SYSTEM: createModalType('updateSystem', UpdateModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 50,
  }),
  UPDATE_SYSTEM_ERROR: createModalType('updateSystemError', ErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 60,
    defaultProps: {
      heading: 'modals.alerts.uploadError.heading',
      description: 'modals.alerts.uploadError.description',
      description2: 'modals.alerts.uploadError.description2',
      imageSrc: deviceDisconnectedImage,
      uploadData: false,
      endTrainingButton: false,
      restartButton: true,
    },
  }),
  WIFI_CONNECT_NETWORK: createModalType('wifiConnectNetwork', WifiConnectNetworkModal, {
    includesOnlyModalBody: true,
  }),
  WIFI_ADD_HIDDEN_NETWORK: createModalType('wifiAddHiddenNetwork', WifiConnectNetworkModal, {
    includesOnlyModalBody: true,
    defaultProps: {
      addHiddenNetwork: true,
    },
  }),
  SYSTEM_COMPONENTS: createModalType('systemComponents', SystemComponentsModal, {
    useBlurBackdrop: true,
    canBeOmitted: true,
    priority: 40,
  }),
  SPASTICISM: createModalType('spasticism', SpasticismModal, {
    doNotCloseOnOverlayClickOrEsc: true,
  }),
  LICENSE: createModalType('license', LicenseModal),
  SESSION_CONNECTION_ERROR: createModalType('sessionConnectionError', ConnectionErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'warning',
    // Connection error priority is higher than emergency button and overvoltage flag because
    // both of those mechanisms do not work without a connection to the device. We cannot clear
    // emergency button lock or overvoltage flag without sending message to the device.
    priority: 80,
  }),
  IMPORT_DATABASE: createModalType('importDatabase', ImportDatabaseModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 50,
  }),
  EXPORT_DATABASE: createModalType('exportDatabase', ExportDatabaseModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 50,
  }),
  FIRMWARE_MODULES_SOFT_ERROR: createModalType('firmwareModulesSoftError', FirmwareModulesSoftErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'warning',
    priority: 10,
  }),
  FIRMWARE_MODULES_HARD_ERROR: createModalType('firmwareModulesHardError', FirmwareModulesHardErrorModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'critical',
    priority: 15,
  }),

  EXERCISE_FINISH_MODAL: createModalType('exerciseFinishModal', ExerciseModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 20,
  }),
  EMERGENCY_BUTTON: createModalType('emergencyButton', EmergencyModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'warning',
    priority: 70,
  }),
  PEC_CALIBRATION: createModalType('pecCalibration', PECCalibrationModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    priority: 50,
  }),
  OVERVOLTAGE_FLAG: createModalType('overvoltageFlag', OvervoltageModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'warning',
    priority: 70,
  }),
  DEVICE_NOT_SELECTED: createModalType('deviceNotSelected', DeviceNotSelectedModal, {
    doNotCloseOnOverlayClickOrEsc: true,
    severity: 'warning',
  }),
} as const satisfies { [key: string]: ModalTypeDefinition<any> };

export const Modal = () => {
  useSignals();
  const dispatch = useAppDispatch();
  const { isOpened, queue } = useAppSelector(state => state.modal);

  const closeCallbacks = useMemo(() => ({} as Record<string, () => void>), []);

  return (
    <>
      {queue.map((v, i, arr) => {
        // Close callbacks need to be memoized to not cause rerenders
        const close =
          closeCallbacks[v.type.name] ??
          (closeCallbacks[v.type.name] = () => {
            dispatch(forceCloseModal(v.type.name));
          });

        const {
          doNotCloseOnOverlayClickOrEsc: doNotCloseOnOverlayClickOrEscOverride,
          type,
          id,
          callback,
          secondCallback,
          additionalData,
        } = v;

        const doNotCloseOnOverlayClickOrEsc =
          doNotCloseOnOverlayClickOrEscOverride ?? type?.options?.doNotCloseOnOverlayClickOrEsc ?? false;

        const ModalComponent = type.component;

        const modal = (
          <ModalComponent
            containerProps={{
              // Fixes error when chakra cannot close modal on overlay click due to bad logic in ModalManager
              // https://github.com/chakra-ui/chakra-ui/blob/969fe57c97540cafa736ff64cfdff970c948c810/packages/components/src/modal/use-modal.ts#L131
              // Chakra falsely reports that the modal is not a 'top modal' in function `isTopModal`, because in our case
              // we sometimes close modals that were displayed more before currentyl shown modal. For the fix
              // to work this prop need to passed to `ModalContent` component.
              onClick: doNotCloseOnOverlayClickOrEsc ? undefined : close,
            }}
            callback={callback}
            secondCallback={secondCallback}
            close={close}
            id={id}
            {...type?.options?.defaultProps}
            {...additionalData}
          />
        );

        const isCurrentModal = i === arr.length - 1;

        return (
          <ChakraModal
            key={v.type.name}
            motionPreset="slideInBottom"
            onClose={close}
            isOpen={isOpened}
            isCentered
            scrollBehavior="outside"
            closeOnOverlayClick={!doNotCloseOnOverlayClickOrEsc}
            closeOnEsc={!doNotCloseOnOverlayClickOrEsc}
            variant={virtualKeyboardVisible.value ? 'modalWithKeyboard' : isCurrentModal ? undefined : 'hidden'}
          >
            {type?.options?.useBlurBackdrop ? (
              <ModalOverlay
                background="whiteAlpha.400"
                backdropFilter="3.125rem"
                sx={{ backdropFilter: 'blur(3.125rem)' }}
              />
            ) : (
              <ModalOverlay />
            )}
            {type?.options?.includesOnlyModalBody ? (
              <ModalContent width="auto" maxWidth="full" p="4" textAlign="center" maxH="90vh">
                <ModalBody>{modal}</ModalBody>
              </ModalContent>
            ) : (
              modal
            )}
          </ChakraModal>
        );
      })}
    </>
  );
};
