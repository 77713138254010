import { FC } from 'react';
import { Th, Thead, Tr } from '@chakra-ui/react';

import { TranslateText } from 'components/texts/TranslateText';

export const RecentTrainingHeader: FC = () => {
  const headers = ['date', 'duration', 'report', 'repeat'];
  const getHeader = (text: string) => (
    <Th border="none" key={text}>
      <TranslateText
        text={text}
        variant="openSans24"
        pt="1"
        textAlign="center"
        textTransform="capitalize"
        color="gray.500"
        py="6"
        letterSpacing="0"
      />
    </Th>
  );

  return (
    <Thead position="sticky" top="0" bg="white" zIndex="1">
      <Tr>
        <Th border="none" />
        <Th border="none" pl="1">
          <TranslateText
            text="training.name"
            variant="openSans24"
            pt="1"
            textTransform="capitalize"
            color="gray.500"
            py="6"
            letterSpacing="0"
          />
        </Th>
        {headers.map(header => getHeader(`training.${header}`))}
      </Tr>
    </Thead>
  );
};
