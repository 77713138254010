import { GeneratedCPMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOtCPMExercises = (): { [key: string]: GeneratedCPMExerciseDefinition } => ({
  cpm_classic_meissa: {
    type: 'cpm',
    calibrationFlow: 'cpm/meissa',
    presentation: 'time',
    tags: [],
    cpm: {
      main: {
        program: {
          phases: [
            {
              time: 10 * 60,
              speed: 90,
              trigger: 'direction-change',
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          phases: [
            {
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              speed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            blockAfterStart: false,
          },
        },
      },
    },
  },
});
