import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { SelectData } from '../types';

import { __ } from './i18n';

const POLISH_PREFIX = '+48';

const isCorrectPhoneFormat = (number?: string | null): boolean => {
  if (!number || (parsePhoneNumber(number)?.country === 'PL' && number.length !== 12)) return false;
  return isPossiblePhoneNumber(number) && isValidPhoneNumber(number);
};

export const checkPhoneNumber = () =>
  yup
    .string()
    .nullable()
    .test('len', __('validation.invalidPhoneNumber'), (val?: string | null) => {
      if (!val || val === POLISH_PREFIX) return true;
      return isCorrectPhoneFormat(val);
    });

export const checkPhoneNumberSimply = () =>
  yup
    .string()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .nullable()
    .notRequired()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{2,3}\)?)\s?-?\s?(\(?\d{2,4}\)?)?$/,
      __('validation.wrongPhone'), // safe from ReDos attack (checked via https://devina.io/redos-checker)
    )
    .min(3, __('validation.textTooShort', { value: 3 }))
    .max(15, __('validation.textTooLong', { value: 15 }));

export const checkPhoneNumberRequired = () =>
  yup
    .string()
    .nullable()
    .required(__('validation.fieldIsRequired'))
    .test('len', __('validation.invalidPhoneNumber'), isCorrectPhoneFormat);

export const checkNip = () =>
  yup
    .string()
    .nullable()
    .required(__('validation.fieldIsRequired'))
    .matches(/^[0-9]{10}$/, __('validation.invalidNip')); // safe from ReDos attack (checked via https://devina.io/redos-checker)

export const multipleSelect = () =>
  yup
    .array()
    .test('len', __('validation.fieldIsRequired'), (value?: SelectData[]) =>
      value?.length ? value?.length > 0 : false,
    );

export const numberInput = () =>
  yup.number().nullable().integer(__('validation.mustBeInteger')).typeError(__('validation.mustBeAValidNumber'));

const maxTwoDigitsAfterDecimals = (value?: number | null) =>
  `${value}`.split('.')[1] ? `${value}`.split('.')[1].length <= 2 : true;

export const priceNumber = () =>
  yup
    .number()
    .nullable()
    .typeError(__('validation.mustBeAValidNumber'))
    .min(0.01, __('validation.numberToSmall', { value: 0.01 }))
    .test('max2DigitsAfterDecimal', __('validation.provideCorrectPrice'), maxTwoDigitsAfterDecimals);

export const checkName = () =>
  yup
    .string()
    .required(__('validation.name'))
    .matches(/^[\p{L}\p{M}]+([ ][\p{L}\p{M}]+)*$/u, __('validation.onlyLetters')) // safe from ReDos attack (checked via https://devina.io/redos-checker)
    .max(60, __('validation.textTooLong'));

export const checkSurname = () =>
  yup
    .string()
    .required(__('validation.surname'))
    .matches(/^[\p{L}\p{M}]+([ '-][\p{L}\p{M}]+)*$/u, __('validation.onlyLetters')) // safe from ReDos attack (checked via https://devina.io/redos-checker)
    .max(60, __('validation.textTooLong'));

export const checkClinicName = () =>
  yup
    .string()
    .required(__('validation.fieldIsRequired'))
    .matches(/^[\p{L}\p{M}\d]+([ '-][\p{L}\p{M}\d]+)*$/u, __('validation.onlyLettersAndNumbers')) // safe from ReDos attack (checked via https://devina.io/redos-checker)
    .max(255, __('validation.textTooLong')); // Samodzielny Publiczny Zakład Opieki Zdrowotnej Ministerstwa Spraw Wewnętrznych i Administracji z Warmińsko-Mazurskim Centrum Onkologii w Olsztynie

export const checkPassword = () => yup.string().required(__('validation.fieldIsRequired'));

export const checkStrongPassword = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .min(6, 'validation.passLength')
    .max(40, 'validation.passwordTooLong')
    .matches(/^(?=.*[a-zA-Z])/, 'validation.oneLetter') // safe from ReDos attack (checked via https://devina.io/redos-checker
    .matches(/^(?=.*[0-9])/, 'validation.oneNumber'); // safe from ReDos attack (checked via https://devina.io/redos-checker)

export const checkPasswordWithLength = () =>
  yup
    .string()
    .required(__('validation.fieldIsRequired'))
    .min(5, __('validation.passwordTooShort', { value: '5' }))
    .max(40, __('validation.passwordTooLong', { value: '40' }));

export const checkRepeatedPassword = () =>
  yup.string().oneOf([yup.ref('password')], __('validation.passwordsMustMatch'));

export const checkRepeatedNewPassword = () =>
  yup.string().oneOf([yup.ref('newPassword')], __('validation.passwordsMustMatch'));

export const checkEmail = () =>
  yup.string().email(__('validation.invalidEmail')).max(254, __('validation.emailTooLong'));

export const checkRequiredEmail = () =>
  yup.string().required(__('validation.fieldIsRequired')).email(__('validation.invalidEmail'));

export const optionalTextWithLength = (min: number, max: number) =>
  yup
    .string()
    .nullable()
    .optional()
    .test(
      'len',
      __('validation.textTooShort', { value: min }),
      (value?: string | null) => !(value && value?.length <= min),
    )
    .max(240, __('validation.textTooLong', { value: max }));

export const requiredTextWithLength = (min: number, max: number) =>
  yup
    .string()
    .required(__('validation.fieldIsRequired'))
    .min(min, __('validation.textTooShort', { value: min }))
    .max(max, __('validation.textTooLong', { value: max }));

export const checkBeforeCurrentDate = () =>
  yup
    .date()
    .nullable()
    .notRequired()
    .max(new Date(), __('validation.currentDate'))
    .typeError(__('validation.fullDate'));

export const checkStartDate = () =>
  yup
    .date()
    .max(new Date(), __('validation.startDate'))
    .max(yup.ref('endDate'), __('validation.startDateToEnd'))
    .typeError(__('validation.fullDate'));

export const checkEndDate = () =>
  yup
    .date()
    .min(yup.ref('startDate'), __('validation.endDate'))
    .max(new Date(), __('validation.endDateToCurrent'))
    .typeError(__('validation.fullDate'));

export const checkConditionsCheckbox = () => yup.boolean().oneOf([true], __('validation.conditions'));

export const checkPoliticsCheckbox = () => yup.boolean().oneOf([true], __('validation.privacy'));

export const checkWelcomeCardNote = () => yup.string().required(__('validation.welcomeCardCode'));
