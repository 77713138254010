import { FC } from 'react';
import { Center, Container, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import unexpectedErrorImage from 'assets/images/unexpected-error.svg';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

export const UnexpectedErrorModal: FC<{ id?: string }> = ({ id }) => {
  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} maxH={{ base: 'sm', '2xl': 'xl' }} borderRadius="rLg" p="4">
      <ModalBody>
        <Container
          variant="modalBox"
          py="4"
          px="0"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
        >
          <VStack justify="space-between" flex="1 0">
            <Center>
              <MainHeading variant="subheading36" text="modals.alerts.unexpectedError.heading" />
            </Center>
            <Image src={unexpectedErrorImage} maxH={{ base: '32', '2xl': '2xs' }} maxW={{ base: '32', '2xl': '2xs' }} />
            <TranslateText variant="openSans24" text="modals.alerts.unexpectedError.description" />
            <MainButton
              text="modals.alerts.unexpectedError.exit"
              variant="mdPrimaryButton"
              onClick={() => (location.href = '/')}
              minW="32"
            />
            {id ? (
              <Center position="absolute" top="-3" left="0" right="0">
                <TranslateText variant="openSans16" color="gray.300">
                  {id}
                </TranslateText>
              </Center>
            ) : null}
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
