import { ExoSession } from '@egzotech/exo-session';
import { ExoRemoteFeature } from '@egzotech/exo-session/features/remote';
import { Logger } from '@egzotech/universal-logger-js';

export class RemotePlugin {
  private _remoteFeature: ExoRemoteFeature | null;
  private _enabled: boolean;
  static readonly logger = Logger.getInstance('RemotePlugin');

  constructor(private readonly session: ExoSession, private playable: { play(): void; pause(): void }) {
    this._remoteFeature = null;
    this._enabled = true;
  }

  init() {
    if (this.session.features?.includes('remote')) {
      this._remoteFeature = this.session.activate(ExoRemoteFeature);
      this._remoteFeature.onPress = button => {
        if (button === 'pause') {
          if (this._enabled) {
            this.playable.pause();
          }
        }

        if (button === 'play') {
          if (this._enabled) {
            this.playable.play();
          }
        }
      };
    }
  }

  dispose() {
    this._remoteFeature?.dispose();
  }

  disable() {
    RemotePlugin.logger.debug('disable', `Remote controller's play button has been disabled`);
    this._enabled = false;
  }

  enable() {
    RemotePlugin.logger.debug('enable', `Remote controller's play button has been enabled`);
    this._enabled = true;
  }
}
