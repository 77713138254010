import { SupportedDeviceTypes } from '@egzotech/exo-session';
import { ExoModuleIdentification } from '@egzotech/exo-session/features/module-identification';

// eslint-disable-next-line @typescript-eslint/ban-types
export type CommonModuleId = 'biocan' | (string & {});

export type MeissaOTModuleId =
  | CommonModuleId
  | 'motor:main'
  | 'sensor-force:torque'
  | 'sensor-angle:horizontal'
  | 'sensor-angle:vertical'
  | 'extension';

export type SidraLegModuleId =
  | CommonModuleId
  | 'motor:knee'
  | 'motor:ankle'
  | 'sensor-force:ankle'
  | 'sensor-force:knee'
  | 'remote';

export type ModulesVersionConfiguration<T extends MeissaOTModuleId | SidraLegModuleId> = {
  deviceType: SupportedDeviceTypes;
  'config-version': string;
  modules: { [key in T]?: { version: ExoModuleIdentification['version'] } };
};

export const sidraLegModulesVersionConfiguration: ModulesVersionConfiguration<SidraLegModuleId> = {
  deviceType: 'sidra-leg',
  'config-version': '1.0.0',
  modules: {
    biocan: {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 30,
      },
    },
    'motor:knee': {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 20,
      },
    },
    'motor:ankle': {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 20,
      },
    },
    'sensor-force:knee': {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 20,
      },
    },
    'sensor-force:ankle': {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 20,
      },
    },
    remote: {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 2,
      },
    },
  },
};

export const meissaOTModulesVersionConfiguration: ModulesVersionConfiguration<MeissaOTModuleId> = {
  deviceType: 'meissa-ot',
  'config-version': '1.0.0',
  modules: {
    biocan: {
      version: {
        hardware: 1,
        major: 0,
        minor: 1,
        revision: 30,
      },
    },
    'motor:main': {
      version: {
        hardware: 1,
        major: 0,
        minor: 2,
        revision: 4,
      },
    },
    'sensor-force:torque': {
      version: {
        hardware: 1,
        major: 0,
        minor: 2,
        revision: 4,
      },
    },
    'sensor-angle:horizontal': {
      version: {
        hardware: 1,
        major: 0,
        minor: 2,
        revision: 4,
      },
    },
    'sensor-angle:vertical': {
      version: {
        hardware: 1,
        major: 0,
        minor: 2,
        revision: 4,
      },
    },
    // Extension should not be checked, because it cannot be updated and will never have
    // newest firmware. If not sure contact embedded team for details.
    // extension: {
    //   version: {
    //     hardware: 1,
    //     major: 0,
    //     minor: 1,
    //     revision: 4,
    //   },
    // },
  },
};
