import { Signal, signal } from 'helpers/signal';

import { SensorForcePlugin } from '../global/SensorForcePlugin';

import { TriggerThreshold } from './TriggerThreshold';

export class TriggerThresholdForce implements TriggerThreshold {
  // TODO: _value acutally represents maxValue like mvc for EMG, because SensorForcePlugin does
  // not store maxValues for each sensor. That is why it is stored in the trigger currently.
  // We should unify this logic, either move mvc from EMGSignal to TriggerThresholdEmg
  // or move force maxValue to SensorForcePlugin.
  private _value: number;
  private _threshold: Signal<number>;

  get name() {
    return this.sensor;
  }

  get value() {
    return this._value;
  }

  get threshold() {
    return this._threshold;
  }

  constructor(
    private readonly plugin: SensorForcePlugin,
    private readonly sensor: string,
    private readonly _type?: string | null,
    readonly id?: string,
  ) {
    this._value = 1;
    this._threshold = signal(0.5, `TriggerThresholdForce.${sensor}._threshold`);
    if (!id) {
      this.id = sensor;
    }
  }

  setValue(value: number) {
    this._value = value;
  }

  get type() {
    return this._type;
  }

  setThreshold(threshold: number) {
    this._threshold.value = threshold;
  }

  isTriggered() {
    if (this._value < 0) {
      return this.plugin.getForce(this.sensor) <= this._value * this._threshold.peek();
    }
    return this.plugin.getForce(this.sensor) >= this._value * this._threshold.peek();
  }
}
