import { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { TranslateText } from 'components/texts/TranslateText';

interface Props extends ButtonProps {
  text: string;
  onClick?: () => void;
}

export const MainButton: FC<Props> = ({ text, variant, onClick, ...props }) => (
  <Button type="submit" onClick={onClick} variant={variant ?? 'smPrimaryButton'} {...props}>
    <TranslateText text={text} />
  </Button>
);
