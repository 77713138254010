import { useContext, useEffect, useMemo, useState } from 'react';
import { ExoSession } from '@egzotech/exo-session';
import { logger } from 'helpers/logger';
import { useContextSelector } from 'use-context-selector';

import { DeviceType, GeneratedEMSExerciseDefinition, isSpecificGeneratedExerciseDefinition } from '../../core';
import { EMSExercise } from '../../core/exercises/EMSExercise';
import ExoGlobalContext from '../contexts/ExoGlobalContext';

import { EMSInstanceContext } from './EMSInstanceContext';

export function EMSProvider({ children, errorContent }: { children: React.ReactNode; errorContent?: React.ReactNode }) {
  const session = useContextSelector(ExoGlobalContext, state => state.device.session);
  const selectedProgram = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgram);
  const selectedDeviceType = useContextSelector(ExoGlobalContext, state => state.device.selectedDevice?.type);
  const selectedProgramId = useContextSelector(ExoGlobalContext, state => state.programSelection.selectedProgramId);

  if (
    !session ||
    !(selectedDeviceType === 'stella-bio') ||
    !selectedProgram ||
    !isSpecificGeneratedExerciseDefinition(selectedProgram, ['ems', 'ems-emg'])
  ) {
    logger.error('EMSProvider', 'Provider was used without selected device or with invalid program type');

    return <>{errorContent}</>;
  }

  return (
    <EMSActiveProvider
      exerciseName={selectedProgramId}
      session={session}
      definition={selectedProgram}
      deviceType={selectedDeviceType}
    >
      {children}
    </EMSActiveProvider>
  );
}

interface EMSActiveProviderProps {
  session: ExoSession;
  exerciseName: string | null;
  definition: GeneratedEMSExerciseDefinition;
  deviceType: DeviceType;
  children: React.ReactNode;
}

function EMSActiveProvider({ session, definition, deviceType, children, exerciseName }: EMSActiveProviderProps) {
  const [ready, setReady] = useState(false);

  const emsExercise = useMemo(
    () => new EMSExercise(definition, exerciseName, session, deviceType),
    [definition, exerciseName, session, deviceType],
  );

  useEffect(() => {
    setReady(true);

    return () => {
      emsExercise.destroy();
    };
  }, [emsExercise]);

  if (!ready) {
    return <></>;
  }

  return <EMSInstanceContext.Provider value={emsExercise}>{children}</EMSInstanceContext.Provider>;
}

export const useEMSExerciseInstance = () => {
  const emsInstance = useContext(EMSInstanceContext);

  if (!emsInstance) {
    throw new Error('useEMSProgram must be used within a EMSProvider');
  }

  return emsInstance;
};
