import { useEffect } from 'react';
import { EventBus, EventDefinitions } from '@egzotech/exo-session';

/**
 * Effect that attaches dispatch function to event handler
 */
export default function useEventToReducer<T extends EventDefinitions, TKey extends keyof T>(
  bus: EventBus<T>,
  event: TKey,
  dispatch: React.Dispatch<{ type: TKey; payload: Parameters<T[TKey]>[0] }>,
  inputs: React.DependencyList = [],
) {
  useEffect(() => {
    const handler = (v: Parameters<T[TKey]>[0]) => {
      dispatch({ type: event, payload: v });
    };

    bus.addHandler(event, handler as T[TKey]);

    return () => {
      bus.removeHandler(event, handler as T[TKey]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bus, event, dispatch, ...inputs]);
}
