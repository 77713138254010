import {
  ComponentMultiStyleConfig,
  ComponentSingleStyleConfig,
  ComponentStyleConfig,
  PartsStyleInterpolation,
  SystemStyleInterpolation,
} from '@chakra-ui/react';

type ExtendsVariants<T, Variants extends string> = T extends ComponentSingleStyleConfig
  ? { variants?: Record<Exclude<keyof T['variants'], Variants>, never> & Record<Variants, SystemStyleInterpolation> }
  : T extends ComponentMultiStyleConfig
  ? { variants?: Record<Exclude<keyof T['variants'], Variants>, never> & Record<Variants, PartsStyleInterpolation> }
  : never;

/**
 * Parses the config so types are applied over the value. This function is purely needed
 * for TypeScript to parse configuration types.
 */
export function parseStyleConfig<Variants extends readonly string[], T extends ComponentStyleConfig>(
  _variants: Variants,
  config: T & ExtendsVariants<T, Variants[number]>,
): T {
  return config;
}
