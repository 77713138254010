import { useEffect, useMemo } from 'react';

import { globalAudioCached } from '../globalSignals/globalAudioCached';

export type SoundsName = 'contract' | 'contractAndHold' | 'quickFlick' | 'relax' | 'emergencyStop';

const getSound = (name: SoundsName) => {
  return globalAudioCached.value.find(s => s.name === name)?.sound;
};
const useSound = (soundName: SoundsName, soundLoop = false) => {
  const soundCached = getSound(soundName);

  const audio = useMemo(() => {
    if (soundCached != undefined) {
      return soundCached;
    }
    const retrySound = getSound(soundName);
    if (retrySound) {
      return retrySound;
    }
    return new Audio();
  }, [soundCached, soundName]);

  useEffect(() => {
    audio.loop = soundLoop;
    return () => {
      audio.currentTime = 0;
      audio.pause();
    };
  }, [audio, soundLoop]);

  const play = () => {
    audio.currentTime = 0;
    audio.play().catch(error => {
      console.error('Audio playback failed:', error);
    });
  };

  const pause = () => {
    audio.pause();
  };

  return { play, pause, audio };
};

export default useSound;
