import { Center, Container, Image, ListItem, ModalBody, ModalContent, UnorderedList, VStack } from '@chakra-ui/react';
import errorImage from 'assets/images/StellaBIOConnectionError.svg';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

export interface DeviceNotSelectedModalProps extends ModalProps {
  onExit: (navigateTo: string) => void;
}

const StellaBIONotSelectedModal = ({ onExit, close }: DeviceNotSelectedModalProps) => {
  return (
    <ModalContent maxW={{ base: 'lg', 'xl+': 'xl', '2xl': '3xl' }} borderRadius="rLg" p="2">
      <ModalBody>
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="center"
          py="4"
        >
          <VStack flex="1 0" gap="3" justify="space-between">
            <Center>
              <MainHeading variant="subheading36" text="modals.deviceNotSelected.heading" />
            </Center>
            <VStack pt={{ base: '3', '2xl': '5' }} pb={{ base: '6', '2xl': '10' }}>
              <Image src={errorImage} maxH={{ base: '2xs', '2xl': 'md' }} maxW={{ base: '2xs', '2xl': 'md' }} mt="4" />
              <UnorderedList mt={{ base: '6', '2xl': '9' }}>
                <ListItem>
                  <TranslateText variant="openSans24" text="modals.deviceNotSelected.description1" />
                </ListItem>
                <ListItem mt="5">
                  <TranslateText variant="openSans24" text="modals.deviceNotSelected.description2" />
                </ListItem>
                <ListItem mt="5">
                  <TranslateText variant="openSans24" text="modals.deviceNotSelected.description3" />
                </ListItem>
              </UnorderedList>
            </VStack>
            <MainButton
              text="modals.deviceNotSelected.selectDevice"
              variant="mdPrimaryButton"
              onClick={() => {
                onExit?.('../');
                close();
              }}
              minW="32"
            />
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};

export const DeviceNotSelectedModal = (props: DeviceNotSelectedModalProps) => {
  // TODO: In the future we should also have versions for other devices
  // const device = useDevice();
  // if (device.deviceType === 'stella-bio') {
  //   return <StellaBIONotSelectedModal {...props} />;
  // }

  return <StellaBIONotSelectedModal {...props} />;
};
