import { de, enGB, pl, zhHK, zhTW } from 'date-fns/locale';
import { Language } from 'types/language';

export const getLangName = (lang: Language | undefined | null) => {
  switch (lang) {
    case 'en':
      return 'English (en)';
    case 'pl':
      return 'Polski (pl)';
    case 'de':
      return 'Deutsch (de)';
    case 'zh_Hant':
      // Cantonese
      return '廣東話 (zh_Hant)';
    case 'zh_Hans':
      // Mandarin
      return '官話 / 官话 (zh_Hans)';
    default:
      return 'Choose language';
  }
};

export const getDateFnsLocale = (lang: Language): Locale => {
  switch (lang) {
    case 'en':
      return enGB;
    case 'pl':
      return pl;
    case 'de':
      return de;
    case 'zh_Hant':
      return zhHK;
    case 'zh_Hans':
      return zhTW;
    default:
      return pl;
  }
};
