import { createSlice } from '@reduxjs/toolkit';

export type EmergencyActionSlice = {
  emergencyDialog: 'on' | 'off';
};

const initialState: EmergencyActionSlice = {
  emergencyDialog: 'off',
};

const emergencyActionSlice = createSlice({
  name: 'emergencyAction',
  initialState,

  reducers: {
    emergencyDialogOn: state => {
      state.emergencyDialog = 'on';
    },
    emergencyDialogOff: state => {
      state.emergencyDialog = 'off';
    },
  },
});

export const { emergencyDialogOn, emergencyDialogOff } = emergencyActionSlice.actions;

export default emergencyActionSlice.reducer;
