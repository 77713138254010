import { DependencyList, EffectCallback, useEffect } from 'react';
import { useAppSelector } from 'hooks/store';

import { useDevice } from './useDevice';

export const useEffectWithEmergencyButton = (effect: EffectCallback, additionalDependencies: DependencyList = []) => {
  const emergencyDialog = useAppSelector(state => state.emergencyAction.emergencyDialog);
  const { selectedDevice } = useDevice();

  const isOff = emergencyDialog === 'off';

  const isLocked = !!selectedDevice?.emergencyButton?.locked;

  useEffect(() => {
    if (isOff && !isLocked) {
      const cleanup = effect();
      return cleanup;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, isOff, ...additionalDependencies]);
};
