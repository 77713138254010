import { ApiErrorMessage, Response as RestResponse } from 'types';

import { sessionExpired } from '../services/api';

import { getErrorMessage } from './getErrorMessage';
import { errorNotification } from './handleNotifications';

export const handleError = async (response: Response): Promise<void> => {
  if (!response) {
    errorNotification(getErrorMessage(''));
    return;
  }

  const status = response.status;
  let data: any;

  try {
    data = await response.json();
  } catch (ex) {
    data = null;
  }

  if ([401, 403].includes(status)) {
    let userLocked = false;
    if (data) {
      const { payload } = data as RestResponse<ApiErrorMessage>;
      const { formErrors } = payload;
      if (formErrors) {
        userLocked = formErrors['user'] === 'locked';
      }
    }
    userLocked ? null : sessionExpired();
  }

  if (!data) {
    errorNotification(getErrorMessage(''));
    return;
  }

  const { details, formErrors, errorCode } = data as ApiErrorMessage;

  if (formErrors) {
    const formErrorsArray = Array(formErrors);

    if (formErrorsArray.length) {
      formErrorsArray.forEach(item => {
        const keyName = Object.keys(item)[0];

        const errorName = `${keyName}.${item[keyName]}`;
        errorNotification(getErrorMessage(errorName));
      });

      return;
    }
  }

  if (details) {
    errorNotification(getErrorMessage(details));
    return;
  }
  errorNotification(getErrorMessage(errorCode));
};
