import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'config/store';
import { Timers } from 'config/timers';
import { ExerciseType } from 'libs/exo-session-manager/core';
import { Exercise, ExerciseState, StateSteps } from 'types/exercise';

const initialState: ExerciseState = {
  id: '',
  steps: [],
  stepIndex: 0,
  step: 'loading',
  type: null,
  settings: [],
  imageUrl: '',
  definition: {},
  electrodes: [],
  currentElectrodeIndex: 0,
  selectedChannelIndex: 0,
  exerciseStarted: false,
  exerciseFinished: false,
  exerciseUploaded: false,
};

export const timeOutElectrode = createAsyncThunk<number, number, { state: State }>(
  'exercise/touch-electrode',
  async (channelIndex: number, { getState }) => {
    return await new Promise(res => {
      const currentElectrode = getState().exercise.electrodes[channelIndex];
      if (currentElectrode.status !== ChannelConnectionQuality.NONE) {
        return res(channelIndex);
      }
      setTimeout(() => {
        return res(channelIndex);
      }, Timers.ELECTRODE_CONNECTION_TIMEOUT);
    });
  },
);

const setStepFunction = (state: ExerciseState, payload: StateSteps) => {
  if (payload === 'cable-disconnected' || payload === 'connect-electrodes') {
    state.stepIndex = 0;
  }

  state.step = payload;
  state.currentElectrodeIndex = 0;
};

const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    initializeSteps: (state, { payload }: PayloadAction<StateSteps[]>) => {
      state.steps = payload;
      if (payload[0]) {
        setStepFunction(state, payload[0]);
      }
    },

    nextStep: state => {
      if (state.steps[state.stepIndex + 1]) {
        setStepFunction(state, state.steps[++state.stepIndex]);
      }
    },

    prevStep: state => {
      if (state.steps[state.stepIndex - 1]) {
        setStepFunction(state, state.steps[--state.stepIndex]);
      }
    },

    markAsStarted: state => {
      state.exerciseStarted = true;
    },

    markAsFinished: state => {
      state.exerciseFinished = true;
    },

    markAsUploaded: state => {
      state.exerciseUploaded = true;
    },

    setNewExercise: (state, { payload }: PayloadAction<Exercise>) => {
      const { definitionOverride, programId } = payload;

      state.definition = definitionOverride;
      state.steps = [];
      state.currentElectrodeIndex = 0;
      state.selectedChannelIndex = 0;
      state.exerciseStarted = false;
      state.exerciseFinished = false;
      state.exerciseUploaded = false;
      state.step = 'loading';
      state.stepIndex = 0;

      state.id = programId;
    },

    setExerciseType: (state, { payload }: PayloadAction<ExerciseType | null | undefined>) => {
      state.type = payload ?? null;
    },

    updateElectrodes: (state, { payload }: PayloadAction<ExerciseState['electrodes']>) => {
      state.electrodes = payload;
      state.currentElectrodeIndex = 0;
    },
    setNextElectrode: (state, { payload }: PayloadAction<number>) => {
      state.currentElectrodeIndex = payload;
      state.electrodes[payload].touched = true;
    },
    updateElectrodeStatuses: (state, { payload }: PayloadAction<ChannelConnectionQuality[]>) => {
      state.electrodes.forEach(el => {
        const newStatus = payload[el.channelIndex];
        el.status = newStatus;

        if (newStatus === ChannelConnectionQuality.WELL || newStatus === ChannelConnectionQuality.POOR) {
          el.timedOut = true;
        }
      });

      const disconnectedChannelIndex = state.electrodes.findIndex(
        el => el.status === ChannelConnectionQuality.NONE && el.touched,
      );
      if (disconnectedChannelIndex >= 0) {
        state.currentElectrodeIndex = disconnectedChannelIndex;
      }
    },
    clearExercise: () => {
      return initialState;
    },
    setStep: (state, { payload }: PayloadAction<StateSteps>) => setStepFunction(state, payload),

    setSelectedChannelIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedChannelIndex = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(timeOutElectrode.fulfilled, (state, action) => {
      const updatedElectrodes = state.electrodes.map(el =>
        el.channelIndex === action.payload ? { ...el, timedOut: true } : el,
      );
      return {
        ...state,
        electrodes: updatedElectrodes,
      };
    });
  },
});

export const {
  initializeSteps,
  nextStep,
  prevStep,
  markAsStarted,
  markAsFinished,
  markAsUploaded,
  setNewExercise,
  updateElectrodes,
  setNextElectrode,
  clearExercise,
  updateElectrodeStatuses,
  setStep,
  setSelectedChannelIndex,
  setExerciseType,
} = exerciseSlice.actions;

export default exerciseSlice.reducer;
