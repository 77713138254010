import { BodyRegion, Exercise } from 'types';

export interface Training {
  id: string;
  name: string;
  description: string;
  imgUrl: string;
  parameters: {
    // time or time range [minTime, maxTime]
    duration: number | [number, number];
  };
  /*
    if not licensed (not active) not included in response
    should be used instead of exerciseIds
  */
  exercises?: Exercise[];
  userCreated: boolean;
  trainingType: {
    id: string;
    // ems, emg, emg-triggered, diagnostic, cpm-ems...
    name: string;
  };
  // patientType
  classificationPackages: {
    id: string;
    // NEURO, ORTHO, SPORT...
    name: string;
  }[];
  favorite: boolean;
  bodyRegions: BodyRegion[];
  // Is active if user has bought license containing this training
  active: boolean;
}

export const EMS_INSTANCES = 2;
