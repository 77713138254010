import { Box, BoxProps } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { Signal } from 'helpers/signal';
import { ChartDataSource } from 'libs/chart-datasources';

import { TimeLineChartOptions, useTimelineChart } from 'components/timeline-charts/hooks/useTimelineChart';

export function TimelineChart(
  props: {
    chartDataSource: ChartDataSource;
    time: Signal<number>;
    options: TimeLineChartOptions;
  } & BoxProps,
) {
  const {
    chartDataSource,
    time,
    options = {
      shiftDatasetsOnAxisY: false,
      windowWidth: 20,
    },
    ...args
  } = props;

  const { chartRef } = useTimelineChart(chartDataSource.timelines, time, options);
  useSignals();

  return <Box ref={chartRef} w="full" h="full" {...args} />;
}
