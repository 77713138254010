export type RESTResponse<T> = Omit<Response, 'json'> & { json(): Promise<T> };
export type RESTMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type RESTClientMethod = Extract<keyof RESTClient, 'get' | 'post' | 'put' | 'patch' | 'delete'>;
export type RESTClientFetcher = (
  method: RESTMethod,
  path: string,
  body: BodyInit | null,
  params?: URLSearchParams,
  options?: RequestInit,
) => Promise<RESTResponse<any>>;

export function serializeBody(body: unknown) {
  if (body === undefined) {
    return null;
  }

  if (body instanceof FormData) {
    return body;
  }

  if (body instanceof Blob) {
    return body;
  }

  if (body instanceof ArrayBuffer) {
    return body;
  }

  return JSON.stringify(body);
}

export class RESTClient {
  constructor(private readonly fetcher: RESTClientFetcher) {}

  get<T = unknown>(path: string, params?: URLSearchParams, options?: RequestInit): Promise<RESTResponse<T>> {
    return this.fetcher('GET', path, null, params, options);
  }

  post<T = unknown>(
    path: string,
    body?: unknown,
    params?: URLSearchParams,
    options?: RequestInit,
  ): Promise<RESTResponse<T>> {
    return this.fetcher('POST', path, serializeBody(body), params, options);
  }

  put<T = unknown>(
    path: string,
    body?: unknown,
    params?: URLSearchParams,
    options?: RequestInit,
  ): Promise<RESTResponse<T>> {
    return this.fetcher('PUT', path, serializeBody(body), params, options);
  }

  patch<T = unknown>(
    path: string,
    body?: unknown,
    params?: URLSearchParams,
    options?: RequestInit,
  ): Promise<RESTResponse<T>> {
    return this.fetcher('PATCH', path, serializeBody(body), params, options);
  }

  delete<T = unknown>(
    path: string,
    body?: unknown,
    params?: URLSearchParams,
    options?: RequestInit,
  ): Promise<RESTResponse<T>> {
    return this.fetcher('DELETE', path, serializeBody(body), params, options);
  }
}
