import { useDevice } from 'libs/exo-session-manager/react';

import { ModalProps } from './Modal';
import { SessionConnectionErrorModal } from './SessionConnectionErrorModal';
import { StellaBIOConnectionErrorModal } from './StellaBIOConnectionErrorModal';

export interface ConnectionErrorModalProps extends ModalProps {
  onContinue: () => void;
  onExit: (navigateTo: string) => void;
}

export const ConnectionErrorModal = (props: ConnectionErrorModalProps) => {
  const device = useDevice();

  if (device.deviceType === 'stella-bio') {
    return <StellaBIOConnectionErrorModal {...props} />;
  }

  return <SessionConnectionErrorModal {...props} />;
};
