import { useCallback, useEffect } from 'react';
import { ModalProps, ModalTypeDefinition } from 'containers/modals/Modal';
import { useAppDispatch } from 'hooks/store';
import { forceCloseModal, openModal, OpenModalPayload, updateModal } from 'slices/modalSlice';

/**
 * useModal hook should be use to prepare a Modal to display mainly when the properties for this modal
 * are dynamically changing. Mainly it adds a logic that update callbacks which can be invalidated from
 * changes in useCallback hook dependencies. In simple cases use {@link openModal} action in redux dispatch.
 * @param type Modal type that will be prepared
 * @param payload Data for the modal
 */

export function useModal<T extends ModalProps>(
  type: ModalTypeDefinition<T>,
  payload: Omit<OpenModalPayload<T>, 'type'>,
) {
  const dispatch = useAppDispatch();
  const open = useCallback(
    (data?: Partial<Required<typeof payload>['additionalData']>) => {
      dispatch(
        openModal({ type, ...payload, additionalData: { ...payload.additionalData, ...data } as Omit<T, 'close'> }),
      );
    },
    [dispatch, payload, type],
  );

  const close = useCallback(() => {
    dispatch(forceCloseModal(type.name));
  }, [dispatch, type]);

  const update = useCallback(
    (data: Partial<Required<typeof payload>['additionalData']>) => {
      dispatch(updateModal({ types: [type], data: data as Partial<T> }));
    },
    [dispatch, type],
  );

  useEffect(() => {
    update({
      id: payload.id,
      callback: payload.callback,
      secondCallback: payload.secondCallback,
      ...payload.additionalData,
    } as Partial<T>);

    // We want to observe every property in payload.additionalData not the whole object itself, because it will likely
    // change everytime.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.id, payload.callback, payload.secondCallback, update, ...Object.values(payload.additionalData ?? {})]);

  return [open, close, update] as const;
}
