import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { doesExtensionNeedRelief } from 'helpers/doesExtensionNeedRelief';
import { isInReliefRange } from 'helpers/isInReliefRange';
import { percentageFromRange } from 'helpers/percentageFromRange';
import { SettingsParameterId } from 'libs/exo-session-manager/core/settings/SettingsTemplates';
import { EMGProgramStep } from 'libs/exo-session-manager/core/types/GeneratedEmgProgramDefinition';
import { ConnectedChannelToMuscle } from 'types';
import { MuscleToChannelMap } from 'views/+patientId/training/+trainingId/_components/ChannelMuscleSelector';
import { findForceSources } from 'views/+patientId/training/+trainingId/_components/force-calibration/ForceCalibration';

import { getAlteredRangeForMotor } from '../common/alteredRangeForMotor';
import { CalibrationFlow } from '../common/CalibrationFlow';
import { SettingsBuilder } from '../settings/SettingsBuilder';
import { GeneratedExerciseDefinition, isEMSExerciseDefinition } from '../types/GeneratedExerciseDefinition';

import { ExtractStatesIdentifiersWithData, RangeFunctionsOptions, RangeFunctionsOptionsDefinition } from './types';

export type CalibrationFlowConditionKey = keyof typeof conditionalFunctions | `!${keyof typeof conditionalFunctions}`;

export const conditionalFunctions = {
  isRangeWithinDeviceRange: (flow: CalibrationFlow, options: RangeFunctionsOptionsDefinition) => {
    const { currentState } = flow;
    if (!currentState) return false;

    const range = flow.getStateData(currentState as ExtractStatesIdentifiersWithData<{ min: number; max: number }>);
    const { min, max } = getAlteredRangeForMotor(flow, options.motorName === 'knee' ? 'knee' : 'ankle');
    if (!range || typeof range.min !== 'number' || typeof range.max !== 'number') {
      return false;
    }
    if (range.min < min || range.min > max || range.max > max || range.max < min) {
      return false;
    }

    return true;
  },
  isRangeHasMinValue: (flow: CalibrationFlow, options: RangeFunctionsOptionsDefinition) => {
    if (typeof options.minValue === 'undefined') {
      return false;
    }

    const { currentState } = flow;

    if (!currentState) {
      return false;
    }

    const range = flow.getStateData(currentState as ExtractStatesIdentifiersWithData<{ min: number; max: number }>);

    if (!range || typeof range.min !== 'number' || typeof range.max !== 'number') {
      return false;
    }

    if (range.max - range.min >= options.minValue) {
      return true;
    }

    return false;
  },
  isInRange: (flow: CalibrationFlow, options: RangeFunctionsOptionsDefinition) => {
    const { currentState, context } = flow;
    if (!currentState) return false;

    const motor = context.motors[options.motorName];
    if (!motor) return false;

    const devicePosition = motor.value;
    const range = flow.getStateData(options.rangeProviderState);

    // if the movement range is not defined, current device postion
    // might be treated as acceptable
    if (!range || range.min === undefined || range.max === undefined) {
      return true;
    }

    if (devicePosition === undefined) {
      return false;
    }

    let tolerance = 2.5;

    if (typeof options.tolerance === 'number') {
      tolerance = options.tolerance;
    } else if (options.tolerance) {
      const calculatedTolerance = (range.max - range.min) * options.tolerance.portion * 0.5;
      tolerance = Math.max(options.tolerance.min, calculatedTolerance);
    }

    return devicePosition >= range.min - tolerance && devicePosition <= range.max + tolerance;
  },

  isInMiddleRange: (flow: CalibrationFlow, options: RangeFunctionsOptions) => {
    const { currentState, context } = flow;
    if (!currentState) return false;

    const conditionParam = {
      ...(flow.definition.states[currentState]?.conditionParam as RangeFunctionsOptionsDefinition),
      ...options,
    };

    const motor = context.motors[conditionParam.motorName];
    if (!motor) return false;

    const devicePosition = motor.value;
    const range = flow.getStateData(conditionParam.rangeProviderState);

    if (devicePosition === undefined || !range || typeof range.min !== 'number' || typeof range.max !== 'number') {
      return false;
    }

    let tolerance = 2.5;

    if (typeof conditionParam.tolerance === 'number') {
      tolerance = conditionParam.tolerance;
    } else if (conditionParam.tolerance) {
      const calculatedTolerance = (range.max - range.min) * conditionParam.tolerance.portion * 0.5;
      tolerance = Math.max(conditionParam.tolerance.min, calculatedTolerance);
    }

    const middleRange = (range.max - range.min) / 2 + range.min;
    const minAcceptedRange = middleRange - tolerance;
    const maxAcceptedRange = middleRange + tolerance;

    return devicePosition >= minAcceptedRange && devicePosition <= maxAcceptedRange;
  },
  isInBeginningRange: (flow: CalibrationFlow, options: RangeFunctionsOptions) => {
    const { currentState, context } = flow;
    if (!currentState) return false;

    const conditionParam = {
      ...(flow.definition.states[currentState]?.conditionParam as RangeFunctionsOptionsDefinition),
      ...options,
    };

    const motor = context.motors[conditionParam.motorName];
    if (!motor) return false;

    const devicePosition = motor.value;

    const range = flow.getStateData(conditionParam.rangeProviderState);
    if (devicePosition === undefined || !range || typeof range.min !== 'number' || typeof range.max !== 'number') {
      return false;
    }
    const offset = percentageFromRange(range.min, range.max, 3);

    return devicePosition <= range.min + offset;
  },
  isInEndRange: (flow: CalibrationFlow, options: RangeFunctionsOptionsDefinition) => {
    const { currentState, context } = flow;
    if (!currentState) return false;

    const conditionParam = {
      ...(flow.definition.states[currentState]?.conditionParam as RangeFunctionsOptionsDefinition),
      ...options,
    };

    const motor = context.motors[conditionParam.motorName];
    if (!motor) return false;

    const devicePosition = motor.value;
    const range = flow.getStateData(conditionParam.rangeProviderState);
    if (devicePosition === undefined || !range || typeof range.max !== 'number' || typeof range.min !== 'number') {
      return false;
    }
    const offset = percentageFromRange(range.min, range.max, 3);

    return devicePosition >= range.max - offset;
  },
  skipRelief: (flow: CalibrationFlow) => {
    const { currentState, context } = flow;
    const verticalAngle = context.sensorAngleData.value?.verticalAngle;
    if (verticalAngle === 90) {
      // If verticalAngle is 90 then we skip conditional screen by return true
      return true;
    }
    const extensionName = context.extension.value?.type;

    if (!extensionName) {
      return false;
    }

    const needRelief = doesExtensionNeedRelief(extensionName).isRelief;

    if (!needRelief) {
      return true;
    }
    if (!currentState) return false;

    const motor = context.motors['main'];
    if (!motor) return false;

    const devicePosition = motor.value;
    return !isInReliefRange(devicePosition);
  },
  skipTare: (flow: CalibrationFlow) => {
    if (flow.context.meissaWasTared.value) {
      return true;
    }
    return flow.getStateData('meissa-extension-unweight')?.limbUnweighted ?? false;
  },
  isInReliefCorrectPosition: (flow: CalibrationFlow) => {
    const verticalAngle = flow.context.sensorAngleData.value?.verticalAngle;
    if (verticalAngle === 90) {
      return true;
    }
    const extensionName = flow.context.extension.value?.type;
    if (!extensionName) {
      return false;
    }
    const isReliefRequire = doesExtensionNeedRelief(extensionName);
    if (!isReliefRequire.isRelief) {
      // Skip this screen when extension doesn't need relief
      return true;
    }
    // This is needed for show every time screen with this condition but after showing the screen we want to validate by this function isInReliefRange
    const startConditionalFnValidation = flow.getStateData(
      'meissa-relief-correct-position',
    )?.startConditionalFnValidation;
    if (!startConditionalFnValidation) {
      return false;
    }
    const { currentState, context } = flow;
    if (!currentState) return false;

    const motor = context.motors['main'];
    if (!motor) return false;

    const devicePosition = motor.value;
    return isInReliefRange(devicePosition);
  },
  verifyRepetitionsAndTime: (flow: CalibrationFlow) => {
    const state = flow.getStateData('basing-settings');

    const exerciseParameters = state?.exerciseParameters as Partial<Record<SettingsParameterId, number | string>>;

    if (
      typeof exerciseParameters['cpm.0.time'] === 'number' &&
      typeof exerciseParameters['cpm.0.repetitions'] === 'number'
    ) {
      return false;
    }
    if (
      typeof exerciseParameters['cpm.0.time'] === 'number' ||
      typeof exerciseParameters['cpm.0.repetitions'] === 'number'
    ) {
      return true;
    }
    return false;
  },
  isDeviceDisconnected: (flow: CalibrationFlow) => {
    return ['disconnected', 'connected-idle', 'disposed', 'connecting'].includes(
      flow.context.connectionStatus.value ?? '',
    );
  },
  isCableAttached: (flow: CalibrationFlow) => {
    return flow.context.cableStatus.value === 'attached';
  },
  isCableDetached: (flow: CalibrationFlow) => {
    return flow.context.cableStatus.value === 'detached';
  },

  isProgramLengthGreaterThanZero: (flow: CalibrationFlow) => {
    const emgSteps = flow.getStateData('emg-steps')?.steps as EMGProgramStep[];
    const totalTime = emgSteps.reduce(
      (acc, cur) => acc + cur.initialRelaxation + (cur.workTime + cur.restTime) * cur.repetitions,
      0,
    );
    return totalTime > 0;
  },
  isCorrectExtensionAttached: (flow: CalibrationFlow) => {
    const isAnyExtensionConnected = (flow.context.extension.value?.type ?? 'none') !== 'none';

    const exerciseName = (
      flow.getStateData('basing-settings')?.definition as {
        type: string;
      }
    ).type;

    if (exerciseName === 'cam-turn-key') {
      const extensionFeatures = flow.context.extension.value?.features ?? [];

      return ['force-sensor'].every(i => extensionFeatures.includes(i));
    }

    const forceSources = findForceSources(
      flow.getStateData('basing-settings')?.definition as GeneratedExerciseDefinition,
    );

    const isWrongExtension =
      forceSources.find(v => v.name === 'extension') &&
      !flow.context.extension.value?.features.includes('force-sensor');

    return isAnyExtensionConnected && !isWrongExtension;
  },
  detachExtensionIfAttached: (flow: CalibrationFlow) => {
    if (flow.context.meissaWasTared.value) {
      // When meissa was tared we skip this state
      return true;
    }
    const isAnyExtensionConnected = (flow.context.extension.value?.type ?? 'none') !== 'none';
    if (flow.getStateData('meissa-extension-unweight')?.limbUnweighted) {
      // When meissa was tared we skip this state
      return true;
    }
    return !isAnyExtensionConnected;
  },
  areElectrodesConnected: (flow: CalibrationFlow) => {
    const channelsConnectionQuality = flow.context.channelsConnectionQuality.value;
    const connectedChannelsToMuscles = flow.getStateData('connect-electrodes')?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;
    if (connectedChannelsToMuscles && channelsConnectionQuality) {
      const connectedChannels = connectedChannelsToMuscles.filter(
        connectedChannel => channelsConnectionQuality[connectedChannel.channelIndex] !== ChannelConnectionQuality.NONE,
      );
      if (connectedChannels.length > 0) {
        return true;
      }
    }
    return false;
  },
  areElectrodesDisconnected: (flow: CalibrationFlow) => {
    const channelsConnectionQuality = flow.context.channelsConnectionQuality.value;
    const connectedChannelsToMuscles = flow.getStateData('connect-electrodes')?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;
    if (connectedChannelsToMuscles && channelsConnectionQuality) {
      const disconnectedChannels = connectedChannelsToMuscles.filter(
        connectedChannel => channelsConnectionQuality[connectedChannel.channelIndex] === ChannelConnectionQuality.NONE,
      );
      if (disconnectedChannels.length > 0) {
        return true;
      }
    }
    return false;
  },
  isMinRequiredChannelSelected: (flow: CalibrationFlow) => {
    const definition = isEMSExerciseDefinition(flow.context.selectedProgram) ? flow.context.selectedProgram : null;
    if (!definition) {
      return true;
    }
    const channelsSelected = flow.stateData?.['channel-muscle-selector']?.muscleToChannel as
      | MuscleToChannelMap
      | undefined;

    if (!channelsSelected || !definition.ems.program.minRequiredChannels) {
      return false;
    }

    return Object.values(channelsSelected).length >= definition.ems.program.minRequiredChannels;
  },
  skipParametersScreen: (flow: CalibrationFlow) => {
    const definition = flow.context.selectedProgram;
    const settings = new SettingsBuilder(definition);
    return !settings.hasDefinitionParameters;
  },
};
